import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Checkbox
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
    selectedUsers: Yup.array().min(1, "Select at least one option"),
});

const ShareNotesDialog = ({ handleClose, handleSubmit, users, noteId }) => {

    return (
        <div>

            <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Notes share with users</DialogTitle>
                <Formik
                    initialValues={{
                        selectedUsers: [],
                        noteId : noteId
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <DialogContent>
                                <Autocomplete
                                    multiple
                                    options={users || []}
                                    getOptionLabel={(option) => option.fullName}
                                    value={values.selectedUsers}
                                    onChange={(event, newValue) =>
                                        setFieldValue("selectedUsers", newValue)
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option._id === value._id
                                    }
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                checked={selected}
                                                style={{ marginRight: 8 }}
                                                size="small"
                                            />
                                            {option.fullName}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Options"
                                            error={touched.selectedUsers && !!errors.selectedUsers}
                                            helperText={
                                                touched.selectedUsers && errors.selectedUsers
                                            }
                                        />
                                    )}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button type="submit" variant="contained" color="primary">
                                    Share
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ShareNotesDialog;
