/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Grid, CircularProgress, useMediaQuery } from "@mui/material";
import update from "immutability-helper";
import _, { uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import Page from "../components/Page";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import Card from "../components/dashboard/card/Card";
import CommunityTransferCardDetailsDialog from "../components/dashboard/community-transfer/CommunityTransferCardDetailsDialog";
import CommunityTransferHeader from "../components/dashboard/community-transfer/CommunityTransferHeader";
import SafeDischargeAssLivingSlider from "../components/dashboard/community-transfer/safeDischargeAssLivingSlider/SafeDischargeAssLivingSlider";
import SelectableCardCoTransfer from "../components/dashboard/community-transfer/selectableCardCoTransfer/SelectableCardCoTransfer";
import SixtyDaysAnalysis from "../components/dashboard/community-transfer/sixtyDaysAnalysis/SixtyDaysAnalysis";
import SNFFacilitySlider from "../components/dashboard/community-transfer/snfFacilitySlider/SNFFacilitySlider";
import TotalCoTransferCard from "../components/dashboard/community-transfer/totalCoTransferCard/TotalCoTransferCard";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import ReturnCardList from "../components/dashboard/hospital/returnCardList/ReturnCardList";
import TotalCountCircle from "../components/dashboard/hospital/totalCountCircle/TotalCountCircle";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import { getAllCommunicationPatient } from "../services/community-transfer.service";
import {
	DEFAULT_COMMUNITY_DATA,
	openDetailsDialog,
	setCardFilterCommunity,
	setDynamicCards,
	setIsSpecialComparison,
	setLockTotal,
	setLockedTotalBy,
	setMainTotal,
	setResetFilter,
	setTransferType,
	setLoading as setCommunityLoading
} from "../store/reducers/community-transfer.slice";
import {
	openDetailsDialog as openDetailsDialogComparison,
	setCardFilterCommunity as setCardFilterComparison,
	setFilterListDataCommunity as setFilterListDataComparison,
	setMainTotal as setMainTotalComparison,
	setPatientList as setPatientListComparison,
	setTransferType as setTransferTypeComparison,
} from "../store/reducers/comparisonReducers/communityTransferComparison.slice";
import { setCardExpandState } from "../store/reducers/dataCardsExpand.slice";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { CO_TRANSFER_CARDS_TYPE, COMMUNITY_CARD_LABELS, TRANSFER_TYPE } from "../types/community-transfer.type";
import { PAGE_TYPE } from "../types/pages.type";
import {
	calcProPercentsBasedOnFilterAndDays,
	getDynamicPercentageBy,
	itemPercentage,
	priorityFilter,
	projectionPerMonth,
	updateChartFilterByPriority,
	updateCurrentFilterListData,
} from "../utilis/common";
import { updateFilterListData } from "../utilis/community-common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import tooltipsInfo from "../utilis/tooltipContents";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import { setCommunityTransferCards } from "../store/reducers/customCard.slice";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import { COMMUNITY_CUSTOM_CARD } from "../types/custom-card-type";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";
import NoteContainer from "../components/shared/NoteContainer";
import DynamicDataCardList from "../components/dashboard/hospital/DynamicDataCardList/DynamicDataCardList";
import { getQuestionsData } from "../services/dynamic-data-tab.service";
import { ADT_SUB_TYPES } from "../types/common.type";
import useAdditionalData from "../components/hooks/useAdditionalData";
import { useDashboard } from "../context/DashboardContext";

const DEFAULT_LIST = {
	sixtyDaysData: [],
	list: [],
};

const CommunityTransferPage = (props) => {
	const dispatch = useDispatch();
	const isMd = useMediaQuery("(max-width:1280px)");
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const { saveCustomCardFilter } = useCustomCardFilter();
	const [patientList, setPatientList] = useState(DEFAULT_LIST);
	const [filterListData, setFilterListData] = useState(DEFAULT_COMMUNITY_DATA);
	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const { rangesSet, comparingAgainstDifferentFacility } = useSelector((state) => state.comparison);
	const { communityTransferCards } = useSelector((state) => state.customCard);
	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		cardFilter: cardFilterComparison,
		transferType: transferTypeComparison,
		patientList: patientListComparison,
		filterTotal: filterTotalComparison,
		facilityPercentage: facilityPercentageComparison,
		lockedTotal: lockedTotalComparison,
		lockedByFacility: lockedByFacilityComparison,
		isCensusTotalLocked: isCensusTotalLockedComparison,
	} = useSelector((state) => state.communityTransferComparison);
	const {
		filter,
		dbData,
		transferType,
		cardFilter,
		detailsDialog,
		isResetFilter,
		lockedTotal,
		facilityPercentage,
		lockedByFacility,
		isCensusTotalLocked,
		specialComparisonSet,
		filterTotal,
		lockedTotalBy,
		loading: communityLoading,
		dynamicCards
	} = useSelector((state) => state.communityTransfer);
	const { percentageBy } = useSelector((state) => state.common);
	const COMMUNITY_CUSTOM_CARD_DATA = useAdditionalData(dynamicCards, COMMUNITY_CUSTOM_CARD);
	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);

	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");

	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(false);
	const activeRequestRef = useRef(null);

	const getDynamicTab = useCallback(async (page) => {
		let query = { page, forType: 'transfer', forTransferType: [ADT_SUB_TYPES.AMA, ADT_SUB_TYPES.SAFE_DISCHARGE, ADT_SUB_TYPES.SNF], isCustom: true };
		const res = await getQuestionsData(query);
		if (res && res.length > 0) {
			dispatch(setDynamicCards(res));
		}
	}, [dispatch]); // Add dispatch to the dependency array

	const { updateDashboard } = useDashboard();

	useEffect(() => {
		if (dbData) {
			updateDashboard({ dbData, page: PAGE_TYPE.COMMUNITY_TRANSFER, dbDataComparison });
		}
	}, [dbData, updateDashboard, dbDataComparison]);

	useEffect(() => {
		if (dynamicCards?.length === 0) {
			getDynamicTab(PAGE_TYPE.HOSPITAL);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dynamicCards]);

	useEffect(() => {
		async function fetchMyAPI(filter) {
			batch(() => {
				dispatch(setLockTotal(null));
				dispatch(setLockedTotalBy(null));
			});
			await getAllDataCount(filter);
		}
		if (isDateUpdated && filter && filter.startDate && activeFacilityLocalStorage && activeFacilities.length > 0) {
			fetchMyAPI({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		activeFacilityLocalStorage,
		filter,
	]);

	useEffect(() => {

		async function fetchMyAPI(filter) {
			batch(() => {
				dispatch(setLockTotal(null));
				dispatch(setLockedTotalBy(null));
			});
			const filterData = _.omit(filter, ["defaultComparison"]);
			await getAllDataCount(filterData, true);
		}
		if (isDateUpdated && filter && filter.startDate && activeFacilityLocalStorage && activeFacilities.length > 0) {
			fetchMyAPI({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterComparison]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lockedTotal, projectionDays, percentageAgainst]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferType, projectionDays]);

	useEffect(() => {
		if (isResetFilter) {
			updateTotalToDBFilter(patientList);
			dispatch(setResetFilter(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilter]);

	const fetchRequestRef = useRef(null);
	const fetchRequestRefComparison = useRef(null);

	const getAllDataCount = async (filter, forComparison) => {
		setFilterListData({ ...DEFAULT_COMMUNITY_DATA });
		setPatientList([]);
		setLoading(true);
		const currentRequest = uniqueId();
		if (forComparison) {
			fetchRequestRefComparison.current = currentRequest;
		} else {
			fetchRequestRef.current = currentRequest;
		}
		activeRequestRef.current = currentRequest;
		// const filterData = _.omit(filter, ["rangeFilter"]);

		try {
			const [mainResult, comparisonResult] = await Promise.all([
				getAllCommunicationPatient(filter, false, 'main'),
				rangesSet ? getAllCommunicationPatient({ ...filterComparison, facilityIds: activeFacilitiesComparison }, true, 'comparison') : null
			]);

			if (activeRequestRef.current !== currentRequest) return;

			if (mainResult && mainResult.list) {
				setPatientList(mainResult);
				await updateTotalToDBFilter(mainResult);
			}

			if (comparisonResult && comparisonResult.list) {
				dispatch(setPatientListComparison(comparisonResult));
				await updateTotalToDBFilterComparison(comparisonResult);
			} else {
				dispatch(setPatientListComparison([]));
			}

		} catch (e) {
			if (activeRequestRef.current !== currentRequest) return;
			if (e?.message !== "canceled") {
				setLoading(false);

				if (communityLoading) {
					dispatch(setCommunityLoading(false));
				}
			}
		} finally {
			if (activeRequestRef.current === currentRequest) {
				setLoading(false);

				if (communityLoading) {
					dispatch(setCommunityLoading(false));
				}
			}
		}
	};

	const updateTotalToDBFilter = async (res) => {
		const latestListData = await updateFilterListData(cardFilter, res, cardFilter.priorityData, transferType, false);
		setFilterListData((prevState) => ({
			...prevState,
			...latestListData,
		}));
	};

	const updateTotalToDBFilterComparison = async (res) => {
		const latestListData = await updateFilterListData(
			cardFilterComparison,
			res,
			cardFilterComparison?.priorityData,
			transferType,
			true
		);
		dispatch(setFilterListDataComparison({ ...filterListDataComparison, ...latestListData }));
	};

	const handleToggle = useCallback(
		async ({
			item,
			type: typeData,
			itemComparison = null,
			setSpecialNumberFlag = true,
			isChecked,
			slidData = [],
			cardTitle = null,
			question,
			isSpacialItem = false
		}) => {
			if (item) {
				let type = typeData;
				if (isSpacialItem) {
					type = `${type}_spacial`;
				}
				const value = item._id;
				const patientIds = item?.patientIds ?? [];
				const filtersItem = { ...cardFilter };
				if (filtersItem[type] === undefined) {
					filtersItem[type] = [];
				}
				const filterData = filtersItem[type];
				const currentIndex = filterData.indexOf(value);
				let newChecked = filterData ? [...filterData] : [];
				let isRemoveIds = false

				if (type === "safeDischargeAssLivData") {
					if (item._id === "all") {
						if (filterData.indexOf("all") !== -1) {
							//newChecked.splice(filterData.indexOf("all"), 1);
							isRemoveIds = question?.isCustom ? true : false;
							newChecked = [];
						} else {
							let ids = _.map(slidData, "_id");
							newChecked = [...ids, "all"];
						}
					} else {
						if (filterData.indexOf("all") !== -1) {
							isRemoveIds = question?.isCustom ? true : false;
							newChecked.splice(filterData.indexOf("all"), 1);
						}
						if (currentIndex === -1) {
							newChecked.push(value);
						} else {
							isRemoveIds = question?.isCustom ? true : false;
							newChecked.splice(currentIndex, 1);
						}
					}
				} else {
					if (currentIndex === -1) {
						newChecked.push(value);
					} else {
						isRemoveIds = question?.isCustom ? true : false;
						newChecked.splice(currentIndex, 1);
					}
				}
				if (!isSpacialItem) {
					const [priorityData, priorityDataComparison] = await Promise.all([
						priorityFilter(
							cardFilter.priorityData,
							type,
							newChecked.length,
							cardTitle,
							question,
							patientIds,
							isRemoveIds
						),
						rangesSet
							? priorityFilter(
								cardFilterComparison?.priorityData,
								type,
								newChecked.length,
								cardTitle,
								question,
								patientIds,
								isRemoveIds
							)
							: undefined,
					]);
					batch(() => {
						dispatch(setCardFilterCommunity({ [type]: newChecked, priorityData: priorityData }));
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));

						if (specialComparisonSet && !setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(false));
						} else if (!specialComparisonSet && !setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(true));
						}
					});
					const [latestListData, latestListDataComparison] = await Promise.all([
						updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, transferType, false),
						rangesSet
							? updateFilterListData(
								{ ...cardFilterComparison, [type]: newChecked },
								patientListComparison,
								priorityDataComparison,
								transferTypeComparison,
								true
							)
							: undefined,
					]);
					setFilterListData((prevState) => ({
						...prevState,
						...latestListData,
					}));

					if (rangesSet) {
						const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
						dispatch(setFilterListDataComparison(updatedFilterListData));
					}
				} else {
					batch(() => {
						const updatedFilter = { [type]: newChecked };

						dispatch(setCardFilterCommunity(updatedFilter));

						if (rangesSet) {
							dispatch(setCardFilterComparison(updatedFilter));
						}

						if (!setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(!specialComparisonSet));
						}
					});
				}
			}
		},
		[
			cardFilter,
			rangesSet,
			cardFilterComparison,
			dispatch,
			specialComparisonSet,
			patientList,
			transferType,
			patientListComparison,
			transferTypeComparison,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [priorityData, priorityDataComparison] = await Promise.all([
			priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
			rangesSet ? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle) : undefined,
		]);
		dispatch(setCardFilterCommunity({ [type]: newChecked, priorityData: priorityData }));
		rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));

		const [latestListData, latestListDataComparison] = await Promise.all([
			updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, transferType, false),
			rangesSet
				? updateFilterListData(
					{ ...cardFilterComparison, [type]: newChecked },
					patientListComparison,
					priorityDataComparison,
					transferTypeComparison,
					true
				)
				: undefined,
		]);
		setFilterListData((prevState) => ({
			...prevState,
			...latestListData,
		}));

		if (rangesSet) {
			const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
			dispatch(setFilterListDataComparison(updatedFilterListData));
		}
	};

	const handleClickTotal = useCallback(
		(total) => {
			dispatch(setTransferType(_.includes(transferType, TRANSFER_TYPE.ALL) ? [] : [TRANSFER_TYPE.ALL]));
			dispatch(setMainTotal(total));
		},
		[dispatch, transferType]
	);

	const handleClickCardTransferType = useCallback(
		(checked, type) => {
			if (type) {
				const totalSelected = transferType.length || 0;
				let newArray = [];
				if (totalSelected >= 2 && checked) {
					newArray = update(transferType, {
						$splice: [[totalSelected - 1, 1]],
					});
					newArray = update(newArray, { $push: [type] });
				} else {
					if (transferType.includes(type)) {
						newArray = update(transferType, {
							$splice: [[transferType.indexOf(type), 1]],
						});
					} else {
						newArray = update(transferType, { $push: [type] });
					}
				}
				const index = newArray.indexOf(TRANSFER_TYPE.ALL);
				if (index > -1) {
					newArray.splice(index, 1);
				}
				dispatch(setTransferType(newArray));
				dispatch(setTransferTypeComparison(newArray));
				let totalUpdated = 0;
				let totalUpdatedComparison = 0;
				if (newArray.length > 0) {
					newArray.map((ele) => {
						if (ele === "safeDischarge") {
							totalUpdated = totalUpdated + dbData.totalSafeDischarge || 0;
							totalUpdatedComparison = totalUpdated + dbDataComparison.totalSafeDischarge || 0;
						} else {
							totalUpdated = totalUpdated + dbData["total" + ele] || 0;
							totalUpdatedComparison = totalUpdatedComparison + dbDataComparison["total" + ele] || 0;
						}
					});
					dispatch(setMainTotal(totalUpdated));
					dispatch(setMainTotalComparison(totalUpdatedComparison));
				} else {
					dispatch(setMainTotal(dbData.total));
					dispatch(setMainTotalComparison(dbDataComparison?.total));
				}
			}
		},
		[transferType, dispatch, dbData, dbDataComparison]
	);

	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false, defaultTab = null, question = null) => {
			let latestCardFilter = await updateChartFilterByPriority(cardFilter, type);
			let filterSelected =
				(cardFilter.priorityData.length > 0 && cardFilter.priorityData[0]["type"] !== type) ||
				transferType.length > 0;
			let data = [];
			if (
				type === CO_TRANSFER_CARDS_TYPE.TOTAL ||
				type === CO_TRANSFER_CARDS_TYPE.AMA ||
				type === CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE ||
				type === CO_TRANSFER_CARDS_TYPE.SNF
			) {
				data = [];
				filterSelected = false;
			} else {
				data = filterListData[type];
			}
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				type,
				selectedFilterData: cardFilter[type] || [],
				filter,
				relation,
				transferType,
				filterSelected,
				facilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				filterListData,
				defaultTab,
				question
			};

			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
				})
			);

			// COMPARISON ANALOG ------>
			let latestCardFilterComparison = await updateChartFilterByPriority(cardFilterComparison, type);
			let filterSelectedComparison =
				(cardFilterComparison?.priorityData.length > 0 &&
					cardFilterComparison?.priorityData[0]["type"] !== type) ||
				transferType != null;
			let dataComparison = [];
			if (
				type === CO_TRANSFER_CARDS_TYPE.UNPLANNED ||
				type === CO_TRANSFER_CARDS_TYPE.PLANNED ||
				type === CO_TRANSFER_CARDS_TYPE.TOTAL
			) {
				filterSelectedComparison = false;
				dataComparison = [];
			} else {
				dataComparison = filterListDataComparison[type];
			}
			const filtersComparison = {
				facilityId: activeFacilitiesComparison[0],
				facilityIds: activeFacilitiesComparison,
				cardFilter: latestCardFilterComparison,
				selectedFilterData: cardFilterComparison?.[type] || [],
				type,
				filter: filterComparison,
				relation,
				transferType: transferTypeComparison,
				filterSelected: filterSelectedComparison,
				facilityPercentage: facilityPercentageComparison,
				lockedByFacility: lockedByFacilityComparison,
				lockedTotal: lockedTotalComparison,
				isCensusTotalLocked: isCensusTotalLockedComparison,
			};
			dispatch(
				openDetailsDialogComparison({
					isOpen: true,
					title,
					type,
					data: dataComparison,
					filters: filtersComparison,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			filterListData,
			cardFilter,
			filter,
			filterListDataComparison,
			cardFilterComparison,
			filterComparison,
			filterListDataComparison,
		]
	);

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.priorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const handleOnClickReport = async (type, title, isTotalCard = false, automaticallyReport = null, customData = null) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				isTotalCard,
				automaticallyReport: { ...automaticallyReport, ...customData },
				transferTypes: transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				isTotalCard,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferTypes: transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				graphData: { ...graphDataUpdated, detailsDialog },
				tableFilterType: graphData?.tableFilterType,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				isTotalCard: false,
				graphData,
				isGraphReport: true,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...communityTransferCards];
		if (key === "selectAll") {
			cards = _.map(COMMUNITY_CUSTOM_CARD_DATA, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setCommunityTransferCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.COMMUNITY_TRANSFER, cards })
	}, [COMMUNITY_CUSTOM_CARD_DATA, communityTransferCards, dispatch, saveCustomCardFilter]);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	const handleDisableCustomCard = useCallback((ele) => {
		if (transferType.length === 0) {
			return false;
		} else {
			return !_.includes(transferType, ele?.forTransferType) ? true : false;
		}

	}, [transferType]);

	return (
		<Page title="Dashboard: Community Transfer">
			{detailsDialog.isOpen && (
				<CommunityTransferCardDetailsDialog
					handleOnClickReport={handleOnClickReportGraph}
					handleGenerateExcelReport={downloadExcel}
				/>
			)}
			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.aoWrapper}`}>
						<CommunityTransferHeader
							filterListData={filterListData}
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							dynamicCards={dynamicCards}
						/>
					</div>
				</div>
				{(loading || communityLoading) ?
					<div style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "90vh"
					}}>
						<CircularProgress />
					</div> : (
						<NoteContainer page={PAGE_TYPE.COMMUNITY_TRANSFER}>
							<Grid container justifyContent={"center"} sx={{ mt: "30px" }}>
								<Grid item xs={12} position={"relative"}>
									{rangesSet && <ColorInfoBox style={{ zIndex: 100, right: "1%" }} loading={loading || loadingComparison} />}
									<AddCardDrawer
										listData={COMMUNITY_CUSTOM_CARD_DATA}
										selectedCards={communityTransferCards}
										handleAddCustomCard={handleAddCustomCard}
										scrollTop={scrollPosition?.scrollTop}
									/>
									<TotalCountCircle
										loading={loading || loadingComparison}
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										totalTitle="Total Community Transfers"
										projectTitle={percentageBy}
										selected={_.includes(transferType, TRANSFER_TYPE.ALL)}
										handleClickTotal={() =>
											handleClickTotal(calcProPercentsBasedOnFilterAndDays(dbData.total, filter, projectionDays))
										}
										total={dbData.total}
										totalComparison={dbDataComparison?.total}
										filter={filter}
										filterComparison={filterComparison}
										totalPercentage={itemPercentage(dbData.total, percentageAgainst, "number")}
										totalComparisonPercentage={itemPercentage(
											dbDataComparison?.total,
											dbDataComparison?.censusAverage,
											"number"
										)}
										projectTotal={projectionPerMonth(dbData.total, filter, projectionDays)}
										projectTotalComparison={projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays)}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.total, filter, projectionDays),
											percentageAgainst,
											"number"
										)}
										totalProjectionComparisonPercentage={itemPercentage(
											projectionPerMonth(dbDataComparison?.total, filterComparison, true),
											dbDataComparison?.censusAverage,
											"number"
										)}
										tooltipContent={tooltipsInfo.communityTrasnferPage.totalCommunityTransfers}
										handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.TOTAL, null, true, defaultTab)}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(CO_TRANSFER_CARDS_TYPE.TOTAL, title, true, otherData)
										}
										projectionDays={projectionDays}
										totalCardType={"total"}
									/>
								</Grid>
							</Grid>

							<Box sx={{ p: 2 }}>
								<Grid container spacing={2} direction={"row"} sx={{ mb: "80px" }}>
									<Grid item md={4} xs={12}>
										<SelectableCardCoTransfer
											pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
											title="Safe Discharges"
											loading={loading || loadingComparison}
											selected={_.includes(transferType, TRANSFER_TYPE.SAFE_DISCHARGE)}
											handleOnclickCard={(e) => handleClickCardTransferType(e, TRANSFER_TYPE.SAFE_DISCHARGE)}
											tooltipContent={tooltipsInfo.communityTrasnferPage.safeDischarges}
											handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE, null, true, defaultTab)}
											handleOnClickReport={(title, otherData) =>
												handleOnClickReport("totalSafeDischarge", title, true, otherData)
											}
										>
											<TotalCoTransferCard
												loading={loading || loadingComparison}
												selected={_.includes(transferType, TRANSFER_TYPE.SAFE_DISCHARGE)}
												type={TRANSFER_TYPE.SAFE_DISCHARGE}
												handleSelectCard={handleClickCardTransferType}
												page={PAGE_TYPE.COMMUNITY_TRANSFER}
												title={"Total Safe Discharges"}
												dbData={dbData}
												dbDataComparison={dbDataComparison}
												total={dbData.totalSafeDischarge}
												totalComparison={dbDataComparison?.totalSafeDischarge}
												totalComparisonPercentage={itemPercentage(
													dbDataComparison?.totalSafeDischarge,
													dbDataComparison?.censusAverage,
													"number"
												)}
												filter={filter}
												filterComparison={filterComparison}
												totalPercentage={itemPercentage(dbData.totalSafeDischarge, percentageAgainst, "number")}
												totalProjection={projectionPerMonth(dbData.totalSafeDischarge, filter, projectionDays)}
												totalProjectionComparison={projectionPerMonth(
													dbDataComparison?.totalSafeDischarge,
													filterComparison,
													projectionDays
												)}
												totalProjectionPercentage={itemPercentage(
													projectionPerMonth(dbData.totalSafeDischarge, filter, projectionDays),
													percentageAgainst,
													"number"
												)}
												totalProjectionComparisonPercentage={itemPercentage(
													projectionPerMonth(dbDataComparison?.totalSafeDischarge, filterComparison),
													dbDataComparison?.censusAverage,
													"number"
												)}
												secondaryTitle={percentageBy}
												secondaryText={percentageBy}
												projectionDays={projectionDays}
											/>
										</SelectableCardCoTransfer>
									</Grid>
									<Grid item md={4} xs={12}>
										<SelectableCardCoTransfer
											pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
											flex="1"
											loading={loading || loadingComparison}
											title="SNF Transfers"
											selected={_.includes(transferType, TRANSFER_TYPE.SNF)}
											handleOnclickCard={(e) => handleClickCardTransferType(e, TRANSFER_TYPE.SNF)}
											tooltipContent={tooltipsInfo.communityTrasnferPage.snf}
											handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SNF, null, true, defaultTab)}
											handleOnClickReport={(title, otherData) => handleOnClickReport("totalSNF", title, true, otherData)}
										>
											<TotalCoTransferCard
												loading={loading || loadingComparison}
												selected={_.includes(transferType, TRANSFER_TYPE.SNF)}
												type={TRANSFER_TYPE.SNF}
												handleSelectCard={handleClickCardTransferType}
												page={PAGE_TYPE.COMMUNITY_TRANSFER}
												title={"Total SNF Transfers"}
												total={dbData.totalSNF}
												totalComparison={dbDataComparison?.totalSNF}
												filter={filter}
												filterComparison={filterComparison}
												totalComparisonPercentage={itemPercentage(
													dbDataComparison?.totalSNF,
													dbDataComparison?.censusAverage,
													"number"
												)}
												totalPercentage={itemPercentage(dbData.totalSNF, percentageAgainst, "number")}
												totalProjection={projectionPerMonth(dbData.totalSNF, filter, projectionDays)}
												totalProjectionComparison={projectionPerMonth(
													dbDataComparison?.totalSNF,
													filterComparison,
													projectionDays
												)}
												totalProjectionPercentage={itemPercentage(
													projectionPerMonth(dbData.totalSNF, filter, projectionDays),
													percentageAgainst,
													"number"
												)}
												totalProjectionComparisonPercentage={itemPercentage(
													projectionPerMonth(dbDataComparison?.totalSNF, filterComparison),
													dbDataComparison?.censusAverage,
													"number"
												)}
												secondaryTitle={percentageBy}
												secondaryText={percentageBy}
												projectionDays={projectionDays}
											/>
										</SelectableCardCoTransfer>
									</Grid>
									<Grid item md={4} xs={12}>
										<SelectableCardCoTransfer
											pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
											flex="1"
											title="AMA"
											loading={loading || loadingComparison}
											selected={_.includes(transferType, TRANSFER_TYPE.AMA)}
											handleOnclickCard={(e) => handleClickCardTransferType(e, TRANSFER_TYPE.AMA)}
											tooltipContent={tooltipsInfo.communityTrasnferPage.ama}
											handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.AMA, null, true, defaultTab)}
											handleOnClickReport={(title, otherData) => handleOnClickReport("totalAMA", title, true, otherData)}
										>
											<TotalCoTransferCard
												loading={loading || loadingComparison}
												selected={_.includes(transferType, TRANSFER_TYPE.AMA)}
												type={TRANSFER_TYPE.AMA}
												handleSelectCard={handleClickCardTransferType}
												page={PAGE_TYPE.COMMUNITY_TRANSFER}
												title={"Total AMA Transfers"}
												total={dbData.totalAMA}
												totalComparison={dbDataComparison?.totalAMA}
												filter={filter}
												filterComparison={filterComparison}
												totalPercentage={itemPercentage(dbData.totalAMA, percentageAgainst, "number")}
												totalComparisonPercentage={itemPercentage(
													dbDataComparison?.totalAMA,
													dbDataComparison?.censusAverage,
													"number"
												)}
												totalProjection={projectionPerMonth(dbData.totalAMA, filter, projectionDays)}
												totalProjectionComparison={projectionPerMonth(
													dbDataComparison?.totalAMA,
													filterComparison,
													projectionDays
												)}
												totalProjectionPercentage={itemPercentage(
													projectionPerMonth(dbData.totalAMA, filter, projectionDays),
													percentageAgainst,
													"number"
												)}
												totalProjectionComparisonPercentage={itemPercentage(
													projectionPerMonth(dbDataComparison?.totalAMA, filterComparison),
													dbDataComparison?.censusAverage,
													"number"
												)}
												secondaryTitle={percentageBy}
												secondaryText={percentageBy}
												projectionDays={projectionDays}
											/>
										</SelectableCardCoTransfer>
									</Grid>

									{/* End of Transfer Type Selection */}

									<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
												loading={loading || loadingComparison}
												title={COMMUNITY_CARD_LABELS.insuranceData}
												itemAlign={"ais"}
												priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)}
												tooltipContent={tooltipsInfo.communityTrasnferPage.transfersPerInsurance}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.INSURANCE, false, defaultTab)
												}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.insuranceData}
												data={filterListData.insuranceData}
												type={CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA}
											>
												<InsuranceCard
													cardTitle={COMMUNITY_CARD_LABELS.insuranceData}
													height={350}
													data={filterListData.insuranceData}
													dataComparison={filterListDataComparison?.insuranceData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.insuranceData}
													averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
													}
													type={"insuranceData"}
													loading={loading || loadingComparison}
													page={PAGE_TYPE.COMMUNITY_TRANSFER}
													filter={filter}
													filterComparison={filterComparison}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									{isMd && (
										<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.RETURNS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}>
											<Grid item xs={12} md={6} lg={4}>
												<Card
													pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
													loading={loading || loadingComparison}
													title="Returned / Didn't Return"
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
													handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA, null, false, defaultTab)}
													tooltipContent={tooltipsInfo.communityTrasnferPage["returned/didntReturn"]}
													handleOnClickReport={(title, otherData) =>
														handleOnClickReport(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA, title, false, otherData)
													}
													cardType={CO_TRANSFER_CARDS_TYPE.RETURNS_DATA}
												>
													<ReturnCardList
														cardTitle="Returned / Didn't Return"
														handleToggle={handleToggle}
														transfers={dbData.total}
														transfersComparison={dbDataComparison?.total}
														selectedItem={cardFilter.returnsData}
														type={"returnsData"}
														data={filterListData.returnsData}
														comparisonData={filterListDataComparison?.returnsData}
														filter={filter}
														filterComparison={filterComparison}
														page={PAGE_TYPE.COMMUNITY_TRANSFER}
														averageCensus={percentageAgainst}
														averageCensusComparison={dbDataComparison?.censusAverage}
														loading={loading || loadingComparison}
														projectionDays={projectionDays}
														isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
														priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
														transferType={transferType}
														lockedTotalBy={lockedTotalBy}
													/>
												</Card>
											</Grid>
										</CardAnimation>
									)}

									<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}>
										<Grid item xs={12} md={12} lg={8}>
											<Card
												pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
												loading={loading || loadingComparison}
												title="60 Days Analysis"
												priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}
												tooltipContent={tooltipsInfo.communityTrasnferPage["60DaysAnalysis"]}
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA, null, false, defaultTab)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA, title, false, otherData)
												}
												cardType={CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA}
											>
												<SixtyDaysAnalysis
													cardTitle="60 Days Analysis"
													handleToggle={handleToggle}
													selectedItem={cardFilter.sixtyDaysData}
													type={"sixtyDaysData"}
													loading={loading || loadingComparison}
													data={filterListData.sixtyDaysData}
													comparisonData={filterListDataComparison?.sixtyDaysData}
													page={PAGE_TYPE.COMMUNITY_TRANSFER}
													averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
													}
													filter={filter}
													filterComparison={filterComparison}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									{!isMd && (
										<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.RETURNS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}>
											<Grid item xs={12} md={4}>
												<Card
													pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
													loading={loading || loadingComparison}
													title="Returned / Didn't Return"
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
													handleOnClickAction={(title, defaultTab) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA, null, false, defaultTab)}
													tooltipContent={tooltipsInfo.communityTrasnferPage["returned/didntReturn"]}
													handleOnClickReport={(title, otherData) =>
														handleOnClickReport(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA, title, false, otherData)
													}
													cardType={CO_TRANSFER_CARDS_TYPE.RETURNS_DATA}
												>
													<ReturnCardList
														cardTitle="Returned / Didn't Return"
														handleToggle={handleToggle}
														transfers={dbData.total}
														transfersComparison={dbDataComparison?.total}
														selectedItem={cardFilter.returnsData}
														type={"returnsData"}
														data={filterListData.returnsData}
														comparisonData={filterListDataComparison?.returnsData}
														filter={filter}
														filterComparison={filterComparison}
														page={PAGE_TYPE.COMMUNITY_TRANSFER}
														averageCensus={percentageAgainst}
														averageCensusComparison={dbDataComparison?.censusAverage}
														loading={loading || loadingComparison}
														projectionDays={projectionDays}
														isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
														priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
														transferType={transferType}
														lockedTotalBy={lockedTotalBy}
													/>
												</Card>
											</Grid>
										</CardAnimation>
									)}

									<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
												loading={loading || loadingComparison}
												title={COMMUNITY_CARD_LABELS.doctorData}
												priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={"doctorData"}
														data={filterListData.doctorData}
														selectedItem={cardFilter.doctorData}
													/>
												}
												tooltipContent={tooltipsInfo.communityTrasnferPage.perDoctor}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.doctorData}
												data={filterListData.doctorData}
												type={CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA}
											>
												<DoctorCard
													cardTitle={COMMUNITY_CARD_LABELS.doctorData}
													data={filterListData.doctorData}
													minimizable={true}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.doctorData}
													type={"doctorData"}
													page={PAGE_TYPE.COMMUNITY_TRANSFER}
													comparisonData={filterListDataComparison?.doctorData}
													averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
													}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
												loading={loading || loadingComparison}
												title={COMMUNITY_CARD_LABELS.floorsData}
												priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={"floorsData"}
														data={filterListData.floorsData}
														selectedItem={cardFilter.floorsData}
													/>
												}
												tooltipContent={tooltipsInfo.communityTrasnferPage.floor}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(CO_TRANSFER_CARDS_TYPE.FLOORS_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.floorsData}
												data={filterListData.floorsData}
												type={CO_TRANSFER_CARDS_TYPE.FLOORS_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={CO_TRANSFER_CARDS_TYPE.FLOORS_DATA}
											>
												<FloorCardList
													cardTitle={COMMUNITY_CARD_LABELS.floorsData}
													data={filterListData.floorsData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.floorsData}
													type={"floorsData"}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													page={PAGE_TYPE.COMMUNITY_TRANSFER}
													dataComparison={filterListDataComparison?.floorsData}
													averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
													}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)}>
										<Grid item xs={6}>
											<Card
												pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
												loading={loading || loadingComparison}
												itemAlign="aif"
												title="Safe discharges per Assisted Living"
												priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)}
												isDisabled={transferType.length !== 0 && !_.includes(transferType, TRANSFER_TYPE.SAFE_DISCHARGE)}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(
														title,
														CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA,
														PATIENT_RELATION.TRANSFER_TO_WHICH_ASSISTED_LIVING,
														false,
														defaultTab
													)
												}
												taggedList={
													<TaggedList
														type={"safeDischargeAssLivData"}
														data={filterListData.safeDischargeAssLivData}
														selectedItem={cardFilter.safeDischargeAssLivData}
													/>
												}
												tooltipContent={tooltipsInfo.communityTrasnferPage.safeDischargesPerAssitedLiving}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "communityPage",
															card: "safeDischarges",
															state: newState,
														})
													)
												}
												selectedItems={cardFilter.safeDischargeAssLivData}
												data={filterListData.safeDischargeAssLivData}
												type={CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA}
											>
												<SafeDischargeAssLivingSlider
													cardTitle="Safe discharges per Assisted Living"
													page={PAGE_TYPE.COMMUNITY_TRANSFER}
													totalSafeDischarge={dbData.totalSafeDischarge}
													totalSafeDischargeComparison={dbDataComparison?.totalSafeDischarge}
													data={filterListData.safeDischargeAssLivData}
													dataComparison={filterListDataComparison?.safeDischargeAssLivData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.safeDischargeAssLivData}
													type={"safeDischargeAssLivData"}
													isDetailsOpen={true}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
													}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)}>
										<Grid item xs={6}>
											<Card
												pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
												loading={loading || loadingComparison}
												title="SNF Transfers per Facility"
												priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)}
												isDisabled={transferType.length !== 0 && !_.includes(transferType, TRANSFER_TYPE.SNF)}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA, PATIENT_RELATION.SNF, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={"snfFacilityData"}
														data={filterListData.snfFacilityData}
														selectedItem={cardFilter.snfFacilityData}
													/>
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												tooltipContent={tooltipsInfo.communityTrasnferPage.snf}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "communityPage",
															card: "transfers",
															state: newState,
														})
													)
												}
												selectedItems={cardFilter.snfFacilityData}
												data={filterListData.snfFacilityData}
												type={CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA}
											>
												<SNFFacilitySlider
													// page={PAGE_TYPE.COMMUNITY_TRANSFER}
													// totalSNF={dbData.totalSNF}
													// totalSNFComparison={dbDataComparison?.totalSNF}
													cardTitle="SNF Transfers per Facility"
													data={filterListData.snfFacilityData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.snfFacilityData}
													type={"snfFacilityData"}
													isDetailsOpen={true}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													dataComparison={filterListDataComparison?.snfFacilityData}
													averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
													}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													priorityData={cardFilter.priorityData}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>
								</Grid>
								{dynamicCards?.length > 0 ? (
									<Grid container spacing={2} sx={{ mt: "-80px" }} display="flex" direction="row">
										{dynamicCards.map((ele) => (
											<CardAnimation id={`${ele?.accessor}_scroll`} checked={_.includes(communityTransferCards, ele?.accessor)}>
												<Grid item xs={12} sm={6} md={6} lg={4} key={ele?.accessor || Math.random()}>
													<Card
														pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
														loading={loading || loadingComparison}
														title={ele?.label || ele?.tableLabel}
														priorityNumber={getPriorityNumber(ele?.accessor)}
														handleOnClickAction={(title, defaultTab) =>
															handleChartDialog(title, ele?.accessor, null, false, defaultTab, { isCustom: true, customQuestionInputType: ele?.customQuestionInputType })
														}
														isDisabled={handleDisableCustomCard(ele)}
														taggedList={
															<TaggedList
																type={ele?.accessor}
																data={filterListData?.[ele?.accessor] ?? []}
																selectedItem={cardFilter?.[ele?.accessor] ?? []}
															/>
														}
														isMinimized={true}
														handleOnClickReport={(title, otherData) =>
															handleOnClickReport(ele?.accessor, title, false, otherData, { questionId: ele?._id, isCustomCard: true })
														}
														selectedItems={cardFilter?.[ele?.accessor] ?? []}
														data={filterListData?.[ele?.accessor] ?? []}
														type={ele?.accessor}
														handleToggleAll={handleToggleAll}
														canBeSearched={true}
														cardType={ele?.accessor}
														selectAllOption={false}
													>
														<DynamicDataCardList
															question={ele}
															cardTitle={ele?.label || ele?.tableLabel}
															data={filterListData?.[ele?.accessor] ?? []}
															dataComparison={filterListDataComparison?.[ele?.accessor] ?? []}
															handleToggle={handleToggle}
															handleToggleAll={handleToggleAll}
															selectedItem={cardFilter?.[ele?.accessor]}
															spacialSelectedItem={cardFilter?.[ele?.accessor + "_spacial"]}
															type={ele?.accessor}
															filter={filter}
															loading={loading || loadingComparison}
															filterComparison={filterComparison}
															page={PAGE_TYPE.COMMUNITY_TRANSFER}
															averageCensusComparison={dbDataComparison?.censusAverage}
															averageCensus={percentageAgainst}
															projectionDays={projectionDays}
															isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
															priorityNumber={getPriorityNumber(ele?.accessor)}
															transferType={transferType}
															lockedTotalBy={lockedTotalBy}
														/>
													</Card>
												</Grid>
											</CardAnimation>
										))}
									</Grid>
								) : null}
							</Box>
						</NoteContainer>)
				}
				<ShadowLineWithText data={communityTransferCards} />
			</div>
		</Page>
	);
};

export default CommunityTransferPage;
