/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-new-object */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import _ from "lodash";
import { store } from "..";
import { ADMISSION_TYPES } from "../data/admission.data";
import {
	setAdtFacilityPercentage,
	setFacilityPercentage,
	setFilterTotal,
	setFilterTotalWithoutLock,
	setIsCensusTotalLocked,
	setLockTotal,
	setLockedByFacility,
	setLockedTotalBy,
	setLockedlockeByADT,
} from "../store/reducers/admission.slice";
import {
	setAdtFacilityPercentageComparison,
	setFacilityPercentage as setFacilityPercentageComparison,
	setFilterTotal as setFilterTotalComparison,
	setFilterTotalWithoutLock as setFilterTotalWithoutLockComparison,
	setIsCensusTotalLocked as setIsCensusTotalLockedComparison,
	setLockTotal as setLockTotalComparison,
	setLockedTotalBy as setLockedTotalByComparison,
	setLockedlockeByADT as setLockedlockeByADTComparison,
	setLockedByFacility as setLockedByFacilityComparison,
} from "../store/reducers/comparisonReducers/admissionComparison.slice";
import { ADMISSION_CARDS_TYPE, ADMISSION_FILTER_TYPE } from "../types/admission.type";
import { ADT_TABLE_TYPE, ADT_TYPES, TOTAL_TYPE } from "../types/common.type";
import { PAGE_TYPE } from "../types/pages.type";
import { dynamicCardFilter, filterCustomPatientData, filterListDataItems, getDynamicPercentageBy, itemPercentage, processDynamicCard } from "./common";
import { batch } from "react-redux";

export const updateADTChartFilter = async (cardFilter, selectedCardItem) => {
	let latestCardFilter = cardFilter;
	const selectedPriority = _.find(cardFilter.mainPriorityData, {
		childId: selectedCardItem.childId,
		cardId: selectedCardItem.cardId,
	});
	if (selectedPriority) {
		const idx = cardFilter.mainPriorityData.findIndex(
			(p) => p.childId === selectedCardItem.childId && p.cardId === selectedCardItem.cardId
		);
		let selectedCardPriority = cardFilter.mainPriorityData.slice(0, idx);
		let updatedFilter = Object();
		for await (const [key, value] of Object.entries(cardFilter)) {
			if (key == "adtAdmitPatientIds") {
				updatedFilter[key] = selectedCardPriority;
			} else {
				updatedFilter[key] = value;
			}
		}
		latestCardFilter = updatedFilter;
	}
	return _.omit(latestCardFilter, ["mainPriorityData"]);
};

export const updateAdmissionChartFilter = async (cardFilter, type) => {
	let latestCardFilter = cardFilter;
	const selectedPriority = _.find(cardFilter.mainPriorityData, { type: type });
	if (selectedPriority) {
		let idx = cardFilter.mainPriorityData.findIndex((elem) => elem.type === type);
		if (idx !== -1) {
			let selectedCardPriority = [];
			let selectedFilters = [];
			let updatedFilter = Object();
			if (cardFilter.mainPriorityData.length > 1) {
				selectedCardPriority = cardFilter.mainPriorityData.slice(0, idx);
				selectedFilters = _.map(selectedCardPriority, "type");
				let alreadySaved = false;
				selectedCardPriority
					.slice(0)
					.reverse()
					.map((element) => {
						if (element.filterType === ADMISSION_FILTER_TYPE.ADT && !alreadySaved) {
							alreadySaved = true;
							updatedFilter["admissionsIds"] = element.ids;
							updatedFilter["ADTFilter"] = true;
						}
					});
			}
			for await (const [key, value] of Object.entries(cardFilter)) {
				if (_.includes(selectedFilters, key)) {
					updatedFilter[key] = value;
				} else {
					updatedFilter[key] = [];
				}
			}
			latestCardFilter = updatedFilter;
		}
	} else {
		if (cardFilter.mainPriorityData.length > 0) {
			let adtPriorityData = _.filter(cardFilter.mainPriorityData, { filterType: ADMISSION_FILTER_TYPE.ADT });
			if (adtPriorityData.length > 0) {
				let lastElement = adtPriorityData.slice(-1);
				if (lastElement.length > 0) {
					latestCardFilter = Object.assign({}, latestCardFilter, {
						admissionsIds: lastElement[0]["ids"],
						ADTFilter: true,
					});
				}
			}
		}
	}
	return _.omit(latestCardFilter, ["mainPriorityData"]);
};

export async function plannedFilter(patientData, oldFilter, type = null) {
	patientData = _.filter(patientData, ({ floorId, doctorId, dxIds, day, hospitalId, insuranceId }) => {
		let filterArr = [];

		if (oldFilter.insuranceData.length > 0 && type !== "insuranceData") {
			filterArr.push(_.includes(oldFilter.insuranceData, insuranceId));
		}
		if (oldFilter.floorsData.length > 0 && type !== "floorsData") {
			filterArr.push(_.includes(oldFilter.floorsData, floorId));
		}
		if (oldFilter.daysData.length > 0 && type !== "daysData") {
			filterArr.push(_.includes(oldFilter.daysData, day));
		}
		if (oldFilter.doctorData.length > 0 && type !== "doctorData") {
			filterArr.push(_.includes(oldFilter.doctorData, doctorId));
		}
		if (oldFilter.hospitalData.length > 0 && type !== "hospitalData") {
			filterArr.push(_.includes(oldFilter.hospitalData, hospitalId));
		}
		if (oldFilter.dxData.length > 0 && type !== "dxData") {
			filterArr.push(_.isEqual(_.intersection(oldFilter.dxData, dxIds), dxIds));
		}
		return _.every(filterArr);
	});
	return patientData;
}

async function hospitalListData(data, totalFilterData, filter, projectionDays, shouldCalcProj) {
	//insuranceData Cal
	let hospitalDBDataGroup = [];
	if (data && data.length > 0) {
		const hospitalDBData = _.groupBy(data, "hospitalId");
		const originalData = _.groupBy(totalFilterData.originalData, "hospitalId");
		if (hospitalDBData) {
			let percentageTotal = totalFilterData.totalForPercentage ? totalFilterData.totalForPercentage : data?.length;

			hospitalDBDataGroup = await filterListDataItems(hospitalDBData, "hospital", percentageTotal, {
				...totalFilterData,
				originalData,
			});
		}
	}
	return hospitalDBDataGroup;
}

async function insuranceData(data, totalFilterData, filter, projectionDays, shouldCalcProj) {
	//insuranceData Cal
	let insuranceDBDataGroup = [];
	if (data && data.length > 0) {
		const insuranceDBData = _.groupBy(data, "insuranceId");
		if (insuranceDBData) {
			const originalData = _.groupBy(totalFilterData.originalData, "insuranceId");
			let percentageTotal = totalFilterData.totalForPercentage ? totalFilterData.totalForPercentage : data?.length;

			insuranceDBDataGroup = await filterListDataItems(insuranceDBData, "payerSourceInsurance", percentageTotal, {
				...totalFilterData,
				originalData,
			});
		}
	}

	return insuranceDBDataGroup;
}

async function doctorData(data, totalFilterData, filter, projectionDays, shouldCalcProj) {
	//Doctor list update Cal
	let doctorDBDataGroup = [];
	if (data && data.length > 0) {
		const doctorDBData = _.groupBy(data, "doctorId");
		if (doctorDBData) {
			const originalData = _.groupBy(totalFilterData.originalData, "doctorId");
			let percentageTotal = totalFilterData.totalForPercentage ? totalFilterData.totalForPercentage : data?.length;

			doctorDBDataGroup = await filterListDataItems(doctorDBData, "doctor", percentageTotal, {
				...totalFilterData,
				originalData,
			});
		}
	}
	return doctorDBDataGroup;
}

async function floorsData(data, totalFilterData, filter, projectionDays, shouldCalcProj) {
	//FloorsData Cal
	let unitDBDataGroup = [];
	if (data && data.length > 0) {
		const floorDBData = _.groupBy(data, "floorId");
		if (floorDBData) {
			const originalData = _.groupBy(totalFilterData.originalData, "floorId");
			let percentageTotal = totalFilterData.totalForPercentage ? totalFilterData.totalForPercentage : data?.length;

			unitDBDataGroup = await filterListDataItems(floorDBData, "unit", percentageTotal, {
				...totalFilterData,
				originalData,
			});
		}
	}
	return unitDBDataGroup;
}

async function daysData(data, totalFilterData, filter, projectionDays, shouldCalcProj) {
	const dayDBData = _.countBy(data, "day");
	const { originalData = [], totalType = null } = totalFilterData;
	const originalDataGroupBy = _.countBy(originalData, "day");
	let percentageTotal = totalFilterData.totalForPercentage ? totalFilterData.totalForPercentage : data?.length;

	const daysDataArr = [
		{
			_id: "Sun",
			label: "Sun",
			value: dayDBData?.Sun || 0,
			percentage: itemPercentage(dayDBData?.Sun, percentageTotal, "number"),
			color: "#FFECA6",
			originalTotal: originalDataGroupBy?.Sun || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
		{
			_id: "Mon",
			label: "Mon",
			value: dayDBData?.Mon || 0,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Mon, percentageTotal, "number"),
			originalTotal: originalDataGroupBy?.Mon || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
		{
			_id: "Tue",
			label: "Tue",
			value: dayDBData?.Tue || 0,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Tue, percentageTotal, "number"),
			originalTotal: originalDataGroupBy?.Tue || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
		{
			_id: "Wed",
			label: "Wed",
			value: dayDBData?.Wed || 0,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Wed, percentageTotal, "number"),
			originalTotal: originalDataGroupBy?.Wed || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
		{
			_id: "Thu",
			label: "Thu",
			value: dayDBData?.Thu || 0,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Thu, percentageTotal, "number"),
			originalTotal: originalDataGroupBy?.Thu || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
		{
			_id: "Fri",
			label: "Fri",
			value: dayDBData?.Fri || 0,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Fri, percentageTotal, "number"),
			originalTotal: originalDataGroupBy?.Fri || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
		{
			_id: "Sat",
			label: "Sat",
			value: dayDBData?.Sat || 0,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Sat, percentageTotal, "number"),
			originalTotal: originalDataGroupBy?.Sat || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
		},
	];

	return daysDataArr;
}

async function dxData(data, totalFilterData, filter, projectionDays, shouldCalcProj) {
	let dxDataArr = [];
	let patientData = [];
	let patientDxIds = [];
	const { originalData = [], totalType = null } = totalFilterData;
	let patientOriginalData = [];
	let dxOriginalDataArr = [];

	if (data && data.length > 0) {
		// eslint-disable-next-line array-callback-return
		data.filter((ele, i) => {
			if (ele.dx && ele.dx.length > 0) {
				dxDataArr = [...ele.dx, ...dxDataArr];
				patientData.push(ele);
				patientDxIds = [...ele.dxIds, ...patientDxIds];
			}
		});

		originalData.filter((ele) => {
			if (ele.dx && ele.dx.length > 0) {
				dxOriginalDataArr = [...ele.dx, ...dxOriginalDataArr];
				patientOriginalData.push(ele);
			}
		});
	}
	let percentageTotal = totalFilterData.totalForPercentage ? totalFilterData.totalForPercentage : data?.length;

	let dxDataDB = [];
	if (dxDataArr.length > 0) {
		const dxDataGroup = _.groupBy(dxDataArr, "_id");
		const dxOriginalDataGroup = _.groupBy(dxOriginalDataArr, "_id");

		if (dxDataGroup) {
			for (const [key, value] of Object.entries(dxDataGroup)) {
				const valueArr = value[0];
				if (key && valueArr) {
					let object = Object();
					object._id = key;
					object.id = key;
					object.label = valueArr.label;
					object.name = valueArr.label;
					object.total = value.length;
					let original = dxOriginalDataGroup[key] ? dxOriginalDataGroup[key]?.length : 0;
					object.originalTotal = original;
					object.isTooltip = totalType && totalType === TOTAL_TYPE.MAIN ? false : true;
					object.percentage = itemPercentage(value.length, percentageTotal, "number");
					dxDataDB.push(object);
				}
			}
		}
		dxDataDB = _.orderBy(dxDataDB, "total", "desc");
	}
	return dxDataDB;
}

//All ADT TAB FUNCTIONS HERE
async function getADTTotalData(
	element,
	patientList,
	adtTotal,
	type = "transferType",
	{ totalType = null, isTooltip = false }
) {
	let filtersArray = [];
	const eleID = element.id;
	const eleLabel = element.label;
	let findData = [];
	if (type === "transferType") {
		findData = _.filter(patientList, { transferType: eleID });
	} else {
		findData = patientList;
	}
	const total = findData.length || 0;
	let originalTotal = total;
	if (totalType == TOTAL_TYPE.FILTER) {
		const tableData = element.tableData ? element.tableData : [];
		const findTableData = _.find(tableData, { _id: eleID });
		originalTotal = findTableData?.originalTotal || 0;
	}
	const object = {
		id: eleID,
		_id: eleID,
		label: eleLabel,
		name: eleLabel,
		total,
		originalTotal,
		isTooltip,
		admissionIds: _.map(findData, "admissionId"),
		transferIds: _.map(findData, "id"),
		percentage: itemPercentage(total, adtTotal, "number"),
		type: element.type,
		element,
	};
	filtersArray.push(object);
	return filtersArray;
}

export function matchedArray(array1, array2) {
	return _.intersectionWith(array1, array2, _.isEqual);
}

async function ninetyDaysDataList(listData, element, patients, adtTotal, { totalType = null, isTooltip = false }) {
	const matchedIds = patients.length > 0 ? patients.map((item) => item.id) : [];
	const matchedAdmissionIds = patients.length > 0 ? patients.map((item) => item.admissionId) : [];

	if (listData && listData.length > 0) {
		const latestData = listData.map((item) => {
			const intersection = matchedArray(matchedIds, item.ids);
			const intersectionAdmission = matchedArray(matchedAdmissionIds, item.admissionIds);
			const total = intersection.length;
			let originalTotal = total;
			if (totalType == TOTAL_TYPE.FILTER) {
				const tableData = element.tableData ? element.tableData : [];
				const findTableData = _.find(tableData, { _id: item._id });
				originalTotal = findTableData?.originalTotal || 0;
			}
			let obj = {
				id: item._id,
				name: item.label,
				_id: item._id,
				label: item.label,
				total: total || 0,
				ids: intersection,
				originalTotal,
				isTooltip,
				patientIds: intersection,
				percentage: itemPercentage(total, adtTotal, "number", PAGE_TYPE.ADMISSION),
				type: element.type,
				element: element,
				admissionIds: intersectionAdmission,
			};
			return Object.assign({}, item, obj);
		});
		return latestData;
	}
}

async function getADTAnalysisChartData(element, patientList, transferPatientList, adtTotal, totalType) {
	let chartArray = element.arrayName ? patientList[element.arrayName] : [];
	const latest90Days = ninetyDaysDataList(chartArray, element, transferPatientList, adtTotal, totalType);
	return latest90Days;
}

async function getADTChartDaysData(element, patientList, adtTotal, { totalType = null, isTooltip = false }) {
	let chartADTData = [];
	const groupByChartData = await _.groupBy(patientList, element.id);
	const chartLabels = element.chartsLabel || [];

	for await (const item of chartLabels) {
		//chartLabels.filter((item) => {
		const chartLabelItem = groupByChartData[item.filterId];
		const patientIds = await _.map(chartLabelItem, "admissionId");
		const transferIds = await _.map(chartLabelItem, "id");
		const total = chartLabelItem?.length || 0;
		let originalTotal = total;
		if (totalType == TOTAL_TYPE.FILTER) {
			const tableData = element.tableData ? element.tableData : [];
			const findTableData = _.find(tableData, { _id: item.id });
			originalTotal = findTableData?.originalTotal || 0;
		}
		let object = {
			id: item.id,
			filterId: item.filterId,
			name: item.label,
			_id: item.id,
			label: item.label,
			total,
			patientIds,
			ids: patientIds,
			admissionIds: patientIds,
			transferIds,
			originalTotal,
			isTooltip,
			percentage: await itemPercentage(total, adtTotal, "number", PAGE_TYPE.ADMISSION),
			type: element.type,
			element: element,
			chartsLabel: element.chartsLabel,
		};
		chartADTData.push(object);
	}
	return chartADTData;
}

async function getADTChartData(element, patientList, adtTotal, { totalType = null, isTooltip = false }) {
	let chartADTData = [];

	// check why the percentage is different
	const groupByChartData = _.groupBy(patientList, element.id);
	const chartLabels = element.chartsLabel || [];

	for await (const item of chartLabels) {
		const chartLabelItem = groupByChartData[item.filterId];
		const admissionIds = _.map(chartLabelItem, "admissionId");
		const transferIds = _.map(chartLabelItem, "id");
		const total = chartLabelItem?.length || 0;
		let originalTotal = total;
		if (totalType == TOTAL_TYPE.FILTER) {
			const tableData = element.tableData ? element.tableData : [];
			const findTableData = _.find(tableData, { _id: item.id });
			originalTotal = findTableData?.originalTotal || 0;
		}
		let object = {
			id: item.id,
			filterId: item.filterId,
			name: item.label,
			_id: item.id,
			label: item.label,
			total,
			ids: transferIds,
			originalTotal,
			isTooltip,
			patientIds: transferIds,
			admissionIds: admissionIds,
			percentage: itemPercentage(total, adtTotal, "number", PAGE_TYPE.ADMISSION),
			type: element.type,
			element: element,
		};
		chartADTData.push(object);
	}
	return chartADTData;
}

async function getADTGroupData(element, patientList, adtTotal, { totalType = null, isTooltip = false }) {
	let filtersArray = [];

	const groupByData = await _.groupBy(patientList, element.id);
	if (!_.isEmpty(groupByData)) {
		for await (const [key, value] of Object.entries(groupByData)) {
			if (key != "null") {
				const arrayLabelData = value[0];
				const admissionIds = _.map(value, "admissionId") || [];
				const transferIds = _.map(value, "id") || [];
				const total = value.length || 0;
				let originalTotal = total;
				if (totalType == TOTAL_TYPE.FILTER) {
					const tableData = element.tableData ? element.tableData : [];
					const findTableData = _.find(tableData, { _id: arrayLabelData[element.relation]["_id"] });
					originalTotal = findTableData?.originalTotal || 0;
				}
				const relationIdLet = element && element.relation && arrayLabelData[element.relation] ? arrayLabelData[element.relation]["_id"] : null;
				const relationLabelLet = element && element.relation && arrayLabelData[element.relation] ? arrayLabelData[element.relation]["label"] : null;
				if (relationIdLet && relationLabelLet) {
					const object = {
						id: relationIdLet,
						name: relationLabelLet,
						_id: relationIdLet,
						label: relationLabelLet,
						total,
						originalTotal,
						isTooltip,
						patientIds: admissionIds,
						transferIds,
						admissionIds: admissionIds,
						percentage: await itemPercentage(total, adtTotal, "number", PAGE_TYPE.ADMISSION),
						type: element.type,
						element: element,
					};
					filtersArray.push(object);
				}

			}
		}
		filtersArray = _.orderBy(filtersArray, "total", "desc");
	}
	return filtersArray;
}

async function getADTGroupArrayData(element, patientList, adtTotal, { totalType = null, isTooltip = false }) {
	let filtersArray = [];
	let dxDataArr = [];
	if (patientList && patientList.length > 0) {
		// eslint-disable-next-line array-callback-return
		patientList.filter((ele) => {
			if (ele[element.id] && ele[element.id].length > 0) {
				let objectDev = ele[element.id];
				objectDev = objectDev.map((obj) => ({ ...obj, admissionId: ele.admissionId, patientId: ele.id }));
				dxDataArr = [...objectDev, ...dxDataArr];
			}
		});
		if (dxDataArr.length > 0) {
			const dxDataGroup = _.groupBy(dxDataArr, "_id");
			if (dxDataGroup) {
				for (const [key, value] of Object.entries(dxDataGroup)) {
					const valueArr = value[0];
					const admissionIds = _.map(value, "admissionId") || [];
					const transferIds = _.map(value, "patientId") || [];

					let originalTotal = value.length;
					if (totalType == TOTAL_TYPE.FILTER) {
						const tableData = element.tableData ? element.tableData : [];
						const findTableData = _.find(tableData, { _id: key });
						originalTotal = findTableData?.originalTotal || 0;
					}

					if (key && valueArr) {
						let object = {
							id: key,
							_id: key,
							name: valueArr.label,
							label: valueArr.label,
							total: value.length,
							type: element.type,
							originalTotal,
							isTooltip,
							percentage: itemPercentage(value.length, adtTotal, "number", PAGE_TYPE.ADMISSION),
							element: element,
							patientIds: transferIds,
							admissionIds: admissionIds,
							transferIds,
						};
						filtersArray.push(object);
					}
				}
			}
			filtersArray = _.orderBy(filtersArray, "total", "desc");
		}
	}
	return filtersArray;
}

export async function adtCardFilterParam(adtData) {
	let transferTypes = [];
	let relations = [];
	let isReturnData = false;
	let isReHospitalization = false;
	let isNinetyDays = false;
	let isSixtyDays = false;
	let isNinetyDeceasedDays = false;
	let isNinetyOverallDays = false;
	adtData.map((ele) => {
		if (_.find(ele.children, { id: "wasReturned" })?.isSelected) {
			isReturnData = true;
		}
		if (ele.id === PAGE_TYPE.HOSPITAL) {
			if (_.find(ele.children, { id: "90Days" })?.isSelected) {
				isNinetyDays = true;
			}
			if (_.find(ele.children, { id: "reHospitalization" })?.isSelected) {
				isReHospitalization = true;
			}
			transferTypes = _.concat(transferTypes, ["plannedHospitalTransfer", "unplannedHospitalTransfer"]);
		}
		if (ele.id === PAGE_TYPE.COMMUNITY_TRANSFER) {
			if (_.find(ele.children, { id: "60Days" })?.isSelected) {
				isSixtyDays = true;
			}
			transferTypes = _.concat(transferTypes, ["AMA", "SNF", "safeDischarge"]);
		}
		if (ele.id === PAGE_TYPE.DECEASED) {
			if (_.find(ele.children, { id: "90DaysDeceased" })?.isSelected) {
				isNinetyDeceasedDays = true;
			}
			transferTypes = _.concat(transferTypes, ["deceased"]);
		}
		if (ele.id === PAGE_TYPE.OVERALL) {
			if (_.find(ele.children, { id: "90DaysOverall" })?.isSelected) {
				isNinetyOverallDays = true;
			}
			transferTypes = [];
		}
		if (ele.children && ele.children.length > 0) {
			const relationsData = _.map(_.filter(ele.children, { isSelected: true }), "relation").filter((item) => item);
			relations = _.concat(relations, relationsData);
		}
		relations = _.uniq(relations);
	});

	return {
		transferTypes,
		relations,
		isReturnData,
		isReHospitalization,
		isNinetyDays,
		isSixtyDays,
		isNinetyDeceasedDays,
		isNinetyOverallDays,
	};
}
export async function updateFacilityPercentageTotal(data, forComparison) {
	let facilityWiseTotal = _(data)
		.groupBy("facilityId")
		.sortBy((group) => data.indexOf(group[0]))
		.map((product) => {
			return {
				id: product[0]?.facilityId,
				total: product.length || 0,
			};
		})
		.value();
	forComparison
		? store.dispatch(setAdtFacilityPercentageComparison(facilityWiseTotal))
		: store.dispatch(setAdtFacilityPercentage(facilityWiseTotal));
}

export async function updateFilterListDataBoth(
	patientList,
	cardFilter,
	transferType,
	priorityData,
	defaultADTData,
	forComparison
) {
	const { list, adtList } = patientList;
	let adtTabTableData = adtList;
	let patientFilterData = list;
	let adtFilterData = adtList.list;
	const {
		dbData,
		lockedTotal,
		lockedTotalBy
	} = forComparison ? store.getState().admissionComparison : store.getState().admission;
	const { dynamicCards } = store.getState().admission;

	const admissionCards = dynamicCards?.length > 0
		? dynamicCards.filter((ele) => ele.forType === ADT_TYPES.ADMISSIONS)
		: [];

	let censusAverage = dbData?.censusAverage;

	if (!forComparison) {
		censusAverage = await getDynamicPercentageBy(dbData);
	}

	let lockedTotalModified = lockedTotal;
	let mainNumPercentage = censusAverage;
	let isComparingAgainstAvgCensus = true;

	if (!forComparison) {
		store.dispatch(setAdtFacilityPercentage([]));
	} else {
		store.dispatch(setAdtFacilityPercentageComparison([]));
	}

	let totalFilterData = {
		originalData: patientFilterData,
		// totalType: transferType ? TOTAL_TYPE.FILTER : TOTAL_TYPE.MAIN,
		totalType: !forComparison ? transferType || priorityData?.length > 0 ? TOTAL_TYPE.FILTER : TOTAL_TYPE.MAIN : TOTAL_TYPE,
		totalForPercentage: censusAverage,
	};

	if (transferType === ADMISSION_TYPES.ADMISSION) {
		patientFilterData = _.filter(patientFilterData, {
			type: ADMISSION_TYPES.ADMISSION,
		});
	}
	if (transferType === ADMISSION_TYPES.READMISSION) {
		patientFilterData = _.filter(patientFilterData, {
			type: ADMISSION_TYPES.READMISSION,
		});
	}
	if (transferType) {
		mainNumPercentage = null;
		totalFilterData.totalForPercentage = patientFilterData.length;
		isComparingAgainstAvgCensus = false;
	}
	let objectCustom = Object();
	let newSavedFilters = [];
	let newADTData = [];
	
	batch(async () => {
	const newPayload = [];
	if (forComparison) {
		const currentFacilityPercentage = store.getState().admissionComparison.facilityPercentage;
		if (!_.isEqual(currentFacilityPercentage, newPayload)) {
			store.dispatch(setFacilityPercentageComparison(newPayload))
		}
	} else {
		const currentFacilityPercentage = store.getState().admission.facilityPercentage;
		if (!_.isEqual(currentFacilityPercentage, newPayload)) {
			store.dispatch(setFacilityPercentage(newPayload))
		}
	}

	if (!lockedTotalModified) {

		const newLockedTotalBy = null;
		if (forComparison) {
			const currentLockedTotalBy = store.getState().admissionComparison.lockedTotalBy;
			if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
				store.dispatch(setLockedTotalByComparison(newLockedTotalBy))
			}
		} else {
			const currentLockedTotalBy = store.getState().admission.lockedTotalBy;
			if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
				store.dispatch(setLockedTotalBy(newLockedTotalBy))
			}
		}

		!forComparison && store.dispatch(setIsCensusTotalLocked(false));
	}

	if (lockedTotalBy) {
		const newLockedTotalBy = null;
		const newLockTotal = null;
		if (
			lockedTotalBy === ADMISSION_TYPES.ALL ||
			lockedTotalBy === ADMISSION_TYPES.ADMISSION ||
			lockedTotalBy === ADMISSION_TYPES.READMISSION ||
			lockedTotalBy === "census"
		) {
			//if (lockedTotalModified && transferType !== lockedTotalBy && lockedTotalBy !== "census") {
			if (lockedTotalModified && !transferType && lockedTotalBy !== "census") {
				if (forComparison) {
					const currentLockedTotalBy = store.getState().admissionComparison.lockedTotalBy;
					if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
						store.dispatch(setLockedTotalByComparison(newLockedTotalBy))
					}
				} else {
					const currentLockedTotalBy = store.getState().admission.lockedTotalBy;
					if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
						store.dispatch(setLockedTotalBy(newLockedTotalBy))
					}
				}

				if (forComparison) {
					const currentLockedTotal = store.getState().admissionComparison.lockedTotal;
					if (!_.isEqual(currentLockedTotal, newLockTotal)) {
						store.dispatch(setLockTotalComparison(newLockTotal))
					}
				} else {
					const currentLockedTotal = store.getState().admission.lockedTotal;
					if (!_.isEqual(currentLockedTotal, newLockTotal)) {
						store.dispatch(setLockTotal(newLockTotal))
					}
				}

				lockedTotalModified = null;
			}
		} else {
			const lockedFilterRemoved = _.find(priorityData, (ele) => {
				let typeData =
					ele.filterType === ADMISSION_FILTER_TYPE.ADT ? ele?.type + "_" + ADMISSION_FILTER_TYPE.ADT : ele?.type;
				return typeData === lockedTotalBy ? ele : null;
			});
			//const lockedFilterRemoved = _.find(mainPriorityData, { type: lockedTotalBy });
			if (!lockedFilterRemoved) {
				if (forComparison) {
					const currentLockedTotalBy = store.getState().admissionComparison.lockedTotalBy;
					if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
						store.dispatch(setLockedTotalByComparison(newLockedTotalBy))
					}
				} else {
					const currentLockedTotalBy = store.getState().admission.lockedTotalBy;
					if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
						store.dispatch(setLockedTotalBy(newLockedTotalBy))
					}
				}

				if (forComparison) {
					const currentLockedTotal = store.getState().admissionComparison.lockedTotal;
					if (!_.isEqual(currentLockedTotal, newLockTotal)) {
						store.dispatch(setLockTotalComparison(newLockTotal))
					}
				} else {
					const currentLockedTotal = store.getState().admission.lockedTotal;
					if (!_.isEqual(currentLockedTotal, newLockTotal)) {
						store.dispatch(setLockTotal(newLockTotal))
					}
				}

				lockedTotalModified = null;
			}
		}
	}

	});

	if (lockedTotalModified) {
		totalFilterData.totalForPercentage = lockedTotalModified;
	}

	if (priorityData?.length > 0) {
		isComparingAgainstAvgCensus = false;
	}

	if (
		(priorityData?.length > 0 && (!lockedTotalBy || !lockedTotal)) ||
		(priorityData?.length > 0 &&
			lockedTotalBy !== "census" &&
			lockedTotalBy !== ADMISSION_TYPES.ALL &&
			lockedTotalBy !== ADMISSION_TYPES.ADMISSION &&
			lockedTotalBy !== ADMISSION_TYPES.READMISSION)
	) {
		totalFilterData.totalForPercentage = null;
	}

	let dynamicCardsObj = {};

	if (priorityData?.length > 0) {
		let filterAdmissionsIds = _.map(patientFilterData, "id");
		let filterADTAdmissionId = [];
		let updatedADTData = [];
		let isADTFilterApply = false;
		let i = 0;
		let j = 0;
		let admissionTotal = 0;
		for await (const ele of priorityData) {
			j++;
			let totalCountData = mainNumPercentage && j === 1 ? mainNumPercentage : totalFilterData.totalForPercentage;
			//totalCountData = lockedTotalModified ? lockedTotalModified : totalCountData;
			const { type, transferIds, childId, selectedIds, filterType } = ele;

			if (priorityData?.length === j && lockedTotalModified && !lockedTotalBy) {
				if (filterType === ADMISSION_FILTER_TYPE.ADMISSION) {
					forComparison
						? store.dispatch(setLockedTotalByComparison(ele.type))
						: store.dispatch(setLockedTotalBy(ele.type));
				} else {
					forComparison
						? store.dispatch(setLockedTotalByComparison(ele.type + "_" + filterType))
						: store.dispatch(setLockedTotalBy(ele.type + "_" + filterType));
					forComparison ? store.dispatch(setLockedlockeByADTComparison(ele)) : store.dispatch(setLockedlockeByADT(ele));
				}
			}

			if (filterType === ADMISSION_FILTER_TYPE.ADMISSION) {

				if (ele?.question?.isCustom) {
					patientFilterData = filterCustomPatientData(patientFilterData, cardFilter, ele);
				}

				if (isADTFilterApply) {
					patientFilterData = _.filter(patientFilterData, async ({ id }) =>
						_.every([_.includes(filterADTAdmissionId, id)])
					);
				}

				if (ele.type === ADMISSION_CARDS_TYPE.DOCTOR_DATA) {
					objectCustom.doctorData = await doctorData(patientFilterData, {
						...totalFilterData,
						totalForPercentage: totalCountData,
					});

					patientFilterData = _.filter(patientFilterData, ({ doctorId }) =>
						_.every([_.includes(cardFilter.doctorData, doctorId)])
					);
				}

				if (ele.type === ADMISSION_CARDS_TYPE.DAYS_DATA) {
					objectCustom.daysData = await daysData(patientFilterData, {
						...totalFilterData,
						totalForPercentage: totalCountData,
					});

					patientFilterData = _.filter(patientFilterData, ({ day }) => _.every([_.includes(cardFilter.daysData, day)]));
				}

				if (ele.type === ADMISSION_CARDS_TYPE.DX_DATA) {
					objectCustom.dxData = await dxData(patientFilterData, {
						...totalFilterData,
						totalForPercentage: totalCountData,
					});

					patientFilterData = _.filter(patientFilterData, ({ dxIds }) => {
						const matchedIds = _.intersection(cardFilter.dxData, dxIds);
						return matchedIds.length > 0 ? true : false;
					});
				}

				if (ele.type === ADMISSION_CARDS_TYPE.INSURANCE_DATA) {
					objectCustom.insuranceData = await insuranceData(patientFilterData, {
						...totalFilterData,
						totalForPercentage: totalCountData,
					});

					patientFilterData = _.filter(patientFilterData, ({ insuranceId }) =>
						_.every([_.includes(cardFilter.insuranceData, insuranceId)])
					);
				}

				if (ele.type === ADMISSION_CARDS_TYPE.FLOORS_DATA) {
					objectCustom.floorsData = await floorsData(patientFilterData, {
						...totalFilterData,
						totalForPercentage: totalCountData,
					});

					patientFilterData = _.filter(patientFilterData, ({ floorId }) =>
						_.every([_.includes(cardFilter.floorsData, floorId)])
					);
				}

				if (ele.type === ADMISSION_CARDS_TYPE.HOSPITAL_DATA) {
					objectCustom.hospitalData = await hospitalListData(patientFilterData, {
						...totalFilterData,
						totalForPercentage: totalCountData,
					});

					patientFilterData = _.filter(patientFilterData, ({ hospitalId }) =>
						_.every([_.includes(cardFilter.hospitalData, hospitalId)])
					);
				}
				admissionTotal = patientFilterData.length;

				store.dispatch(
					forComparison
						? setFilterTotalComparison(lockedTotalModified ? lockedTotalModified : patientFilterData.length)
						: setFilterTotal(lockedTotalModified ? lockedTotalModified : patientFilterData.length)
				);
				store.dispatch(
					forComparison
						? setFilterTotalWithoutLockComparison(patientFilterData.length)
						: setFilterTotalWithoutLock(patientFilterData.length)
				);
				filterAdmissionsIds = _.map(patientFilterData, "id");
				newSavedFilters.push(ele);
				if (j === priorityData?.length) {
					updateFacilityPercentageTotal(patientFilterData, forComparison);
				}
			} else {
				i++;
				isADTFilterApply = true;
				adtFilterData = _.filter(adtFilterData, ({ admissionId }) =>
					_.every([_.includes(filterAdmissionsIds, admissionId)])
				);
				let totalForPercentage = i == 1 ? patientFilterData?.length : adtFilterData.length;
				admissionTotal = totalForPercentage;
				let totalCountADTData =
					!transferType && j === 1 ? censusAverage : lockedTotalModified ? lockedTotalModified : totalForPercentage;
				if (!transferType) {
					isComparingAgainstAvgCensus = true;
				}
				let tableDataNew = await getTableDataFilter(ele, adtFilterData, adtTabTableData, totalCountADTData, {
					totalType: TOTAL_TYPE.FILTER,
					isTooltip: transferType ? true : false,
					forComparison,
				});

				updatedADTData.push({ ...ele, tableData: tableDataNew });

				let transferIdsData = transferIds;
				if (i == 1 && (type === ADT_TABLE_TYPE.ANALYSIS_CHART || type === ADT_TABLE_TYPE.CUSTOM)) {
					const selectedIdData = ele.selectedIds;
					if (selectedIdData.length > 0) {
						let transferIdsDataLatest2 = [];
						tableDataNew.map(async (eleTable) => {
							if (_.includes(selectedIdData, eleTable._id)) {
								transferIdsDataLatest2 = [...transferIdsDataLatest2, ...eleTable.ids];
							}
						});
						transferIdsData = transferIdsDataLatest2;
					}
				}
				adtFilterData = _.filter(adtFilterData, (item) => {
					let filterArr = [];
					const idD = item[childId];
					const { id } = item;
					if (type === ADT_TABLE_TYPE.GROUP) {
						filterArr.push(_.includes(selectedIds, idD));
					} else if (type === ADT_TABLE_TYPE.GROUP_ARRAY) {
						filterArr.push(_.includes(transferIdsData, id));
					} else if (type === ADT_TABLE_TYPE.DAY_CHART) {
						filterArr.push(_.includes(transferIdsData, idD));
					} else if (type === ADT_TABLE_TYPE.CUSTOM) {
						filterArr.push(_.includes(transferIdsData, id));
					} else if (type === ADT_TABLE_TYPE.ANALYSIS_CHART) {
						filterArr.push(_.includes(transferIdsData, id));
					} else if (type === ADT_TABLE_TYPE.CHART || type === ADT_TABLE_TYPE.ALL || ADT_TABLE_TYPE.TOTAL) {
						filterArr.push(_.includes(transferIdsData, id));
					}
					return _.every(filterArr);
				});

				if (ele.parentId === PAGE_TYPE.HOSPITAL) {
					adtFilterData = _.filter(adtFilterData, ({ transferType }) =>
						_.every([_.includes(["plannedHospitalTransfer", "unplannedHospitalTransfer"], transferType)])
					);
				}

				if (ele.parentId === PAGE_TYPE.COMMUNITY_TRANSFER) {
					adtFilterData = _.filter(adtFilterData, ({ transferType }) =>
						_.every([_.includes(["AMA", "SNF", "safeDischarge"], transferType)])
					);
				}

				if (ele.parentId === PAGE_TYPE.DECEASED) {
					adtFilterData = _.filter(adtFilterData, ({ transferType }) =>
						_.every([_.includes(["deceased"], transferType)])
					);
				}
				filterADTAdmissionId = _.map(adtFilterData, "admissionId");
				patientFilterData = _.filter(patientFilterData, ({ id }) => {
					return _.every([_.includes(filterADTAdmissionId, id)]);
				});
				if (j === priorityData?.length) {
					updateFacilityPercentageTotal(adtFilterData, forComparison);
				}
				if (i == 1) {
					store.dispatch(
						forComparison
							? setFilterTotalComparison(lockedTotalModified ? lockedTotalModified : adtFilterData.length)
							: setFilterTotal(lockedTotalModified ? lockedTotalModified : adtFilterData.length)
					);
					store.dispatch(
						forComparison
							? setFilterTotalWithoutLockComparison(adtFilterData.length)
							: setFilterTotalWithoutLock(adtFilterData.length)
					);
				} else {
					if (forComparison) {
						if (lockedTotalModified) {
							store.dispatch(setFilterTotalComparison(lockedTotalModified));
						} else {
							if (mainNumPercentage) {
								store.dispatch(setFilterTotalComparison(mainNumPercentage));
							} else {
								store.dispatch(setFilterTotalComparison(patientFilterData.length));
							}
						}
					} else {
						if (lockedTotalModified) {
							store.dispatch(setFilterTotal(lockedTotalModified));
						} else {
							if (mainNumPercentage && i === 1) {
								store.dispatch(setFilterTotal(mainNumPercentage));
							} else {
								store.dispatch(setFilterTotal(patientFilterData.length));
							}
						}
					}

					store.dispatch(
						forComparison
							? setFilterTotalWithoutLockComparison(mainNumPercentage ? mainNumPercentage : patientFilterData.length)
							: setFilterTotalWithoutLock(mainNumPercentage ? mainNumPercentage : patientFilterData.length)
					);
				}
			}
		}
		const totalPercentageCount = lockedTotalModified ? lockedTotalModified : patientFilterData.length; //transferType ? patientFilterData?.length : censusAverage

		const selectedDoctorFilter = _.find(newSavedFilters, {
			type: ADMISSION_CARDS_TYPE.DOCTOR_DATA,
			filterType: ADMISSION_FILTER_TYPE.ADMISSION,
		});

		if (_.isEmpty(selectedDoctorFilter)) {
			objectCustom.doctorData = await doctorData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}

		const selectedDaysFilter = _.find(newSavedFilters, {
			type: ADMISSION_CARDS_TYPE.DAYS_DATA,
			filterType: ADMISSION_FILTER_TYPE.ADMISSION,
		});
		if (_.isEmpty(selectedDaysFilter)) {
			objectCustom.daysData = await daysData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}

		const selectedDxFilter = _.find(newSavedFilters, {
			type: ADMISSION_CARDS_TYPE.DX_DATA,
			filterType: ADMISSION_FILTER_TYPE.ADMISSION,
		});
		if (_.isEmpty(selectedDxFilter)) {
			objectCustom.dxData = await dxData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}

		const selectedInsuranceFilter = _.find(newSavedFilters, {
			type: ADMISSION_CARDS_TYPE.INSURANCE_DATA,
			filterType: ADMISSION_FILTER_TYPE.ADMISSION,
		});
		if (_.isEmpty(selectedInsuranceFilter)) {
			objectCustom.insuranceData = await insuranceData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}

		const selectedFloorFilter = _.find(newSavedFilters, {
			type: ADMISSION_CARDS_TYPE.FLOORS_DATA,
			filterType: ADMISSION_FILTER_TYPE.ADMISSION,
		});
		if (_.isEmpty(selectedFloorFilter)) {
			objectCustom.floorsData = await floorsData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}

		const selectedHospitalFilter = _.find(newSavedFilters, {
			type: ADMISSION_CARDS_TYPE.HOSPITAL_DATA,
			filterType: ADMISSION_FILTER_TYPE.ADMISSION,
		});
		if (_.isEmpty(selectedHospitalFilter)) {
			objectCustom.hospitalData = await hospitalListData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		objectCustom.admissionTotal = admissionTotal;

		adtFilterData = _.filter(adtFilterData, ({ admissionId }) =>
			_.every([_.includes(filterAdmissionsIds, admissionId)])
		);		

		if (admissionCards.length > 0) {
			for (const item of admissionCards) {
				let objectCustomRes = {};
				const selectedCustomFilter = _.find(newSavedFilters, {
					type: item?.accessor,
					filterType: ADMISSION_FILTER_TYPE.ADMISSION,
				});

				if (_.isEmpty(selectedCustomFilter)) {
					await processDynamicCard(item, patientFilterData, objectCustomRes, {
						...totalFilterData,
						totalType: TOTAL_TYPE.FILTER,
						totalForPercentage: totalPercentageCount,
					});
					if (!_.isEmpty(objectCustomRes)) {
						objectCustom[item?.accessor] = objectCustomRes?.[item?.accessor];
					}
				}
			}
		}

		for await (const ele of defaultADTData) {
			const selectedFilter = _.find(updatedADTData, { childId: ele.childId, cardId: ele.cardId });
			if (selectedFilter) {
				newADTData.push(selectedFilter);
			} else {
				let tableDataNew = await getTableDataFilter(
					ele,
					adtFilterData,
					adtTabTableData,
					lockedTotalModified ? lockedTotalModified : patientFilterData.length, //patientFilterData.length,
					{ totalType: TOTAL_TYPE.FILTER, isTooltip: true, totalForPercentage: totalPercentageCount }
				);
				newADTData.push({ ...ele, tableData: tableDataNew });
			}
		}

		if (!lockedTotalModified) {
			let lastPrioritySelected = _.last(priorityData);
			let patientLockedData = [];
			if (lastPrioritySelected && lastPrioritySelected.filterType === ADMISSION_FILTER_TYPE.ADMISSION) {
				patientLockedData = patientFilterData;
			} else if (lastPrioritySelected && lastPrioritySelected.filterType === ADMISSION_FILTER_TYPE.ADT) {
				patientLockedData = adtFilterData;
			}
			// ! problematic
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
			// store.dispatch(setLockedByFacility(patientFilterData));
		}
	} else {
		let totalCount;
		batch(async () => {
		if (lockedTotalModified) {
			totalCount = lockedTotalModified;
		} else if (mainNumPercentage) {
			totalCount = mainNumPercentage;
		} else if (transferType) {
			totalCount = patientFilterData?.length; // Optional chaining for safety
		} else {
			totalCount = censusAverage;
		}

		store.dispatch(forComparison ? setFilterTotalComparison(totalCount) : setFilterTotal(totalCount));

		if (!transferType && lockedTotalModified) {
			var isCensusTotalLocked = true;
			if (forComparison) {
				const currentIsCensusTotalLocked = store.getState().admissionComparison.isCensusTotalLocked;
				if (!_.isEqual(currentIsCensusTotalLocked, isCensusTotalLocked)) {
					store.dispatch(setIsCensusTotalLockedComparison(isCensusTotalLocked));
				}
			} else {
				const currentIsCensusTotalLocked = store.getState().admission.isCensusTotalLocked;
				if (!_.isEqual(currentIsCensusTotalLocked, isCensusTotalLocked)) {
					store.dispatch(setIsCensusTotalLocked(isCensusTotalLocked));
				}
			};
		}
		if (lockedTotalModified && !lockedTotalBy) {
			if (transferType) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(transferType))
					: store.dispatch(setLockedTotalBy(transferType));
			} else {
				forComparison
					? store.dispatch(setLockedTotalByComparison("census"))
					: store.dispatch(setLockedTotalBy("census"));
			}
		}
		if (!lockedTotalModified) {
			// ! problematic
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
		});
		[
			objectCustom.doctorData,
			objectCustom.daysData,
			objectCustom.dxData,
			objectCustom.insuranceData,
			objectCustom.floorsData,
			objectCustom.hospitalData,
			objectCustom.admissionTotal,
		] = await Promise.all([
			doctorData(patientFilterData, totalFilterData),
			daysData(patientFilterData, totalFilterData),
			dxData(patientFilterData, totalFilterData),
			insuranceData(patientFilterData, totalFilterData),
			floorsData(patientFilterData, totalFilterData),
			hospitalListData(patientFilterData, totalFilterData),
			patientFilterData?.length,
		]);

		let filterADTAdmissionId = _.map(patientFilterData, "id");
		
		adtFilterData = _.filter(adtFilterData, ({ admissionId }) =>
			_.every([_.includes(filterADTAdmissionId, admissionId)])
		);

		if (admissionCards.length > 0) {
			dynamicCardsObj = await dynamicCardFilter(patientFilterData, admissionCards, totalFilterData);
		}

		objectCustom = { ...objectCustom, ...dynamicCardsObj }

		for await (const ele of defaultADTData) {
			let tableDataNew = await getTableDataFilter(ele, adtFilterData, adtTabTableData, totalCount, {
				totalType: TOTAL_TYPE.FILTER,
				isTooltip: transferType ? true : false,				
			});
			newADTData.push({ ...ele, tableData: tableDataNew });
		}
	}
	objectCustom.isComparingAgainstAvgCensus = isComparingAgainstAvgCensus;
	objectCustom.transferType = transferType;

	return { patientList: objectCustom, adtList: newADTData };
}

async function getTableDataFilter(
	element,
	patientList,
	defaultPatientData,
	totalForPercentage,
	typeData,
	forComparison
) {
	let filtersArray = [];
	const type = element.type;

	if (element.parentId === PAGE_TYPE.HOSPITAL) {
		patientList = _.filter(patientList, ({ transferType }) =>
			_.every([_.includes(["plannedHospitalTransfer", "unplannedHospitalTransfer"], transferType)])
		);
	}
	if (element.parentId === PAGE_TYPE.COMMUNITY_TRANSFER) {
		patientList = await _.filter(patientList, ({ transferType }) =>
			_.every([_.includes(["AMA", "SNF", "safeDischarge"], transferType)])
		);
	}
	if (element.parentId === PAGE_TYPE.DECEASED) {
		patientList = await _.filter(patientList, ({ transferType }) => _.every([_.includes(["deceased"], transferType)]));
	}
	if (type === ADT_TABLE_TYPE.TOTAL) {
		filtersArray = await getADTTotalData(element, patientList, totalForPercentage, "transferType", typeData);
	}
	if (type === ADT_TABLE_TYPE.ALL) {
		filtersArray = await getADTTotalData(element, patientList, totalForPercentage, ADT_TABLE_TYPE.ALL, typeData);
	}
	if (type === ADT_TABLE_TYPE.ANALYSIS_CHART) {
		filtersArray = await getADTAnalysisChartData(
			element,
			defaultPatientData,
			patientList,
			totalForPercentage,
			typeData
		);
	}

	if (type === ADT_TABLE_TYPE.DAY_CHART) {
		filtersArray = await getADTChartDaysData(element, patientList, totalForPercentage, typeData);
	}

	if (type === ADT_TABLE_TYPE.CHART) {
		filtersArray = await getADTChartData(element, patientList, totalForPercentage, typeData);
	}

	if (type === ADT_TABLE_TYPE.GROUP) {
		filtersArray = await getADTGroupData(element, patientList, totalForPercentage, typeData);
	}

	if (type === ADT_TABLE_TYPE.GROUP_ARRAY) {
		filtersArray = await getADTGroupArrayData(element, patientList, totalForPercentage, typeData);
	}

	if (type === ADT_TABLE_TYPE.CUSTOM) {
		let objectCustomRes = {};
		await processDynamicCard(element, patientList, objectCustomRes, { totalForPercentage: totalForPercentage, totalType: TOTAL_TYPE.FILTER, isADT: true });
		filtersArray = objectCustomRes?.[element?.accessor];
	}


	return filtersArray;
}

export async function getADTDataDefault(res, adtData, forComparison = false) {
	let transferData = [];
	for await (const item of adtData) {
		const childData = await _.filter(item.children, { isSelected: true });
		for await (const ele of childData) {
			// eslint-disable-next-line no-new-object
			let tableData = await getDefaultTableData(ele, res, forComparison);
			if (
				tableData &&
				tableData.length > 0 &&
				(ele.type === ADT_TABLE_TYPE.GROUP || ele.type === ADT_TABLE_TYPE.GROUP_ARRAY)
			) {
				tableData = _.orderBy(tableData, "total", "desc");
			}
			let newItem = new Object();
			newItem.title = item.label;
			newItem.label = item.label;
			newItem.cardId = item.id;
			newItem.parentId = item.id;
			newItem.relation = ele.relation;
			newItem.helperText = ele?.helperText || "";
			newItem.childId = ele.id;
			newItem.id = ele.id;
			newItem.childId = ele.id;
			newItem.subTitle = ele.label;
			newItem.type = ele.type;
			newItem.tableData = tableData;
			newItem.chartsLabel = ele?.chartsLabel || [];
			newItem.arrayName = ele?.arrayName || [];
			newItem.validationBase = ele?.validationBase;
			newItem.customQuestionInputType = ele?.customQuestionInputType;
			newItem.customQuestionOptions = ele?.customQuestionOptions;
			newItem.validationOptions = ele?.validationOptions;
			newItem.accessor = ele?.accessor;
			transferData.push(newItem);
		}
	}
	return transferData;
}

export async function getDefaultTableData(element, adtTabTableData, forComparison) {
	let filtersArray = [];
	let patientList = [];
	let arrayData = adtTabTableData.list || [];
	const type = element.type;
	const { adtTotal } = forComparison ? store.getState().admissionComparison : store.getState().admission;
	let totalForPercentage = adtTotal;
	if (element.parentId === PAGE_TYPE.HOSPITAL) {
		patientList = arrayData
			.map((item) =>
				_.includes(["plannedHospitalTransfer", "unplannedHospitalTransfer"], item.transferType) ? item : null
			)
			.filter((item) => item);
	}
	if (element.parentId === PAGE_TYPE.COMMUNITY_TRANSFER) {
		patientList = arrayData
			.map((item) => (_.includes(["AMA", "SNF", "safeDischarge"], item.transferType) ? item : null))
			.filter((item) => item);
	}
	if (element.parentId === PAGE_TYPE.DECEASED) {
		patientList = arrayData
			.map((item) => (_.includes(["deceased"], item.transferType) ? item : null))
			.filter((item) => item);
	}
	if (element.parentId === PAGE_TYPE.OVERALL) {
		patientList = arrayData;
	}

	if (type === ADT_TABLE_TYPE.TOTAL) {
		filtersArray = getADTTotalData(element, patientList, totalForPercentage, "transferType", TOTAL_TYPE.MAIN);
	}
	if (type === ADT_TABLE_TYPE.ALL) {
		filtersArray = getADTTotalData(element, patientList, totalForPercentage, ADT_TABLE_TYPE.ALL, TOTAL_TYPE.MAIN);
	}
	if (type === ADT_TABLE_TYPE.ANALYSIS_CHART) {
		let patientListAnalysis = patientList;
		filtersArray = getADTAnalysisChartData(
			element,
			adtTabTableData,
			patientListAnalysis,
			totalForPercentage,
			TOTAL_TYPE.MAIN
		);
	}

	if (type === ADT_TABLE_TYPE.DAY_CHART) {
		filtersArray = getADTChartDaysData(element, patientList, totalForPercentage, TOTAL_TYPE.MAIN);
	}

	if (type === ADT_TABLE_TYPE.CHART) {
		filtersArray = getADTChartData(element, patientList, totalForPercentage, TOTAL_TYPE.MAIN);
	}

	if (type === ADT_TABLE_TYPE.GROUP) {
		filtersArray = getADTGroupData(element, patientList, totalForPercentage, TOTAL_TYPE.MAIN);
	}

	if (type === ADT_TABLE_TYPE.GROUP_ARRAY) {
		filtersArray = getADTGroupArrayData(element, patientList, totalForPercentage, TOTAL_TYPE.MAIN);
	}
	return filtersArray;
}
