import HeaderFilter from "../../shared/dashboard-header/HeaderFilter";
import { ADMISSION_CARDS_LABELS, ADMISSION_CARDS_TYPE } from "../../../types/admission.type";
import { ADMISSION_TYPES } from "../../../data/admission.data";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";


export const headerItemsData = [
    { label: ADMISSION_CARDS_LABELS.total, type: "label", dataKey: ADMISSION_TYPES.ALL, isMain: true, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.admission, type: "label", dataKey: ADMISSION_TYPES.ADMISSION, isMain: true, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.readmission, type: "label", dataKey: ADMISSION_TYPES.READMISSION, isMain: true, isDefault: true },

    { label: ADMISSION_CARDS_LABELS.doctorData, dataKey: ADMISSION_CARDS_TYPE.DOCTOR_DATA, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.daysData, dataKey: ADMISSION_CARDS_TYPE.DAYS_DATA, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.dxData, dataKey: ADMISSION_CARDS_TYPE.DX_DATA, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.insuranceData, dataKey: ADMISSION_CARDS_TYPE.INSURANCE_DATA, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.floorsData, dataKey: ADMISSION_CARDS_TYPE.FLOORS_DATA, isDefault: true },
    { label: ADMISSION_CARDS_LABELS.hospitalData, dataKey: ADMISSION_CARDS_TYPE.HOSPITAL_DATA, isDefault: true },

];

const AdmissionHeaderFilterList = ({
    filterListData,
    cardFilter,
    transferType,
    percentageBy,
    lockedTotal,
    lockedTotalBy,
    lockeByADT,
    openFrom = "default", // "detailView"
}) => {
    const { dynamicCards } = useSelector((state) => state.admission);

    const headerItemsDataArray = useMemo(() => {

        let headerItemsDataArr = [...headerItemsData];


        if (cardFilter?.adtAdmitPatientIds && cardFilter?.adtAdmitPatientIds.length > 0) {
            headerItemsDataArr = [...headerItemsDataArr, ...cardFilter.adtAdmitPatientIds.map((item) => ({
                label: `${item.label}: ${item?.subTitle}` || item.label,
                dataKey: item.id,
                filterType: "ADT",
                ...item
            }))];
        }

        const cardFilterKeys = Object.keys(cardFilter);

        cardFilterKeys.forEach((key) => {
            if (
                key.includes("_spacial") || 
                ["priorityData", "adtAdmitPatientIds", "mainPriorityData", "adtData", "admissionTotal"].includes(key)
            ) return;

            const existObj = headerItemsDataArr.find((item) => item.dataKey === key);

            if (!existObj) {
                const labelData = _.find(dynamicCards, { accessor: key })
                headerItemsDataArr.push({ label: labelData?.tableLabel || labelData?.label || key, dataKey: key, isDefault: false });
            }
        });

        return headerItemsDataArr;
    }, [cardFilter, dynamicCards]);

    return (
        <>
            <HeaderFilter
                items={headerItemsDataArray}
                filterListData={filterListData}
                cardFilter={cardFilter}
                transferType={transferType}
                percentageBy={percentageBy}
                lockedTotal={lockedTotal}
                lockedTotalBy={lockedTotalBy}
                lockeByADT={lockeByADT}
                openFrom={openFrom}
            />
        </>
    )
};

export default AdmissionHeaderFilterList;