import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"; // Bullet point icon
import _ from "lodash";

const renderErrors = (errors) => {
    if (!errors || Object.keys(errors).length === 0) return null;

    const errorList = [];

    const addErrorItem = (key, message) => {
        errorList.push(
            <ListItem key={key} disablePadding>
                <ListItemIcon sx={{ minWidth: "20px", fontSize: "10px" }}>
                    <FiberManualRecordIcon sx={{ fontSize: "14px" }} />
                </ListItemIcon>
                <ListItemText primary={message} primaryTypographyProps={{ sx: { color: "red", fontSize: "14px" } }} />
            </ListItem>
        );
    };

    if (errors.label) addErrorItem("label", errors.label);
    if (errors.tableLabel) addErrorItem("tableLabel", errors.tableLabel);
    if (errors.customQuestionInputType) addErrorItem("inputType", errors.customQuestionInputType);

    if (errors.customQuestionOptions) {
        if (typeof errors.customQuestionOptions === "string") {
            addErrorItem("optionsString", errors.customQuestionOptions);
        } else if (Array.isArray(errors.customQuestionOptions)) {            
            errors.customQuestionOptions.forEach((rangeErrors, index) => {                
                if (rangeErrors?.min) addErrorItem(`min-${index}`, `Min (Range ${index + 1}): ${rangeErrors.min}`);
                if (rangeErrors?.max) addErrorItem(`max-${index}`, `Max (Range ${index + 1}): ${rangeErrors.max}`);
                if (rangeErrors?.title) addErrorItem(`title-${index}`, rangeErrors?.title ? _.capitalize(rangeErrors.title) : `Title (Range ${index + 1})`);
            });
        }
    }

    return <List disablePadding>{errorList}</List>;
};

const QuestionErrorList = ({ errors, openErrorDialog, handleCloseErrorDialog }) => {
    const hasErrors = Boolean(errors && Object.keys(errors).length > 0);    
    
    return (
        <Dialog open={hasErrors && openErrorDialog} onClose={handleCloseErrorDialog}>
            <DialogTitle>Validation Errors</DialogTitle>
            <DialogContent>{renderErrors(errors)}</DialogContent>
            <DialogActions>
                <Button onClick={handleCloseErrorDialog} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuestionErrorList;
