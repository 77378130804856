import { Box, Typography } from "@mui/material";
import * as React from "react";
import IconWithCount from "../../shared/IconWithCount";

const DashboardTotalCount = ({
    isLockedTotal,
    background,
    handleChange,
    filterTotal,
    cardFilter
}) => {
    return (
        <>
            <Typography
                sx={{
                    fontFamily: "inter",
                    textTransform: "uppercase",
                    color: "#fff",
                    display: "flex",
                    direction: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <Box component="div" sx={{ mb: "-12px" }}>
                    <Typography
                        noWrap
                        color="#fff"
                        sx={{
                            fontWeight: 500,
                            fontFamily: "inter",
                            fontSize: "14px"
                        }}
                    >
                        # percentage against:
                    </Typography>
                </Box>
                <Box component={"div"} sx={{
                    alignItems: "center",
                    ...filterTotal <= 0 && {
                        marginLeft: "10px",                        
                    }
                }}>
                    <IconWithCount
                        isLockedTotal={isLockedTotal}
                        count={filterTotal}
                        handleChange={() => {
                            handleChange();
                        }}
                        background={background}
                        cardFilter={cardFilter}
                    />
                </Box>
            </Typography>
        </>
    );
};

export default DashboardTotalCount;
