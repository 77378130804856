import * as React from "react";
import { styled } from "@mui/material/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Badge, Box, IconButton, keyframes } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { isEqual, round } from "lodash";
import LockedCustomBadge from "./total-number-badge/LockedCustomBadge";


const shineEffect = keyframes`
  0% {
    background-position: bottom right;
    opacity: 1;
  }
  80% {
    background-position: top left;
    opacity: 1;
  }
  100% {
    background-position: top left;
    opacity: 0;
  }
`;

const StyledBadge = styled(Badge)(({ theme, animationKey, background, showShine }) => ({
    position: 'relative',
    '& .MuiBadge-badge': {
        right: 24,
        color: '#fff',
        fontSize: '14px',
        top: 32,
        borderRadius: '5px',
        marginRight: '1.6px',
        height: '30px',
        width: '65%',
        transition: 'width 0.7s ease, font-size 0.8s ease',
        background: background,
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(120deg, transparent 30%, rgba(255, 255, 255, 0.6) 50%, transparent 70%)',
            animationIterationCount: '1',
            // opacity: showShine ? 1 : 0,
            // transition: 'opacity 0.4s ease',
            backgroundSize: "200% 200%",
            animation: `${shineEffect} 2s ease-out 0.5s forwards`
        },
    },
}));


const LockIconButton = ({ children, handleClick }) => {
    return (
        <IconButton
            disableFocusRipple={true}
            sx={{
                height: "60px",
            }}
            onClick={handleClick}
        >
            {children}
        </IconButton>
    )
}

// Keyframe animation (Slower and with less "bounce")
const fadeIn = keyframes`
    0% { 
        opacity: 0; 
        transform: scale(0.9); // Start slightly larger
    }
    100% { 
        opacity: 1; 
        transform: scale(1); 
    }
`;

const shine = keyframes`
    0% { background-position: -200px; }
    100% { background-position: 200px; }
`;

const AnimatedCountContainer = styled(Box)` // New container
    display: inline-block;
    position: relative; // Needed for absolute positioning of shine
`;

const AnimatedCount = styled(Box)`
    display: inline-block;
    animation: ${fadeIn} 1s ease-out; // 1-second duration
`;

const Shine = styled(Box)` // Separate shine element
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255,255,255,0.3), rgba(255,255,255,0)), linear-gradient(to right, #fff, rgba(255,255,255,0)); // Gradient for shine
    background-size: 200px 100%;
    animation: ${shine} 2s linear infinite; // Shine animation
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1; // Ensure shine is on top
`;


export default function IconWithCount({
    count = 0,
    isLockedTotal,
    handleChange,
    background = "-webkit-linear-gradient(45deg, #14AAE9 0%, #8B47DA 100%)",
    cardFilter
}) {
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        setIsOpen(isLockedTotal);
    }, [isLockedTotal]);

    const [prevCount, setPrevCount] = React.useState(count);
    const [animationKey, setAnimationKey] = React.useState(0);

    React.useEffect(() => {
        if (!isEqual(prevCount, count)) {
            setAnimationKey(prev => prev + 1);
            setPrevCount(count);
        }
    }, [count, prevCount]);


    const isEqualObjects = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    };

    const cardFilterRef = React.useRef(cardFilter);

    React.useEffect(() => {
        if (!isEqualObjects(cardFilterRef.current, cardFilter) && isLockedTotal) {
            setAnimationKey(prev => prev + 1);
        }
        // Update the ref to the latest value
        cardFilterRef.current = cardFilter;
    }, [cardFilter, isLockedTotal]);

    const badgeContent = (
        <AnimatedCountContainer> {/* Wrap with the container */}
            <AnimatedCount key={animationKey}>
                {round(count)}
            </AnimatedCount>
            <Shine /> {/* Shine element */}
        </AnimatedCountContainer>
    );

    const [showShine, setShowShine] = React.useState(false);

    React.useEffect(() => {
        setShowShine(true);
        const timer = setTimeout(() => {
            setShowShine(false);
        }, 4000); // Adjust timeout as needed

        return () => clearTimeout(timer);
    }, [animationKey]);

    if (count > 0) {
        if (!isOpen) {
            return (
                <LockIconButton
                    handleClick={handleChange}
                >
                    <StyledBadge
                        animationKey={animationKey}
                        key={animationKey}
                        showShine={showShine}
                        sx={{
                            ".MuiBadge-badge": {
                                background,
                                ...(count && round(count).toString().length === 5 && {
                                    width: "80% !important",
                                    fontSize: "13.5px",
                                }),
                                ...(count && round(count).toString().length >= 6 && {
                                    width: "84% !important",
                                    fontSize: "12px",
                                })
                            },
                        }}
                        badgeContent={badgeContent}
                        color="success"
                        max={9999999}
                    >
                        <LockOpenIcon
                            sx={{
                                fontSize: 51,
                                color: "#fff",
                            }}
                            color="primary"
                        />
                    </StyledBadge>
                </LockIconButton>
            );
        } else {
            return (
                <LockIconButton
                    handleClick={handleChange}
                >
                    <LockedCustomBadge
                        count={count}
                        badgeContent={round(count)}
                        background={background}
                        icon={LockOutlinedIcon}
                        animationKey={animationKey}
                    />
                </LockIconButton>
            );
        }
    }
    return null;
}
