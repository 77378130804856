import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setDynamicCards as setDynamicDeceasedCards } from "../../store/reducers/deceased.slice";
import { setDynamicCards as setDynamicHospitalCards } from "../../store/reducers/hospital.slice";
import { setDynamicCards as setDynamicAdmissionCards } from "../../store/reducers/admission.slice";
import { setDynamicCards as setDynamicCommunityTransferCards } from "../../store/reducers/community-transfer.slice";

const useResetDynamicCards = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(setDynamicHospitalCards([]));
        dispatch(setDynamicAdmissionCards([]));
        dispatch(setDynamicCommunityTransferCards([]));
        dispatch(setDynamicDeceasedCards([]));
    }, [dispatch]);
};

export default useResetDynamicCards;