import { useSelector } from "react-redux";
import { useMemo } from "react";
import { Paper, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays, getPositivePlusSign, itemPercentage } from "../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../utilis/percentage-comparison";
import CardItemTooltip from "../../shared/CardItemTooltip";
import NoRecordFound from "../../shared/NoRecordFound";
import HospitalizationsProgressBar from "../hospital/hospitalizationsList/HospitalizationsProgressBar";
import InsuranceCardSkeleton from "./InsuranceCardSkeleton";
import EllipsisTooltipTypography from "../shared/EllipsisTooltipTypography";
import CheckboxLoader from "../../shared/checkbox-loader/CheckboxLoader";
import useLoadingToggle from "../../hooks/useLoadingToggle";

export const InsuranceListItem = styled(ListItem)(() => ({
	paddingTop: 0,
	paddingBottom: 0,
	margin: "2px 0px 2px 0px",
	"& .MuiListItemIcon-root": {
		minWidth: 0,
	},
	"& .MuiButton-root": {
		width: "66px",
		height: "24px",
		borderRadius: "5px",
		fontSize: "10px",
		fontFamily: "manrope-bold",
		"&.selected": {
			background: "#4879F5",
			color: "#FFFFFF !important",
		},
	},
	"& .MuiCheckbox-root": {
		padding: 0,
		paddingRight: "4px",
	},
	"&.Mui-selected, .Mui-focusVisible": {
		backgroundColor: "#fff",
		"&:before": {
			content: `""`,
			display: "block",
			height: "16px",
			width: "16px",
			backgroundColor: "#4879f5",
			position: "absolute",
			left: "-1px",
			transform: "translateX(-100%)",
			borderRadius: "50%",
			transition: "all 0.2s",
		},
	},
	"& .MuiListItemButton-root": {
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	".insuranceProgressBar": {
		width: "65%",
		"& .MuiButtonBase-root": {
			color: "#444652",
			"&:hover": {
				background: "#EEF3F7",
			},
		},
	},
}));

const YesPaper = styled(Paper)`
	&::-webkit-scrollbar {
		background-color: #fff;
		height: 4px;
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background-color: #fff;
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #babac0;
		border-radius: 4px;
	}
`;

const InsuranceCard = ({
	data,
	dataComparison,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	averageCensus,
	averageCensusComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	page,
	filter,
	filterComparison,
	height,
	isPercentageShow = true,
	totalField = "total",
	cardTitle,
	reverseColors,
	reverseColorsAdmissionPage,
	loading,
	projectionDays,
	isComparingAgainstAvgCensus,
	transferType,
	priorityNumber,
	lockedTotalBy,
	searchValue,
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const isFirstItemInPriorityData = priorityNumber === 1;
	const { comparingAgainstDifferentFacility } = useSelector(state => state.comparison);

	const isPlusSign = useMemo(() => {
		return page === PAGE_TYPE.OVERALL && transferType === OVERALL_PAGE_SUB_TYPE.TOTAL;
	}, [page, transferType]);

	const { loadingItems, handleToggleWithLoader } = useLoadingToggle();

	return (
		<YesPaper style={{ minHeight: 350, height: 350, overflow: "auto", width: "100%" }}>
			<List
				sx={{
					width: "100%",
					padding: 1,
					...(height && { height }),
				}}
			>
				{loading ? (
					<InsuranceCardSkeleton />
				) : (
					data.length > 0 &&
					data
						.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
						.map((item, index) => {
							const ID = item._id;
							const labelId = `checkbox-list-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							let itemComparison = dataComparison?.find((x) => x._id === item._id);
							if (comparingAgainstDifferentFacility) {
								itemComparison = dataComparison?.find((x) => x?.label === item?.label);
							}
							// const itemComparison = dataComparison?.find((x) => x._id === item._id);
							const itemTotal = !isNaN(Number(item.total)) ? Number(item.total) : 0;
							const itemTotalComparison = !isNaN(Number(itemComparison?.[totalField]))
								? Number(itemComparison?.[totalField])
								: 0;
							let comparisonColor;
							let comparingAgainstScaled;
							let numberOfDays;

							if (page === PAGE_TYPE.ADMISSION) {
								const itemTotalTemp = itemPercentage(itemTotal, admissionCompareAgainst, "number");
								const itemTotalComparisonTemp = itemPercentage(
									itemComparison?.total,
									admissionCompareAgainstComparison,
									"number"
								);
								const {
									percentageDiff,
									itemTotalComparisonScaled,
									numberOfDays: numberOfDaysTemp,
								} = calculateDiffBetweenPercentages(
									itemTotalTemp,
									itemTotalComparisonTemp,
									reverseColorsAdmissionPage,
									filter,
									filterComparison,
									itemComparison?.total,
									projectionDays
								);

								numberOfDays = numberOfDaysTemp;
								comparingAgainstScaled = itemTotalComparisonScaled;
								comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
							} else {
								const percentageOfAverageCensus = itemPercentage(itemTotal, averageCensus, "number");
								const percentageOfAverageCensusComparison = itemPercentage(
									itemTotalComparison,
									averageCensusComparison,
									"number"
								);
								const {
									percentageDiff,
									itemTotalComparisonScaled,
									numberOfDays: numberOfDaysTemp,
								} = calculateDiffBetweenPercentages(
									percentageOfAverageCensus,
									percentageOfAverageCensusComparison,
									reverseColors,
									filter,
									filterComparison,
									itemComparison?.total,
									projectionDays
								);

								numberOfDays = numberOfDaysTemp;
								comparingAgainstScaled = itemTotalComparisonScaled;
								comparisonColor = pickComparisonColor(percentageDiff);
							}

							return (
								<InsuranceListItem key={ID} selected={selected} disableRipple={true}>
									<ListItemButton
										role={undefined}
										onClick={() =>
											handleToggleWithLoader(async () => await handleToggle({
												item,
												type,
												itemComparison,
												isChecked: selected,
												cardTitle,
											}), item._id)
										}
										dense={true}
										disableGutters
										disableRipple={true}
									>
										<ListItemIcon>
											<Box position="relative" display="inline-flex" alignItems="center">
												<Checkbox
													edge="start"
													checked={selected}
													tabIndex={-1}
													disableRipple
													inputProps={{ "aria-labelledby": labelId }}
													sx={{
														...(loadingItems[ID] && { opacity: 0 })
													}}
												/>
												<CheckboxLoader isLoading={loadingItems[ID]} />
											</Box>
										</ListItemIcon>
										<CardItemTooltip item={item}>
											<ListItemText
												primaryTypographyProps={{
													variant: "subtitle3",
													noWrap: true,
													sx: {
														maxWidth: { xs: "85px", lg: "150px", },
														minWidth: { xs: "85px", lg: "150px", },
														width: { xs: "85px", lg: "150px", },
													},
												}}
												disableRipple
												id={labelId}
												primary={<EllipsisTooltipTypography label={item.label} />}
											/>
										</CardItemTooltip>

										<HospitalizationsProgressBar
											comparisonColor={comparisonColor}
											comparingAgainstScaled={isPlusSign ? getPositivePlusSign(comparingAgainstScaled || 0) : comparingAgainstScaled || 0}
											comparingAgainst={itemComparison?.total}
											numberOfDays={numberOfDays}
											className={"insuranceProgressBar"}
											btnBgColor={"#EEF3F7"}
											selected={selected}
											progressColor={"#92C7FF"}
											progressHeight={calcProPercentsBasedOnFilterAndDays(
												item.percentage,
												filter,
												projectionDays,
												true,
												isComparingAgainstAvgCensus ||
												lockedTotalBy === "census" ||
												(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
											)}
											total={calcProPercentsBasedOnFilterAndDays(item.total, { ...filter, transferType }, projectionDays)}
											percentage={`(${calcProPercentsBasedOnFilterAndDays(
												item.percentage,
												filter,
												projectionDays,
												true,
												isComparingAgainstAvgCensus ||
												lockedTotalBy === "census" ||
												(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
											)}%)`}
											isPercentageShow={isPercentageShow}
											loading={loading}
										/>
									</ListItemButton>
								</InsuranceListItem>
							);
						})
				)}
				<NoRecordFound data={data} />
			</List>
		</YesPaper >
	);
};

export default InsuranceCard;
