import classNames from "classnames";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import ColorBox from "../../../shared/dashboard/ColorBox";
import styles from "./TotalOverallCard.module.scss";

const TotalOverallCard = ({
	total,
	totalComparison,
	isProjection = false,
	totalPercentage,
	totalProjection = 0,
	totalProjectionPercentage,
	subTitleText = "Avg. Cencus",
	totalWithOutSign,
	page,
	isCompareColor,
	averageCensus,
	filter,
	filterComparison,
	averageCensusComparison,
	loading,
	projectionDays,
	filterTotal,
	type
}) => {
	
	const percentage = itemPercentage(totalWithOutSign, averageCensus, "number", page, true);
	const percentageComparison = itemPercentage(totalComparison, averageCensusComparison, "number", page, true);
	
	const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
		percentage,
		percentageComparison,
		true,
		filter,
		filterComparison,
		totalComparison,
		projectionDays
	);

	const comparisonDiffColor = pickComparisonColor(percentageDiff, true);

	return (
		<div className={`df aic ${styles.totalOverall} ${type === "total" ? styles.mainTotalCard : ""}`}>
			<div className={classNames(styles.totalOverallContainer, styles.skeleton)}>
				<div
					className={classNames(
						"ffmar-bold",
						"fw700",
						"fs49",
						"df",
						"aic",
						styles.gradientTextPrimary,
						loading && styles.skeleton
					)}
				>
					{loading ? (
						<span className="skeleton-text">0</span>
					) : (
						(calcProPercentsBasedOnFilterAndDays(total, filter, projectionDays) < 0 ? "" : "+") + calcProPercentsBasedOnFilterAndDays(total, filter, projectionDays)
					)}
					{comparisonDiffColor && isCompareColor && !loading && (
						<ColorBox
							color={comparisonDiffColor}
							comparingAgainst={totalComparison}
							comparingAgainstScaled={itemTotalComparisonScaled}
							numberOfDays={numberOfDays}
						/>
					)}
				</div>
				{totalPercentage && (
					<div className={`${styles.textNumberContainer}`}>
						<div className={`${styles.gradientTextSecondary}`}>
							{calcProPercentsBasedOnFilterAndDays(totalPercentage, filter, projectionDays, filterTotal, averageCensus)}
						</div>
						<div className={`${styles.gradientTextSubtitle}`}>{subTitleText}</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TotalOverallCard;
