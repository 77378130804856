import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import AsstLivingRedialChart from "../../community-transfer/safeDischargeAssLivingSlider/AsstLivingRedialChart";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./HospitalSlider.module.scss";
import HospitalSliderSkeleton from "./HospitalSliderSkeleton";
import useLoadingToggle from "../../../hooks/useLoadingToggle";
import CheckboxLoader from "../../../shared/checkbox-loader/CheckboxLoader";

const HospitalSlider = ({
	data,
	dataComparison,
	totalTransfers,
	filter,
	filterCommaprison,
	totalTransfersComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	cardTitle,
	comparingAgainstDifferentFacility,
	loading,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
}) => {
	const sliderRef = useRef();
	const [slidData, setSlidData] = useState([]);
	const isFirstItemInPriorityData = priorityNumber === 1;
	const { loadingItems, handleToggleWithLoader } = useLoadingToggle();

	useEffect(() => {
		setSlidData([]);
		setSlidData(data);
	}, [data]);

	const settings = {
		infinite: false,
		dots: true,
		slidesToShow: 3,
		slidesToScroll: 1,
	};

	return loading ? (
		<HospitalSliderSkeleton />
	) : (
		<div className={"no-return-slider"}>
			{slidData?.length > 0 && (
				<Slider ref={sliderRef} {...settings}>
					{slidData
						.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
						.map((ele, index) => {
							const ID = ele._id;
							const labelId = `checkbox-list-floor-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							const returnedPercentage = itemPercentage(ele.totalTransfer, totalTransfers, "number"); //itemPercentage(ele?.totalTransfer || 0, totalTransfers, "number");
							const didntReturnPercentage = itemPercentage(ele.totalDCCount, ele.totalTransfer, "number");
							const eleComparison = _.find(dataComparison, { _id: ele._id });
							const returnedPercentageComparison = itemPercentage(
								eleComparison?.totalTransfer,
								totalTransfersComparison,
								"number"
							);
							const didntReturnPercentageComparison = itemPercentage(
								eleComparison?.totalDCCount,
								totalTransfersComparison,
								"number"
							);

							const {
								percentageDiff: percentageDiffTransferred,
								itemTotalComparisonScaled: itemTotalComparisonScaledTransferred,
								numberOfDays: numberOfDaysTransferred,
							} = calculateDiffBetweenPercentages(
								returnedPercentage,
								returnedPercentageComparison,
								false,
								filter,
								filterCommaprison,
								eleComparison?.totalTransfer,
								projectionDays
							);
							const {
								percentageDiff: percentageDiffDidntReturn,
								itemTotalComparisonScaled: itemTotalComparisonScaledDidntReturn,
								numberOfDays: numberOfDaysDidntReturn,
							} = calculateDiffBetweenPercentages(
								didntReturnPercentage,
								didntReturnPercentageComparison,
								false,
								filter,
								filterCommaprison,
								eleComparison?.totalDCCount,
								projectionDays
							);

							let comparisonColorTransferred = pickComparisonColor(percentageDiffTransferred);
							let comparisonColorDidntReturn = pickComparisonColor(percentageDiffDidntReturn);

							if (comparingAgainstDifferentFacility) {
								comparisonColorTransferred = null;
								comparisonColorDidntReturn = null;
							}

							return (
								<Card className={`${styles.slideCard} ${selected ? styles.selected : null}`} key={"row" + index}>
									<CardContent className={styles.slideCardContent}>
										<Grid container direction={"row"}>
											<Grid item xs={8}>
												<CardItemTooltip item={ele}>
													<Typography sx={{ mb: 1, ml: 0.5 }} variant="subtitle1">
														{ele.name}
													</Typography>
												</CardItemTooltip>
												<Box display={"flex"} alignItems={"center"}>
													<div className={styles.labelCircle} style={{ backgroundColor: "#15BDB2" }} />
													<p className={`ffmar-bold fs12 fw700 df aic`}>
														{!loading && (
															<ColorBox
																type="list"
																sx={{ height: "18px", mr: 0.5, ml: 0.5 }}
																color={comparisonColorTransferred}
																comparingAgainst={eleComparison?.totalTransfer || 0}
																comparingAgainstScaled={itemTotalComparisonScaledTransferred}
																numberOfDays={numberOfDaysTransferred}
															/>
														)}
														Transferred:{" "}
														{calcProPercentsBasedOnFilterAndDays(ele.totalTransfer, filter, projectionDays)}{" "}
														{percentageLabel(
															calcProPercentsBasedOnFilterAndDays(
																ele.percentage,
																filter,
																projectionDays,
																true,
																isComparingAgainstAvgCensus ||
																lockedTotalBy === "census" ||
																(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
															)
														)}
													</p>
												</Box>
												<div className={`df aic ${styles.textWrprReturn}`}>
													<div className={styles.labelCircle} style={{ backgroundColor: "#076673" }} />
													<p className={`ffmar-bold fs12 fw700 df aic`}>
														{!loading && (
															<ColorBox
																type="list"
																sx={{ height: "19px", mr: 0.5, ml: 0.5 }}
																color={comparisonColorDidntReturn}
																comparingAgainst={eleComparison?.totalDCCount || 0}
																comparingAgainstScaled={itemTotalComparisonScaledDidntReturn}
																numberOfDays={numberOfDaysDidntReturn}
															/>
														)}
														DC: {calcProPercentsBasedOnFilterAndDays(ele.totalDCCount, filter, projectionDays)}{" "}
														{percentageLabel(
															calcProPercentsBasedOnFilterAndDays(
																didntReturnPercentage,
																filter,
																projectionDays,
																true,
																isComparingAgainstAvgCensus ||
																lockedTotalBy === "census" ||
																(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
															)
														)}
													</p>
												</div>
											</Grid>
											<Grid item xs={4}>
												<AsstLivingRedialChart data={ele.graphData} />
											</Grid>
										</Grid>

										<div className={`df fs15 fw500 ${styles.checkboxButtonContainer}`}>
											<Box position="relative" display="inline-flex" alignItems="center">
												<CheckboxButton
													className={styles.checkboxButton}
													labelId={labelId}
													handleToggle={() =>
														handleToggleWithLoader(() => handleToggle({
															item: ele,
															type,
															itemComparison: eleComparison,
															setSpecialNumberFlag: true,
															isChecked: selectedItem.indexOf(ID) !== -1,
															cardTitle,
														}), ele?._id)
													}
													sx={{
														...(loadingItems[ele?._id] && { opacity: 0 })
													}}
													checked={selectedItem.indexOf(ID) !== -1}
												/>
												<CheckboxLoader
													className={"small-dots-left"}
													isLoading={loadingItems[ele?._id]}
												/>
											</Box>
										</div>
									</CardContent>
								</Card>
							);
						})}
				</Slider>
			)}
		</div>
	);
};

export default HospitalSlider;
