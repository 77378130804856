import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import _ from "lodash";
import { useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import ReportTable from "../../components/report";
import { ADMISSION_TYPES } from "../../data/admission.data";
import { ReportTransferTypeTitleEnum } from "../../data/common.data";
import { TYPES } from "../../data/hospital.data";
import { saveReportSubscription } from "../../services/report-subscription.service";
import { ADD_NOTIFICATION } from "../../store/types";
import { ADMISSION_FILTER_TYPE } from "../../types/admission.type";
import { CHART_TAB_BUTTON } from "../../types/chart-dialog.type";
// import { DASHBOARD_FILTER_TYPE } from "../../types/common.type";
import { CO_TRANSFER_CARDS_TYPE, TRANSFER_TYPE } from "../../types/community-transfer.type";
import { HOSPITAL_CARDS_TYPE } from "../../types/hospital.type";
import { ACCOUNT_PERCENTAGE_BY, OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../../types/pages.type";
import { getQuestionAnswer } from "../../utilis/charts-common";
import { getDynamicPercentageBy, getPercentageByTotal, getPercentageByType, itemPercentage, patientDataOrderBy } from "../../utilis/common";
import { dateFormat, toDisplayTime } from "../../utilis/date-formats";

const usePdfReport = () => {
	const pdfRef = useRef(null);
	const facilities = useSelector((state) => state.facility);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const deceasedState = useSelector((state) => state.deceased);
	const hospitalState = useSelector((state) => state.hospital);
	const communityTransferState = useSelector((state) => state.communityTransfer);
	const admissionState = useSelector((state) => state.admission);
	const overallState = useSelector((state) => state.overall);
	const dispatch = useDispatch();
	const { percentageBy } = useSelector((state) => state.common);

	const getFacilityNames = () => {
		let names = [];
		if (facilities.length > 0 && activeFacilities.length > 0) {
			// eslint-disable-next-line array-callback-return
			facilities?.find((acc) => {
				if (_.includes(activeFacilities, acc._id)) {
					names.push(acc?.name);
				}
			});
			return names.length > 0 ? names.join(", ") : null;
		}
		return null;
	};

	const filterWithChangeObj = (data, percentageAgainst, filterItem = null, filterItemGraph) => {
		if (data.length > 0) {
			return data.map((ele) => {
				return {
					...ele,
					total: ele?.total || ele?.value || 0,
					percentage: ele.percentage ? `${ele.percentage}%` : "",
					percentageAgainst,
					...(filterItem && { filterItem }),
					...(filterItemGraph && { filterItemGraph }),
				};
			});
		} else {
			return [];
		}
	};

	const filterByItems = async (cardFilter, ele, dataType, isSpecialFilter = false) => {
		const specialFilter = isSpecialFilter ? _.get(cardFilter, `${ele.type}_spacial`, []) : [];
		const baseFilter = _.get(cardFilter, ele.type, []);

		const responseArr = await _.filter(dataType, ({ _id }) =>
			_.includes([...baseFilter, ...specialFilter], _id)  // Combine both filters
		);
		return responseArr;
	};

	const renderContent = async (props) => {
		const facilityNames = getFacilityNames();

		const {
			cardFilter,
			filterListData,
			type,
			page = null,
			dbData,
			isTotalCard = false,
			isAdt = false,
			selectedADTTableData = [],
			admissionReportType = null,
			graphHtml,
			graphData,
			isGraphReport = false,
		} = props;
		// console.log(props, 'props');
		
		let transferType;
		let lockedTotalBy = null;
		let percentageAgainstSelected = null
		if (page === PAGE_TYPE.DECEASED) {
			percentageAgainstSelected = await getDynamicPercentageBy(deceasedState?.dbData, percentageBy);
			transferType = deceasedState?.transferType;
			lockedTotalBy = deceasedState?.lockedTotalBy;
		} else if (page === PAGE_TYPE.HOSPITAL) {
			percentageAgainstSelected = await getDynamicPercentageBy(hospitalState?.dbData, percentageBy);
			transferType = hospitalState?.transferType;
			lockedTotalBy = hospitalState?.lockedTotalBy;
		} else if (page === PAGE_TYPE.ADMISSION) {
			percentageAgainstSelected = await getDynamicPercentageBy(admissionState?.dbData, percentageBy);
			transferType = admissionState?.transferType;
			lockedTotalBy = admissionState?.lockedTotalBy;
		} else if (page === PAGE_TYPE.OVERALL) {
			percentageAgainstSelected = await getDynamicPercentageBy(overallState?.dbData, percentageBy);
			transferType = overallState?.transferType;
			lockedTotalBy = overallState?.lockedTotalBy;
		} else if (page === PAGE_TYPE.COMMUNITY_TRANSFER) {
			percentageAgainstSelected = await getDynamicPercentageBy(communityTransferState?.dbData, percentageBy);
			transferType = communityTransferState?.transferType;
			lockedTotalBy = communityTransferState?.lockedTotalBy;
		}
		let cardData = [];
		let cardFilterData = [];
		if (page !== PAGE_TYPE.ADMISSION) {
			cardFilterData = cardFilter.priorityData;
		} else {
			cardFilterData = cardFilter.mainPriorityData;
		}
		const percentageByText = `Against ${percentageBy}`;
		let percentageAgainst = lockedTotalBy ? lockedTotalBy : percentageByText;
		let isPercentage = page === PAGE_TYPE.OVERALL && transferType === "total" ? false : true;
		let lockedTitleSet = true;
		let alreadyChecked = false;
		if (isTotalCard) {
			cardData.push({
				title: _.capitalize(props?.title),
				type: "total",
				data: [
					{
						label: _.capitalize(type),
						total: dbData[type],
						percentage: `${itemPercentage(dbData[type], getPercentageByTotal(dbData), "number")}%`,
						percentageAgainst: percentageByText,
					},
				],
			});
		} else {
			if (transferType) {
				let total;
				if (page === PAGE_TYPE.ADMISSION) {
					total =
						transferType === "all"
							? dbData?.total
							: transferType === ADMISSION_TYPES.ADMISSION
								? dbData?.totalAdmissions
								: dbData?.totalReAdmissions;
				} else {
					total = transferType === "all" ? dbData?.total : dbData[transferType] || 0;
				}
				if (Array.isArray(transferType)) {
					if (transferType.length > 0) {
						let title;
						if (transferType.length > 0 && _.includes(transferType, "all")) {
							percentageAgainst = `Against Total Community Transfers`;
							total = dbData?.total;
							title = "Total Community Transfers";
							if (lockedTotalBy && lockedTotalBy === "transferType") {
								percentageAgainst = `Against ${title}`;
								title += " (Locked)";
								lockedTitleSet = false;
							}
						} else {
							percentageAgainst = `Against ${transferType.toString()}`;
							let totalSub = 0;
							title = transferType.toString();
							if (lockedTotalBy && lockedTotalBy === "transferType") {
								percentageAgainst = `Against ${title}`;
								title += " (Locked)";
								lockedTitleSet = false;
							}
							// eslint-disable-next-line array-callback-return
							transferType.map((ele) => {
								if (ele === TRANSFER_TYPE.SAFE_DISCHARGE) {
									totalSub += dbData?.totalSafeDischarge || 0;
								}
								if (ele === TRANSFER_TYPE.SNF) {
									totalSub += dbData?.totalSNF || 0;
								}
								if (ele === TRANSFER_TYPE.AMA) {
									totalSub += dbData?.totalAMA || 0;
								}
							});
							total = totalSub;
						}
						cardData.push({
							title: title,
							type: "total",
							data: [
								{
									total: total,
									percentage: `${itemPercentage(total, getPercentageByTotal(dbData), "number")}%`,
									percentageAgainst: percentageByText,
								},
							],
						});
					}
				} else {
					let transferTypeTitle = ReportTransferTypeTitleEnum[transferType]
						? ReportTransferTypeTitleEnum[transferType]
						: transferType;
					percentageAgainst = `Against ${transferTypeTitle}`;
					let mainTitle = transferType === "all" ? transferTypeTitle + " " + page : transferTypeTitle;
					if (transferType === "all" && page === PAGE_TYPE.HOSPITAL) {
						mainTitle = "Total Hospital Transfers";
						percentageAgainst = `Against ${transferTypeTitle}`;
					} else if (transferType === "all" && page === PAGE_TYPE.ADMISSION) {
						mainTitle = "Total Admissions";
						percentageAgainst = transferType === "all" ? `Against ${mainTitle}` : `Against ${transferTypeTitle}`;
					}
					if (
						lockedTotalBy &&
						(lockedTotalBy === "all" ||
							lockedTotalBy === ADMISSION_TYPES.ADMISSION ||
							lockedTotalBy === ADMISSION_TYPES.READMISSION ||
							lockedTotalBy === TYPES.PLANNED ||
							lockedTotalBy === TYPES.UNPLANNED ||
							lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING ||
							lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING)
					) {
						let transferTypeLockedTitle = ReportTransferTypeTitleEnum[lockedTotalBy]
							? ReportTransferTypeTitleEnum[lockedTotalBy]
							: transferType;
						let mainTitleLocked =
							lockedTotalBy === "all" ? transferTypeLockedTitle + " " + page : transferTypeLockedTitle;
						if (lockedTotalBy === "all" && page === PAGE_TYPE.HOSPITAL) {
							mainTitleLocked = "Total Hospital Transfers";
						} else if (lockedTotalBy === "all" && page === PAGE_TYPE.ADMISSION) {
							mainTitleLocked = "Total Admissions";
						}
						percentageAgainst = `Against ${mainTitleLocked}`;
						mainTitleLocked += " (Locked)";
						lockedTitleSet = false;
						mainTitle = mainTitleLocked;
						if (page === PAGE_TYPE.ADMISSION) {
							total =
								lockedTotalBy === "all"
									? dbData?.total
									: lockedTotalBy === ADMISSION_TYPES.ADMISSION
										? dbData?.totalAdmissions
										: dbData?.totalReAdmissions;
						} else {
							total = lockedTotalBy === "all" ? dbData?.total : dbData[lockedTotalBy] || 0;
						}
					}
					cardData.push({
						title: mainTitle,
						type: "total",
						data: [
							{
								total: total,
								percentage: `${itemPercentage(total, getPercentageByTotal(dbData), "number")}%`,
								percentageAgainst: percentageByText,
							},
						],
					});
					if (lockedTotalBy === "all" && transferType !== "all") {
						let transferTotal = transferType === "all" ? dbData?.total : dbData[transferType] || 0;
						let transferTypeTitle2 = ReportTransferTypeTitleEnum[transferType]
							? ReportTransferTypeTitleEnum[transferType]
							: transferType;
						if (page === PAGE_TYPE.ADMISSION) {
							transferTotal =
								transferType === "all"
									? dbData?.total
									: transferType === ADMISSION_TYPES.ADMISSION
										? dbData?.totalAdmissions
										: dbData?.totalReAdmissions;
						}
						cardData.push({
							title: transferTypeTitle2,
							type: "total",
							data: [
								{
									total: transferTotal,
									percentage: transferTotal ? `${itemPercentage(transferTotal, getPercentageByTotal(dbData), "number")}% ` : "",
									percentageAgainst: percentageByText,
								},
							],
						});
					}
				}
			}

			if (cardFilterData.length > 0 && !isTotalCard) {
				let i = 0;
				let filterItems = [];
				let filterItemGraph = [];
				if (isAdt) {
					//ADT report DATA
					for await (const ele of cardFilterData) {
						if (ele.filterType === ADMISSION_FILTER_TYPE.ADMISSION) {
							let dataType = filterListData[ele.type] || [];
							if (dataType.length > 0 && !alreadyChecked) {
								const onlyFilterData = await filterByItems(cardFilter, ele, dataType, ele.type === type);
								let title = ele.cardTitle;
								if (lockedTotalBy && lockedTotalBy === ele.type) {
									title = `${ele.cardTitle} (Locked)`;
									percentageAgainst = ele.cardTitle;
									lockedTitleSet = false;
								}
								if (onlyFilterData.length > 0) {
									let filtersNames = _.map(onlyFilterData, "label");
									filterItemGraph.push(filtersNames);
									cardData.push({
										title: title,
										data: filterWithChangeObj(
											onlyFilterData,
											percentageAgainst,
											filterItems[i - 1] || null,
											filterItemGraph[i]
										),
									});
									filterItems.push(filtersNames);
								}
							}
							if (lockedTitleSet) {
								let againstTitle = ReportTransferTypeTitleEnum[ele.type]
									? ReportTransferTypeTitleEnum[ele.type]
									: ele.type;
								percentageAgainst = `Against ${againstTitle}`;
							}
							if (ele.type === type) {
								alreadyChecked = true;
							}
						} else {
							const { parentId, childId } = ele;
							const selectedADTTable = _.find(selectedADTTableData, { parentId, childId });
							let selectedADTTableDataArr = [];
							if (cardFilter?.adtAdmitPatientIds?.length > 0) {
								selectedADTTableDataArr = _.find(cardFilter?.adtAdmitPatientIds, ({ parentId, childId }));
							}

							let onlyFilterData = selectedADTTable?.tableData;
							if (
								(selectedADTTableDataArr?.selectedIds?.length || 0) > 0 ||
								(selectedADTTableDataArr?.selectedSpecialIds?.length || 0) > 0
							) {
								const selectedIds = selectedADTTableDataArr?.selectedIds || [];
								const selectedSpecialIds = selectedADTTableDataArr?.selectedSpecialIds || [];

								onlyFilterData = selectedADTTable?.tableData?.filter((eleItem) => {
									const applicableSpecialIds =
									ele.parentId === type.parentId && ele.childId === type.childId
											? selectedSpecialIds
											: [];

									return _.includes([...selectedIds, ...applicableSpecialIds], eleItem?.id);
								});
							}

							let title = `${ele?.subTitle} (${_.capitalize(ele.title)})`;

							if (lockedTotalBy && lockedTotalBy === ele.type + "_" + ADMISSION_FILTER_TYPE.ADT) {
								title = `${selectedADTTable?.subTitle} (${_.capitalize(selectedADTTable.title)}) (Locked)`;
								percentageAgainst = `${selectedADTTable?.subTitle} (${_.capitalize(selectedADTTable.title)})`;
								lockedTitleSet = false;
							}
							if (onlyFilterData.length > 0) {
								let filtersNames = _.map(onlyFilterData, "label");
								filterItemGraph.push(filtersNames);
								cardData.push({
									title: title,
									data: filterWithChangeObj(
										onlyFilterData,
										percentageAgainst,
										filterItems[i - 1] || null,
										filterItemGraph[i]
									),
								});
								filterItems.push(filtersNames);
							}
							if (lockedTitleSet) {
								percentageAgainst = `Against ${selectedADTTable?.subTitle} (${_.capitalize(selectedADTTable.title)})`;
							}
							if (ele.parentId === type.parentId && ele.childId === type.childId) {
								alreadyChecked = true;
							}
						}
						i++;
					}
					if (!alreadyChecked) {
						if (admissionReportType === ADMISSION_FILTER_TYPE.ADT) {
							let title2 = _.capitalize(props?.title);
							const { parentId, childId } = type;
							const selectedADT = _.find(selectedADTTableData, { parentId, childId });
							if (selectedADT) {
								title2 = `${selectedADT?.subTitle} (${_.capitalize(selectedADT.title)})`;
							}
							cardData.push({
								title: _.capitalize(title2),
								data: filterWithChangeObj(selectedADT?.tableData || [], percentageAgainst),
							});
						} else {
							let cardItemsArr = filterListData[type] || [];

							if (_.get(cardFilter, `${type}_spacial`, []).length > 0) {
								cardItemsArr = cardItemsArr.filter(({ _id }) =>
									_.includes(cardFilter[`${type}_spacial`], _id)
								);
							}
							cardData.push({
								title: props?.title,
								data: filterWithChangeObj(
									cardItemsArr || [],
									percentageAgainst,
									filterItems[cardFilterData?.length - 1],
									filterItemGraph[cardFilterData?.length]
								),
							});
						}
					}
					//END OF ADT report DATA
				} else {
					for await (const ele of cardFilterData) {
						let dataType = filterListData[ele.type] || [];
						if (dataType.length > 0 && !alreadyChecked) {
							const onlyFilterData = await filterByItems(cardFilter, ele, dataType, ele.type === type);
							let title = ele.cardTitle;
							if (lockedTotalBy && lockedTotalBy === ele.type) {
								title = `${ele.cardTitle} (Locked)`;
								percentageAgainst = ele.cardTitle;
								lockedTitleSet = false;
							}
							if (onlyFilterData.length > 0) {
								let filtersNames = _.map(onlyFilterData, "label");
								filterItemGraph.push(filtersNames);
								cardData.push({
									title: title,
									data: filterWithChangeObj(
										onlyFilterData,
										percentageAgainst,
										filterItems[i - 1] || null,
										filterItemGraph[i] || null
									),
								});
								filterItems.push(filtersNames);
							}
						}
						if (lockedTitleSet) {
							percentageAgainst = `Against ${ele.cardTitle}`;
						}
						if (ele.type === type) {
							alreadyChecked = true;
						}
						i++;
					}
					if (!alreadyChecked) {
						let cardItemsArr = filterListData[type] || [];

						if (_.get(cardFilter, `${type}_spacial`, []).length > 0) {
							cardItemsArr = cardItemsArr.filter(({ _id }) =>
								_.includes(cardFilter[`${type}_spacial`], _id)
							);
						}
						cardData.push({
							title: props?.title,
							data: filterWithChangeObj(
								cardItemsArr,
								percentageAgainst,
								filterItems[cardFilterData?.length - 1],
								filterItemGraph[cardFilterData?.length]
							),
						});
					}
				}
			} else {
				if (admissionReportType === ADMISSION_FILTER_TYPE.ADT) {
					const { parentId, childId } = type;
					const selectedADT = _.find(selectedADTTableData, { parentId, childId });


					let selectedADTTableDataArr = [];
					if (cardFilter?.adtAdmitPatientIds?.length > 0) {
						selectedADTTableDataArr = _.find(cardFilter?.adtAdmitPatientIds, ({ parentId, childId }));
					}

					let onlyFilterData = selectedADT?.tableData;
					if (selectedADTTableDataArr?.selectedIds?.length > 0 || selectedADTTableDataArr?.selectedSpecialIds?.length > 0) {
						onlyFilterData = selectedADT?.tableData?.filter(ele =>
							_.includes(
								[...(selectedADTTableDataArr?.selectedIds || []), ...(selectedADTTableDataArr?.selectedSpecialIds || [])],
								ele?.id
							)
						);
					}

					cardData.push({
						title: selectedADT?.title,
						data: filterWithChangeObj(onlyFilterData || [], percentageAgainst),
					});
				} else {
					let cardItemsArr = filterListData[type] || [];

					if (_.get(cardFilter, `${type}_spacial`, []).length > 0) {
						cardItemsArr = cardItemsArr.filter(({ _id }) =>
							_.includes(cardFilter[`${type}_spacial`], _id)
						);
					}
					cardData.push({
						title: props?.title,
						data: filterWithChangeObj(cardItemsArr, percentageAgainst),
					});
				}
			}
		}

		let graphCardData = [];

		if (isGraphReport) {
			let findTotalFilter = _.find(cardData, { type: "total" });
			if (!findTotalFilter && graphData && graphData.selectedTab === CHART_TAB_BUTTON.BUILDING) {
				if (lockedTotalBy === "census") {
					graphCardData.push({ title: `Avg ${getPercentageByType()} (Locked)` });
				} else {
					graphCardData.push({ title: `Avg ${getPercentageByType()}` });
				}
			}
			if (cardData.length > 0) {
				cardData.map((ele) => {
					if (ele && ele.data && ele.data.length > 0) {
						if (ele.type && ele.type === "total") {
							let isAllTransfer = false;
							if (Array.isArray(transferType) && transferType.length > 0 && _.includes(transferType, "all")) {
								isAllTransfer = true;
							} else {
								isAllTransfer = transferType === "all" || transferType === "total";
							}
							if (graphData.selectedTab === CHART_TAB_BUTTON.BUILDING) {
								graphCardData.push({ title: ele?.title, filterItemGraph: [] });
							} else if (!isAllTransfer) {
								graphCardData.push({ title: ele?.title, filterItemGraph: [] });
							}
						} else {
							let filterItemGraph = ele.data[0]?.filterItemGraph;
							if (filterItemGraph && filterItemGraph.length > 0) {
								graphCardData.push({ title: ele?.title, filterItemGraph });
							}
						}
					}
					return ele;
				});
			}

			if (
				type === HOSPITAL_CARDS_TYPE.TOTAL ||
				type === HOSPITAL_CARDS_TYPE.PLANNED ||
				type === HOSPITAL_CARDS_TYPE.UNPLANNED ||
				type === CO_TRANSFER_CARDS_TYPE.AMA ||
				type === CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE ||
				type === CO_TRANSFER_CARDS_TYPE.SNF ||
				type === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING ||
				type === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING
			) {
				graphCardData = [];
			}
		}
		return ReactDOMServer.renderToString(
			<ReportTable
				{...props}
				{...(isGraphReport && { filter: graphData?.queryFilters })}
				isLockedTotalByCensus={lockedTotalBy === "census"}
				cardContentData={cardData}
				graphCardData={graphCardData}
				facilityNames={facilityNames}
				ref={pdfRef}
				isPercentage={isPercentage}
				graphHtml={graphHtml}
				graphData={graphData}
				isGraphReport={isGraphReport}
				alreadyChecked={alreadyChecked}
				percentageAgainst={percentageAgainstSelected}
				percentageBy={percentageBy}
			/>
		);
	};

	const downloadPdf = async (props) => {
		const content = await renderContent(props);
		const pdf = new jsPDF({
			unit: "px",
		});
		pdf.setFont("manrope-bold", "bold", "800");
		//pdf.text("Report", 30, 20)
		pdf.html(`<!DOCTYPE html>${content}`, {
			callback: function (doc) {
				//doc.save("report.pdf");
				doc.output("dataurlnewwindow");
			},
			html2canvas: { scale: 0.5 },
			width: 750,
			windowWidth: 750,
			x: 30,
			y: 10,
		});
	};

	const saveAutomaticReport = async (props) => {
		await saveReportSubscription({ ...props, activeFacilities }).then((res) => {
			if (res && res.status === 200) {
				dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "success",
						label: `Automated Report config successfully`,
						id: "patientAddedUpdated",
					},
				});
				return true;
			} else {
				dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						label: res.error,
						type: "error",
						id: "overallSaveFilterError",
					},
				});
				return false;
			}
		});
	};

	const downloadExcel = async (props) => {
		const {
			data,
			getFieldNames,
			filters,
			bedCapacity,
			questions,
			selectedColumns = [],
			orderBy,
			order,
			activeFacilitiesCount
		} = props;
		const censusAverage = getPercentageByTotal(props);
		const censusType = `${getPercentageByType()} as of`;
		const facilityNames = getFacilityNames();

		const workbook = new ExcelJS.Workbook();
		const title = props.title ? props.title.replace(/[^a-zA-Z ]/g, "") : null;
		const sheet = workbook.addWorksheet(title || "Report Sheet");

		// Add header data to the sheet
		sheet.addRow();

		sheet.addRow(["Facility", facilityNames]);
		sheet.addRow([
			"Date",
			`${dateFormat(filters?.filter?.startDate, "MM/DD/YYYY")} - ${dateFormat(filters?.filter?.endDate, "MM/DD/YYYY")}`,
		]);
		sheet.addRow(["Bed cap", bedCapacity]);

		if (getPercentageByType("y") === ACCOUNT_PERCENTAGE_BY.CENSUS) {
			sheet.addRow([censusType, censusAverage]);
		}

		sheet.addRow();

		const headerColumns = [
			...(activeFacilitiesCount > 1 ? ["Facility Name"] : []),
			"Last Name",
			"First Name",
			"Date of birth",
			"Type"
		];
		if (questions && questions.length > 0) {
			for await (const ele of questions) {
				let isSelected = true;
				if (selectedColumns && selectedColumns.length > 0) {
					isSelected = await selectedColumns.includes(ele?.question?.accessor);
				}
				if (isSelected) {
					await headerColumns.push(
						ele?.order?.tableLabel || ele?.order?.label || ele?.question?.tableLabel || ele?.question?.label
					);
				}
			}
		}

		const headerRow = sheet.addRow(headerColumns);

		headerRow.font = { bold: true };

		headerRow.state = "frozen";

		// Fill data to excel report

		const sortedData = patientDataOrderBy(data, order, orderBy);

		for await (const row of sortedData) {
			try {
				const rowData = [
					...(activeFacilitiesCount > 1 ? [row?.facilityName] : []),
					row?.lastName,
					row?.firstName,
					await toDisplayTime(row.DOB, "MM/DD/YYYY"),
					await getFieldNames(row),
				];

				if (questions && questions.length > 0) {
					for await (const ele of questions) {
						let isSelected = true;
						if (selectedColumns && selectedColumns.length > 0) {
							isSelected = await selectedColumns.includes(ele?.question?.accessor);
						}
						if (isSelected) {
							const questionValue = await getQuestionAnswer(ele?.question, row);
							rowData.push(questionValue);
						}
					}
				}
				sheet.addRow(rowData);
			} catch (e) {
				console.log(e);
			}
		}

		// Generate the Excel file
		const buffer = await workbook.xlsx.writeBuffer();

		// Create a Blob from the buffer
		const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

		// Create a URL for the Blob
		const url = window.URL.createObjectURL(blob);

		// Create a link element to trigger the download
		const link = document.createElement("a");
		link.href = url;
		link.download = `${title}.xlsx`;

		// Append the link to the document body and trigger the download
		document.body.appendChild(link);
		link.click();

		// Cleanup
		window.URL.revokeObjectURL(url);
		document.body.removeChild(link);
	};

	return {
		downloadPdf,
		saveAutomaticReport,
		downloadExcel,
	};
};

export default usePdfReport;
