import HeaderFilter from "../../shared/dashboard-header/HeaderFilter";
import { DECEASED_CARDS_LABELS, DECEASED_CARDS_TYPE } from "../../../types/deceased.type";
import { DECEASED_TYPES } from "../../../data/common.data";
import { useSelector } from "react-redux";
import useHeaderCustomLabelsItem from "../../hooks/useHeaderCustomLabelsItem";

export const headerItemsData = [
    { label: DECEASED_CARDS_LABELS.totalMain, type: "label", dataKey: DECEASED_TYPES.ALL, isMain: true, isDefault: true },

    { label: DECEASED_CARDS_LABELS.doctorData, dataKey: DECEASED_CARDS_TYPE.DOCTOR_DATA, isDefault: true },
    { label: DECEASED_CARDS_LABELS.insuranceData, dataKey: DECEASED_CARDS_TYPE.INSURANCE_DATA, isDefault: true },
    { label: DECEASED_CARDS_LABELS.floorsData, dataKey: DECEASED_CARDS_TYPE.FLOORS_DATA, isDefault: true },
    { label: DECEASED_CARDS_LABELS.ninetyDaysData, dataKey: DECEASED_CARDS_TYPE.NINETY_DAYS_DATA, isDefault: true },
];

const DeceasedHeaderFilterList = ({
    filterListData,
    cardFilter,
    transferType,
    percentageBy,
    lockedTotal,
    lockedTotalBy,
    openFrom = "default", // "detailView"
}) => {
    const { dynamicCards } = useSelector((state) => state.deceased);
    const headerItemsDataArray = useHeaderCustomLabelsItem(cardFilter, dynamicCards, headerItemsData);

    return <HeaderFilter
        items={headerItemsDataArray}
        filterListData={filterListData}
        cardFilter={cardFilter}
        transferType={transferType}
        percentageBy={percentageBy}
        lockedTotal={lockedTotal}
        lockedTotalBy={lockedTotalBy}
        openFrom={openFrom}
    />
};

export default DeceasedHeaderFilterList;