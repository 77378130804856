import React, { useCallback, useEffect, useMemo, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear'; // Import ClearIcon
import Cleave from "cleave.js/react";
import Validation from "./validation/Validation";
import styles from "./AddPatient.module.scss";
import questionsStyles from "./Questions.module.scss";
import TimeKeeper from "react-timekeeper";
import { useRef } from "react";
import classNames from "classnames";
import HtmlTooltip from "../../../../shared/HtmlTooltip";
import { getAllQuestions } from "../../../../../services/api/question.api";
import _ from "lodash";
import { IconButton, InputAdornment, MenuItem, Popover, Select, TextField } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import QuestionLabel from "./QuestionLabel";
import AddPatientTextPopup from "./AddPatientTextPopup";
import { QUESTION_INPUT_TYPE } from "../../../../../types/question.type";


const SelectInputField = ({
    question,
    allFields,
    setAllFieldsValue,
    checkError = false,
    handleClickExpand,
    handleAddHighlight,
}) => {
    const isExpand = useMemo(() => {
        return question.accessor === "notes" || question?.defaultOrder === undefined;
    }, [question]);

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
    }, [allFields?.highlighter, question.accessor]);

    const handleChange = (e) => {
        setAllFieldsValue(question.accessor, e.target.value);
    };

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <Select
                fullWidth
                value={allFields[question.accessor] || ''}
                onChange={handleChange}
                variant="standard"
                disableUnderline
                InputProps={{
                    ...isExpand && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    sx={{ marginRight: "-2px" }}
                                    onClick={() => handleClickExpand(allFields[question.accessor], question.accessor)}
                                    edge="end"
                                    size="small"
                                    disableRipple={true}
                                >
                                    <OpenInFullIcon sx={{ color: "#8f8f8f" }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
                sx={{
                    height: "40px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontFamily: "mont reg",
                    '& .MuiSelect-select': {
                        height: "20px",
                        borderRadius: "4px",
                        border: isHighLightInfo ? `1px solid ${isHighLightInfo} !important` : 'none !important',
                        padding: '10px',
                        // marginTop:"5px",
                        '&:focus': {
                            backgroundColor: 'transparent !important',
                            outline: 'none !important',
                        },
                        '&:focus-visible': {
                            backgroundColor: 'transparent !important',
                            outline: 'none !important',
                        }
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '& .MuiSelect-icon': {
                        color: '#8f8f8f',
                    },
                }}
            >
                {question?.customQuestionOptions?.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

const NumberAndSelectQuestion = ({
    question,
    allFields,
    setAllFieldsValue,
    checkError = false,
    handleAddHighlight,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const previousValue = useRef(allFields[question?.accessor] || '');

    const options = useMemo(() => {

        return question.customQuestionOptions
            ?.filter((ele) => ele?.type === "limitedAnswers")
            .map((item) => (
                <MenuItem
                    key={item?.option}
                    value={item?.option}
                    onClick={() => handleOptionClick(item?.option)}
                >
                    {item?.option}
                </MenuItem>
            ));
    }, [question.customQuestionOptions, setAllFieldsValue, question.accessor]); // eslint-disable-line react-hooks/exhaustive-deps

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find((item) => item.fieldName === question.accessor)?.color;
    }, [allFields, question.accessor]);

    const handleArrowClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option) => {
        setAllFieldsValue(question.accessor, option);
        previousValue.current = option;
        handleClose();
    };

    const handleChange = (e) => {
        const value = e.target.value;
        const isNumber = /^\d+$/.test(value);
        const isValidOption = question.customQuestionOptions
            ?.filter((ele) => ele?.type === 'limitedAnswers')
            .some((item) => item?.option === value);

        if (isNumber || isValidOption || value === '') {
            setAllFieldsValue(question.accessor, value);
            if (value !== '') {
                previousValue.current = value;
            }
        } else {
            setAllFieldsValue(question.accessor, previousValue.current);
            e.target.value = previousValue.current;
        }
    };

    const handleClear = () => {
        setAllFieldsValue(question.accessor, '');
        previousValue.current = '';
    };

    return (
        <>
            <div className={`m-b-20 inputWrpr`}>
                <QuestionLabel handleAddHighlight={handleAddHighlight} accessor={question.accessor} allFields={allFields}>
                    <label
                        className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true ? 'error' : null
                            }`}
                    >
                        {question?.order?.label || question.label}
                    </label>
                </QuestionLabel>
                <TextField
                    fullWidth
                    value={allFields[question.accessor] || ''}
                    onChange={handleChange}
                    autoComplete="off"
                    type={'text'}
                    InputProps={{
                        startAdornment: (
                            <>
                                <InputAdornment position="end">
                                    <IconButton onClick={handleArrowClick} size="small">
                                        <ArrowDropDownIcon fontSize="10px" />
                                    </IconButton>
                                </InputAdornment>
                                {allFields[question.accessor] && question.customQuestionOptions
                                    ?.filter((ele) => ele?.type === 'limitedAnswers')
                                    .some((item) => item?.option === allFields[question.accessor]) && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleClear} size="small">
                                                <ClearIcon fontSize="10px" />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                            </>
                        ),
                    }}
                    sx={{
                        input: {
                            borderRadius: '4px',
                            border: 'none !important',
                        },
                        '& .MuiInputBase-root': {
                            height: '40px',
                            borderRadius: '4px',
                            ...isHighLightInfo && { border: `1px solid ${isHighLightInfo} !important` },
                        },
                    }}
                />
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {options}
            </Popover>
        </>
    );
};

const TextQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleClickExpand, handleAddHighlight, isOnlyNumber = false, customQuestion = false }) => {

    const isExpand = useMemo(() => {
        if (customQuestion) {
            return false;
        }
        // eslint-disable-next-line eqeqeq
        return question.accessor === "notes" || question?.defaultOrder == undefined;
    }, [question, customQuestion]);

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <TextField
                fullWidth
                value={allFields[question.accessor]}
                onChange={e => setAllFieldsValue(question.accessor, e.target.value)}
                variant="standard"
                autoComplete="off"
                type={isOnlyNumber ? 'number' : 'text'}
                InputProps={{
                    disableUnderline: true,
                    ...isExpand && {
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                sx={{ marginRight: "-2px" }}
                                onClick={() => handleClickExpand(allFields[question.accessor], question.accessor)}
                                edge="end"
                                size="small"
                                disableRipple={true}
                            >
                                <OpenInFullIcon sx={{ color: "#8f8f8f" }} />
                            </IconButton>
                        </InputAdornment>
                    }
                }}
                sx={{
                    "input": {
                        borderRadius: "4px",
                        border: "none !important",
                    },
                    '& .MuiInputBase-root': {
                        height: "40px",
                        borderRadius: "4px",
                        ...isHighLightInfo && { border: `1px solid ${isHighLightInfo} !important` },
                    },
                }}
            />
        </div>
    );
};

const DateQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleAddHighlight, setIsReadmissionValid, isReadmissionValid }) => {
    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields])

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${(checkError && !allFields[question.accessor] && question?.isRequired === true) || (question.accessor === "dateOfLatestAdmission" && isReadmissionValid)
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label} <span style={{ fontSize: "0.8em", opacity: 0.7 }}>(MM/DD/YYYY)</span>
                </label>
            </QuestionLabel>

            <Cleave
                className={`ffml fs16`}
                value={allFields[question.accessor]}
                onChange={e => {
                    setIsReadmissionValid(false)
                    setAllFieldsValue(question.accessor, e.target.value)
                }}
                options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                }}
                style={{
                    ...(isHighLightInfo ? { border: `1px solid ${isHighLightInfo}` } : {})
                }}
            />
        </div>
    );
};

const ValidationQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleAddHighlight, selectedProps, name = "dx" }) => {
    let value = allFields[question.accessor];

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        value = value ? value?._id : null;
    } else if (question.multiple) {
        const isObjectArray = Array.isArray(value) && value.every(item => typeof item === 'object' && item !== null && !Array.isArray(item));
        if (isObjectArray) {
            value = value && value.length > 0 ? _.map(value, "_id") : [];
        } else {
            value = value && value.length > 0 ? value : [];
        }
    }

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <Validation
                selectedItem={selectedProps?.selectedItem}
                isHighLightInfo={isHighLightInfo}
                type={question.validationType}
                validationBase={question.validationBase}
                value={value}
                multiple={question.multiple}
                isCustomQuestion={question?.isCustom}
                onChange={val => {
                    if (question.multiple) {
                        const value = val.filter(item => item !== undefined)
                        if (value) {
                            setAllFieldsValue(
                                name,
                                value.length > 0 ? value.map(v => v?._id) : []
                            );
                        }
                    } else {
                        setAllFieldsValue(question.accessor, val);
                    }
                }}
            />
        </div>
    );
};

const ToggleQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, isForceRequired = false, handleAddHighlight }) => {
    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);
    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError &&
                        (allFields[question.accessor] === undefined || allFields[question.accessor] == null || isForceRequired || (allFields?.type === "transfer" && allFields?.transferType === "hospitalTransfer")) &&
                        (question?.isRequired === true || isForceRequired)
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <div className={`df aic ffml fs14 ${styles.toggleWrpr}`}
                style={{
                    ...(isHighLightInfo && {
                        border: `1px solid ${isHighLightInfo}`,
                        borderRadius: "4px",
                    }),
                }}
            >
                {question.options.map(option => (
                    <div
                        className={`${styles.sec} ${allFields[question.accessor] === option.value ? styles.selected : ""
                            }`}
                        onClick={() => setAllFieldsValue(question.accessor, option.value)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

const TimeQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleAddHighlight }) => {
    const [showTimeSelect, setShowTimeSelect] = useState(false);
    const [time, setTime] = useState(allFields[question.accessor] || undefined);
    const timePickerRef = useRef(null);

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    useEffect(() => {
        const outsideClick = e => {
            showTimeSelect &&
                timePickerRef.current &&
                !timePickerRef.current.contains(e.target) &&
                setShowTimeSelect(false);
        };

        document.addEventListener("mousedown", outsideClick);

        return () => document.removeEventListener("mousedown", outsideClick);
    }, [showTimeSelect]);

    return (
        <>
            <div className={`m-b-20 inputWrpr`}>
                <QuestionLabel
                    handleAddHighlight={handleAddHighlight}
                    accessor={question.accessor}
                    allFields={allFields}
                >
                    <label
                        className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                            ? "error"
                            : null
                            }`}
                    >
                        {question?.order?.label || question.label}
                    </label>
                </QuestionLabel>

                <div className={questionsStyles.timeInputWrapper}>
                    <input
                        readOnly={true}
                        onClick={() => setShowTimeSelect(!showTimeSelect)}
                        className={classNames(`ffml fs16 customInput`, questionsStyles.customInput)}
                        value={time}
                        style={{
                            ...(isHighLightInfo && {
                                border: `1px solid ${isHighLightInfo}`,
                                borderRadius: "4px",
                            }),
                        }}
                        onChange={e => setAllFieldsValue(question.accessor, e.target.value)}

                    />
                    {allFields.transferTime && (
                        <div
                            className={questionsStyles.clearBtn}
                            onClick={() => {
                                setTime("");
                                setAllFieldsValue(question.accessor, null);
                            }}
                        >
                            <HtmlTooltip content={"Clear Time"}>
                                <div>x</div>
                            </HtmlTooltip>
                        </div>
                    )}
                </div>
            </div>
            {showTimeSelect ? (
                <>
                    <div className={styles.overlay} />
                    <div className={styles.timePopup} ref={timePickerRef}>
                        <TimeKeeper
                            // time={allFields[question.accessor] || "12:00pm"}
                            switchToMinuteOnHourSelect
                            onChange={data => {
                                setTime(data.formatted12);
                                return setAllFieldsValue(question.accessor, data.formatted12);
                            }}
                            doneButton={newTime => (
                                <div
                                    style={{
                                        textAlign: "center",
                                        padding: "10px 0",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setTime(newTime.formatted12);
                                        setAllFieldsValue(question.accessor, newTime.formatted12);
                                        setShowTimeSelect(false);
                                    }}
                                >
                                    Done
                                </div>
                            )}
                        />
                    </div>
                </>
            ) : undefined}
        </>
    );
};

const Questions = props => {
    const { checkError = false, isAskAdmissionDate, selectedItem, isOnlyHospitalDashboard,
        setIsReadmissionValid,
        isReadmissionValid,
        validation
    } = props;
    const [questions, setQuestions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    //const [allFields, setAllFields] = useState(props.allFields);
    const allFields = useMemo(() => {
        return props.allFields
    }, [props.allFields]);

    const getQuestions = async (propsAllFields) => {
        let transferType = propsAllFields?.transferType;
        if (propsAllFields && (propsAllFields.transferType === "unplannedHospitalTransfer" || propsAllFields.transferType === "plannedHospitalTransfer")) {
            transferType = "hospitalTransfer"
        }
        const res = await getAllQuestions({
            facilityid: localStorage.getItem("facilityId"),
            forType: propsAllFields?.type === "readmission" ? "admission" : propsAllFields?.type,
            ...propsAllFields?.type === "transfer" && { forTransferType: transferType }
        })
        if (res && res.length > 0) {
            setQuestions(res);
            if (props.setQuestions) {
                props.setQuestions(res);
            }
        }
    };

    useEffect(() => {
        getQuestions(props.allFields);
        // eslint-disable-next-line
    }, [props.allFields]);

    const ifDepends = question => {
        const { dependsOn, dependsOnValues, accessor } = question;

        if (!dependsOn) return true;
        if (dependsOn === "server") {
            if (selectedItem || isOnlyHospitalDashboard) {
                return isAskAdmissionDate || allFields[accessor] != null ? true : false;
            } else {
                return isAskAdmissionDate ? true : false;
            }
        } else {
            return dependsOnValues.includes(allFields[dependsOn]);
        }
    };

    const handleClickExpand = useCallback((textValue, accessor) => {
        setExpanded({ value: textValue, accessor });
    }, [setExpanded]);


    const renderQuestion = (question) => {

        if (question?.customQuestionInputType) {
            if (question.customQuestionInputType === QUESTION_INPUT_TYPE.UNLIMITED_ANSWERS) {
                return (
                    <>
                        {ifDepends(question) ? (
                            <ValidationQuestion
                                question={question}
                                allFields={props.allFields}
                                selectedProps={props}
                                name={question?.accessor}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );
            } else if (question.customQuestionInputType === QUESTION_INPUT_TYPE.LIMITED_ANSWERS) {
                return (
                    <SelectInputField
                        question={question}
                        allFields={props.allFields}
                        setAllFieldsValue={props.setAllFieldsValue}
                        checkError={checkError}
                        handleClickExpand={handleClickExpand}
                        handleAddHighlight={props.handleAddHighlight}
                    />
                )
            } else if (question.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE) {
                return <TextQuestion
                    isOnlyNumber={question.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE}
                    question={question}
                    allFields={props.allFields}
                    setAllFieldsValue={props.setAllFieldsValue}
                    checkError={checkError}
                    handleClickExpand={handleClickExpand}
                    handleAddHighlight={props.handleAddHighlight}
                    customQuestion={true}
                />
            } else if (question.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS) {
                return <NumberAndSelectQuestion
                    question={question}
                    allFields={props.allFields}
                    setAllFieldsValue={props.setAllFieldsValue}
                    checkError={checkError}
                    handleClickExpand={handleClickExpand}
                    handleAddHighlight={props.handleAddHighlight}
                />
            }
        }


        switch (question.type) {
            case "text":
                return (
                    <>
                        {ifDepends(question) ? (
                            <TextQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleClickExpand={handleClickExpand}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );
            case "date":
                return (
                    <>
                        {ifDepends(question) ? (
                            <DateQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                                validation={validation}
                                setIsReadmissionValid={setIsReadmissionValid}
                                isReadmissionValid={isReadmissionValid}
                            />
                        ) : undefined}
                    </>
                );
            case "validation":
                return (
                    <>
                        {ifDepends(question) ? (
                            <ValidationQuestion
                                question={question}
                                allFields={props.allFields}
                                selectedProps={props}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );

            case "toggle":
                return (
                    <>
                        {ifDepends(question) ? (
                            <ToggleQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );

            case "time":
                return (
                    <>
                        {ifDepends(question) ? (
                            <TimeQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );
            default:
                return undefined;
        }
    };

    return (
        <div>
            <div>

                {questions && questions.length > 0 && (
                    <>
                        {questions.map((question, idx) => {
                            return (
                                <React.Fragment key={`idx-${idx}`}>{renderQuestion({ ...question.question, order: question?.order })}</React.Fragment>
                            );
                        })}
                    </>
                )}
            </div>
            {!!expanded && (
                <AddPatientTextPopup
                    title="Details"
                    isConfirm={false}
                    content={expanded?.value}
                    handleClose={() => setExpanded(null)}
                    handleSubmit={(valueData) => {
                        props.setAllFieldsValue(expanded?.accessor, valueData);
                        setExpanded(null);
                    }}
                />
            )}

        </div>
    );
};

export default Questions;
