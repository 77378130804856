import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as Yup from 'yup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from "formik";
import { ALERT_TYPE_TYPE } from '../../types/common.type';
import { ADD_NOTIFICATION } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomAlertsReport, getAlertsReports } from '../../services/alert-report.service';
import _ from 'lodash';
import AlertDialog from './AlertDialog';
import useCardFilterAndTransferType from '../hooks/useCardFilterAndTransferType';

export default function AlertReportCustomDialog({
    handleClose,
    pageType,
    cardType,
    totalCardType = null
}) {
    const { facilities: activeFacilities } = useSelector((state) => state.activeFacilities);

    const { cardFilter, transferType } = useCardFilterAndTransferType(pageType);

    const [existingAlerts, setExistingAlerts] = React.useState();
    const [isChangesDetected, setIsChangesDetected] = React.useState(null);

    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required')
    });

    /* eslint-disable no-unused-vars */
    const [initialValues, setInitialValues] = React.useState({
        alertType: [ALERT_TYPE_TYPE.WEEKS],
        isRising: false,
        isDropping: false,
        name: "",
        id: null
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialValues
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            handleSave(values);
            setSubmitting(false);
        },
    });

    const compareAlertSettings = (valuesData, existingAlerts) => {
        // Compare only the specific fields
        const fieldsToCompare = ['alertType', 'isRising', 'isDropping'];

        let hasChanges = false;

        // Loop through each field and compare
        fieldsToCompare.forEach(field => {
            if (JSON.stringify(valuesData[field]) !== JSON.stringify(existingAlerts[field])) {
                hasChanges = true;
            }
        });

        return hasChanges;
    };

    const handleSave = React.useCallback(async (valuesData, isSkipValidation = false) => {
        try {
            let hasChanges = await compareAlertSettings(valuesData, { ...existingAlerts, alertType: existingAlerts?.type ?? null });

            if (hasChanges && !isSkipValidation) {
                setIsChangesDetected(valuesData);
                return false;
            }

            setIsChangesDetected(null);

            formik.setSubmitting(true);
            const updateData = {
                id: valuesData?.id,
                name: valuesData?.name,
                page: pageType,
                facilityIds: activeFacilities,
                type: valuesData?.alertType,
                isRising: valuesData?.isRising,
                isDropping: valuesData?.isDropping,
                filter: { 
                    cardFilter, 
                    transferType, 
                    cardType, 
                    ...(totalCardType ? { totalCardType } : {})
                },
            };

            const res = await createCustomAlertsReport(updateData);
            formik.resetForm();
            formik.setSubmitting(false);

            if (res) {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "success",
                        label: "Alert settings updated successfully",
                        id: "alertSettingsUpdated",
                    },
                });
            }

            handleClose();

        } catch (error) {

            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "error",
                    label: "Failed to save alert settings",
                    id: "alertSettingsSaveError",
                },
            });

            handleClose();
        }
    }, [existingAlerts, formik, pageType, activeFacilities, cardFilter, transferType, cardType, totalCardType, handleClose, dispatch]);

    const getAlertsReportsDB = React.useCallback(async (facilityId = null) => {
        setLoading(true);
        try {
            const res = await getAlertsReports({
                page: pageType,
                facilityId,
            });

            if (res) {
                setExistingAlerts(res);
                setLoading(false);
                formik?.setFieldValue("id", res?._id);
                formik?.setFieldValue("alertType", res?.type);
                formik?.setFieldValue("isRising", res?.isRising);
                formik?.setFieldValue("isDropping", res?.isDropping);
                formik?.setFieldValue("type", res?.type);
            } else {
                formik?.setFieldValue("alertType", [ALERT_TYPE_TYPE.WEEKS]);
                formik?.setFieldValue("isRising", false);
                formik?.setFieldValue("isDropping", false);
                formik?.setFieldValue("id", null);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "error",
                    label: "Failed to load alert settings 2",
                    id: "loadSettingsError",
                },
            });
        } finally {
            setLoading(false);
        }
    }, [pageType, formik, dispatch]);

    React.useEffect(() => {
        getAlertsReportsDB(activeFacilities?.[0]);
    }, [activeFacilities]); // eslint-disable-line react-hooks/exhaustive-deps

    const AlertTypeSelect = ({ formik }) => {
        return (
            <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel id="alert-type-label">Alert frequency</FormLabel>
                <ToggleButtonGroup
                    value={formik.values.alertType}
                    onChange={(event, newAlertType) => formik.setFieldValue("alertType", newAlertType)}
                    aria-labelledby="alert-type-label"
                    color="primary"
                    sx={{ display: 'flex' }}
                    size="large"
                >
                    <ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.WEEKS}>Weekly</ToggleButton>
                    <ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.BI_WEEKS}>Bi-Weekly</ToggleButton>
                    <ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.MONTHS}>Monthly</ToggleButton>
                </ToggleButtonGroup>
            </FormControl>
        );
    };

    const renderContent = () => (
        <form onSubmit={formik.handleSubmit}>
            <FormGroup>
                <AlertTypeSelect formik={formik} />

                <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 2, ml: 0.2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isRising"
                                checked={formik.values.isRising}
                                onChange={formik.handleChange}
                            />
                        }
                        // label="Are Rising"
                        label="Trending up"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isDropping"
                                checked={formik.values.isDropping}
                                onChange={formik.handleChange}
                            />
                        }
                        // label="Are Dropping"
                        label="Trending down"
                    />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        name="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.name && formik.errors.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                </Box>
            </FormGroup>
        </form>
    );

    return (
        <>
            <Dialog fullWidth maxWidth="md" open={true} onClose={handleClose}>
                <DialogTitle>{_.capitalize(pageType)} Alerts</DialogTitle>

                <DialogContent>
                    {loading && (
                        <Backdrop open={true} style={{
                            position: 'absolute', top: 0, left: 0, zIndex: 5,
                            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Slightly translucent
                            backdropFilter: 'blur(0.5px)', // Applies a blur effect
                        }}>
                            <CircularProgress color="primary" />
                        </Backdrop>
                    )}
                    {renderContent()}
                </DialogContent>
                <DialogActions>
                    <Box flex="1" display="flex" justifyContent="flex-end">
                        <Button onClick={handleClose} variant="outlined">Close</Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            sx={{ marginLeft: 2 }}
                            loading={formik.isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            {!!isChangesDetected &&
                <AlertDialog
                    handleClose={() => setIsChangesDetected(null)}
                    content={`The changes you made to will be apply for all alert setting.`}
                    title="Warning"
                    handleSubmit={() => handleSave(isChangesDetected, true)}
                    isConfirm={true}
                    onlyOkay={false}
                />
            }
        </>
    );
}
