import React from "react";
import { makeStyles } from '@mui/styles';
import { Button } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GroupsIcon from "@mui/icons-material/Groups";
import { CHART_TAB_BUTTON } from "../../../types/chart-dialog.type";
import { useSelector } from "react-redux";
import BusinessIcon from '@mui/icons-material/Business';
import FacilityList from "./FacilityList";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PopupState, { bindPopover, bindHover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";

const useStyles = makeStyles(theme => ({
    animatedItem: {
        animation: `$myEffect 100ms ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes myEffect": {
        "0%": {
            opacity: 0,
            transform: "translateY(0%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
}));

export default function DetailsCardTab({ 
    selectedTab, 
    handleTabButton, 
    tableFilterTypeOptions, 
    setSelectedFacility, 
    selectedFacility
 }) {
    const facilities = useSelector((state) => state.facility);
    const activeFacilities = useSelector(state => state.activeFacilities.facilities);
    const classes = useStyles();

    return (
        <>
            <Button
                color={selectedTab === CHART_TAB_BUTTON.TABLE ? "primary" : "inherit"}
                variant="outlined"
                onClick={() => handleTabButton(CHART_TAB_BUTTON.TABLE)}
                sx={{
                    borderRadius: "50%",
                    minWidth: "50px",
                    height: "50px",
                    padding: 0
                }}
            >
                <GroupsIcon
                    color={selectedTab === CHART_TAB_BUTTON.TABLE ? "primary" : "inherit"}
                />
            </Button>
            <Button
                color={selectedTab === CHART_TAB_BUTTON.CHART ? "primary" : "inherit"}
                variant="outlined"
                sx={{
                    borderRadius: "50%",
                    minWidth: "50px",
                    height: "50px",
                    padding: 0
                }}
                onClick={() => handleTabButton(CHART_TAB_BUTTON.CHART)}
            >
                <TrendingUpIcon
                    color={selectedTab === CHART_TAB_BUTTON.CHART ? "primary" : "inherit"}
                />
            </Button>
            {activeFacilities.length > 1 && (
                <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                        <div>
                            <Button
                                color={selectedTab === CHART_TAB_BUTTON.BUILDING ? "primary" : "inherit"}
                                variant="outlined"
                                sx={{
                                    borderRadius: "50%",
                                    minWidth: "50px",
                                    height: "50px",
                                    padding: 0
                                }}
                                onClick={() => handleTabButton(CHART_TAB_BUTTON.BUILDING)}
                                {...(popupState.isOpen ? {
                                    ...bindHover(popupState)
                                } : {})}
                                endIcon={popupState.isOpen ?
                                    <ExpandLessIcon fontSize="small" sx={{ ml: "-8px", mr: "5px" }} /> :
                                    <ExpandMoreIcon fontSize="small" sx={{ ml: "-8px", mr: "5px" }}  {...bindHover(popupState)} />
                                }
                            >
                                <BusinessIcon
                                    sx={{ ml: "10px" }}
                                    fontSize="small"
                                    color={selectedTab === CHART_TAB_BUTTON.BUILDING ? "primary" : "inherit"}
                                />
                            </Button>

                            <HoverPopover
                                {...bindPopover(popupState)}
                                className={`${classes.animatedItem}`}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                                PaperProps={{
                                    elevation: 24,
                                    variant: "outlined",
                                    rounded: true,
                                    sx: {
                                        pointerEvents: 'auto',
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0.5,
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            left: "70%",
                                            width: 20,
                                            height: 20,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndexm: 0,
                                        },
                                    }
                                }}
                            >
                                <FacilityList
                                    activeFacilities={activeFacilities}
                                    facilities={facilities}
                                    setSelectedFacility={setSelectedFacility}
                                    selectedFacility={selectedFacility}
                                />
                            </HoverPopover>
                        </div>
                    )}
                </PopupState>
            )}
            
            {tableFilterTypeOptions && (
                tableFilterTypeOptions
            )}
        </>
    );
}