import HeaderFilter from "../../shared/dashboard-header/HeaderFilter";
import { OVERALL_CARDS_LABELS, OVERALL_CARDS_TYPE } from "../../../types/overall.type";

export const headerItemsData = [
    { label: OVERALL_CARDS_LABELS.totalMain, type: "label", dataKey: OVERALL_CARDS_TYPE.TOTAL, isMain: true },
    { label: OVERALL_CARDS_LABELS.totalOutgoing, type: "label", dataKey: OVERALL_CARDS_TYPE.TOTAL_OUTGOING, isMain: true },
    { label: OVERALL_CARDS_LABELS.totalIncoming, type: "label", dataKey: OVERALL_CARDS_TYPE.TOTAL_INCOMING, isMain: true },

    { label: OVERALL_CARDS_LABELS.doctorData, dataKey: OVERALL_CARDS_TYPE.DOCTOR_DATA },
    { label: OVERALL_CARDS_LABELS.insuranceData, dataKey: OVERALL_CARDS_TYPE.INSURANCE_DATA },
    { label: OVERALL_CARDS_LABELS.floorsData, dataKey: OVERALL_CARDS_TYPE.FLOORS_DATA },
    { label: OVERALL_CARDS_LABELS.ninetyDaysData, dataKey: OVERALL_CARDS_TYPE.NINETY_DAYS_DATA },

];

const OverallHeaderFilterList = ({
    filterListData,
    cardFilter,
    transferType,
    percentageBy,
    lockedTotal,
    lockedTotalBy,
    openFrom = "default", // "detailView"
}) => {
    return <HeaderFilter
        items={headerItemsData}
        filterListData={filterListData}
        cardFilter={cardFilter}
        transferType={transferType}
        percentageBy={percentageBy}
        lockedTotal={lockedTotal}
        lockedTotalBy={lockedTotalBy}
        openFrom={openFrom}
    />
};

export default OverallHeaderFilterList;