/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import NewCloseSVG from "../../../assets/svgs/new-close.svg";
import styles from "./AddUser.module.scss";
import ValidationADT from "../../landing/facility/data/add-patient/ValidationADT";
import { createUser, getAccountFacility, updateOneUser } from "../../../services/user.service";
import _ from "lodash";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    IconButton,
    Box,
    Stack,
    Tooltip,
} from "@mui/material";
import { AUTH_ROLES } from "../../../types/auth.type";
import { LoadingButton } from "@mui/lab";
import { LOGIN } from "../../../store/types";
import { setRoleName } from "../../../store/reducers/permission.slice";
import { useNavigate } from "react-router";
import AlertReport from "../../shared/alert-report/AlertReport";
import { PAGE_TYPE } from "../../../types/pages.type";

const AddUser = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roles = [] } = props;
    const { auth, permission } = useSelector(({ auth, permission }) => ({
        auth,
        permission,
    }));
    const [searchValue, setSearchValue] = useState("");
    const [searchAccount, setSearchAccount] = useState("");
    const [fullName, setFullName] = useState(props?.currentEdit?.fullName || "");
    const [jobTitle, setJobTitle] = useState(props?.currentEdit?.jobTitle || "");
    const [email, setEmail] = useState(props?.currentEdit?.email || "");
    const [role, setRole] = useState(props?.currentEdit?.role || null);
    const [validation, setValidation] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [accountFacilities, setAccountFacilities] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [permissions, setPermissions] = useState();
    const [loading, setLoading] = useState(false);
    const { selectedAccount } = useSelector((state) => state.common);
    const { dbData } = useSelector((state) => state.hospital);
    const { facilities: activeFacilities } = useSelector((state) => state.activeFacilities);    

    useEffect(() => {
        if (permission.role === AUTH_ROLES.SUPER || props?.currentEdit?._id === auth._id) {
            setRolesData(roles);
        } else {
            setRolesData(roles.filter((item) => item.slug !== permission.role));
        }
    }, [roles]);

    const handleSelectRole = useCallback(
        (value, isEditData = false) => {
            setRole(value);
            const selRole = props.roles.find((item) => item._id === value);
            setSelectedRole(selRole || null);
            if (selRole && !isEditData) {
                permissions.filter((item) => {
                    item.access =
                        selRole.slug === AUTH_ROLES.TOTAL || selRole.slug === AUTH_ROLES.OWNER ? true : false;
                    item.read =
                        selRole.slug === AUTH_ROLES.TOTAL ||
                            selRole.slug === AUTH_ROLES.OWNER ||
                            selRole.slug === AUTH_ROLES.ADMIN ||
                            selRole.slug === AUTH_ROLES.REGIONAL
                            ? true
                            : false;
                    item.write =
                        selRole.slug === AUTH_ROLES.TOTAL ||
                            selRole.slug === AUTH_ROLES.OWNER ||
                            selRole.slug === AUTH_ROLES.ADMIN ||
                            selRole.slug === AUTH_ROLES.REGIONAL
                            ? true
                            : false;
                    return item;
                });
                setPermissions(permissions);
            }
        },
        [permissions, props.roles]
    );

    useEffect(() => {
        if (props.currentEdit) {
            if (props.currentEdit.role._id) {
                handleSelectRole(props.currentEdit.role._id, true);
            }
        }
    }, [props]);

    const getFacilities = async () => {
        let facilitiesRes = [];
        facilitiesRes = await getAccountFacility({ grouped: true });
        const editFacilityData = props?.currentEdit?.facilities || [];
        const updateFacilityPermission = facilitiesRes
            .map((account) => account.facilities.map((facility) => facility))
            .flat()
            .map((fl) => {
                let object = {
                    accountId: fl.accountId,
                    facilityId: fl._id,
                    access: false,
                    read: false,
                    write: false,
                };
                if (editFacilityData.length > 0) {
                    const selectedEditFac = _.find(editFacilityData, {
                        facilityId: fl._id,
                    });
                    if (selectedEditFac) {
                        Object.assign(object, selectedEditFac);
                    }
                }
                return object;
            });
        setPermissions(updateFacilityPermission);
        setAccountFacilities(facilitiesRes);
    };

    useEffect(() => {
        getFacilities();
    }, [auth]);

    const updateFacilityPermissions = (e, type, facilityId, accountId) => {
        let idx = permissions.findIndex((elem) => elem.facilityId === facilityId);
        if (idx === -1) {
            const newData = update(permissions, {
                $push: [
                    {
                        accountId: accountId,
                        facilityId: facilityId,
                        access: false,
                        read: false,
                        write: false,
                        [type]: e.target.checked,
                    },
                ],
            });

            setPermissions(newData);
        } else {
            const newData = update(permissions, {
                [idx]: { [type]: { $set: e.target.checked } },
            });
            setPermissions(newData);
        }
    };

    const updateAccount = async (field, account) => {
        let filtered = permissions.filter(
            (elem) => elem.accountId === account?._id?._id && elem[field] === true
        );
        if (filtered.length === account.facilities.length) {
            let newData = update(permissions, {
                $set: permissions.map((elem) => {
                    if (elem.accountId === account?._id?._id) {
                        return {
                            ...elem,
                            [field]: false,
                        };
                    }
                    return elem;
                }),
            });
            setPermissions(newData);
        } else if (filtered.length > 0) {
            let newData = update(permissions, {
                $set: permissions.map((elem) => {
                    if (elem.accountId === account?._id?._id) {
                        return {
                            ...elem,
                            [field]: true,
                        };
                    }
                    return elem;
                }),
            });

            setPermissions(newData);
        } else {
            // iterate through all permissions and set them to true
            let newData = update(permissions, {
                $set: permissions.map((elem) => {
                    if (elem.accountId === account?._id?._id) {
                        return {
                            ...elem,
                            [field]: true,
                        };
                    }
                    return elem;
                }),
            });
            setPermissions(newData);
        }
    };

    const isAccountChecked = (field, account) => {
        let result = "";
        let filtered = permissions?.filter(
            (elem) => elem.accountId === account?._id?._id && elem[field] === true
        );
        if (filtered?.length === account?.facilities?.length) {
            result = "checked";
        } else if (filtered?.length > 0) {
            result = "partial";
        }
        return result;
    };

    const isChecked = (facilityId, type) => {
        return (
            permissions.find((facility) => facility.facilityId === facilityId)?.[
            type
            ] || false
        );
    };

    const resetValiDation = useCallback(() => {
        setValidation(null);
    }, []);

    const checkValidation = useCallback(async () => {
        let errors = [];
        if (!fullName) {
            errors.push("Please enter Full Na‍me");
        }
        if (!email) {
            errors.push("Please enter Email");
        }
        if (!role) {
            errors.push("Please select Role");
        }
        if (errors.length > 0) {
            setValidation({ type: "basicInfo", errors });
            return false;
        } else {
            resetValiDation();
            return true;
        }
    }, [email, fullName, resetValiDation, role]);

    const checkMenuPermission = useCallback(
        (roles) => {
            const { role = null } = auth;
            if (role && role.slug) {
                if (_.includes(roles, role.slug)) {
                    return true;
                }
            }
            return false;
        },
        [auth]
    );

    const save = async () => {
        resetValiDation();
        if (await checkValidation()) {
            let accountsData = [];
            const roleName = selectedRole?.slug;
            // eslint-disable-next-line array-callback-return
            permissions.map((item) => {
                let obj = {};
                obj.accountId = item.accountId;

                if (
                    roleName === AUTH_ROLES.SUPER ||
                    roleName === AUTH_ROLES.OWNER ||
                    roleName === AUTH_ROLES.TOTAL
                ) {
                    if (item.access) {
                        obj.access = true;
                        accountsData.push(obj);
                    }
                } else if (roleName === AUTH_ROLES.REGIONAL || roleName === AUTH_ROLES.ADMIN) {
                    if (item.access) {
                        obj.access = true;
                        accountsData.push(obj);
                    }
                } else {
                    if (item.write || item.read) {
                        obj.access = true;
                        accountsData.push(obj);
                    }
                }
            });
            accountsData =
                accountsData.length > 0 ? _.uniqBy(accountsData, "accountId") : [];
            const resultData =
                accountsData.length > 0 ? accountsData.map((a) => a.accountId) : [];
            if (
                roleName === AUTH_ROLES.SUPER ||
                roleName === AUTH_ROLES.OWNER ||
                roleName === AUTH_ROLES.TOTAL
            ) {
                permissions.filter((item) => {
                    if (_.includes(resultData, item.accountId)) {
                        item.access = true;
                        item.read = true;
                        item.write = true;
                    } else {
                        item.access = false;
                        item.read = false;
                        item.write = false;
                    }
                    return item;
                });
            } else if (roleName === AUTH_ROLES.REGIONAL || roleName === AUTH_ROLES.ADMIN) {
                permissions.filter((item) => {
                    if (item.access) {
                        item.access = true;
                        item.read = true;
                        item.write = true;
                    } else {
                        item.access = false;
                        item.read = false;
                        item.write = false;
                    }
                    return item;
                });
            }
            if (permissions && permissions.length > 0) {
                const isSelectedRecord = _.filter(permissions, (ele) => ele.read === true || ele.write === true);
                if (isSelectedRecord.length === 0) {
                    setValidation({ type: "basicInfo", errors: ["Please select at least one facility or account"] });
                    return false;
                }
            }
            if (!props.currentEdit?._id) {
                const body = {
                    fullName,
                    email,
                    facilities: permissions,
                    accounts: accountsData,
                    jobTitle,
                    role: selectedRole._id,
                }
                setLoading(true)
                await createUser(body).then((res) => {
                    props.refreshData();
                    props.close();
                    setLoading(false)
                }).catch((err) => {
                    setLoading(false)
                })
            } else {
                const updateBody = {
                    fullName,
                    email,
                    facilities: permissions,
                    accounts: accountsData,
                    jobTitle,
                    role: selectedRole._id,
                }
                setLoading(true)
                await updateOneUser(props.currentEdit._id, updateBody).then(async (res) => {
                    setLoading(false)
                    props.refreshData();
                    props.close();
                    if (res && res.data && res.data && res.data._id === auth._id) {
                        await dispatch({ type: LOGIN, payload: res.data });
                        await dispatch(setRoleName(res.data?.role?.slug));
                        if (_.includes(res.data.role.slug, "user")) {
                            navigate("/dashboard/hospital")
                        }
                    }
                }).catch((err) => {
                    setLoading(false)
                })
            }
        }
    };

    const checkAccess = useCallback(
        (type) => {
            if (
                selectedRole &&
                selectedRole.slug === AUTH_ROLES.USER &&
                (type === "read" || type === "write")
            ) {
                return true;
            }
            if (
                selectedRole &&
                (selectedRole.slug === AUTH_ROLES.ADMIN || selectedRole.slug === AUTH_ROLES.REGIONAL) &&
                type === "access"
            ) {
                return true;
            }
            if (
                selectedRole &&
                (selectedRole.slug === AUTH_ROLES.TOTAL || selectedRole.slug === AUTH_ROLES.OWNER) &&
                (type === "access" || type === "read" || type === "write")
            ) {
                return true;
            }
            return false;
        },
        [selectedRole]
    );

    const handleClose = useCallback(() => {
        props.close();
    }, []);
    
    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                scroll={"paper"}
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <>
                        <Box display="flex" alignItems="center" >
                            {props.currentEdit?._id ? "Edit" : "Add"} User
                            {props.currentEdit?._id && permission?.role === AUTH_ROLES.SUPER && (
                                <Stack sx={{ ml: 2 }}>                                    
                                        <AlertReport
                                            isAutomaticReportSaved={true}
                                            page={PAGE_TYPE.HOSPITAL}
                                            activeFacilities={activeFacilities}
                                            type="userPage"
                                            accountFacilities={accountFacilities}
                                            isAccountChecked={isAccountChecked}
                                            isChecked={isChecked}
                                            selectedAccount={selectedAccount}
                                            userId={props?.currentEdit?._id}                                            
                                        />                                    
                                </Stack>
                            )}
                        </Box>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <NewCloseSVG />
                        </IconButton>
                    </>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div>
                        {validation && validation.type && (
                            <ValidationADT
                                selectedItem={validation}
                                close={() => resetValiDation()}
                            />
                        )}
                        <div className={`m-b-15 inputWrpr`}>
                            <label className={`ffmr fs12`}>Full Name</label>
                            <input
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </div>
                        <div className={`m-b-15 inputWrpr`}>
                            <label className={`ffmr fs12`}>Job title</label>
                            <input
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                        </div>
                        <div className={`m-b-15 inputWrpr`}>
                            <label className={`ffmr fs12`}>Email</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className={`m-b-20 inputWrpr`}>
                            <label className={`ffmr fs12`}>Role</label>
                            <select
                                className={`ffml fs16`}
                                value={role}
                                onChange={(e) => handleSelectRole(e.target.value, true)}
                            >
                                <option value="">Select Role</option>
                                {rolesData &&
                                    rolesData.length > 0 &&
                                    rolesData.map((item, index) => (
                                        <option key={index} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        {selectedRole &&
                            (selectedRole.slug === AUTH_ROLES.TOTAL ||
                                selectedRole.slug === AUTH_ROLES.OWNER) && (
                                <>
                                    <label className={`ffmr fs12 p-b-5 m-t-20 db`}>
                                        Permissions
                                    </label>
                                    {permission?.role === AUTH_ROLES.SUPER && (
                                        <div className={` ${styles.facilityList}`}>
                                            <input
                                                value={searchAccount}
                                                onChange={(e) => setSearchAccount(e.target.value)}
                                                type="text"
                                                placeholder="Search Accounts"
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                        </div>
                                    )}
                                    {accountFacilities?.filter((elem) =>
                                        elem._id?.name
                                            .toLowerCase()
                                            .includes(searchAccount.toLowerCase())
                                    ).map((account, index) => (
                                        <div
                                            className={`df aic m-b-2 ${styles.accountHdr} ${styles.accountRow}`}
                                        >
                                            <h3 className={`ffmm fs14`}>
                                                {account?._id?.name || "Noname account"}
                                            </h3>
                                            <div className={`df aic mla`}>
                                                {checkAccess("access") && (
                                                    <div className={`m-r-10 df ffc aic ${styles.sec}`}>
                                                        <p className={`fs10 ffmr m-b-4`}>Access</p>
                                                        <p
                                                            className={`${styles[isAccountChecked("access", account)]
                                                                } ${styles.checkboxWrpr}`}
                                                            onClick={() => {
                                                                updateAccount("access", account);
                                                            }}
                                                        ></p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        {selectedRole &&
                            (selectedRole.slug === AUTH_ROLES.REGIONAL ||
                                selectedRole.slug === AUTH_ROLES.ADMIN ||
                                selectedRole.slug === AUTH_ROLES.USER || selectedRole.slug === AUTH_ROLES.SUPER) && (
                                <>
                                    <label className={`ffmr fs12 p-b-5 m-t-20 db`}>
                                        Permissions
                                    </label>
                                    {permission?.role === AUTH_ROLES.SUPER && (
                                        <div className={` ${styles.facilityList}`}>
                                            <input
                                                value={searchAccount}
                                                onChange={(e) => setSearchAccount(e.target.value)}
                                                type="text"
                                                placeholder="Search Accounts"
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "4px",
                                                    padding: "10px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className={` ${styles.facilityList}`}>
                                        <input
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            type="text"
                                            placeholder="Search facilities"
                                            style={{
                                                width: "100%",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                padding: "10px",
                                                height: "40px",
                                                marginBottom: "10px",
                                            }}
                                        />
                                        {accountFacilities?.filter((elem) =>
                                            elem._id?.name
                                                .toLowerCase()
                                                .includes(searchAccount.toLowerCase())
                                        ).map((account) => (
                                            <div key={account?._id?._id}>
                                                <div className={`df aic m-b-2 ${styles.accountHdr}`}>
                                                    <h3 className={`ffmm fs14`}>
                                                        {account?._id?.name || "Noname account"}
                                                    </h3>
                                                    <div className={`df aic mla`}>
                                                        {checkAccess("access") && (
                                                            <div
                                                                className={`m-r-10 df ffc aic ${styles.sec}`}
                                                            >
                                                                <p className={`fs10 ffmr m-b-4`}>Access</p>
                                                                <p
                                                                    className={`${styles[isAccountChecked("access", account)]
                                                                        } ${styles.checkboxWrpr}`}
                                                                    onClick={() => {
                                                                        updateAccount("access", account);
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        )}
                                                        {checkAccess("read") && (
                                                            <div
                                                                className={`m-r-10 df ffc aic ${styles.sec}`}
                                                            >
                                                                <p className={`fs10 ffmr m-b-4`}>Read</p>
                                                                <p
                                                                    className={`${styles[isAccountChecked("read", account)]
                                                                        } ${styles.checkboxWrpr}`}
                                                                    onClick={() => {
                                                                        updateAccount("read", account);
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        )}
                                                        {checkAccess("write") && (
                                                            <div
                                                                className={`m-r-10 df ffc aic ${styles.sec}`}
                                                            >
                                                                <p className={`fs10 ffmr m-b-4`}>Write</p>
                                                                <p
                                                                    className={`${styles[isAccountChecked("write", account)]
                                                                        } ${styles.checkboxWrpr}`}
                                                                    onClick={() => {
                                                                        updateAccount("write", account);
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {account?.facilities
                                                    ?.filter((elem) =>
                                                        elem.name
                                                            .toLowerCase()
                                                            .includes(searchValue.toLowerCase())
                                                    )
                                                    .map((facility) => (
                                                        <div
                                                            className={`df aic ffmr ${styles.facilityLine}`}
                                                            key={facility._id}
                                                        >
                                                            <div className={`${styles.name} ${styles.sec}`}>
                                                                <p className={`fs10 m-b-4 ${styles.lbl}`}>
                                                                    Facility Name
                                                                </p>
                                                                <p> {facility.name}</p>
                                                            </div>
                                                            <div className={`df aic mla`}>
                                                                {checkAccess("access") && (
                                                                    <div
                                                                        className={`df acc m-r-10 ${styles.name} ${styles.sec}`}
                                                                    >
                                                                        <label
                                                                            htmlFor={`access_${facility._id}`}
                                                                            className={`fs10 ${styles.lbl} ${isChecked(facility._id, "access")
                                                                                ? styles.checked
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            Access
                                                                        </label>
                                                                        <input
                                                                            checked={isChecked(
                                                                                facility._id,
                                                                                "access"
                                                                            )}
                                                                            type="checkbox"
                                                                            id={`access_${facility._id}`}
                                                                            value={`access-${facility._id}`}
                                                                            onChange={(e) =>
                                                                                updateFacilityPermissions(
                                                                                    e,
                                                                                    "access",
                                                                                    facility._id,
                                                                                    facility.accountId
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {checkAccess("read") && (
                                                                    <div
                                                                        className={`df acc m-r-10 ${styles.name} ${styles.sec}`}
                                                                    >
                                                                        <label
                                                                            htmlFor={`read_${facility._id}`}
                                                                            className={`fs10 ${styles.lbl} ${isChecked(facility._id, "read")
                                                                                ? styles.checked
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            Read
                                                                        </label>
                                                                        <input
                                                                            checked={isChecked(facility._id, "read")}
                                                                            type="checkbox"
                                                                            id={`read_${facility._id}`}
                                                                            value={`read-${facility._id}`}
                                                                            onChange={(e) =>
                                                                                updateFacilityPermissions(
                                                                                    e,
                                                                                    "read",
                                                                                    facility._id,
                                                                                    facility.accountId
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {checkAccess("write") && (
                                                                    <div
                                                                        className={`df acc m-r-10 ${styles.name} ${styles.sec}`}
                                                                    >
                                                                        <label
                                                                            htmlFor={`write_${facility._id}`}
                                                                            className={`fs10 ${styles.lbl} ${isChecked(facility._id, "write")
                                                                                ? styles.checked
                                                                                : ""
                                                                                }`}
                                                                        >
                                                                            Write
                                                                        </label>
                                                                        <input
                                                                            checked={isChecked(facility._id, "write")}
                                                                            type="checkbox"
                                                                            id={`write_${facility._id}`}
                                                                            value={`write-${facility._id}`}
                                                                            onChange={(e) =>
                                                                                updateFacilityPermissions(
                                                                                    e,
                                                                                    "write",
                                                                                    facility._id,
                                                                                    facility.accountId
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <LoadingButton loading={loading} onClick={save} variant="contained">
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddUser;
