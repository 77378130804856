import { useState, useCallback } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, InputAdornment, IconButton, Grid, FormLabel, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changePassword } from '../../services/user.service';
import { ChangePasswordSchema } from '../../validations/ChangePasswordSchema';

export default function ChangePassword() {
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmNewPassword: ''
        },
        validationSchema: ChangePasswordSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            await changePassword(values.id, values).then(() => {
                setSubmitting(false);
                resetForm();
            }).catch((err) => {
                setSubmitting(false)
            })
        }
    });

    const handleShowOldPassword = useCallback(() => {
        setShowOldPassword((show) => !show);
    }, []);

    const handleShowPassword = useCallback(() => {
        setShowPassword((show) => !show);
    }, []);

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={2} maxWidth="sm">
                            <Stack spacing={3}>
                                <FormControl>
                                    <FormLabel>Old Password</FormLabel>
                                    <TextField
                                        {...getFieldProps('oldPassword')}
                                        fullWidth
                                        autoComplete="off"
                                        type={showOldPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleShowOldPassword} edge="end" size='small'>
                                                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            Boolean(touched.oldPassword && errors.oldPassword)
                                        }
                                        helperText={(touched.oldPassword && errors.oldPassword)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>New Password</FormLabel>
                                    <TextField
                                        {...getFieldProps('password')}
                                        fullWidth
                                        autoComplete="off"
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleShowPassword} edge="end" size='small'>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={(touched.password && errors.password)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Confirm New Password</FormLabel>
                                    <TextField
                                        {...getFieldProps('confirmNewPassword')}
                                        fullWidth
                                        autoComplete="off"
                                        type="password"
                                        error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                                        helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    loadingIndicator="Loading..."
                                >
                                    Update Password
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </Form>
                </FormikProvider>

            </Grid>
        </Grid>
    );
}
