import { store } from "../..";
import { ADD_NOTIFICATION } from "../../store/types";

import { useCallback, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import TimesheetSVG from "../../assets/svgs/timesheet.svg";
import styles from "../../assets/styles/Users.module.scss";
import DeleteSVG from "../../assets/svgs/delete.svg";
import EditSVG from "../../assets/svgs/edit.svg";
import axios from "../../axios";
import PopupDialog from "../../components/popupDialog/PopupDialog";
import { ShowForPermission } from "../../components/shared/ShowForPermission";
import Button from "../../components/ui/button/Button";
import AddUser from "../../components/users/add-user/AddUser";
import { getOnlyRoles } from "../../services/role.service";
import { getFacilityOption } from "../../services/user.service";
import api from '../../services/api/api';
import { IconButton, InputAdornment, TextField } from "@mui/material";

const AccountUserPage = () => {
	const location = useLocation();
	//const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [showAdd, setShowAdd] = useState(false);
	const [currentEdit, setCurrentEdit] = useState(null);
	const [facilityFilter, setFacilityFilter] = useState(null);
	const [roles, setRoles] = useState([]);
	const { auth } = useSelector(({ auth }) => ({ auth }));
	const [facilities, setFacilities] = useState([]);
	const [deleteUserState, setDeleteUserState] = useState({ state: false, userId: null });
	const [deleteUserLoading, setDeleteUserLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		getOnlyRoles().then((res) => {
			setRoles(res || []);
		});
	}, []);

	const getUsers = async (facilityId = null) => {
		setLoading(true);
		if (facilityId) {
			let re = await axios
				.get(`/api/user/${localStorage.getItem("accountId")}`, { params: { facilityId } })
				.catch((e) => e);
			setUsers(re.data || []);
			setLoading(false);
		} else {
			let re = await axios.get(`/api/user/${localStorage.getItem("accountId")}`).catch((e) => e);
			setUsers(re.data || []);
			setLoading(false);
		}
	};

	useEffect(() => {
		const search = new URLSearchParams(location.search);
		// eslint-disable-next-line
		setShowAdd(search.get("showAdd") == "true");
		getUsers();
		// eslint-disable-next-line
	}, []);

	// const userAdded = (user) => {
	// 	setUsers([...users, user]);
	// 	navigate(`/settings/users?showAdd=false`);
	// 	setShowAdd(false);
	// };

	// const userUpdated = (user) => {
	// 	const newData = users.map((u) => {
	// 		if (u._id === user._id) {
	// 			return user;
	// 		}
	// 		return u;
	// 	});

	// 	setUsers(newData);
	// };

	const deleteUser = async (id) => {
		try {
			await api.delete(`/api/user/${id}`);
			const newData = users.filter((u) => u._id !== id);
			setUsers(newData);
		} catch (e) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "error",
					label: e?.response?.data?.message || "Error while update new user.",
					id: "updateUserError",
				},
			});
		}
	};

	const handleEdit = useCallback((editUser) => {
		setCurrentEdit(editUser);
		setShowAdd(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowAdd(false);
		setCurrentEdit(null);
	}, []);

	const handleAddUser = useCallback(() => {
		setCurrentEdit(null);
		setShowAdd(true);
	}, []);

	const getFacilities = async () => {
		await getFacilityOption().then((res) => {
			if (res && res.length > 0) {
				setFacilities(res[0]["facilities"]);
			}
		});
	};

	useEffect(() => {
		if (!auth?.accountId) return;
		getFacilities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	const highlightText = (text, highlight) => {
		if (!highlight) return text;
		const parts = text.split(new RegExp(`(${highlight})`, "gi"));
		return parts.map((part, index) =>
			part.toLowerCase() === highlight.toLowerCase() ? (
				<span key={index} style={{ backgroundColor: "yellow" }}>{part}</span>
			) : (
				part
			)
		);
	};

	const filteredUsers = users.filter((user) =>
		[user.fullName, user.email].some(field =>
			field?.toLowerCase().includes(searchTerm.toLowerCase())
		)
	);

	return (
		<div className={styles.accountUsers}>
			<div className={`df ${styles.hdr}`}>
				<div className={`mla df`}>
					<TextField
						variant="outlined"
						label="Search users"
						value={searchTerm}
						size="medium"
						sx={{
							mr: 2,
							"& .MuiOutlinedInput-root": {
								borderRadius: "8px",
								height: "40px",
								width: "320px"
							},
							"& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
								marginTop: "-5px",
							}
						}}
						InputProps={{
							endAdornment: searchTerm && (
								<InputAdornment position="end">
									<IconButton onClick={() => setSearchTerm("")} size="small">
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
						onChange={(e) => setSearchTerm(e.target.value)}
						className={`ffml fs16 mr-2`}
					/>
					<div className={`inputWrpr ${styles.facilitySelect}`}>
						<select
							className={`ffml fs16 mr-2`}
							value={facilityFilter}
							onChange={(e) => {
								setFacilityFilter(e.target.value);
								getUsers(e.target.value);
							}}
						>
							<option value="">Select All</option>
							{facilities &&
								facilities.length > 0 &&
								facilities.map((item, index) => (
									<option key={index} value={item._id}>
										{item.name}
									</option>
								))}
						</select>
					</div>
					<ShowForPermission type="addUser">
						<Button action={handleAddUser} buttonStyle="theme" text="Add User" size="medium" />
						{showAdd && <AddUser currentEdit={currentEdit} close={handleClose} roles={roles} refreshData={getUsers} />}
					</ShowForPermission>
				</div>
			</div>
			<div className={styles.listWrpr}>
				{filteredUsers.length > 0 &&
					filteredUsers.map((user, index) => (
						<div key={index} className={`df aic ffmm fs14 ${styles.line}`}>
							<h2 style={{ width: "20%" }}>{highlightText(user.fullName, searchTerm)}</h2>
							<h3 style={{ width: "40%" }}>{highlightText(user.email, searchTerm)}</h3>

							<h3 className={`m-l-20`} style={{ width: "10%" }}>
								{user.role?.name}
							</h3>
							<div className={`mla df aic`}>
								<ShowForPermission type="editUser">
									<Link to="/logs" state={{ userId: user._id }} style={{ marginTop: "3px", marginRight: "15px" }}>
										<TimesheetSVG fill="#4879f5" />
									</Link>
								</ShowForPermission>
								<ShowForPermission type="editUser">
									<div className={`m-r-10 c-pointer`} onClick={() => handleEdit(user)} style={{ marginBotton: "5px" }}>
										<EditSVG fill="#4879f5" />
									</div>
								</ShowForPermission>
								<ShowForPermission type="deleteUser">
									<div
										className={`m-l-10 c-pointer`}
										onClick={() => {
											setDeleteUserState({ state: true, userId: user._id });
										}}
									>
										<DeleteSVG fill="#F93B5F" />
									</div>
								</ShowForPermission>
							</div>
						</div>
					))}
				{filteredUsers.length === 0 && !loading && <div key={"no-data-user"} className={`df aic ffmm fs14 ${styles.line}`}>No data found</div>}
			</div>
			<PopupDialog
				state={deleteUserState.state}
				isDestructiveAction={true}
				isLoading={deleteUserLoading}
				onClose={() => setDeleteUserState({ state: false, accountId: null })}
				onConfirm={async () => {
					setDeleteUserLoading(true);
					await deleteUser(deleteUserState.userId);
					setDeleteUserLoading(false);
					setDeleteUserState({ state: false, userId: null });
				}}
				dialogMessage={"Are you sure you want to delete this user?"}
				confirmBtnText={"Delete"}
			/>
		</div>
	);
};

export default AccountUserPage;