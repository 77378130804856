import { Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import MinimizeSVG from "../../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../../assets/svgs/popup-plus.svg";
import styles from "./SelectableCardCoTransfer.module.scss";
import classNames from "classnames";
import CardTooltipContent from "../../card/CardTooltipContent";
import HtmlTooltip from "../../../shared/HtmlTooltip";
import InfoIcon from "../../../icon/InfoIcon";
import { IconButton } from "@mui/material";
import ReportButton from "../../../shared/ReportButton";
import CommonIconButtons from "../../card/RightAction";

const SelectableCardCoTransfer = ({
	children,
	flex,
	title,
	minimizable,
	disabled,
	handleOnclickCard,
	selected,
	tooltipContent,
	handleOnClickAction,
	handleOnClickReport,
	loading = false,
	pageType
}) => {
	const [showDetails, setShowDetails] = useState(true);

	const [isAnimating, setIsAnimating] = useState(false);
	const timerRef = useRef(null);


	const handleAnimationChange = useCallback(async () => {
		setIsAnimating(true);
		await new Promise(resolve => setTimeout(resolve, 0)); // Ensure state update

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		const stopAnimation = () => {
			setIsAnimating(false);
			timerRef.current = null;
		};

		if (loading) {
			const checkLoading = () => {
				if (!loading) {
					stopAnimation();
				} else {
					requestAnimationFrame(checkLoading); // Continue checking
				}
			};
			requestAnimationFrame(checkLoading); // Start checking
		} else {
			timerRef.current = setTimeout(stopAnimation, 500); // Minimum 1000ms
		}
	}, [loading]);

	useEffect(() => {
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, []);

	const handleClickOnDetails = useCallback(
		(totalTitle, defaultTab) => {
			handleOnClickAction(totalTitle, defaultTab);
		},
		[handleOnClickAction]
	);


	return (
		<div
			className={classNames(
				styles.selectableCardCoTransfer,
				disabled && styles.disabledCard,
				selected && styles.selected,
				loading && styles.skeleton,
				isAnimating && styles.cardAnimation,
			)}
			style={{ flex, height: `${showDetails ? "initial" : "fit-content"}` }}
			onClick={() => {
				handleAnimationChange()
				!loading && handleOnclickCard(!selected)
			}}
		>
			<div className={classNames("df aic", styles.cardHeader)}>
				<Typography className={styles.title} variant="subtitle1" component="p" sx={{ maxWidth: "44%" }}>
					{title}
				</Typography>
				<div className="df aic jce w100">
					<div>
						{tooltipContent && (
							<div className={styles.htmlTooltip}>
								<HtmlTooltip content={<CardTooltipContent content={tooltipContent} />}>
									<IconButton disableFocusRipple={true} disableRipple={true}>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>
						)}
					</div>
					{loading ? (
						<div
							style={{ width: "23px", height: "23px", borderRadius: "50%", marginRight: "7px" }}
							className="skeleton"
						/>
					) : (
						<ReportButton
							handleOnClickReport={handleOnClickReport}
							loading={loading}
							title={title}
							openFrom="card"
							pageType={pageType}
							isCustomAlertEnable={false}
						/>
					)}
					<CommonIconButtons
						title={title}
						handleOnClickAction={(title, defaultTab) => handleClickOnDetails(title, defaultTab)}
						loading={loading}
					/>
					{minimizable && (
						<div
							className="p-l-9 p-r-5 p-t-5 p-b-5 m-l-15"
							style={{ cursor: "pointer" }}
							onClick={() => setShowDetails(!showDetails)}
						>
							{showDetails ? <MinimizeSVG /> : <PopupPlusSVG />}
						</div>
					)}
				</div>
			</div>
			{showDetails && <div className={classNames("df aic", styles.cardContent)}>{children}</div>}
		</div>
	);
};

export default SelectableCardCoTransfer;
