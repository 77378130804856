import "./styles.scss"; // Import your CSS

const CheckboxLoader = ({ isLoading, className }) => {
    if (!isLoading) return null; // Avoid unnecessary rendering

    const combinedClassName = className ? `lds-ellipsis small-dots ${className}` : "lds-ellipsis small-dots";

    return (
        <div className={combinedClassName}><div></div><div></div><div></div></div>
    );
};

export default CheckboxLoader;
