import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, IconButton, FormControl, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEqual } from 'lodash';
import RangeInput from './number-range/RangeInput';
import ConditionPopover from './number-range/ConditionPopover';
import { NUMBER_RANGE_TYPE } from '../../../../../../types/question.type';

const NumberRangeType = ({ setQuestionValues, questionValues }) => {
    const [ranges, setRanges] = useState(questionValues?.customQuestionOptions);
    const previousRanges = useRef(null);
    const [anchorEl, setAnchorEl] = useState({ index: null, type: null, element: null });

    const handleMinChange = (index, value) => {
        setRanges(prevRanges => {
            const newRanges = prevRanges.map((range, i) =>
                i === index ? { ...range, min: value } : range
            );
            return newRanges;
        });
    };

    const handleTitleChange = (index, value) => {
        setRanges(prevRanges => {
            const newRanges = prevRanges.map((range, i) =>
                i === index ? { ...range, title: value } : range
            );
            return newRanges;
        });
    };

    const handleMaxChange = (index, value) => {
        setRanges(prevRanges => {
            const newRanges = prevRanges.map((range, i) =>
                i === index ? { ...range, max: value } : range
            );
            return newRanges;
        });
    };

    const handleConditionChange = (index, type, value) => {
        setRanges(prevRanges => {
            const newRanges = prevRanges.map((range, i) => {
                if (i === index) {
                    if (type === 'min') {
                        return { ...range, min: value };
                    } else {
                        return { ...range, max: value };
                    }
                }
                return range;
            });
            return newRanges;
        });
        setAnchorEl({ index: null, type: null, element: null });
    };

    const addRange = (type) => {
        if (type === NUMBER_RANGE_TYPE.RANGE) {
            setRanges(prevRanges => [...prevRanges, { min: '', max: '', type, title: '' }]);
        } else {
            setRanges((prevRanges) => {
                const hasType = prevRanges.some(range => range.type === type);

                // If the type already exists, return the current ranges
                if (hasType) {
                    return prevRanges;
                }

                // Add the new type if it doesn't exist
                return [...prevRanges, { min: '', max: '', type, title: '' }];
            });
        }
    };

    const removeRange = (index) => {
        setRanges(prevRanges => prevRanges.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (!isEqual(ranges, previousRanges.current)) {
            setQuestionValues({
                ...questionValues,
                customQuestionOptions: ranges,
            });
            previousRanges.current = ranges;
        }
    }, [ranges, setQuestionValues, questionValues]);

    return (
        <FormControl fullWidth margin="normal">
            <Box>
                {ranges.map((range, index) => (
                    <Box key={index} display="flex" alignItems="center" gap={2} marginBottom={1}>
                        {(range.type === NUMBER_RANGE_TYPE.TOTAL || range.type === NUMBER_RANGE_TYPE.AVERAGE) ? (
                            <>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={range.title}
                                    onChange={(e) => handleTitleChange(index, e.target.value)}
                                    label={`${range.type === 'total' ? 'Total' : 'Average'} Title`}
                                    sx={{ mb: 2 }}
                                />
                                <IconButton aria-label="delete" onClick={() => removeRange(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <RangeInput
                                    range={range}
                                    index={index}
                                    handleMinChange={handleMinChange}
                                    handleMaxChange={handleMaxChange}
                                    setAnchorEl={setAnchorEl}
                                />

                                <IconButton aria-label="delete" onClick={() => removeRange(index)}>
                                    <DeleteIcon />
                                </IconButton>

                                <ConditionPopover
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl({ index: null, type: null, element: null })}
                                    index={index}
                                    type={'min'}
                                    handleConditionChange={handleConditionChange}
                                />
                                <ConditionPopover
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl({ index: null, type: null, element: null })}
                                    index={index}
                                    type={'max'}
                                    handleConditionChange={handleConditionChange}
                                />
                            </>
                        )}
                    </Box>
                ))}
                <Button
                    variant={"outlined"}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        // handleNumberRageChange(NUMBER_RANGE_TYPE.RANGE);
                        addRange(NUMBER_RANGE_TYPE.RANGE);
                    }}
                >
                    Add Range
                </Button>
                <Button
                    sx={{ ml: 2 }}
                    variant={"outlined"}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        // handleNumberRageChange(NUMBER_RANGE_TYPE.AVERAGE)
                        addRange(NUMBER_RANGE_TYPE.AVERAGE);
                    }}
                    disabled={ranges?.some((range) => range.type === NUMBER_RANGE_TYPE.AVERAGE)}
                >
                    Add Average
                </Button>
                <Button
                    sx={{ ml: 2 }}
                    variant={"outlined"}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        // handleNumberRageChange(NUMBER_RANGE_TYPE.TOTAL)
                        addRange(NUMBER_RANGE_TYPE.TOTAL);
                    }}
                    disabled={ranges?.some((range) => range.type === NUMBER_RANGE_TYPE.TOTAL)}
                >
                    Add Total
                </Button>
            </Box>
        </FormControl>
    );
};

export default NumberRangeType;