import { useCallback, useMemo } from "react";
import HeaderItem from "./HeaderItem";
import { TRANSFER_TYPE } from "../../../types/community-transfer.type";

const sortItemsByPriority = (items, priorityData) => {
    if (!priorityData?.length) return items;

    const mainItems = items.filter((item) => item.isMain);
    const nonMainItems = items.filter((item) => !item.isMain);

    const sortedNonMainItems = priorityData.reduce((acc, priorityItem) => {
        const foundItem = nonMainItems.find((item) => {
            if (item.filterType === "ADT") {
                return item.cardId === priorityItem.cardId && item.childId === priorityItem.childId;
            }
            return item.dataKey === priorityItem.type;
        });

        if (foundItem) {
            acc.push(foundItem);
        }
        return acc;
    }, []);

    // Efficiently find remaining items using a Set
    const sortedSet = new Set(sortedNonMainItems);
    const remainingItems = nonMainItems.filter((item) => !sortedSet.has(item));

    return [...mainItems, ...sortedNonMainItems, ...remainingItems];
};

const getTotalNumberOfFilter = (cardFilter, type) => cardFilter?.[type]?.length ?? null;

const HeaderFilter = ({
    items,
    filterListData,
    cardFilter,
    transferType,
    percentageBy,
    lockedTotal,
    lockedTotalBy,
    lockeByADT,
    openFrom
}) => {
    const shouldShowDefault = useMemo(() => {
        if (openFrom === "detailView") {
            return false;
        }
        const isDefaultCondition =
            !transferType ||
            (Array.isArray(transferType) && transferType.length === 0) ||
            (typeof transferType === 'string' && transferType.trim() === '');

        const hasPriorityData = cardFilter?.priorityData?.length > 0 || cardFilter?.mainPriorityData?.length > 0;

        const isLockedByCensus = lockedTotal && lockedTotalBy === 'census';

        const resBool = (isDefaultCondition && !hasPriorityData) || isLockedByCensus;

        return resBool;
    }, [openFrom, transferType, cardFilter?.priorityData?.length, cardFilter?.mainPriorityData?.length, lockedTotal, lockedTotalBy]);

    const sortedItemsResult = (arrayData, lockedTotalBy, lockeByADT, lockedTotal) => {
        let foundLockedIndex = -1;
        if (!lockedTotal) {
            return arrayData.map((item) => {
                return { ...item, isLocked: false };

            });
        }

        // First pass: Find the index of the matching item
        const updatedItems = arrayData.map((item, index) => {
            if (item?.isCommunity && (lockedTotalBy === TRANSFER_TYPE.AMA || lockedTotalBy === TRANSFER_TYPE.SNF || lockedTotalBy === TRANSFER_TYPE.SAFE_DISCHARGE)) {
                foundLockedIndex = index;
                return { ...item, isLocked: true }; // Lock the matching item
            } else if (item?.filterType === "ADT" && lockeByADT && item.cardId === lockeByADT?.cardId && item.childId === lockeByADT?.childId) {
                foundLockedIndex = index;
                return { ...item, isLocked: true }; // Lock the matching item
            } else if (item.dataKey === lockedTotalBy) {
                foundLockedIndex = index;
                return { ...item, isLocked: true }; // Lock the matching item
            }

            return item;
        });

        // Second pass: Apply isLocked based on the found index
        return updatedItems.map((item, index) => {
            if (foundLockedIndex !== -1 && item.dataKey) {
                if (index < foundLockedIndex) {
                    return { ...item, isLocked: true }; // Lock items before the match
                } else if (index > foundLockedIndex) {
                    return { ...item, isLocked: false }; // Unlock items after the match
                }
            }
            return item;
        });
    };

    const sortedItems = useMemo(() => {
        const arrayData = sortItemsByPriority(items, cardFilter?.priorityData || cardFilter?.mainPriorityData, lockedTotalBy)
        const result = sortedItemsResult(arrayData, lockedTotalBy, lockeByADT, lockedTotal);
        return result
    },
        [cardFilter, items, lockedTotalBy, lockeByADT, lockedTotal]
    );

    const isTransferMatch = useCallback((dataKey) => {
        if (
            ((Array.isArray(transferType) && transferType.includes("all")) || transferType === 'all') &&
            (cardFilter?.priorityData?.length > 0 || cardFilter?.mainPriorityData?.length > 0) &&
            (lockedTotalBy !== 'all' || !lockedTotalBy)
        ) {
            return false;
        }

        if (
            openFrom === "detailView" &&
            (transferType === "all" || (Array.isArray(transferType) && transferType.length === 1 && transferType[0] === "all"))
        ) {
            return false;
        }

        return Array.isArray(transferType)
            ? transferType.includes(dataKey)
            : transferType === dataKey;
    }, [transferType, cardFilter?.priorityData?.length, cardFilter?.mainPriorityData?.length, lockedTotalBy, openFrom]);

    return [
        shouldShowDefault && (
            <HeaderItem
                key="default"
                label={percentageBy}
                type="label"
                isLocked={lockedTotal && lockedTotalBy === 'census'}
            />
        ),
        ...sortedItems.map((item, index) => {
            const {
                label,
                type,
                dataKey,
                isMain = false,
                isLocked,
                filterType,
                subTitle,
                total,
                selectedIds,
                tableData,
            } = item;

            if (isMain && !isTransferMatch(dataKey)) return null;

            const headerItemProps = {
                key: index,
                label: filterType === "ADT" ? `${label}: ${subTitle || ''}`.trim() : label,
                isLocked,
            };

            if (filterType === "ADT") {
                return (
                    <HeaderItem
                        {...headerItemProps}
                        total={total}
                        cardFilter={selectedIds}
                        data={tableData}
                    />
                );
            } else {
                return (
                    <HeaderItem
                        {...headerItemProps}
                        type={type}
                        total={getTotalNumberOfFilter(cardFilter, dataKey)}
                        data={filterListData?.[dataKey]}
                        cardFilter={cardFilter?.[dataKey]}
                    />
                );
            }
        })
    ];
};

export default HeaderFilter;
