import { useMemo } from "react";

const useHeaderCustomLabelsItem = (cardFilter, dynamicCards, defaultHeaderItems) => {
    
    return useMemo(() => {
        if (!cardFilter) {
            return [...defaultHeaderItems];
        }

        const headerItemsDataArr = [...defaultHeaderItems];
        const cardFilterKeys = Object.keys(cardFilter);

        cardFilterKeys.forEach((key) => {
            if (key === "priorityData" || key.includes("_spacial")) return;

            const existingItemIndex = headerItemsDataArr.findIndex((item) => item.dataKey === key);

            if (existingItemIndex === -1) {
                const labelData = dynamicCards?.find((item) => item.accessor === key);
                if (labelData) {
                    headerItemsDataArr.push({
                        label: labelData.tableLabel || labelData.label || key,
                        dataKey: key,
                        isDefault: false,
                    });
                }
            }
        });

        return headerItemsDataArr;
    }, [cardFilter, defaultHeaderItems, dynamicCards]);
};

export default useHeaderCustomLabelsItem;