import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OVERALL_CARDS_TYPE } from "../../../../types/overall.type";
import { getPositivePlusSign, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import NoRecordFound from "../../../shared/NoRecordFound";
import ColorBox from "../../dashboard/ColorBox";
import { EnhancedTableHead, getComparator, stableSort } from "./EnhancedTableHead";
import { useSelector } from "react-redux";
import { OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../../../../types/pages.type";
import { ReportTransferTypeTitleEnum } from "../../../../data/common.data";
import { ADMISSION_TYPES } from "../../../../data/admission.data";
import { TYPES } from "../../../../data/hospital.data";
import { Typography } from "@mui/material";
import HtmlTooltip from "../../HtmlTooltip";
import { ADMISSION_FILTER_TYPE } from "../../../../types/admission.type";

export default function ChartBuildingList({
	data,
	dataComparison,
	type = "dashboard",
	filterCardType = null,
	chartData,
	chartDataComparison,
	censusByFacility = [],
	bedByFacility = [],
	censusByFacilityComparison = [],
	bedCapacityByFacility = [],
	bedCapacityByFacilityComparison = [],
	filterSelected,
	filterSelectedComparison,
	filterTotal,
	filterTotalComparison,
	facilityPerText = null,
	adtFacilityPercentage = [],
	adtFacilityPercentageComparison = [],
	filter,
	filterComparison,
	buildingElementRef,
	forAdmission,
	page,
	isTotalCard = false,
	filterListData,
	isAdt = false,
	selectedADTTableData,
	typeCard,
	isPlusSign = false
}) {
	const [tableData, setTableData] = useState([]);
	// not needed for now but already implemented, so maybe we will need it in the future
	const [tableDataComparison, setTableDataComparison] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("calories");
	const [, setCombinedPercentageComparison] = useState(null);

	const { percentageBy } = useSelector((state) => state.common);

	const deceasedState = useSelector((state) => state.deceased);
	const hospitalState = useSelector((state) => state.hospital);
	const communityTransferState = useSelector((state) => state.communityTransfer);
	const admissionState = useSelector((state) => state.admission);
	const overallState = useSelector((state) => state.overall);


	const { lockedTotalBy, transferType, cardFilterData, cardFilter } = useMemo(() => {
		let pages = {
			[PAGE_TYPE.DECEASED]: deceasedState,
			[PAGE_TYPE.HOSPITAL]: hospitalState,
			[PAGE_TYPE.ADMISSION]: admissionState,
			[PAGE_TYPE.OVERALL]: overallState,
			[PAGE_TYPE.COMMUNITY_TRANSFER]: communityTransferState,
		};
		let pageState = pages[page];
		if (isAdt) {
			pageState = admissionState
		}

		let lockedTotalBy = null;
		let transferType = null;
		let cardFilterData = [];
		let cardFilter = null;
		if (pageState) {
			transferType = pageState.transferType;
			lockedTotalBy = pageState?.lockedTotal ? pageState.lockedTotalBy : null;
			cardFilterData = (page === PAGE_TYPE.ADMISSION || isAdt) ? pageState.cardFilter.mainPriorityData : pageState.cardFilter.priorityData;
			cardFilter = pageState.cardFilter;
		}

		return { lockedTotalBy, transferType, cardFilterData, cardFilter };
	}, [page, deceasedState, hospitalState, admissionState, overallState, communityTransferState, isAdt]);

	const getTooltipText = (text, count = 25, variant = "body2") => {
		if (!text) return null;
		const tooltipContent = text.length > count ? (
			<HtmlTooltip
				title={<span dangerouslySetInnerHTML={{ __html: text }} />}
				arrow
				key={text + "tooltip_html"}
				sx={{ background: "#fff", color: "#000", maxWidth: "420px !important" }}
			>
				<span style={{ cursor: "pointer", fontWeight: 600, color: "#212B36", fontSize: "0.875rem" }}>
					{text?.substring(0, count) + "..."}
				</span>
			</HtmlTooltip>
		) : (
			<span style={{ cursor: "pointer" }}>{text || ""}</span>
		);
		return <Typography variant={variant}>{tooltipContent}</Typography>;
	};

	const getFacilityHeadLabel = useCallback(() => {
		let labelMain = "Against Each Facilities Own: </br >";
		let lockedTitleSet = true;
		const percentageByText = percentageBy;
		let percentageAgainst = lockedTotalBy ? lockedTotalBy : percentageByText;

		if (transferType) {
			if (Array.isArray(transferType)) {
				if (transferType.length > 0) {
					let title;
					if (transferType.length > 0 && _.includes(transferType, "all")) {
						percentageAgainst = `Total Community Transfers`;
						title = "Total Community Transfers";
						if (lockedTotalBy && lockedTotalBy === "transferType") {
							percentageAgainst = `${title}`;
							title += " (Locked)";
							lockedTitleSet = false;
						}
					} else {
						percentageAgainst = `${transferType.toString()}`;
						title = transferType.toString();
						if (lockedTotalBy && lockedTotalBy === "transferType") {
							percentageAgainst = `${title}`;
							title += " (Locked)";
							lockedTitleSet = false;
						}
					}
				}
			} else {
				let transferTypeTitle = ReportTransferTypeTitleEnum[transferType]
					? ReportTransferTypeTitleEnum[transferType]
					: transferType;
				percentageAgainst = `${transferTypeTitle}`;
				let mainTitle = transferType === "all" ? transferTypeTitle + " " + page : transferTypeTitle;

				if (isAdt && transferType === "all") {
					mainTitle = "Total Admissions";
					percentageAgainst = transferType === "all" ? `${mainTitle}` : `${transferTypeTitle}`;
				} else if (transferType === "all" && page === PAGE_TYPE.HOSPITAL) {
					mainTitle = "Total Hospital Transfers";
					percentageAgainst = `${mainTitle}`;
				} else if (transferType === "all" && page === PAGE_TYPE.ADMISSION) {
					mainTitle = "Total Admissions";
					percentageAgainst = transferType === "all" ? `${mainTitle}` : `${transferTypeTitle}`;
				}
				if (
					lockedTotalBy &&
					(lockedTotalBy === "all" ||
						lockedTotalBy === ADMISSION_TYPES.ADMISSION ||
						lockedTotalBy === ADMISSION_TYPES.READMISSION ||
						lockedTotalBy === TYPES.PLANNED ||
						lockedTotalBy === TYPES.UNPLANNED ||
						lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING ||
						lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING)
				) {

					let transferTypeLockedTitle = ReportTransferTypeTitleEnum[lockedTotalBy]
						? ReportTransferTypeTitleEnum[lockedTotalBy]
						: transferType;
					let mainTitleLocked =
						lockedTotalBy === "all" ? transferTypeLockedTitle + " " + page : transferTypeLockedTitle;
					if (isAdt && lockedTotalBy === "all") {
						mainTitleLocked = "Total Admissions";
					} else if (lockedTotalBy === "all" && page === PAGE_TYPE.HOSPITAL) {
						mainTitleLocked = "Total Hospital Transfers";
					} else if (lockedTotalBy === "all" && page === PAGE_TYPE.ADMISSION) {
						mainTitleLocked = "Total Admissions";
					}
					percentageAgainst = `${mainTitleLocked}`;
					mainTitleLocked += " (Locked)";
					lockedTitleSet = false;
					mainTitle = mainTitleLocked;
				}
			}
		}

		let alreadyChecked = false;
		// let eleTitle = "";
		let percentageAgainstFilter;
		// let i = 0;
		if (cardFilterData.length > 0 && !isTotalCard) {

			if (isAdt) {
				for (const ele of cardFilterData) {
					if (ele.filterType === ADMISSION_FILTER_TYPE.ADMISSION) {
						let dataType = filterListData[ele.type] || [];
						if (dataType.length > 0 && !alreadyChecked) {
							const onlyFilterData = _.filter(dataType, ({ _id }) =>
								_.every([_.includes(cardFilter[ele.type] || [], _id)])
							);
							if (onlyFilterData.length > 0 && lockedTitleSet) {
								const filterNames = _.map(onlyFilterData, "label").join(', ');
								percentageAgainstFilter = percentageAgainstFilter ? `${percentageAgainstFilter} </br > ${filterNames}` : filterNames;
							}
							if (lockedTotalBy && lockedTotalBy === ele.type) {
								// eleTitle = !eleTitle ? ele.cardTitle : eleTitle + " </br > " + ele.cardTitle;
								// percentageAgainstFilter = eleTitle;
								lockedTitleSet = false;
							}
						}
						// if (lockedTitleSet) {
						// 	eleTitle = !eleTitle ? ele.cardTitle : eleTitle + " </br > " + ele.cardTitle;
						// 	percentageAgainstFilter = eleTitle;

						// }
						if (ele.type === typeCard) {
							alreadyChecked = true;
						}
					} else {
						const { parentId, childId } = ele;
						const selectedADTTable = _.find(selectedADTTableData, { parentId, childId });

						const onlyFilterData = selectedADTTable?.tableData;

						// let title = `${ele?.subTitle} (${_.capitalize(ele.title)})`;
						if (onlyFilterData.length > 0 && lockedTitleSet) {
							let filtersNamesData = [];
							filtersNamesData = onlyFilterData.filter(row =>
								selectedADTTable?.selectedIds?.includes(row.id)
							).map(row => row.label);
							const filterNames = filtersNamesData?.join(', ');
							percentageAgainstFilter = percentageAgainstFilter ? `${percentageAgainstFilter} </br > ${selectedADTTable?.title}: ${filterNames}` : `${selectedADTTable?.title}: ${filterNames}`;
						}
						if (lockedTotalBy && lockedTotalBy === ele.type + "_" + ADMISSION_FILTER_TYPE.ADT) {
							// percentageAgainst = `${selectedADTTable?.subTitle} (${_.capitalize(selectedADTTable.title)})`;
							// const eleTitleItem = `${selectedADTTable?.subTitle} (${_.capitalize(selectedADTTable.title)})`;
							// eleTitle = !eleTitle ? eleTitleItem : eleTitle + " </br > " + eleTitleItem;
							// percentageAgainstFilter = eleTitle;
							lockedTitleSet = false;
						}
						if (lockedTitleSet) {
							// const eleTitleItem2 = `${selectedADTTable?.subTitle} (${_.capitalize(selectedADTTable.title)})`;
							// eleTitle = !eleTitle ? eleTitleItem2 : eleTitle + " </br > " + eleTitleItem2;
							// percentageAgainstFilter = eleTitle;
						}
						if (ele.parentId === type.parentId && ele.childId === type.childId) {
							alreadyChecked = true;
						}
					}
				}
			} else {
				cardFilterData.forEach((ele) => {
					if (ele.type === typeCard) {
						return;
					}
					const dataType = filterListData[ele.type] || [];
					if (dataType.length > 0 && !alreadyChecked) {
						const onlyFilterData = _.filter(dataType, ({ _id }) =>
							_.includes(cardFilter[ele.type] || [], _id)
						);
						if (onlyFilterData.length > 0 && lockedTitleSet) {
							const filterNames = _.map(onlyFilterData, "label").join(', ');
							percentageAgainstFilter = percentageAgainstFilter ? `${percentageAgainstFilter} </br > ${filterNames}` : filterNames;
						}
						if (lockedTotalBy && lockedTotalBy === ele.type) {
							lockedTitleSet = false;
						}
					}
					if (ele.type === typeCard) {
						alreadyChecked = true;
					}
					// i++;
				});
			}
		}
		if (!percentageAgainstFilter) {
			return getTooltipText(labelMain + percentageAgainst);
		} else {
			return getTooltipText(labelMain + percentageAgainstFilter);
		}
	}, [percentageBy, lockedTotalBy, transferType, cardFilterData, isTotalCard, page, isAdt, filterListData, typeCard, selectedADTTableData, type.parentId, type.childId, cardFilter]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	useEffect(() => {
		if (data.length > 0) {
			if (type === "overall") {
				let tableDataOverall = [];
				let tableDataArrObj = _.groupBy(data, "facilityId");
				if (tableDataArrObj) {
					for (const key in tableDataArrObj) {
						const facilityWiseData = tableDataArrObj[key];
						const facilityId = facilityWiseData[0].facilityId;
						const typeWiseData = _.countBy(facilityWiseData, "isOutgoing");
						let totalIncoming = typeWiseData?.false || 0;
						let totaloutgoinng = typeWiseData?.true || 0;
						let total;
						let totalByFacility = 0;
						if (filterCardType && filterCardType === OVERALL_CARDS_TYPE.TOTAL_INCOMING) {
							total = totalIncoming;
							if (chartData.mainData && chartData.mainData.length > 0 && filterSelected) {
								totalByFacility = _.filter(chartData.mainData, (o) => {
									if (o.facilityId === key && !o.isOutgoing) return o;
								}).length;
							}
						} else if (filterCardType && filterCardType === OVERALL_CARDS_TYPE.TOTAL_OUTGOING) {
							total = totaloutgoinng;
							if (chartData.mainData && chartData.mainData.length > 0 && filterSelected) {
								totalByFacility = _.filter(chartData.mainData, (o) => {
									if (o.facilityId === key && o.isOutgoing) return o;
								}).length;
							}
						} else {
							total = totalIncoming - totaloutgoinng;
						}
						if (filter && filter.lockedTotal && filter.lockedByFacility) {
							totalByFacility =
								_.filter(filter.lockedByFacility, function (o) {
									if (o.facilityId === key) return o;
								})?.length || 0;
						}
						if (censusByFacility && censusByFacility.length > 0 && !filterSelected) {
							totalByFacility = Math.round(_.find(censusByFacility, { id: key })?.total) || 0;
						}
						if (censusByFacility && censusByFacility.length > 0 && filter?.isCensusTotalLocked) {
							totalByFacility = _.find(censusByFacility, { id: key })?.total;
						}
						let facilityWiseCount = itemPercentage(total, totalByFacility, "number");
						const facilityAverageCensus = Number(censusByFacility.find((x) => x.id === facilityId)?.total);
						const againstAverageCensusPercentage = itemPercentage(total, facilityAverageCensus, "number");

						tableDataOverall.push({
							id: key,
							name: facilityWiseData[0]?.facility?.name || "",
							total: total,
							facilityPercentage: facilityWiseCount ? Number(facilityWiseCount) : null,
							againstAverageCensusPercentage,
						});
					}
				}
				if (tableDataOverall.length > 0) {
					tableDataOverall = _.orderBy(tableDataOverall, "total", "desc");
				}
				setTableData(tableDataOverall);

				// ------> START OF COMPARISON BLOCK
				let tableDataOverallComparison = [];
				let tableDataArrObjComparison = _.groupBy(dataComparison, "facilityId");
				const sumValuesComparison = dataComparison?.filter((x) => x.isOutgoing).length;
				const sumAverageCensusesComparison = censusByFacilityComparison.reduce((a, v) => {
					const total = Number(v.total);

					return a + !isNaN(total) ? total : 0;
				}, 0);
				const percentageComparisonTemp = itemPercentage(sumValuesComparison, sumAverageCensusesComparison, "number");
				setCombinedPercentageComparison(percentageComparisonTemp);

				if (tableDataArrObjComparison) {
					for (const key in tableDataArrObjComparison) {
						const facilityWiseDataComparison = tableDataArrObjComparison[key];
						const typeWiseDataComparison = _.countBy(facilityWiseDataComparison, "isOutgoing");
						let totalIncomingComparison = typeWiseDataComparison?.false || 0;
						let totaloutgoinngComparison = typeWiseDataComparison?.true || 0;
						let totalComparison;
						let totalByFacilityComparison = 0;
						if (filterCardType && filterCardType === OVERALL_CARDS_TYPE.TOTAL_INCOMING) {
							totalComparison = totalIncomingComparison;
							if (
								chartDataComparison?.mainData &&
								chartDataComparison?.mainData.length > 0 &&
								filterSelectedComparison
							) {
								totalByFacilityComparison = _.filter(chartDataComparison?.mainData, (o) => {
									if (o.facilityId === key && !o.isOutgoing) return o;
								}).length;
							}
						} else if (filterCardType && filterCardType === OVERALL_CARDS_TYPE.TOTAL_OUTGOING) {
							totalComparison = totaloutgoinngComparison;
							if (chartDataComparison.mainData && chartDataComparison.mainData.length > 0 && filterSelectedComparison) {
								totalByFacilityComparison = _.filter(chartDataComparison?.mainData, (o) => {
									if (o.facilityId === key && o.isOutgoing) return o;
								}).length;
							}
						} else {
							totalComparison = totalIncomingComparison - totaloutgoinngComparison;
						}
						if (filterComparison && filterComparison?.lockedTotal && filterComparison?.lockedByFacility) {
							totalByFacilityComparison =
								_.filter(filterComparison?.lockedByFacility, function (o) {
									if (o.facilityId === key) return o;
								})?.length || 0;
						}
						if (censusByFacilityComparison && censusByFacilityComparison.length > 0 && !filterSelectedComparison) {
							totalByFacilityComparison = Math.round(_.find(censusByFacilityComparison, { id: key })?.total) || 0;
						}
						if (
							censusByFacilityComparison &&
							censusByFacilityComparison?.length > 0 &&
							filterComparison?.isCensusTotalLocked
						) {
							totalByFacilityComparison = _.find(censusByFacilityComparison, { id: key })?.total;
						}
						let facilityWiseCountComparison = itemPercentage(totalComparison, totalByFacilityComparison, "number");
						tableDataOverallComparison.push({
							id: key,
							name: facilityWiseDataComparison[0]?.facility?.name || "",
							total: totalComparison,
							facilityPercentage: facilityWiseCountComparison ? Number(facilityWiseCountComparison) : null,
						});
					}
				}

				if (tableDataOverallComparison.length > 0) {
					tableDataOverallComparison = _.orderBy(tableDataOverallComparison, "total", "desc");
				}
				setTableDataComparison(tableDataOverallComparison);
				// ------> END OF COMPARISON BLOCK
			} else {
				let tableDataArr = _(data)
					.groupBy("facilityId")
					.sortBy((group) => data.indexOf(group[0]))
					.map((product) => {
						let totalByFacility = 0;
						if (chartData.mainData && chartData.mainData.length > 0 && filterSelected) {
							totalByFacility = _.filter(chartData.mainData, function (o) {
								if (o.facilityId === product[0]?.facilityId) return o;
							}).length;
						}

						if (
							filter &&
							(filter.type === "ninetyDaysData" || filter.type === "sixtyDaysData") &&
							filter.facilityPercentage &&
							filter.facilityPercentage.length > 0
						) {
							totalByFacility =
								_.find(filter.facilityPercentage, function (o) {
									if (o.id === product[0]?.facilityId) return o;
								})?.total || 0;
						}
						// if (isOnlyHospitalDashboard) {
						// 	if (bedByFacility && bedByFacility.length > 0 && !filterSelected) {
						// 		totalByFacility = Math.round(_.find(bedByFacility, { id: product[0]?.facilityId })?.total) || 0;
						// 	}
						// } else {
						if (censusByFacility && censusByFacility.length > 0 && !filterSelected) {
							totalByFacility = Math.round(_.find(censusByFacility, { id: product[0]?.facilityId })?.total) || 0;
						}
						// }

						if (adtFacilityPercentage && adtFacilityPercentage.length > 0) {
							totalByFacility =
								_.find(adtFacilityPercentage, function (o) {
									if (o.id === product[0]?.facilityId) return o;
								})?.total || 0;
						}
						if (filter && filter.lockedTotal && filter.lockedByFacility) {
							totalByFacility =
								_.filter(filter.lockedByFacility, function (o) {
									if (o.facilityId === product[0]?.facilityId) return o;
								})?.length || 0;
						}
						if (censusByFacility && censusByFacility.length > 0 && filter?.isCensusTotalLocked) {
							totalByFacility = Math.round(_.find(censusByFacility, { id: product[0]?.facilityId })?.total) || 0;
						}
						// if (isOnlyHospitalDashboard && bedByFacility && bedByFacility.length > 0 && filter?.isCensusTotalLocked) {
						// 	totalByFacility = Math.round(_.find(bedByFacility, { id: product[0]?.facilityId })?.total) || 0;
						// }

						let facilityWiseCount = itemPercentage(
							product.length,
							filterTotal ? filterTotal : totalByFacility,
							"number"
						);
						const censusForThisFacility = Number(censusByFacility.find((x) => x.id === product[0]?.facilityId));
						const againstAverageCensusPercentage = itemPercentage(product.length, censusForThisFacility, "number");
						return {
							id: product[0]?.facilityId,
							name: product[0]?.facility?.name || "",
							total: product.length || 0,
							facilityPercentage: facilityWiseCount ? Number(facilityWiseCount) : null,
							againstAverageCensusPercentage,
						};
					})
					.value();
				if (tableDataArr.length > 0) {
					tableDataArr = _.orderBy(tableDataArr, "total", "desc");
				}
				setTableData(tableDataArr);

				// ------> START OF COMPARISON BLOCK
				const sumAverageCensusesComparison = censusByFacilityComparison
					.map((x) => (!isNaN(Number(x.total)) ? Number(x.total) : 0))
					.reduce((a, v) => a + v, 0);
				const sumValuesComparison = _(dataComparison)
					.groupBy("facilityId")
					.sortBy((group) => dataComparison.indexOf(group[0]))
					.reduce((a, v) => {
						return a + (v.length || 0);
					}, 0);
				const percentageComparisonTemp = itemPercentage(sumValuesComparison, sumAverageCensusesComparison, "number");
				setCombinedPercentageComparison(percentageComparisonTemp);

				let tableDataArrComparison = _(dataComparison)
					.groupBy("facilityId")
					.sortBy((group) => dataComparison?.indexOf(group[0]))
					.map((product) => {
						let totalByFacilityComparison = 0;
						if (chartDataComparison?.mainData && chartDataComparison?.mainData.length > 0 && filterSelectedComparison) {
							totalByFacilityComparison = _.filter(chartDataComparison?.mainData, function (o) {
								if (o.facilityId === product[0]?.facilityId) return o;
							}).length;
						}

						if (
							filterComparison &&
							(filterComparison?.type === "ninetyDaysData" || filterComparison?.type === "sixtyDaysData") &&
							filterComparison?.facilityPercentage &&
							filterComparison?.facilityPercentage.length > 0
						) {
							totalByFacilityComparison =
								_.find(filterComparison.facilityPercentage, function (o) {
									if (o.id === product[0]?.facilityId) return o;
								})?.total || 0;
						}
						if (censusByFacilityComparison && censusByFacilityComparison.length > 0 && !filterSelectedComparison) {
							totalByFacilityComparison =
								Math.round(_.find(censusByFacilityComparison, { id: product[0]?.facilityId })?.total) || 0;
						}
						if (adtFacilityPercentageComparison && adtFacilityPercentageComparison.length > 0) {
							totalByFacilityComparison =
								_.find(adtFacilityPercentageComparison, function (o) {
									if (o.id === product[0]?.facilityId) return o;
								})?.total || 0;
						}
						if (filterComparison && filterComparison.lockedTotal && filterComparison.lockedByFacility) {
							totalByFacilityComparison =
								_.filter(filterComparison.lockedByFacility, function (o) {
									if (o.facilityId === product[0]?.facilityId) return o;
								})?.length || 0;
						}
						if (
							censusByFacilityComparison &&
							censusByFacilityComparison.length > 0 &&
							filterComparison?.isCensusTotalLocked
						) {
							totalByFacilityComparison =
								Math.round(_.find(censusByFacilityComparison, { id: product[0]?.facilityId })?.total) || 0;
						}
						let facilityWiseCountComparison = itemPercentage(
							product.length,
							filterTotalComparison ? filterTotalComparison : totalByFacilityComparison,
							"number"
						);
						return {
							id: product[0]?.facilityId,
							name: product[0]?.facility?.name || "",
							total: product.length || 0,
							facilityPercentage: facilityWiseCountComparison ? Number(facilityWiseCountComparison) : null,
						};
					})
					.value();
				if (tableDataArrComparison.length > 0) {
					tableDataArrComparison = _.orderBy(tableDataArrComparison, "total", "desc");
				}
				setTableDataComparison(tableDataArrComparison);
			}
		} else {
			setTableData([]);
			setTableDataComparison([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		chartData,
		data,
		filterCardType,
		type,
		filterSelected,
		censusByFacility,
		filterTotal,
		dataComparison,
		filter,
		filterComparison,
		censusByFacilityComparison,
		filterSelectedComparison,
		chartDataComparison?.mainData,
		filterTotalComparison,
	]);

	return (
		<TableContainer
			component={Paper}
			sx={{ maxHeight: "calc(100vh - 220px)", overflow: "auto" }}
			ref={buildingElementRef}
		>
			<Table className="print-table" sx={{ minWidth: 650 }} size="small" aria-label="a table" stickyHeader>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					rowCount={tableData.length}
					type={type}
					getFacilityHeadLabel={getFacilityHeadLabel}
				/>
				<TableBody>
					{tableData.length > 0 &&
						stableSort(tableData, getComparator(order, orderBy)).map((row, index) => {
							// * averageCensus is actually bed capacity when the page is admissions, but the variable is left with averageCensus for clarity.
							const facilitiesToCompareAgainst =
								bedCapacityByFacility.length > 0 ? bedCapacityByFacility : censusByFacility;
							const facilitiesToCompareAgainstComparison =
								bedCapacityByFacilityComparison.length > 0
									? bedCapacityByFacilityComparison
									: censusByFacilityComparison;

							const averageCensus = Number(facilitiesToCompareAgainst.find((x) => x.id === row.id).total);

							const averageCensusComparison = Number(facilitiesToCompareAgainstComparison.find((x) => x.id === row.id)?.total);

							const totalComparison = Number(tableDataComparison.find((x) => x.id === row.id)?.total);

							const percentageAgainstAverageCensus = itemPercentage(Number(row.total), averageCensus, "number");
							let combinedValuesForComparison = tableDataComparison.reduce((a, v) => {
								return a + Number(v.total);
							}, 0);
							const summedAverageCensusComparison = facilitiesToCompareAgainstComparison.reduce((a, v) => {
								return a + Number(v.total);
							}, 0);


							const percentageAgainstAverageCensusComparison = itemPercentage(
								combinedValuesForComparison,
								summedAverageCensusComparison,
								"number"
							);
							const totalPercentage = itemPercentage(row.total, data.length);

							let percentageDiff;
							let itemTotalComparisonScaled;
							let numberOfDays;

							if (isTotalCard && (page === PAGE_TYPE.OVERALL || page === PAGE_TYPE.ADMISSION)) {
								combinedValuesForComparison = totalComparison;
								const totalWithOutSign = row.total;
								
								const percentage = itemPercentage(totalWithOutSign, averageCensus, "number");
								const percentageComparison = itemPercentage(totalComparison, averageCensusComparison, "number", null, true);

								const res = calculateDiffBetweenPercentages(
									percentage,
									percentageComparison,
									true,
									filter.filter,
									filterComparison?.filter,
									totalComparison
								);
								percentageDiff = res.percentageDiff;
								itemTotalComparisonScaled = res.itemTotalComparisonScaled;
								numberOfDays = res.numberOfDays;
							} else {
								const res = calculateDiffBetweenPercentages(
									percentageAgainstAverageCensus,
									percentageAgainstAverageCensusComparison,
									forAdmission,
									filter.filter,
									filterComparison?.filter,
									combinedValuesForComparison
								);
								percentageDiff = res.percentageDiff;
								itemTotalComparisonScaled = res.itemTotalComparisonScaled;
								numberOfDays = res.numberOfDays;
							}

							const comparisonColor = pickComparisonColor(percentageDiff);

							return (
								<TableRow key={index}>
									<TableCell align="center" component="th" scope="row">
										{row.name}
									</TableCell>

									<TableCell align="center">
										{isPlusSign ? getPositivePlusSign(row.total) : row.total} {type !== "overall" && totalPercentage}
									</TableCell>
									{type !== "overall" && (
										<TableCell align="center" component="th" scope="row">
											{row.facilityPercentage}%
										</TableCell>
									)}
									<TableCell align="center">
										<ColorBox
											color={comparisonColor}
											comparingAgainst={isPlusSign ? getPositivePlusSign(combinedValuesForComparison) : combinedValuesForComparison}
											comparingAgainstScaled={isPlusSign ? getPositivePlusSign(itemTotalComparisonScaled) : itemTotalComparisonScaled}
											numberOfDays={numberOfDays}
											type="list"
											sx={{ marginTop: "3px" }}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					{tableData.length === 0 && <NoRecordFound data={tableData} />}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
