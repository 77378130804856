import { ADT_TABLE_TYPE, CARD_LABEL_COMMON } from "../types/common.type";
import { PAGE_TYPE } from "../types/pages.type";

export const VIEW_ADT_DATA = [
    {
        value: "Admissions",
        label: "Admissions",
        adts: [
            {
                value: "Total",
                label: "Total",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per Dr.",
                        label: "Per Dr.",
                    },
                    {
                        value: "Per DX.",
                        label: "Per DX.",
                    },
                    {
                        value: "Per Day of the week",
                        label: "Per Day of the week",
                    },
                    {
                        value: "Per Hospital",
                        label: "Per Hospital",
                    },
                    {
                        value: "Per Insurance",
                        label: CARD_LABEL_COMMON.perInsurance,
                    },
                ],
            },
            {
                value: "New Admissions:",
                label: "New Admissions:",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per Dr.",
                        label: "Per Dr.",
                    },
                    {
                        value: "Per DX.",
                        label: "Per DX.",
                    },
                    {
                        value: "Per Day of the week",
                        label: "Per Day of the week",
                    },
                    {
                        value: "Per Hospital",
                        label: "Per Hospital",
                    },
                    {
                        value: "Per Insurance",
                        label: CARD_LABEL_COMMON.perInsurance,
                    },
                ],
            },
            {
                value: "Re-Admissions",
                label: "Re-Admissions",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per Dr.",
                        label: "Per Dr.",
                    },
                    {
                        value: "Per DX.",
                        label: "Per DX.",
                    },
                    {
                        value: "Per Day of the week",
                        label: "Per Day of the week",
                    },
                    {
                        value: "Per Hospital",
                        label: "Per Hospital",
                    },
                    {
                        value: "Per Insurance",
                        label: CARD_LABEL_COMMON.perInsurance,
                    },
                ],
            },
        ],
    },
    {
        value: "Community Transfers",
        label: "Community Transfers",
        adts: [
            {
                value: "Community Transfers",
                label: "Community Transfers",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR.",
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit",
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay",
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)",
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return",
                    },
                ],
            },
            {
                value: "Safe Discharges",
                label: "Safe Discharges",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR.",
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit",
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay",
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)",
                    },
                    {
                        value: "Per Assisted Living",
                        label: "Per Assisted Living",
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return",
                    },
                ],
            },
            {
                value: "SNF Transfers",
                label: "SNF Transfers",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per Facility",
                        label: "Per Facility",
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR.",
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit",
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)",
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay",
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return",
                    },
                ],
            },
            {
                value: "AMA'S",
                label: "AMA'S",
                buttons: [
                    {
                        value: "Total",
                        label: "Total",
                    },
                    {
                        value: "Per Facility",
                        label: "Per Facility",
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR.",
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit",
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)",
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay",
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return",
                    },
                ],
            },
        ],
    },
];

export const ADMISSION_TYPES = {
    ADMISSION: "admission",
    READMISSION: "readmission",
    ALL: "all",
};

export const ResStatusEnum = {
    error: "error",
    success: "success",
};

export const VALIDATION_TYPES = {
    insurance: "insurance",
    hospital: "hospital",
    unit: "unit",
    doctor: "doctor",
    dx: "dx",
    nurse: "nurse",
};

export const DAYS_CHART_NAME = [
    {
        id: "Sun",
        filterId: "Sun",
        label: "Sun",
        value: 0,
    },
    {
        id: "Mon",
        filterId: "Mon",
        label: "Mon",
        value: 0,
    },
    {
        id: "Tue",
        filterId: "Tue",
        label: "Tue",
        value: 0,
    },
    {
        id: "Wed",
        filterId: "Wed",
        label: "Wed",
        value: 0,
    },
    {
        id: "Thu",
        filterId: "Thu",
        label: "Thu",
        value: 0,
    },
    {
        id: "Fri",
        filterId: "Fri",
        label: "Fri",
        value: 0,
    },
    {
        id: "Sat",
        filterId: "Sat",
        label: "Sat",
        value: 0,
    },
];
export const SHIFT_CHART_NAME = [
    {
        id: "Morning",
        filterId: "Morning",
        label: "Morning",
        value: 0,
    },
    {
        id: "Evening",
        filterId: "Evening",
        label: "Evening",
        value: 0,
    },
    {
        id: "Night",
        filterId: "Night",
        label: "Night",
        value: 0
    },
];

export const OTHER_ADT_OPTIONS = [
    {
        id: PAGE_TYPE.HOSPITAL,
        label: "Hospital Transfer",
        helperText: "Only showing 1st hospital transfer per Admission/Readmission",
        children: [
            {
                id: ADT_TABLE_TYPE.ALL,
                label: "Total Hospitalizations",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.ALL
            },
            {
                id: "unplannedHospitalTransfer",
                label: "Total Unplanned Transfers",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.TOTAL
            },
            {
                id: "plannedHospitalTransfer",
                label: "Total Planned Transfers",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.TOTAL,
            },
            {
                id: "reHospitalization",
                label: "New Hospitalizations & Re-Hospitalizations",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.CHART,
                chartsLabel: [
                    { filterId: false, label: "New Hospitalizations", id: "newHospitalizations" },
                    { filterId: true, label: "Re-Hospitalizations", id: "reHospitalizations" },
                ]
            },
            {
                id: "wasAdmitted",
                label: "per DC/ER",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.CHART,
                chartsLabel: [
                    { id: "DC", label: "DC", filterId: true },
                    { id: "ER", label: "ER", filterId: false },
                ]
            },
            {
                id: "insuranceId",
                label: "Transfers Per Insurance",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "insurance",
            },
            {
                id: "wasReturned",
                label: "Didn't Return",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.CHART,
                chartsLabel: [
                    { filterId: true, label: "Returned", id: "returned" },
                    { filterId: false, label: "Didn't Return", id: "notReturned" },
                ]
            },
            {
                id: "90Days",
                label: "90 Days Analysis",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.ANALYSIS_CHART,
                arrayName: 'ninetyDaysData'
                // chartsLabel: [
                //     { id: true, label: "Returned" },
                //     { id: false, label: "Didn't Return" },
                // ]
            },
            {
                id: "floorId",
                label: "Floor",
                parentId: PAGE_TYPE.HOSPITAL, isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "unit",
            },
            {
                id: "doctorId",
                label: "Doctor",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "doctor",
            },
            {
                id: "day",
                label: "Day",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.DAY_CHART,
                chartsLabel: DAYS_CHART_NAME
            },
            {
                id: "dx",
                label: "Diagnosis",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP_ARRAY,
                // helperText: "* Only showing Unplanned Hospital transfers",
                relation: "dx",
            },
            {
                id: "shiftName",
                label: "Shift",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.CHART,
                // helperText: "* Only showing Unplanned Hospital transfers",
                chartsLabel: SHIFT_CHART_NAME
            },
            {
                id: "nurseId",
                label: "Per Nurse",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                // helperText: "* Only showing Unplanned Hospital transfers",
                type: ADT_TABLE_TYPE.GROUP,
                relation: "nurse",
            },
            {
                id: "hospitalId",
                label: "Hospitals resident transferred",
                parentId: PAGE_TYPE.HOSPITAL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "hospital",
            },
        ],
    },
    {
        id: PAGE_TYPE.COMMUNITY_TRANSFER,
        label: "Community Transfer",
        children: [
            {
                id: ADT_TABLE_TYPE.ALL,
                label: "Total Community Transfers",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.ALL
            },
            {
                id: "safeDischarge",
                label: "Total Safe Discharges",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.TOTAL
            },
            {
                id: "SNF",
                label: "Total SNF",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.TOTAL
            },
            {
                id: "AMA",
                label: "Total AMA",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.TOTAL
            },
            {
                id: "insuranceId",
                label: "Transfers Per Insurance",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "insurance",
            },
            {
                id: "60Days",
                label: "60 Days Analysis",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.ANALYSIS_CHART,
                arrayName: 'sixtyDaysData'
            },
            {
                id: "wasReturned",
                label: "Didn't Return",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.CHART,
                chartsLabel: [
                    { filterId: true, label: "Returned", id: "returned" },
                    { filterId: false, label: "Didn't Return", id: "notReturned" },
                ]
            },
            {
                id: "doctorId",
                label: "Doctor",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "doctor",
            },
            {
                id: "floorId",
                label: "Floor",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "unit",
            },
            {
                id: "assistantLivId",
                label: "Safe discharges per Assisted Living",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "transferToWhichAssistedLiving",
            },
            {
                id: "snfFacilityId",
                label: "Per Facility",
                parentId: PAGE_TYPE.COMMUNITY_TRANSFER,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "snf",
            },
        ],
    },
    {
        id: PAGE_TYPE.DECEASED,
        label: "Deceased",
        children: [
            {
                id: ADT_TABLE_TYPE.ALL,
                label: "Total Deceased",
                parentId: PAGE_TYPE.DECEASED,
                isSelected: false,
                type: ADT_TABLE_TYPE.ALL
            },
            {
                id: "insuranceId",
                label: "Transfers Per Insurance",
                parentId: PAGE_TYPE.DECEASED,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "insurance",
            },
            {
                id: "doctorId",
                label: "Doctor",
                parentId: PAGE_TYPE.DECEASED,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "doctor",
            },
            {
                id: "floorId",
                label: "Floor",
                parentId: PAGE_TYPE.DECEASED,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "unit",
            },
            {
                id: "90DaysDeceased",
                label: "90 Days Analysis",
                parentId: PAGE_TYPE.DECEASED,
                isSelected: false,
                type: ADT_TABLE_TYPE.ANALYSIS_CHART,
                arrayName: 'ninetyDaysDeceasedData'
            },
        ],
    },
    {
        id: PAGE_TYPE.OVERALL,
        label: "Overall",
        children: [
            {
                id: ADT_TABLE_TYPE.ALL,
                label: "Total Outgoing Transfers",
                parentId: PAGE_TYPE.OVERALL,
                isSelected: false,
                type: ADT_TABLE_TYPE.ALL
            },
            {
                id: "insuranceId",
                label: "Transfers Per Insurance",
                parentId: PAGE_TYPE.OVERALL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "insurance",
            },
            {
                id: "doctorId",
                label: "Doctor",
                parentId: PAGE_TYPE.OVERALL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "doctor",
            },
            {
                id: "floorId",
                label: "Floor",
                parentId: PAGE_TYPE.OVERALL,
                isSelected: false,
                type: ADT_TABLE_TYPE.GROUP,
                relation: "unit",
            },
            {
                id: "90DaysOverall",
                label: "90 Days Analysis",
                parentId: PAGE_TYPE.OVERALL,
                isSelected: false,
                type: ADT_TABLE_TYPE.ANALYSIS_CHART,
                arrayName: 'ninetyDaysOverallData'
            },
        ],
    },
];
