import { useState } from "react";

const forceDOMUpdate = () => {
    let target = document.getElementById("render-tem");
    if (target) {
      target.parentNode.removeChild(target);
    } else {
      target = document.createElement("div");
      target.id = "render-tem";
      target.style.display = "none";
      document.body.appendChild(target);
    }
    // Access a layout property to force a reflow
    void document.body.offsetHeight;
  };

const useLoadingToggle = () => {
    const [loadingItems, setLoadingItems] = useState({});

    const handleToggleWithLoader = async (callback, itemId, minDuration = 2000) => {
        setLoadingItems(prev => ({ ...prev, [itemId]: true }));
        // Allow state update to propagate
        await new Promise(resolve => setTimeout(resolve, 100));

        forceDOMUpdate();
    
        // Start timing
        const startTime = Date.now();
    
        try {
            await callback();
        } finally {
            // Calculate the elapsed time
            const elapsed = Date.now() - startTime;
            const remainingTime = minDuration - elapsed;
    
            // Ensure loader stays for at least minDuration
            if (remainingTime > 0) {
                await new Promise(resolve => setTimeout(resolve, remainingTime));
            }
    
            setLoadingItems(prev => ({ ...prev, [itemId]: false }));
        }
    };
    
    return { loadingItems, handleToggleWithLoader };
};

export default useLoadingToggle;
