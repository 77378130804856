import { Typography, Button, MenuItem, Select, FormControl, Stack, OutlinedInput, ListItemText } from "@mui/material";
import { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "@mui/material/Popover";

const HeaderFilterOptions = ({
  selectedFilterMenu,
  handleChangeSelect,
  filterOptions,
  filterValue,
  setFilterValue,
  handleSaveFilter,
  handleRemoveFilter,
  handleClearFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDeleteEl, setAnchorDeleteEl] = useState(null);
  const [isDeleteFilter, setIsDeleteFilter] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteFilter = useCallback((event, value) => {
    event.stopPropagation();
    setAnchorDeleteEl(event.currentTarget);
    setIsDeleteFilter(value);
  }, []);

  const handleCloseConfirmation = useCallback(() => {
    setAnchorDeleteEl(null);
    setIsDeleteFilter(null);
  }, []);

  const handleDeleteDBFilter = useCallback(async () => {
    await handleRemoveFilter(isDeleteFilter);
    setAnchorDeleteEl(null);
    setIsDeleteFilter(null);
  }, [handleRemoveFilter, isDeleteFilter]);

  return (
    <Stack direction={"row"} alignItems="center" justifyContent={"flex-end"}>
      {isDeleteFilter && (
        <Popover
          anchorEl={anchorDeleteEl}
          open={!!isDeleteFilter}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleCloseConfirmation}
        >
          <Stack display={"flex"} sx={{ padding: 2 }}>
            <Stack item direction="row">
              <Typography variant="subtitle2">Are you sure want to delete ?</Typography>
            </Stack>
            <Stack item direction="row" spacing={2} sx={{ mt: 1 }}>
              <Button size="small" variant="outlined" onClick={handleCloseConfirmation}>
                Cancel
              </Button>
              <Button size="small" variant="contained" color="error" onClick={handleDeleteDBFilter}>
                Delete
              </Button>
            </Stack>
          </Stack>
        </Popover>
      )}
      <FormControl sx={{ mr: 2, minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={selectedFilterMenu}
          input={<OutlinedInput sx={{ background: "#fff" }} />}
          renderValue={(selected) => {
            if (selected === "all") {
              return <em>Open All Filter Sets</em>;
            }
            return selected?.name;
          }}
          onChange={handleChangeSelect}
        >
          {filterOptions &&
            filterOptions.length > 0 &&
            filterOptions.map((item, index) => (
              <MenuItem key={index} value={item} dense={true}>
                <ListItemText>{item.name}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  <DeleteIcon sx={{ fontSize: 17 }} onClick={(e) => handleDeleteFilter(e, item)} />
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        size="small"
        sx={{
          padding: "4px 4px"
        }}
      >
        Save filters
      </Button>
      <Popover
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >

        6353630999 mehul 
        500
        

        <Stack display={"flex"} sx={{ padding: 3 }}>
          <Stack
            sx={{
              position: "absolute",
              right: "2%",
              top: "8%",
            }}
          >
            <CloseIcon
              sx={{ cursor: "pointer", fontSize: "18px" }}
              size="small"
              onClick={() => {
                setFilterValue("");
                handleClose();
              }}
            />
          </Stack>
          <Stack>
            <Typography variant="subtitle1">Save Your Filters Set</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <OutlinedInput
              size="small"
              type={"text"}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
            <Button
              disabled={!filterValue}
              size="small"
              variant="contained"
              onClick={async () => {
                const res = await handleSaveFilter();
                if (res) {
                  handleClose();
                }
              }}
            >
              Save Set
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Button size="small" sx={{ color: "#FFFFFF" }} onClick={handleClearFilter}>
        Clear
      </Button>
    </Stack >
  );
};

export default HeaderFilterOptions;
