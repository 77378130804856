import React from "react";
import { keyframes, styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { Box } from "@mui/material";
import { round } from "lodash";

// Pulse Effect for Badge Glow
const pulseEffect = keyframes`
    0%, 100% { box-shadow: 0 0 0px rgba(231, 180, 0, 0); }
    50% { box-shadow: 0 0 20px rgba(231, 180, 0, 0.8); }
`;

// Shake Effect for Number
const shakeEffect = keyframes`
    0%, 100% { transform: translateX(0); }
    20%, 60% { transform: translateX(-1px); }
    40%, 80% { transform: translateX(1px); }
`;

// Styled Badge Component
const StyledBadge = styled(Badge)(({ animationKey }) => ({
    "& .MuiBadge-badge": {
        right: 24,
        color: "#fff",
        fontSize: "14px",
        top: 32,
        borderRadius: "5px",
        marginRight: "1.6px",
        height: "30px",
        width: "65%",
        transition: "width 0.7s ease, font-size 0.8s ease",
        animation: `${pulseEffect} 1.5s ease`, // Always animate
        animationIterationCount: "1", // Run animation only once per key change
        animationKey, // Trigger animation when key changes
    },
}));

// Container for Shaking Number
const AnimatedCountContainer = styled(Box)`
    display: inline-block;
    position: relative;
`;

// Number with Shake Animation
const AnimatedCount = styled(Box)`
    display: inline-block;
    animation: ${shakeEffect} 0.5s ease;
`;

const LockedCustomBadge = ({
    count,
    background,
    icon: IconComponent,
    iconProps = {},
    animationKey
}) => {
    const badgeStyles = {
        ".MuiBadge-badge": {
            background,
            ...(count && Math.round(count).toString().length === 5 && {
                width: "80% !important",
                fontSize: "13.5px",
            }),
            ...(count && Math.round(count).toString().length >= 6 && {
                width: "84% !important",
                fontSize: "12px",
            }),
        },
    };

    const badgeContent = (
        <AnimatedCountContainer>
            <AnimatedCount key={animationKey}>
                {round(count)}
            </AnimatedCount>
        </AnimatedCountContainer>
    );

    return (
        <StyledBadge
            sx={badgeStyles}
            badgeContent={badgeContent}
            color="success"
            max={9999999}
            key={animationKey} // Restart animation on key change
            animationKey={animationKey}
        >
            <IconComponent
                sx={{
                    fontSize: 51,
                    color: "#E7B400",
                    animation: "background-fade 2s forwards",
                    "@keyframes background-fade": {
                        "99.9%": {
                            color: "#E7B400"
                        },
                    }
                }}
                {...iconProps}
            />
        </StyledBadge>
    );
};

export default LockedCustomBadge;
