import React, {  } from 'react';
import { MenuItem, Popover } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ConditionPopover = ({ anchorEl, onClose, index, type, handleConditionChange }) => {
    return (
        <Popover
            open={anchorEl.index === index && anchorEl.type === type}
            anchorEl={anchorEl.element}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <MenuItem onClick={() => handleConditionChange(index, type, '>')}>
                <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
            </MenuItem>
            <MenuItem onClick={() => handleConditionChange(index, type, '<')}>
                <ArrowBackIosIcon sx={{ fontSize: 14 }} />
            </MenuItem>
        </Popover>
    );
};

export default ConditionPopover; 