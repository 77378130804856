import { TYPES } from "../../../data/hospital.data";
import { HOSPITAL_CARDS_LABELS, HOSPITAL_CARDS_TYPE } from "../../../types/hospital.type";
import HeaderFilter from "../../shared/dashboard-header/HeaderFilter";
import { useSelector } from "react-redux";
import useHeaderCustomLabelsItem from "../../hooks/useHeaderCustomLabelsItem";

export const headerItemsData = [
    { label: "Planned Transfers", type: "label", dataKey: TYPES.PLANNED, isMain: true, isDefault: true },
    { label: "Unplanned Transfers", type: "label", dataKey: TYPES.UNPLANNED, isMain: true, isDefault: true },
    { label: "Total Hospital Transfers", type: "label", dataKey: TYPES.ALL, isMain: true, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.hospitalizationsReHospitalizations, dataKey: HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.DCERData, dataKey: HOSPITAL_CARDS_TYPE.DCER_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.insuranceData, dataKey: HOSPITAL_CARDS_TYPE.INSURANCE_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.returnsData, dataKey: HOSPITAL_CARDS_TYPE.RETURNS_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.ninetyDaysData, dataKey: HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.floorsData, dataKey: HOSPITAL_CARDS_TYPE.FLOORS_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.shiftData, dataKey: HOSPITAL_CARDS_TYPE.SHIFT_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.daysData, dataKey: HOSPITAL_CARDS_TYPE.DAYS_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.dxData, dataKey: HOSPITAL_CARDS_TYPE.DX_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.doctorData, dataKey: HOSPITAL_CARDS_TYPE.DOCTOR_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.nurseData, dataKey: HOSPITAL_CARDS_TYPE.NURSE_DATA, isDefault: true },
    { label: HOSPITAL_CARDS_LABELS.hospitalData, dataKey: HOSPITAL_CARDS_TYPE.HOSPITAL_DATA, isDefault: true },
];

const HospitalHeaderFilterList = ({
    filterListData,
    cardFilter,
    transferType,
    percentageBy,
    lockedTotal,
    lockedTotalBy,
    openFrom = "default", // "detailView"
}) => {
    const { dynamicCards } = useSelector((state) => state.hospital);
    const headerItemsDataArray = useHeaderCustomLabelsItem(cardFilter, dynamicCards, headerItemsData);

    return <HeaderFilter
        items={headerItemsDataArray}
        filterListData={filterListData}
        cardFilter={cardFilter}
        transferType={transferType}
        percentageBy={percentageBy}
        lockedTotal={lockedTotal}
        lockedTotalBy={lockedTotalBy}
        openFrom={openFrom}
    />
};

export default HospitalHeaderFilterList;