import * as React from "react";
import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import { createAlertsReport, deleteCustomAlert, getAlertsReports } from "../../../services/alert-report.service";
import { LoadingButton } from "@mui/lab";
import { ALERT_TYPE_TYPE } from "../../../types/common.type";
import { HOSPITAL_CARDS_LABELS } from "../../../types/hospital.type";
import { isOnlyHospitalTabAccess } from "../../../utilis/common";
import { PAGE_TYPE } from "../../../types/pages.type";
import { ADMISSION_CARDS_LABELS } from "../../../types/admission.type";
import { COMMUNITY_CARD_LABELS } from "../../../types/community-transfer.type";
import { DECEASED_CARDS_LABELS } from "../../../types/deceased.type";
import { OVERALL_CARDS_LABELS } from "../../../types/overall.type";
import AlertSettings from "./AlertSetting";
import _, { capitalize, forEach } from "lodash";



export const RenderCardTypes = ({ formik, handleSelectAll, handleDeleteClick, FILTER_KEYS, formatLabel, type = "default", formType }) => {
	return (
		<>
			{formType === "userPage" && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						width: "100%", // Ensures it spans full width
						p: 0, // Removes any padding
						m: 0, // Removes any margin
					}}
				>
					<Typography
						variant="h6"
						sx={{ mb: 1.5, textAlign: "left" }}
						component="span"
					>
						{capitalize(formik.values.page)} Alerts
					</Typography>
				</Box>
			)}

			<AlertTypeSelect formik={formik} />

			<Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 2, ml: 0.2 }}>
				<FormControlLabel
					control={
						<Checkbox
							name="isRising"
							checked={formik.values.isRising}
							onChange={formik.handleChange}
						/>
					}
					// label="Are Rising"
					label="Trending up"
				/>
				<FormControlLabel
					control={
						<Checkbox
							name="isDropping"
							checked={formik.values.isDropping}
							onChange={formik.handleChange}
						/>
					}
					// label="Are Dropping"
					label="Trending down"
				/>
				{/* <FormControlLabel
					control={
						<Checkbox
							name="isTransferNeedWork"
							checked={formik.values.isTransferNeedWork}
							onChange={formik.handleChange}
						/>
					}
					label="Transfers Need Work"
				/> */}
			</Box>

			<FormControlLabel
				sx={{ mb: 2 }}
				control={
					<Switch
						checked={formik.values.selectAll}
						onChange={handleSelectAll}
					/>
				}
				label="Select all alerts"
			/>

			<Grid container spacing={2}>
				{FILTER_KEYS.map((key) => (
					<Grid item xs={6} sm={4} md={3} key={key}>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								position: "relative",
								"&:hover .delete-icon": {
									opacity: 1,
								},
							}}
						>
							<FormControlLabel
								control={
									<Switch
										size={type === "settings" ? "medium" : "medium"}
										name={`filters.${key}`}
										checked={formik.values.filters[key]}
										onChange={formik.handleChange}
									/>
								}
								label={formatLabel(key)}
							/>
							{formatLabel(key, "checkLabel") && (
								<Tooltip title="Delete custom alert">
									<IconButton
										className="delete-icon"
										sx={{
											opacity: 0,
											transition: "opacity 0.2s",
										}}
										onClick={() => handleDeleteClick(formatLabel(key, "checkLabelReturnObj"))}
										size="medium"
										color="error"
									>
										<DeleteIcon
											sx={{ fontSize: "medium" }}
										/>
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</Grid>
				))}
			</Grid>
		</>

	)
}

export const AlertTypeSelect = ({ formik }) => {
	return (
		<FormControl fullWidth sx={{ mb: 2 }}>
			<FormLabel id="alert-type-label">Alert frequency</FormLabel>
			<ToggleButtonGroup
				value={formik.values.alertType}
				onChange={(event, newAlertType) => formik.setFieldValue("alertType", newAlertType)}
				aria-labelledby="alert-type-label"
				color="primary"
				sx={{ display: 'flex' }}
				size="large"
			>
				<ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.WEEKS}>Weekly</ToggleButton>
				<ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.BI_WEEKS}>Bi-Weekly</ToggleButton>
				<ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.MONTHS}>Monthly</ToggleButton>
			</ToggleButtonGroup>
		</FormControl>
	);
};

export default function AlertReport({
	page: pageType,
	activeFacilities,
	type,
	accountFacilities,
	isAccountChecked,
	isChecked,
	selectedAccount,
	isOpenAlertReport,
	dialogType = "default",
	setIsAlertReportOpen,
	selectedSortCut,
	userId,
	dynamicCards
}) {
	const isOnlyHospitalDashboard = React.useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);

	const [page, setPage] = React.useState(pageType);
	const [pageLoading, setPageLoading] = React.useState(false);
	const [customAlerts, setCustomAlerts] = React.useState([]);

	const getDefaultCardFilter = (page, customAlertsData, dynamicCards = []) => {
		const filters = {
			[PAGE_TYPE.ADMISSION]: {
				total: false,
				admission: false,
				readmission: false,
				doctorData: false,
				daysData: false,
				dxData: false,
				insuranceData: false,
				floorsData: false,
				hospitalData: false,
			},
			[PAGE_TYPE.COMMUNITY_TRANSFER]: {
				total: false,
				safeDischarge: false,
				SNF: false,
				AMA: false,
				insuranceData: false,
				sixtyDaysData: false,
				returnsData: false,
				doctorData: false,
				floorsData: false,
			},
			[PAGE_TYPE.DECEASED]: {
				total: false,
				insuranceData: false,
				doctorData: false,
				floorsData: false,
				ninetyDaysData: false,
			},
			[PAGE_TYPE.OVERALL]: {
				totalIncoming: false,
				totalOutgoing: false,
				insuranceData: false,
				doctorData: false,
				floorsData: false,
				ninetyDaysData: false,
			},
			[PAGE_TYPE.HOSPITAL]: {
				total: false,
				unplannedHospitalTransfer: false,
				plannedHospitalTransfer: false,
				hospitalizations: false,
				DCERData: false,
				insuranceData: false,
				returnsData: false,
				ninetyDaysData: false,
				doctorData: false,
				nurseData: false,
				floorsData: false,
				daysData: false,
				dxData: false,
				shiftData: false,
				hospitalData: false,
			},
		};

		if (customAlertsData.length > 0) {
			forEach(customAlertsData, (item) => {
				filters[page][item.slug] = false;
			});
		}
		if (dynamicCards?.length > 0) {
			forEach(dynamicCards, (item) => {
				filters[page][item.accessor] = false;
			});
		}		
		return filters[page] || {}; // Return an empty object for unknown pages
	};

	const DEFAULT_CARD_FILTER = React.useMemo(() => getDefaultCardFilter(page, customAlerts, dynamicCards), [page, customAlerts, dynamicCards]);

	const [isOpen, setIsOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [selectedAlertId, setSelectedAlertId] = React.useState(null);

	const FILTER_KEYS_DATA = Object.keys(DEFAULT_CARD_FILTER).filter(
		(key) => !(isOnlyHospitalDashboard && key === 'returnsData')
	);

	const FILTER_KEYS = React.useMemo(() => {
		return FILTER_KEYS_DATA
	}, [FILTER_KEYS_DATA]);

	const [initialValues, setInitialValues] = React.useState({
		filters: FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
		alertType: [ALERT_TYPE_TYPE.WEEKS],
		isRising: false,
		isDropping: false,
		isTransferNeedWork: false,
		selectAll: false,
		page: page
	});
	const [facilitiesList, setFacilitiesList] = React.useState([]);
	const [accountList, setAccountList] = React.useState([]);
	const dispatch = useDispatch();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...initialValues
		},
		onSubmit: (values, { setSubmitting }) => {
			handleSave(values);
			setSubmitting(false);
		},
	});

	const handleClose = React.useCallback(() => {
		setIsOpen(false);
		setIsAlertReportOpen && setIsAlertReportOpen(false);
		formik?.setFieldValue("facilityId", []);
		formik?.resetForm();
		setInitialValues({
			filters: FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
			alertType: [ALERT_TYPE_TYPE.WEEKS],
			isRising: false,
			isDropping: false,
			selectAll: false,
			page
		});
	}, [setIsAlertReportOpen, formik, FILTER_KEYS, page]);

	React.useEffect(() => {
		if (isOpenAlertReport) {
			setIsOpen(true);
		}
	}, [isOpenAlertReport]);

	React.useMemo(() => {
		if (accountFacilities && type === "userPage") {
			setAccountList([]);
			setFacilitiesList([]);
			const selectedAccounts = accountFacilities.filter((itemAccount) => {
				const checkResult = isAccountChecked("access", itemAccount);
				const checkResult2 = isAccountChecked("read", itemAccount);
				const checkResult3 = isAccountChecked("write", itemAccount);
				return (checkResult || checkResult2 || checkResult3) && itemAccount._id?._id;
			}).map((itemAccount) => itemAccount._id);
			setAccountList(selectedAccounts);
		}
	}, [accountFacilities, type, isAccountChecked]);

	const getAlertsReportsDB = React.useCallback(async (facilityId = null, pageType = null) => {
		setLoading(true);
		try {
			let userIdData = type === "userPage" ? userId : null;
			const res = await getAlertsReports({
				page: pageType ? pageType : page,
				facilityId,
				userId: userIdData,
				...type === "userPage" && { accountId: formik.values?.accountId }
			});

			const customAlertsData = (res?.customAlert && res?.customAlert.length > 0) ? res?.customAlert?.map((item) => ({
				slug: item.slug,
				name: item.name,
				isCustom: true,
				_id: item._id
			})) : [];

			const DEFAULT_CARD_FILTER_DATA = getDefaultCardFilter(pageType ? pageType : page, customAlertsData, dynamicCards);

			const FILTER_KEYS_DATA = Object.keys(DEFAULT_CARD_FILTER_DATA).filter(
				(filterKey) => !(isOnlyHospitalDashboard && filterKey === 'returnsData')
			);

			if (res) {
				setSelectedAlertId(res?._id);
				const databaseFilters = res?.alerts || {};
				setCustomAlerts(customAlertsData);

				const updatedInitialValues = FILTER_KEYS_DATA.reduce((acc, key) => {
					acc[key] = databaseFilters[key] !== undefined ? databaseFilters[key] : false;
					return acc;
				}, {});

				setLoading(false);
				formik?.setFieldValue("filters", updatedInitialValues);
				formik?.setFieldValue("alertType", res?.type);
				formik?.setFieldValue("isRising", res?.isRising);
				formik?.setFieldValue("isDropping", res?.isDropping);
				//formik?.setFieldValue("isTransferNeedWork", res?.isTransferNeedWork);
				formik?.setFieldValue("type", res?.type);
				formik?.setFieldValue("selectAll", Object.values(updatedInitialValues).every(value => value === true));
				if (type === "userPage") {
					formik?.setFieldValue("facilityId", [res?.facilityId]);
					formik?.setFieldValue("accountId", res?.accountId);
				}

			} else {
				formik?.setFieldValue("filters", FILTER_KEYS_DATA.reduce((acc, key) => ({ ...acc, [key]: false }), {}));
				formik?.setFieldValue("alertType", [ALERT_TYPE_TYPE.WEEKS]);
				formik?.setFieldValue("isRising", false);
				formik?.setFieldValue("isDropping", false);
				// formik?.setFieldValue("isTransferNeedWork", false);
				formik?.setFieldValue("selectAll", false);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "error",
					label: "Failed to load alert settings 3",
					id: "loadSettingsError",
				},
			});
		} finally {
			setLoading(false);
		}
	}, [type, userId, page, formik, isOnlyHospitalDashboard, dispatch, dynamicCards]);

	const handleUpdateDataByPage = React.useCallback(async (page) => {
		if (formik.values?.facilityId && formik.values?.facilityId.length === 1) {
			await getAlertsReportsDB(formik.values?.facilityId[0], page);
			setPageLoading(false);
		} else {
			setPageLoading(false);
		}
	}, [formik?.values]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		setPageLoading(true);
		setPage(formik.values?.page);
		handleUpdateDataByPage(formik.values?.page);
	}, [formik.values?.page]); // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		if (isOpen) {
			if (type !== "userPage") {
				getAlertsReportsDB(activeFacilities?.[0]);
			} else {
				setLoading(false);
			}
		}
	}, [isOpen, activeFacilities]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSave = React.useCallback(
		async (valuesData) => {
			try {
				if (type === "userPage" && !valuesData?.accountId) {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: "Please select account",
							id: "alertSettingsSaveError",
						},
					});
					return;
				}

				if (type === "userPage" && valuesData?.facilityId?.length === 0) {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: "Please select at least one facility",
							id: "alertSettingsSaveError",
						},
					});
					return;
				}

				let userIdData = type === "userPage" ? userId : null;

				const updateData = {
					alerts: valuesData?.filters,
					page,
					facilityIds: type === "userPage" ? valuesData?.facilityId : activeFacilities,
					type: valuesData?.alertType,
					isRising: valuesData?.isRising,
					isDropping: valuesData?.isDropping,
					// isTransferNeedWork: valuesData?.isTransferNeedWork,
					...userId && { userId: userIdData },
					...type === "userPage" && { accountId: valuesData?.accountId }
				};
				const res = await createAlertsReport(updateData);
				if (res) {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "success",
							label: "Alert settings updated successfully",
							id: "alertSettingsUpdated",
						},
					});
					setIsAlertReportOpen && setIsAlertReportOpen(false);
				}
				handleClose();
			} catch (error) {
				dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "error",
						label: "Failed to save alert settings",
						id: "alertSettingsSaveError",
					},
				});
				setIsAlertReportOpen && setIsAlertReportOpen(false);

				handleClose();
			}
		},
		[page, type, activeFacilities, dispatch, userId, formik] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const handleSelectAll = React.useCallback((event) => {
		const isChecked = event.target.checked;
		formik.setFieldValue('selectAll', isChecked);
		FILTER_KEYS.forEach((key) => {
			formik.setFieldValue(`filters.${key}`, isChecked);
		});
	}, [formik, FILTER_KEYS]);

	const formatLabel = React.useCallback((key, needCustomLabelCheck = "default") => {
		let label;
		switch (page) {
			case PAGE_TYPE.ADMISSION:
				label = ADMISSION_CARDS_LABELS[key];
				break;
			case PAGE_TYPE.COMMUNITY_TRANSFER:
				label = COMMUNITY_CARD_LABELS[key];
				break;
			case PAGE_TYPE.DECEASED:
				label = DECEASED_CARDS_LABELS[key];
				break;
			case PAGE_TYPE.HOSPITAL:
				label = HOSPITAL_CARDS_LABELS[key];
				break;
			case PAGE_TYPE.OVERALL:
				label = OVERALL_CARDS_LABELS[key];
				break;
			default:
				label = null;
		}
		if (needCustomLabelCheck === "checkLabel" && label) {
			return false;
		} else if (needCustomLabelCheck === "checkLabelReturnObj" && label) {
			return null;
		}
		if (!label && dynamicCards?.length > 0) {
			let customCardLabelItem = _.find(dynamicCards, { accessor: key });
			label = customCardLabelItem?.tableLabel || customCardLabelItem?.label;
			if (label) {
				return needCustomLabelCheck === "default" ? label : null;
			}
		}
		if (!label && customAlerts.length > 0) {
			let customLabel = _.find(customAlerts, { slug: key })?.name;
			label = customLabel ? customLabel : null;
			if (needCustomLabelCheck === "checkLabel") {
				return customLabel ? true : false;
			} else if (needCustomLabelCheck === "checkLabelReturnObj" && customLabel) {
				return _.find(customAlerts, { slug: key });
			}
		}
		return label;
	}, [page, dynamicCards, customAlerts]);

	const handleOnChangeFacility = React.useCallback(async (e) => {
		if (e && e.length === 1) {
			await getAlertsReportsDB(e[0]);
		} else {
			formik?.setFieldValue("filters", FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {}));
			formik?.setFieldValue("alertType", [ALERT_TYPE_TYPE.WEEKS]);
			formik?.setFieldValue("isRising", false);
			formik?.setFieldValue("isDropping", false);
			// formik?.setFieldValue("c", false);
		}
	}, [getAlertsReportsDB, formik, FILTER_KEYS]);

	const handleOnChangeAccount = React.useCallback((e) => {
		formik?.setFieldValue("accountId", e);
		formik?.setFieldValue("facilityId", []);

		const selectedFacilities = accountFacilities.flatMap((item) => {
			if (e === item._id?._id) {
				return item.facilities?.filter((facility) => isChecked(facility._id, "read")) || [];
			}
			return [];
		});

		setFacilitiesList(selectedFacilities);

	}, [accountFacilities, formik, isChecked]);

	// Extracted form controls for cleaner JSX

	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
	const [selectedKey, setSelectedKey] = React.useState(null);

	const handleDeleteClick = (key) => {
		setSelectedKey(key);
		setIsDeleteDialogOpen(true);
	};

	const handleCloseDelete = () => {
		setIsDeleteDialogOpen(false);
		setSelectedKey(null);
	};

	const handleConfirmDelete = async () => {
		const resData = await deleteCustomAlert({ ...selectedKey, selectedAlertId });
		if (resData && resData.success) {
			if (type === "userPage") {
				handleClose()
			} else {
				getAlertsReportsDB(activeFacilities?.[0]);
			}

			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: `Custom alert deleted successfully`,
					id: "customAlertDeleted",
				},
			});
		} else {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					label: resData?.message,
					type: "error",
					id: "customAlertDeletedErr",
				},
			});
		}
		handleCloseDelete();
	};


	const renderContent = () => (
		<form onSubmit={formik.handleSubmit}>
			<FormGroup>
				{type === "userPage" && (
					<>
						<FormControl fullWidth sx={{ mb: 2 }}>
							<FormLabel id="account-type-label">Account</FormLabel>
							<Select
								labelId="account-type-label"
								name="accountId"
								value={formik.values.accountId || ""}
								onChange={(e) => {
									const { value } = e.target;
									formik.setFieldValue("accountId", value);
									handleOnChangeAccount(value);
								}}
								renderValue={(selected) =>
									accountList.find((item) => item._id === selected)?.name || "Select Account"
								}
							>
								{accountList?.map((item) => (
									<MenuItem key={item._id} value={item._id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl fullWidth sx={{ mb: 2 }}>
							<FormLabel id="alert-type-label">Facility</FormLabel>
							<Select
								labelId="alert-type-label"
								name="facilityId"
								value={formik.values.facilityId || []}
								onChange={(e) => {
									const { value } = e.target;
									const allIds = facilitiesList.map((item) => item._id);

									if (value.includes("all")) {
										// If "Select All" is clicked
										formik.setFieldValue(
											"facilityId",
											formik.values?.facilityId?.length === facilitiesList?.length ? [] : allIds
										);
										handleOnChangeFacility(formik.values?.facilityId?.length === facilitiesList?.length ? [] : allIds)
									} else {
										// If individual items are clicked
										formik.setFieldValue("facilityId", value);
										handleOnChangeFacility(value)
									}
								}}
								multiple
								renderValue={(selected) =>
									facilitiesList
										.filter((item) => selected.includes(item._id))
										.map((item) => item.name)
										.join(", ")
								}
							>
								{/* Select All Option */}
								<MenuItem value="all">
									<Checkbox
										checked={formik.values?.facilityId?.length === facilitiesList?.length}
										indeterminate={
											formik.values?.facilityId?.length > 0 &&
											formik.values.facilityId?.length < facilitiesList?.length
										}
									/>
									Select All
								</MenuItem>

								{/* Individual Facility Options */}
								{facilitiesList?.map((item) => (
									<MenuItem key={item._id} value={item._id}>
										<Checkbox checked={formik.values?.facilityId?.includes(item._id)} />
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</>
				)}

				{type !== "userPage" &&
					<RenderCardTypes
						formik={formik}
						handleSelectAll={handleSelectAll}
						FILTER_KEYS={FILTER_KEYS}
						formatLabel={formatLabel}
						page={formik.values.page}
						formType={type}
						handleDeleteClick={handleDeleteClick}
					/>
				}
				{
					type === "userPage" && (
						<AlertSettings
							formik={formik}
							handleSelectAll={handleSelectAll}
							FILTER_KEYS={FILTER_KEYS}
							formatLabel={formatLabel}
							page={formik.values.page}
							pageLoading={pageLoading}
							handleDeleteClick={handleDeleteClick}
							formType={type}
						/>
					)
				}
			</FormGroup>
		</form>
	);


	return (
		<>
			{(selectedSortCut?.isAlertsReport || type === "userPage") && (
				<Box>
					<Tooltip title="Manage Alerts" arrow>
						<IconButton
							onClick={() => setIsOpen(true)}
							sx={{
								width: 30,
								height: 30,
								ml: 1,
								borderRadius: "4px",
								'&:hover': {
									backgroundColor: '#4879f5',
									color: "white",
								},
							}}
						>
							<NotificationsActiveOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			{isOpen && (
				<Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
					<DialogTitle>{`${capitalize(formik.values.page)} Alerts`}</DialogTitle>

					<DialogContent>
						{loading && (
							<Backdrop open={true} style={{
								position: 'absolute', top: 0, left: 0, zIndex: 5,
								backgroundColor: 'rgba(255, 255, 255, 0.2)', // Slightly translucent
								backdropFilter: 'blur(0.5px)', // Applies a blur effect
							}}>
								<CircularProgress color="primary" />
							</Backdrop>
						)}
						{renderContent()}
					</DialogContent>
					<DialogActions>
						<Box flex="1" display="flex" justifyContent="flex-end">
							<Button onClick={handleClose} variant="outlined">Close</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								color="primary"
								onClick={formik.submitForm}
								sx={{ marginLeft: 2 }}
								loading={formik.isSubmitting}
							>
								Save
							</LoadingButton>
						</Box>
					</DialogActions>
				</Dialog>
			)}
			{/* Confirm Delete Dialog */}
			<Dialog open={isDeleteDialogOpen} onClose={handleCloseDelete}>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete `{selectedKey?.name}` alert?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDelete}>Cancel</Button>
					<Button onClick={handleConfirmDelete} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}