import React from 'react';
import { List, ListItem, TextField, ListItemSecondaryAction, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const LimitedAnswersOptions = ({ options, onEdit, onRemove, onAdd }) => {
    return (
        <>
            <List>
                {Array.isArray(options) &&
                    options.map((option, index) => (
                        <ListItem key={index}>
                            <TextField
                                value={option}
                                onChange={(e) => onEdit(index, e.target.value)}
                                fullWidth
                                variant="outlined"
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => onRemove(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
            </List>
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={onAdd}
                fullWidth
                sx={{ mt: 1, width: '150px', ml: 2 }}
            >
                Add Option
            </Button>
        </>
    );
};

export default LimitedAnswersOptions;