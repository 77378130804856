import React, { createContext, useContext, useState, useMemo, useCallback } from "react";

// Create Context
const DashboardContext = createContext();

// Create Provider
export const DashboardProvider = ({ children }) => {
    const [dashboardData, setDashboardData] = useState({
        dbData: null,
        dbDataComparison: null,
        loading: false,          // Added loading state
        error: null, 
        page: null            // Added error state
    });

    // Memoized update function to prevent unnecessary re-renders
    const updateDashboard = useCallback((newData) => {        
        setDashboardData((prev) => ({ ...prev, ...newData }));
    }, []);

    // Use useMemo to optimize the provider value and avoid re-renders
    const contextValue = useMemo(() => ({
        dashboardData,
        updateDashboard,
    }), [dashboardData, updateDashboard]);

    return (
        <DashboardContext.Provider value={contextValue}>
            {children}
        </DashboardContext.Provider>
    );
};

// Custom hook for easy access
export const useDashboard = () => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error("useDashboard must be used within a DashboardProvider");
    }
    return context;
};
