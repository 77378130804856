export const QUESTION_INPUT_TYPE = {
    UNLIMITED_ANSWERS: 'unlimitedAnswers',
    LIMITED_ANSWERS: 'limitedAnswers',
    NUMBER_RANGE: 'numberRange',
    NUMBER_RANGE_LIMITED_ANSWERS: 'numberRangeLimitedAnswers',
    RESIDENCE_LIST: 'residenceList',
}

export const QUESTION_TYPE_OPTIONS = [
    { value: QUESTION_INPUT_TYPE.UNLIMITED_ANSWERS, label: 'Unlimited answers' },
    { value: QUESTION_INPUT_TYPE.LIMITED_ANSWERS, label: 'Limited Answers' },
    { value: QUESTION_INPUT_TYPE.NUMBER_RANGE, label: 'Number' },
    { value: QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS, label: 'Number range / limited answers' },
    { value: QUESTION_INPUT_TYPE.RESIDENCE_LIST, label: 'Residence list' },
]

export const NUMBER_RANGE_TYPE = {
    RANGE: "range",
    AVERAGE: "average",
    TOTAL: "total",
}