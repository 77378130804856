import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { QUESTION_INPUT_TYPE, QUESTION_TYPE_OPTIONS } from '../../../../../types/question.type';
import NumberRangeType from './components/NumberRangeType';
import LimitedAnswersOptions from './components/LimitedAnswersOptions';
import NumberRangeWithUnlimitedAnswer from './components/NumberRangeWithUnlimitedAnswer';
import QuestionErrorList from './components/QuestionErrorList';
import { validateQuestionValues } from '../../../../../utilis/common';
import { checkDuplicateQuestionLabel } from '../../../../../services/api/data-input.api';
import _ from 'lodash';


const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
}));

const AddQuestionDialog = ({
    open,
    onClose,
    onSave,
    editQuestion,
    loading,
    setEditQuestion,
    setQuestionValues,
    questionValues,
    setConfirmDialog,
    resetQuestionValues,
    isCustomQuestion,
    latestType,
    transferType
}) => {
    const [errors, setErrors] = useState({});
    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    // useEffect(() => {
    //     alert(isCustomQuestion);
    // }, [isCustomQuestion]);

    const handleClose = () => {
        resetQuestionValues();
        setEditQuestion(undefined);
        onClose();
    };

    const handleDuplicate = async (questionValues) => {
        let transferTypeArr = [transferType];
        if (transferType === "SNF" || transferType === "AMA" || transferType === "safeDischarge") {
            transferTypeArr = ["SNF", "AMA", "safeDischarge"]
        }

        let updatedQ = await checkDuplicateQuestionLabel(
            {
                label: questionValues.label,
                forType: latestType,
                _id: editQuestion?._id || null,
                tableLabel: questionValues.tableLabel,
                ...latestType === "transfer" && { forTransferType: transferTypeArr }
            }
        );
        return updatedQ;
    }

    const handleSave = async () => {
        let validationErrors = {};

        // Validate label for non-custom questions
        if (!isCustomQuestion && !questionValues.label) {
            validationErrors.label = 'Label is required.';
        }

        // Validate custom questions
        if (isCustomQuestion) {
            validationErrors = validateQuestionValues(questionValues) || {};
        }

        // Proceed only if no validation errors
        if (_.isEmpty(validationErrors)) {
            const res = await handleDuplicate(questionValues);

            if (res) {
                const { label, tableLabel } = questionValues;

                // Check for duplicates
                if (res.label?.trim() === label?.trim()) {
                    validationErrors.label = 'Question Label already taken, please use another label.';
                }
                if (res.tableLabel?.trim() === tableLabel?.trim()) {
                    validationErrors.tableLabel = 'Table Question Label already taken, please use another label.';
                }
            }
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            setOpenErrorDialog(true); // Open the dialog if there are errors
            return;
        }
        if (editQuestion?._id) {
            onSave(isCustomQuestion);
        } else {
            setConfirmDialog(true);
        }
    };

    const handleAddOption = () => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: [...prevValues.customQuestionOptions, ''],
        }));
    };

    const handleRemoveOption = (index) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.filter((_, i) => i !== index),
        }));
    };

    const handleEditOption = (index, value) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.map((option, i) =>
                i === index ? value : option
            ),
        }));
    };

    const handleInputChange = (field, value) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            [field]: value,
        }));
    };


    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };
    
    return (
        <>
            <QuestionErrorList
                errors={errors}
                openErrorDialog={openErrorDialog}
                handleCloseErrorDialog={handleCloseErrorDialog}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <StyledDialogTitle>
                    {editQuestion?._id ? 'Edit Question' : 'Add Question'}
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </StyledDialogTitle>
                <DialogContent sx={{ padding: '20px' }}>
                    {!isCustomQuestion && (<>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Question Label"
                                variant="outlined"
                                fullWidth
                                value={questionValues.label}
                                onChange={(e) => handleInputChange('label', e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Table Question Label"
                                variant="outlined"
                                fullWidth
                                value={questionValues.tableLabel}
                                onChange={(e) => handleInputChange('tableLabel', e.target.value)}
                            />
                        </FormControl>
                    </>
                    )}
                    {isCustomQuestion && (<>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="question-type-label-id">Question Type</InputLabel>
                            <Select
                                labelId="question-type-label-id"
                                id="question-type"
                                value={questionValues.customQuestionInputType}
                                label="Question Type"
                                onChange={(e) => {
                                    handleInputChange('customQuestionInputType', e.target.value)
                                    setQuestionValues(prevValues => ({
                                        ...prevValues,
                                        customQuestionOptions: [],
                                    }));
                                }}
                            >
                                {QUESTION_TYPE_OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Question Label"
                                variant="outlined"
                                fullWidth
                                value={questionValues.label}
                                onChange={(e) => handleInputChange('label', e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                label="Table Question Label"
                                variant="outlined"
                                fullWidth
                                value={questionValues.tableLabel}
                                onChange={(e) => handleInputChange('tableLabel', e.target.value)}
                            />
                        </FormControl>

                        {questionValues.customQuestionInputType === QUESTION_INPUT_TYPE.LIMITED_ANSWERS && (
                            <LimitedAnswersOptions
                                options={questionValues?.customQuestionOptions}
                                onEdit={handleEditOption}
                                onRemove={handleRemoveOption}
                                onAdd={handleAddOption}
                            />
                        )}

                        {questionValues.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE && (
                            <NumberRangeType
                                setQuestionValues={setQuestionValues}
                                questionValues={questionValues}
                            />
                        )}

                        {questionValues.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS && (
                            <NumberRangeWithUnlimitedAnswer
                                setQuestionValues={setQuestionValues}
                                questionValues={questionValues}
                            />
                        )}
                    </>)}
                </DialogContent>
                <DialogActions sx={{ padding: '20px' }}>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" disabled={loading}>
                        {loading ? (
                            <>
                                <CircularProgress size={15} /> &nbsp; Saving...
                            </>
                        ) : (
                            'Save question'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddQuestionDialog;