import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/deceased.slice";
import { getCardPatientData, getCardPatientChartData, getAllDeceasedCount } from "./api/deceased.api";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/deceasedComparison.slice";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const ongoingDeceasedRequestsChart = new Map();


const cardPatientAndChartData = async (data = null, type) => {
	const requestKey = JSON.stringify({ type });
	// Check for duplicate requests
	if (ongoingDeceasedRequestsChart.has(requestKey)) {
		const { controller } = ongoingDeceasedRequestsChart.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingDeceasedRequestsChart.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;
	try {
		const requestPromise = getCardPatientChartData(data, { signal });
		ongoingDeceasedRequestsChart.set(requestKey, { requestPromise, controller });
		const res = await requestPromise;
		return res;
	} catch (e) {
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Cleanup: Remove the request from tracking after completion
		ongoingDeceasedRequestsChart.delete(requestKey);
	}
};

const ongoingDeceasedRequests = new Map();

const getAllDeceasedPatient = async (data = null, forComparison, type) => {
	const requestKey = JSON.stringify({ type });
	// Check for duplicate requests
	if (ongoingDeceasedRequests.has(requestKey)) {
		const { controller } = ongoingDeceasedRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingDeceasedRequests.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;

	try {
		const requestPromise = getAllDeceasedCount(data, { signal });
		ongoingDeceasedRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;

		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res.totals.total) : setMainTotal(res.totals.total));

		return res.data;
	} catch (e) {
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Cleanup: Remove the request from tracking after completion
		ongoingDeceasedRequests.delete(requestKey);
	}
};

export { cardPatientData, cardPatientAndChartData, getAllDeceasedPatient };
