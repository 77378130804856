import { Typography, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TotalAdmissionSVG from "../../../../assets/svgs/admission-total.svg";
import OfAvgCensusSVG from "../../../../assets/svgs/of-avc-census.svg";
import TotalSVG from "../../../../assets/svgs/total.svg";
import { PAGE_TYPE } from "../../../../types/pages.type";
import { itemPercentage, projectionPerMonth } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import ColorBox from "../../../shared/dashboard/ColorBox";
import styles from "./TotalTransferCard.module.scss";

const TotalTransferCard = ({
	dbData,
	dbDataComparison,
	total,
	totalComparison,
	title,
	totalProjectionPercentage,
	totalPercentage,
	secondaryTitle,
	page,
	totalProjection,
	totalProjectionComparison,
	projectionComparison,
	filter,
	filterComparison,
	textColor,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	totalComparisonPercentage,
	totalProjectionComparisonPercentage,
	loading,
	reverseColorsAdmissionPage,
	comparingAgainstDifferentFacility,
	projectionDays,
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const [comparisonDiffColor, setComparisonDiffColor] = useState(null);
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const projectionComparisonSpecial = projectionPerMonth(totalComparison, filterComparison);
	const [comparingAgainst, setComparingAgainst] = useState("");
	const [comparingAgainstScaled, setComparingAgainstScaled] = useState(null);
	const [numberOfDays, setNumberOfDays] = useState(null);
	const [, setComparingAgainstPro] = useState("");
	const [, setComparingAgainstScaledPro] = useState(null);
	const [, setNumberOfDaysPro] = useState(null);
	const [, setComparisonDiffColorPro] = useState(null);
	const { isExpandedSideBar } = useSelector((state) => state.common);
	const isMd = useMediaQuery("(max-width:1281px)");

	useEffect(() => {
		const totalParsed = itemPercentage(total, admissionCompareAgainst, "number");
		const totalComparisonParsed = itemPercentage(totalComparison, admissionCompareAgainstComparison, "number");
		const {
			percentageDiff,
			itemTotalComparisonScaled,
			numberOfDays: numberOfDaysTemp,
		} = isSpecialPage
				? calculateDiffBetweenPercentages(
					totalParsed,
					totalComparisonParsed,
					reverseColorsAdmissionPage,
					filter,
					filterComparison,
					totalComparison,
					projectionDays
				)
				: calculateDiffBetweenPercentages(
					totalPercentage,
					totalComparisonPercentage,
					false,
					filter,
					filterComparison,
					totalComparison,
					projectionDays
				);

		setNumberOfDays(numberOfDaysTemp);
		setComparingAgainstScaled(itemTotalComparisonScaled);
		setComparingAgainst(totalComparison);
		const percentageChange = pickComparisonColor(percentageDiff, isSpecialPage);
		setComparisonDiffColor(percentageChange);

		const {
			percentageDiff: percentageDiffPro,
			itemTotalComparisonScaled: itemTotalComparisonScaledPro,
			numberOfDays: numberOfDaysTempPro,
		} = calculateDiffBetweenPercentages(
			totalProjectionPercentage,
			totalProjectionComparisonPercentage,
			false,
			filter,
			filterComparison,
			totalProjectionComparison,
			projectionDays
		);
		const proPercentageChange = pickComparisonColor(percentageDiffPro);
		setComparingAgainstPro(totalProjectionComparison);
		setComparisonDiffColorPro(proPercentageChange);
		setComparingAgainstScaledPro(itemTotalComparisonScaledPro);
		setNumberOfDaysPro(numberOfDaysTempPro);
	}, [
		projectionDays,
		dbData,
		dbDataComparison,
		totalPercentage,
		totalComparisonPercentage,
		totalProjectionPercentage,
		totalProjectionComparisonPercentage,
		isSpecialPage,
		total,
		totalComparison,
		totalProjection,
		projectionComparison,
		projectionComparisonSpecial,
		page,
		filter,
		filterComparison,
		percentageThresholds,
		comparingAgainstDifferentFacility,
		totalProjectionComparison,
		reverseColorsAdmissionPage,
		admissionCompareAgainst,
		admissionCompareAgainstComparison,
	]);

	return (
		<div className={`df aic p-t-20 p-b-25 ${styles.plannedUnplanned}`}>
			<div className={`p-l-45 ${styles.left} `}>
				<Typography variant="subtitle2">{title}</Typography>
				<div className={`m-t-10 df`}>
					<div
						className={classNames(`m-t-4`, loading && "skeleton")}
						style={{ width: "47px", height: "41.3px", borderRadius: "5px" }}
					>
						{!loading ? page === "admission" ? <TotalAdmissionSVG /> : <TotalSVG /> : ""}
					</div>
					<div className={`df ffc m-l-10 `}>
						<div
							style={{ background: textColor }}
							className={`ffmar-bold fw700 fs49 df acc ${!loading ? (page === "admission" ? styles.gradientText : styles.gradientTextHospital) : ""
								}  ${styles.totalText} ${isMd && isExpandedSideBar ? styles.totalTextExpand : null }`}
						>
							{loading ? (
								<span className="skeleton-text">0</span>
							) : (
								Math.round(projectionDays === null ? total : totalProjection)
							)}
							{!loading && (
								<ColorBox
									color={comparisonDiffColor}
									comparingAgainst={comparingAgainst}
									comparingAgainstScaled={comparingAgainstScaled}
									numberOfDays={numberOfDays}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={`p-l-45 ${styles.right} `}>
				<Typography variant="subtitle2">{secondaryTitle}</Typography>
				<div className={`m-t-10 df`}>
					<div
						className={classNames(`m-t-4`, loading && "skeleton")}
						style={{ width: "47px", height: "41.3px", borderRadius: "5px" }}
					>
						{!loading ? <OfAvgCensusSVG /> : ""}
					</div>
					<div className={`df ffc m-l-10 `}>
						<div
							style={{ background: textColor }}
							className={`ffmar-bold fw700 fs49 df acc ${styles.gradientTextPercentage} ${loading && styles.skeleton} ${styles.totalText
								} ${isMd && isExpandedSideBar ? styles.totalTextExpand : null }`}
						>
							{loading ? (
								<span className="skeleton-text">0%</span>
							) : (
								`${projectionDays === null ? totalPercentage : totalProjectionPercentage}%`
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TotalTransferCard;
