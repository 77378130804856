import api from './api';

const API_PREFIX = "api/census";

const updateCensusData = async (body, facilityId) => {
    const response = await api.put(`${API_PREFIX}/${facilityId}`, body);
    return response;
};

const updateQuestionLabel = async (body, id) => {
    const response = await api.put(`/api/question/set/update-question-label/?id=${id}`, body);
    return response;
};

const checkDuplicateQuestionLabel = async (body) => {
    const response = await api.post(`/api/question/duplicate-question-label`, body);
    return response;
};

const getCensusFirstDateData = async (params) => {
    const response = await api.get(`${API_PREFIX}/first/date`, { params });
    return response;
};

const getFirstAndLastDateOfInput = async (body) => {
    const response = await api.post(`${API_PREFIX}/first-last-adt-date`, body);
    return response;
};

const updateBedCapacityData = async (body) => {
    const response = await api.post(`${API_PREFIX}/update-bed-capacity`, body);
    return response;
};

const updateEndDateOfADTData = async (body) => {
    const response = await api.post(`${API_PREFIX}/update-end-date-of-adt`, body);
    return response;
};



export {
    checkDuplicateQuestionLabel,
    updateQuestionLabel,
    getFirstAndLastDateOfInput,
    updateCensusData,
    getCensusFirstDateData,
    updateBedCapacityData,
    updateEndDateOfADTData
};
