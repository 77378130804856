import { deleteCustomAlertData, getAllAlertsReports, saveAlertReport, saveCustomAlertReport } from "./api/alert-report.api";

const handleAsyncOperation = async (operation, ...args) => {
    try {
        const res = await operation(...args);
        return res;
    } catch (e) {
        console.error(e);
    }
};

const getAlertsReports = async (type) => handleAsyncOperation(getAllAlertsReports, type);
const createAlertsReport = async (body) => handleAsyncOperation(saveAlertReport, body);
const deleteCustomAlert = async (body) => handleAsyncOperation(deleteCustomAlertData, body);
const createCustomAlertsReport = async (body) => handleAsyncOperation(saveCustomAlertReport, body);


export {
    getAlertsReports,
    createAlertsReport,
    createCustomAlertsReport,
    deleteCustomAlert
}
