import { CHART_POINT_TYPE } from "../types/chart-dialog.type";
import { subDays, addDays } from "date-fns";
import { CARD_LABEL_COMMON } from "../types/common.type";

export const DEFAULT_CHART_DATA = {
    mainData: [],
    filterData: [],
    filterPatients: []
}

export const DEFAULT_CHART_FILTER = {
    days: null,
    startDate: null,
    endDate: null,
    filterButtons: [],
    chartPointType: CHART_POINT_TYPE.TOTAL
};

export const DEFAULT_FILTER = {
    startDate: subDays(new Date(), 0),
    endDate: addDays(new Date(), 0),
    key: "selection",
};

export const DECEASED_TYPES = {
    ALL: "all",
};

export const ReportTransferTypeTitleEnum = {
    all: "all",
    unplannedHospitalTransfer: "Unplanned Transfers",
    plannedHospitalTransfer: "Planned Transfers",
    admission: "New Admissions",
    readmission: "Re-Admissions",
    totalOutgoing: "Total Outgoing Transfers",
    totalIncoming: "Total Incoming Transfers",
    doctorData: CARD_LABEL_COMMON.perDoctor,
    daysData: CARD_LABEL_COMMON.perDay,
    dxData: "Per Diagnosis",
    insuranceData: CARD_LABEL_COMMON.perInsurance,
    floorsData: CARD_LABEL_COMMON.perFloor,
    hospitalData: "Per Hospital",
    sixtyDaysData: "60 Days Analysis",
};

export const communityTransferOptions = [
    { label: "All", value: "all" },
    { label: "Safe Discharge", value: "safeDischarge" },
    { label: "SNF", value: "SNF" },
    { label: "AMA", value: "AMA" },
];

export const OverallAdtOptions = [
    { label: "All", value: "all" },
    { label: "Admission/Readmission", value: "admission" },
    // { label: "Readmission", value: "readmission" },
    { label: "Safe Discharge", value: "safeDischarge" },
    { label: "Hospital Transfer", value: "hospitalTransfer" },
    { label: "SNF", value: "SNF" },
    { label: "AMA", value: "AMA" },
    { label: "Deceased", value: "deceased" },
];

export const adtOverallAdtOptions = [
    { label: "All", value: "all" },
    { label: "Safe Discharge", value: "safeDischarge" },
    { label: "Hospital Transfer", value: "hospitalTransfer" },
    { label: "SNF", value: "SNF" },
    { label: "AMA", value: "AMA" },
    { label: "Deceased", value: "deceased" },
];

export const admissionFilterOptions = [
    { label: "All", value: "all" },
    { label: "Admission", value: "admission" },
    { label: "Readmissions", value: "readmission" },
];

export const percentageByEnum = {
    censusAverage: "Average Occupancy",
    bedCapacity: "Bed Capacity",
    censusAsOfNow: "Current Census",
    customNumber: "Custom Occupancy",
}