import {
    Divider,
    IconButton,
    InputAdornment,
    Link as MLink,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    TextField,
} from "@mui/material";
import _ from "lodash";
import Scrollbar from "../scrollbar";
import SearchIcon from "@mui/icons-material/Search";
import HtmlTooltip from "../HtmlTooltip";
import CardTooltipContent from "../../dashboard/card/CardTooltipContent";
import styles from "./AddCardDrawer.module.scss";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const FilterCardList = ({
    setSearchText,
    searchText,
    isOpenCloseAll,
    handleAddCustomCard,
    setIsOpenCloseAll,
    scrollHeight,
    listData,
    scrollToSection,
    selectedCards,
    type = null,
    page
}) => {
    return (
        <Paper
            sx={{
                borderRadius: "0px 0px 8px 8px",
                boxShadow: 3,
                ...type !== "scroll" ? { margin: "0px 4px 4px 4px" } : {}
            }}
        >
            <TextField
                fullWidth
                sx={{ px: 1.5, p: 1.5 }}
                placeholder="Search tab"
                size="small"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <MLink
                sx={{ px: 1.5, pb: 1 }}
                component="button"
                variant="body2"
                onClick={() => {
                    if (!isOpenCloseAll) {
                        handleAddCustomCard("selectAll");
                    } else {
                        handleAddCustomCard("clearAll");
                    }
                    setIsOpenCloseAll((prevState) => !prevState);
                }}
            >
                Open all / Close all
            </MLink>
            <Divider />
            <Scrollbar
                sx={{
                    height: scrollHeight ? scrollHeight : 200,
                    width: "100%",
                    '@media (max-width:1280px)': {
                        height: '160px'                        
                    }
                }}
                rootSx={{
                    borderRadius: "8px 8px 8px 8px",
                }}
            >
                <List
                    sx={{
                        bgColor: "background.paper",
                        px: 2,
                        py: 2,
                    }}
                    dense
                >
                    {_.orderBy(listData, "label", "ASC")
                        .filter((ele) =>
                            ele.label?.toLowerCase().includes(searchText?.toLowerCase())
                        )
                        .map((ele, index) => (
                            <ListItem
                                onClick={() => handleAddCustomCard(ele.value)}
                                key={ele.value}
                                dense
                                sx={{
                                    cursor: "pointer",
                                    "&.MuiListItem-root": {
                                        borderRadius: "4px",
                                        background: _.includes(selectedCards, ele.value)
                                            ? "#2259c9"
                                            : "#e1e1f3",
                                        marginBottom: "10px",
                                    },
                                }}
                                secondaryAction={
                                    <>
                                        {_.includes(selectedCards, ele.value) && (
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (!_.includes(selectedCards, ele.value)) {
                                                        setTimeout(() => {
                                                            scrollToSection(ele.value);
                                                        }, 500);
                                                    } else {
                                                        scrollToSection(ele.value);
                                                    }
                                                }}
                                            >
                                                <ArrowCircleRightOutlinedIcon
                                                    sx={{
                                                        color: _.includes(selectedCards, ele.value)
                                                            ? "#fff"
                                                            : "inherit",
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    </>
                                }
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        color: _.includes(selectedCards, ele.value)
                                            ? "#fff"
                                            : "inherit",
                                        fontFamily: "manrope",
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        fontSize: "11px",
                                    }}
                                    primary={
                                        <Stack direction={"row"}>
                                            <div className={styles.cardTooltipIconList} key={`tooltip-list-${ele.value}`}>
                                                <HtmlTooltip
                                                    key={`tooltip-list--html-${ele.value}`}
                                                    content={
                                                        <CardTooltipContent content={ele?.tooltipContent} />
                                                    }
                                                >
                                                    <IconButton
                                                        disableFocusRipple={true}
                                                        disableRipple={true}
                                                        sx={{ cursor: "pointer" }}
                                                        size="small"
                                                    >
                                                        <InfoOutlinedIcon sx={{ fontSize: "14px", color: "#b3b3b3", mt: "-12px", ml: "-10px" }} />
                                                    </IconButton>
                                                </HtmlTooltip>
                                            </div>
                                            {_.capitalize(ele.label)}
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        ))}
                </List>
            </Scrollbar>
        </Paper>
    );
};

export default FilterCardList;
