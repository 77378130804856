import { createSlice } from "@reduxjs/toolkit";

const persistedThresholds = localStorage.getItem("percentageThresholds");
const initialPercentageThresholds = persistedThresholds
	? JSON.parse(persistedThresholds)
	: {
		veryBad: { value: 50, showColor: true },
		bad: { value: 25, showColor: true },
		littleBad: { value: 5, showColor: true },
		littleGood: { value: 5, showColor: true },
		good: { value: 25, showColor: true },
		veryGood: { value: 50, showColor: true },
	};

const initialState = {
	comparisonRanges: [
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	],
	rangesSet: false,
	percentageThresholds: initialPercentageThresholds,
	activeComparisonFacilitiesId: [],
	activeComparisonAccountId: null,
	comparisonFacilities: [],
	comparingAgainstDifferentFacility: false,
	shouldReverseOverallColors: true,
	resetComparison: false,
	refetchComparison: false,
	isUpdatedDateManually: false,
	manuallyClickComparison: null,
	isOverLapComparison: false,
	isOverLapDateMinimum: false,
	isComparisonRangeSet: true,
	facilityByAccount: []
};

const comparisonReducer = createSlice({
	name: "comparison",
	initialState,
	reducers: {
		setIsComparisonRangeSet(state, action) {
			state.isComparisonRangeSet = action.payload;
		},
		setIsOverLapDateMinimum(state, action) {
			state.isOverLapDateMinimum = action.payload;
		},
		setIsOverLapComparison(state, action) {
			state.isOverLapComparison = action.payload;
		},
		setManuallyClickComparison(state, action) {
			state.manuallyClickComparison = action.payload;
		},
		setIsUpdatedDateManually(state, action) {
			state.isUpdatedDateManually = action.payload;
		},
		setRefetchComparison(state, action) {
			state.refetchComparison = action.payload;
		},
		setComparisonRanges(state, action) {
			state.comparisonRanges = action.payload;
			state.rangesSet = true;
		},
		setRemoveComparisonRanges(state, action) {
			state.comparisonRanges = action.payload;			
		},
		setRemoveCompareFilter(state, action) {
			state.comparisonRanges = initialState.comparisonRanges;
			state.rangesSet = false;			
		},
		setPercentageThresholds(state, action) {
			const updatedThresholds = action.payload;

			Object.keys(updatedThresholds).forEach((key) => {
				if (state.percentageThresholds[key]) {
					state.percentageThresholds[key] = {
						...state.percentageThresholds[key],
						...updatedThresholds[key],
					};
				}
			});
			localStorage.setItem("percentageThresholds", JSON.stringify(state.percentageThresholds));
		},
		setActiveComparisonFacilitiesId(state, action) {
			state.activeComparisonFacilitiesId = action.payload;
		},
		setActiveComparisonAccountId(state, action) {
			state.activeComparisonAccountId = action.payload;
		},
		setComparisonFacilities(state, action) {
			state.comparisonFacilities = action.payload;
		},
		setcomparingAgainstDifferentFacility(state, action) {
			state.comparingAgainstDifferentFacility = action.payload;
		},
		setShouldReverseOverallColors(state, action) {
			state.shouldReverseOverallColors = action.payload;
		},
		setResetComparison(state, action) {
			state.resetComparison = action.payload;
		},
		setFacilityByAccount(state, action) {
			state.facilityByAccount = action.payload;
		},
	},
});

export default comparisonReducer.reducer;

export const {
	setFacilityByAccount,
	setRemoveComparisonRanges,
	setRefetchComparison,
	setComparisonRanges,
	setRemoveCompareFilter,
	setPercentageThresholds,
	setActiveComparisonFacilitiesId,
	setActiveComparisonAccountId,
	setComparisonFacilities,
	setcomparingAgainstDifferentFacility,
	setShouldReverseOverallColors,
	setResetComparison,
	setIsUpdatedDateManually,
	setManuallyClickComparison,
	setIsOverLapDateMinimum,
	setIsOverLapComparison,
	setIsComparisonRangeSet
} = comparisonReducer.actions;
