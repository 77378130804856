import { Box, Grid, Toolbar } from "@mui/material";
import update from "immutability-helper";
import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilters, removeFilter, saveFilter } from "../../../services/filter.service";
import { setFilterDateRange, setLockTotal, setSelectedFilter } from "../../../store/reducers/community-transfer.slice";
import { setLockTotal as setLockTotalComparison, setSelectedFilter as setSelectedFilterComparison } from "../../../store/reducers/comparisonReducers/communityTransferComparison.slice";
import { setFacilityIds, setMultipleFacility } from "../../../store/reducers/permission.slice";
import { ADD_NOTIFICATION } from "../../../store/types";
import { DASHBOARD_FILTER_TYPE } from "../../../types/common.type";
import { PAGE_TYPE } from "../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays } from "../../../utilis/common";
import DateFilterComponent from "../filter/DateFilterComponent";
import DashboardHeader from "../header/DashboardHeader";
import CensusAverageBox from "../shared/CensusAverageBox/CensusAverageBox";
import DashboardTotalCount from "../shared/DashboardTotalCount";
import HeaderFilterOptions from "../shared/headerFilterOptions/HeaderFilterOptions";
import HeaderMenu from "../../shared/header-menu";
import CommunityHeaderFilterList from "./CommunityHeaderFilterList";

const CommunityTransferHeader = ({
	handleFilterUpdate,
	loading,
	setLoading,
	setLoadingComparison,
	projectionDays,
	isComparingAgainstAvgCensus,
	percentageAgainst,	
	filterListData,
	dynamicCards
}) => {
	const dispatch = useDispatch();
	const [selectedFilterMenu, setSelectedFilterMenu] = useState("all");
	const { dbData, cardFilter, filter, transferType, filterTotal, lockedTotalBy } = useSelector(
		(state) => state.communityTransfer
	);
	const { lockedTotal: lockedTotalComparison, filterTotal: filterTotalComparison } = useSelector(
		(state) => state.communityTransferComparison
	);
	const { facilityIds, isMultipleFacility } = useSelector((state) => state.permission);
	const { facilities: activeFacilities, prevStateFacilities } = useSelector((state) => state.activeFacilities);

	const { censusAverage } = dbData;
	const { percentageBy } = useSelector((state) => state.common);
	const [filterValue, setFilterValue] = useState("");
	const [filterOptions, setFilterOptions] = useState([]);
	const { lockedTotal } = useSelector((state) => state.communityTransfer);

	const totalFilterForViewer = calcProPercentsBasedOnFilterAndDays(
		filterTotal,
		filter,
		projectionDays,
		true,
		(!lockedTotalBy && !isComparingAgainstAvgCensus) || (lockedTotalBy && lockedTotalBy !== "census")
	);

	const handleChangeSelect = useCallback(
		(event) => {
			const selectedValue = event.target.value;
			if (selectedValue && selectedValue.facilityIds && selectedValue.facilityIds.length > 1) {
				dispatch(setFacilityIds(selectedValue.facilityIds));
				dispatch(setMultipleFacility(true));
			} else {
				dispatch(setFacilityIds([]));
				dispatch(setMultipleFacility(false));
			}

			handleFilterUpdate(true);
			let latestValues = {
				...selectedValue.filter,
				filter: {
					...selectedValue.filter.filter,
					startDate: moment(selectedValue.filter.filter.startDate).toDate(),
					endDate: moment(selectedValue.filter.filter.endDate).toDate(),
				},
			};
			setSelectedFilterMenu({ ...selectedValue, filter: latestValues });
			dispatch(setSelectedFilter({ ...selectedValue, filter: latestValues }));
		},
		[dispatch, handleFilterUpdate]
	);

	const getFiltersData = useCallback(() => {
		getFilters({ type: DASHBOARD_FILTER_TYPE.communityTransfer }).then((res) => {
			if (res && res.length > 0) {
				setFilterOptions(res);
			}
		});
	}, []);

	useEffect(() => {
		if (localStorage.getItem("facilityId")) {
			getFiltersData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getFiltersData, localStorage.getItem("facilityId")]);

	const handleClearFilter = useCallback(() => {
		setSelectedFilterMenu("all");
		dispatch(setSelectedFilter("all"));
		dispatch(setSelectedFilterComparison("all"));
	}, [dispatch]);

	useEffect(() => {
		if (!_.isEqual(activeFacilities, prevStateFacilities)) {
			handleClearFilter();
		}
	}, [activeFacilities, handleClearFilter, prevStateFacilities]);

	const handleRemoveFilter = async (selectedItem) => {
		await removeFilter(selectedItem._id);
		const latestFacilityList = update(filterOptions, {
			$splice: [[filterOptions.indexOf(selectedItem), 1]],
		});
		setFilterOptions(latestFacilityList);
		return true;
	};

	const handleSaveFilter = async () => {
		let facilityIdsData = [];

		if (isMultipleFacility) {
			facilityIdsData = facilityIds;
		} else {
			facilityIdsData = [localStorage.getItem("facilityId")];
		}

		const resData = await saveFilter({
			type: DASHBOARD_FILTER_TYPE.communityTransfer,
			name: filterValue,
			facilityIds: facilityIdsData,
			filter: { cardFilter, filter, transferType },
		});

		if (resData && resData.status === 200) {
			getFiltersData();
			setFilterValue("");
			return true;
		} else {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					label: resData.error,
					type: "error",
					id: "communitySaveFilterError",
				},
			});
			return false;
		}
	};

	const onChangeFilter = useCallback(
		(value) => {
			dispatch(setFilterDateRange(value));
		},
		[dispatch]
	);

	const handleSetDefaultTotal = useCallback(() => {
		dispatch(setLockTotal(lockedTotal ? null : filterTotal));
		dispatch(setLockTotalComparison(lockedTotalComparison ? null : filterTotalComparison));
	}, [dispatch, filterTotal, filterTotalComparison, lockedTotal, lockedTotalComparison]);

	return (
		<DashboardHeader>
			<Box
				display={"flex"}
				sx={{
					width: "100%",
					height: "60px",
					backgroundColor: "#636578",
					display: "flex",
					alignItems: "center",
					padding: "0px 16px",
				}}
			>
				<DashboardTotalCount
					isLockedTotal={lockedTotal ? true : false}
					filterTotal={totalFilterForViewer}
					handleChange={handleSetDefaultTotal}
					background="linear-gradient(249.91deg, #4CEBEB 28.23%, #076673 76.55%)"
					percentageBy={percentageBy}
					cardFilter={cardFilter}
				/>
				<Grid
					container
					direction={"row"}
					justifyContent="space-between"
					alignItems="center"
					sx={{ ml: "-8px", mt: "10px" }}
				>
					<Grid item lg={8} md={6} xs={12}>
						<CommunityHeaderFilterList
							filterListData={filterListData}
							cardFilter={cardFilter}
							transferType={transferType}
							percentageBy={percentageBy}
							lockedTotal={lockedTotal}
							lockedTotalBy={lockedTotalBy}
						/>						
					</Grid>

					<Grid item lg={4} md={6} xs={12}>
						<HeaderFilterOptions
							selectedFilterMenu={selectedFilterMenu}
							handleChangeSelect={handleChangeSelect}
							filterOptions={filterOptions}
							filterValue={filterValue}
							setFilterValue={setFilterValue}
							handleRemoveFilter={handleRemoveFilter}
							handleSaveFilter={handleSaveFilter}
							handleClearFilter={handleClearFilter}
						/>
					</Grid>
				</Grid>
			</Box>
			<Toolbar sx={{ height: 91 }}>
				<Grid
					container
					direction={"row"}
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
					sx={{ ml: "10px" }}
				>
					{/* <Grid item xs={1} md={2} xl={1}>
					<Stack direction={"row"} spacing={1} sx={{ ml: "-20px" }}>
					<Stack>
								<ComparisonPopup forPage="communityTransfer" loading={loading} setLoadingComparison={setLoadingComparison} />
							</Stack>
							<Stack>
								{projectionSelectionBlock}
							</Stack>
						</Stack>
					</Grid> */}
					<Grid item xs={4} md={4} xl={5}>
						<CensusAverageBox
							censusAverage={censusAverage}
							loading={loading}
							dbData={dbData}
							pageType={PAGE_TYPE.COMMUNITY_TRANSFER}
							percentageAgainst={percentageAgainst}
						/>
					</Grid>
					<Grid item xs={8} md={8} xl={7} display="flex" justifyContent="space-between" alignItems="center">
						<DateFilterComponent
							handleFilterUpdate={handleFilterUpdate}
							onChangeFilter={onChangeFilter}
							filter={filter}
							loading={loading}
							setLoading={setLoading}
							page={PAGE_TYPE.COMMUNITY_TRANSFER}
						/>

						<HeaderMenu
							page={PAGE_TYPE.COMMUNITY_TRANSFER}
							loading={loading}
							setLoadingComparison={setLoadingComparison}
							isAutomaticReportSaved={true}
							activeFacilities={activeFacilities}
							dynamicCards={dynamicCards}
						/>
					</Grid>
				</Grid>
				{/* <AutomaticallyReport isAutomaticReportSaved={isAutomaticReportSaved} page={PAGE_TYPE.COMMUNITY_TRANSFER} /> */}
			</Toolbar>
		</DashboardHeader>
	);
};

export default CommunityTransferHeader;
