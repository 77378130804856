import { CARD_LABEL_COMMON } from "./common.type"

export const DECEASED_CARDS_TYPE = {
    INSURANCE_DATA: 'insuranceData',
    DOCTOR_DATA: 'doctorData',
    FLOORS_DATA: 'floorsData',
    NINETY_DAYS_DATA: 'ninetyDaysData',
    TOTAL: "total",
}


export const DECEASED_CARDS_LABELS = {
    totalMain: "Deceased",
    insuranceData: CARD_LABEL_COMMON.perInsurance,
    doctorData: CARD_LABEL_COMMON.perDoctor,
    floorsData: CARD_LABEL_COMMON.perFloor,
    ninetyDaysData: '90 Days Analysis',
    total: "Total"
}