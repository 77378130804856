import React from "react";
import TableADT from "./tableADT/TableADT";
import styles from "./TransferADTCardListTable.module.scss";

const TransferADTCardListTable = ({
	item,
	itemComparison,
	handleToggleADTTab,
	handleToggleAll,
	filters,
	filtersComparison,
	dbSelectedFilters,
	dbSelectedFiltersComparison,
	page,
	filter,
	dontCompareDiffFacilities,
	filterComparison,
	totalAdmissions,
	totalAdmissionsComparison,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
	selectAll,
	triggerSelectAll,
	dbData,
	setTriggerSelectAll,
	specificData,
	selectedSpecialIds
}) => {
	return (
		<div className={`${styles.transferADTCardListTable} `}>
			<TableADT
				item={item}
				itemComparison={itemComparison}
				handleToggleADTTab={handleToggleADTTab}
				handleToggleAll={handleToggleAll}
				filters={filters}
				filtersComparison={filtersComparison}
				dbSelectedFilters={dbSelectedFilters}
				dbSelectedFiltersComparison={dbSelectedFiltersComparison}
				page={page}
				dontCompareDiffFacilities={dontCompareDiffFacilities}
				filter={filter}
				filterComparison={filterComparison}
				totalAdmissions={totalAdmissions}
				totalAdmissionsComparison={totalAdmissionsComparison}
				projectionDays={projectionDays}
				priorityNumber={priorityNumber}
				transferType={transferType}
				isComparingAgainstAvgCensus={isComparingAgainstAvgCensus}
				lockedTotalBy={lockedTotalBy}
				searchValue={searchValue}
				selectAll={selectAll}
				triggerSelectAll={triggerSelectAll}
				dbData={dbData}
				setTriggerSelectAll={setTriggerSelectAll}
				specificData={specificData}
				selectedSpecialIdsArr={selectedSpecialIds}
			/>
		</div>
	);
};

export default TransferADTCardListTable;
