import { useMemo } from "react";

const useAdditionalData = (dynamicCards, initialCards = []) => {
    return useMemo(() => {
        const hospitalCardsArr = [...initialCards];

        dynamicCards?.forEach((ele) => {
            hospitalCardsArr.push({
                value: ele?.accessor,
                label: ele?.tableLabel || ele?.label,
                tooltipContent: ele?.tableLabel || ele?.label,
            });
        });

        return hospitalCardsArr;
    }, [dynamicCards, initialCards]);
};

export default useAdditionalData;
