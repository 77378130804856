/* eslint-disable no-unused-vars */
import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Box } from "@mui/system";
import _ from "lodash";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import styles from "./DynamicDataCardList.module.scss";
import DynamicDataCardSkeleton from "./DynamicDataCardSkeleton";
import { useSelector } from "react-redux";
import useLoadingToggle from "../../../hooks/useLoadingToggle";
import CheckboxLoader from "../../../shared/checkbox-loader/CheckboxLoader";
import CheckboxCircle from "../../shared/checkboxButton/CheckboxCircle";

const DynamicDataCardList = ({
	data,
	dataComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	page,
	filter,
	filterComparison,
	averageCensusComparison,
	averageCensus,
	cardTitle,
	loading,
	projectionDays,
	priorityData,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
	question,
	spacialSelectedItem = []
}) => {

	const isFirstItemInPriorityData = priorityNumber === 1;
	const { comparingAgainstDifferentFacility } = useSelector(state => state.comparison);
	const { loadingItems, handleToggleWithLoader } = useLoadingToggle();

	return loading ? (
		<DynamicDataCardSkeleton />
	) : (
		<div className={`${styles.diagnosticsCardList} `}>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
				dense={true}
				disablePadding={true}
			>
				{data.length > 0 &&
					data
						.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
						.map((item, index) => {
							const ID = item._id;
							const isSpacialItem = item?.isSpacialItem ?? false;
							let itemComparison = _.find(dataComparison, { _id: ID });
							if (comparingAgainstDifferentFacility) {
								itemComparison = _.find(dataComparison, { label: item?.label });
							}
							const labelId = `checkbox-list-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							const percentageOfAverageCensus = itemPercentage(item.total, averageCensus, "number");
							const percentageOfAverageCensusComparison = itemPercentage(
								itemComparison?.total || 0,
								averageCensusComparison,
								"number"
							);
							const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
								percentageOfAverageCensus,
								percentageOfAverageCensusComparison,
								false,
								filter,
								filterComparison,
								itemComparison?.total,
								projectionDays
							);
							const comparisonColor = pickComparisonColor(percentageDiff);

							return (
								<ListItem
									sx={{ padding: "5px" }}
									key={ID}
									dense={true}
									disablePadding={true}
									className={`${styles.listItem} ${selected ? styles.selected : null}`}
								>
									<ListItemButton
										sx={{
											borderRadius: "8px",
											paddingLeft: 2,
											"@media screen and (max-width: 1424px)": {
												paddingLeft: 0.8,
											},
											paddingRight: { md: 1, lg: 2 },
										}}
										role={undefined}
										onClick={() => !isSpacialItem ? handleToggleWithLoader(() => handleToggle({
											item,
											type,
											isChecked: selected,
											cardTitle,
											question: { isCustom: true, customQuestionInputType: question?.customQuestionInputType }
										}), item?._id) : undefined}
										dense
										className={`${styles.listItemButton}`}
									>
										<Stack direction={"row"} sx={{ width: "100%" }}>
											<Stack
												sx={{ width: "100%" }}
												direction="row"
												alignItems={"center"}
												justifyContent="space-between"
											>
												<div className={`${styles.title}`} style={{ display: "flex", alignItems: "center" }}>
													<ColorBox
														sx={{ mr: "10px" }}
														color={comparisonColor}
														comparingAgainst={itemComparison?.total}
														comparingAgainstScaled={itemTotalComparisonScaled}
														numberOfDays={numberOfDays}
													/>
													<Box position="relative" display="inline-flex" alignItems="center">
														{isSpacialItem ? (
															<>
																<CheckboxCircle
																	className={styles.checkboxButton}
																	labelId={labelId}
																	handleToggle={() =>
																		handleToggleWithLoader(() => handleToggle({
																			item: item,
																			type,
																			itemComparison: itemComparison,
																			isChecked: spacialSelectedItem?.indexOf(ID) !== -1,
																			cardTitle,
																			isSpacialItem: item?.isSpacialItem ?? false
																		}), ID)
																	}
																	sx={{ ...(loadingItems[ID] && { opacity: 0 }) }}
																	checked={spacialSelectedItem?.indexOf(ID) !== -1 || selectedItem.indexOf(ID) !== -1}
																/>
																<CheckboxLoader
																	className={"small-dots-left"}
																	isLoading={loadingItems[item?._id]}
																/>
															</>
														) : (
															<>
																<Checkbox
																	edge="start"
																	checked={selected}
																	tabIndex={-1}
																	disableRipple
																	inputProps={{ "aria-labelledby": labelId }}
																	sx={{
																		paddingRight: { md: 0.2, lg: 0.5 },
																		...(loadingItems[item?._id] && { opacity: 0 })
																	}}
																/>
																<CheckboxLoader
																	className={"small-dots-left"}
																	isLoading={loadingItems[item?._id]}
																/>
															</>
														)}
													</Box>
													<CardItemTooltip item={item}>
														<span>{item.label} &nbsp;</span>
													</CardItemTooltip>
													<Box
														component={"span"}
														sx={{
															display: "none",
															"@media screen and (max-width: 1600px)": {
																display: "inline",
															},
														}}
													>
														<span className="ffmar-bold">
															{calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}{" "}
														</span>
														<span className="ffmar-reg">
															{percentageLabel(
																calcProPercentsBasedOnFilterAndDays(
																	item.percentage ? item.percentage : itemPercentage(item.total, null, null, page),
																	filter,
																	projectionDays,
																	true,
																	isComparingAgainstAvgCensus ||
																	lockedTotalBy === "census" ||
																	(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
																)
															)}
														</span>
													</Box>
												</div>
												<Box
													component={"span"}
													sx={{
														display: "inline",
														"@media screen and (max-width: 1601px)": {
															display: "none",
														},
													}}
												>
													<div className={`${styles.totalCount}`}>
														<span className="ffmar-bold">
															{calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}{" "}
														</span>
														{!isSpacialItem && (
															<span className="ffmar-reg">
																&nbsp;
																{percentageLabel(
																	calcProPercentsBasedOnFilterAndDays(
																		item.percentage ? item.percentage : itemPercentage(item.total, null, null, page),
																		filter,
																		projectionDays,
																		true,
																		isComparingAgainstAvgCensus ||
																		lockedTotalBy === "census" ||
																		(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
																	)
																)}
															</span>
														)}
													</div>
												</Box>
											</Stack>
										</Stack>
									</ListItemButton>
								</ListItem>
							);
						})}
				{!loading && <NoRecordFound data={data} />}
			</List>
		</div>
	);
};

export default DynamicDataCardList;
