/* eslint-disable array-callback-return */
import _ from "lodash";
import { store } from "..";
import { TYPES } from "../data/hospital.data";
import {
	setFacilityPercentage as setFacilityPercentageComparison,
	setFilterTotal as setFilterTotalComparison,
	setIsCensusTotalLocked as setIsCensusTotalLockedComparison,
	setLockedByFacility as setLockedByFacilityComparison,
	setLockedTotalBy as setLockedTotalByComparison,
	setLockTotal as setLockTotalComparison,
} from "../store/reducers/comparisonReducers/deceasedComparison.slice";
import {
	setFacilityPercentage,
	setFilterTotal,
	setIsCensusTotalLocked,
	setLockedByFacility,
	setLockedTotalBy,
	setLockTotal,
} from "../store/reducers/deceased.slice";
import { TOTAL_TYPE } from "../types/common.type";
import { DECEASED_CARDS_TYPE } from "../types/deceased.type";
import { dynamicCardFilter, filterCustomPatientData, filterListDataItems, getDynamicPercentageBy, processDynamicCard, updateListTotalValue } from "./common";
import { batch } from "react-redux";

export async function plannedFilter(patientData, oldFilter, type = null) {
	patientData = _.filter(patientData, ({ floorId, doctorId, insuranceId }) => {
		let filterArr = [];

		if (oldFilter.insuranceData.length > 0 && type !== "insuranceData") {
			filterArr.push(_.includes(oldFilter.insuranceData, insuranceId));
		}
		if (oldFilter.doctorData.length > 0 && type !== "doctorData") {
			filterArr.push(_.includes(oldFilter.doctorData, doctorId));
		}
		if (oldFilter.floorsData.length > 0 && type !== "floorsData") {
			filterArr.push(_.includes(oldFilter.floorsData, floorId));
		}
		return _.every(filterArr);
	});
	return patientData;
}

// * added comparison
export async function updateFacilityPercentageTotal(data, forComparison) {
	let facilityWiseTotal = _(data)
		.groupBy("facilityId")
		.sortBy((group) => data.indexOf(group[0]))
		.map((product) => {
			return {
				id: product[0]?.facilityId,
				total: product.length || 0,
			};
		})
		.value();
	forComparison
		? store.dispatch(setFacilityPercentageComparison(facilityWiseTotal))
		: store.dispatch(setFacilityPercentage(facilityWiseTotal));
}

// * added comparison
export async function updateFilterListData(cardFilter, patientList, priorityData = [], forComparison) {
	let objectCustom = Object();
	let newSavedFilters = [];
	let patientFilterData = patientList.list;
	const {
		dbData,
		lockedTotal,
		transferType,
		lockedTotalBy
	} = forComparison ? store.getState().deceasedComparison : store.getState().deceased;

	const { dynamicCards } = store.getState().deceased;

	let censusAverage = dbData?.censusAverage;

	if (!forComparison) {
		censusAverage = await getDynamicPercentageBy(dbData);
	}

	let lockedTotalModified = lockedTotal;

	let isComparingAgainstAvgCensus = true;

	let totalFilterData = {
		originalData: patientList.list,
		totalType: TOTAL_TYPE.FILTER,
		totalForPercentage: !transferType ? censusAverage : patientFilterData.length,
	};
	let mainNumPercentage = censusAverage;

	batch(async () => {
		if (transferType) {
			mainNumPercentage = null;
			isComparingAgainstAvgCensus = false;
			totalFilterData.totalForPercentage = patientFilterData.length;
		}

		const newPayload = [];
		if (forComparison) {
			const currentFacilityPercentage = store.getState().deceasedComparison.facilityPercentage;
			if (!_.isEqual(currentFacilityPercentage, newPayload)) {
				store.dispatch(setFacilityPercentageComparison(newPayload))
			}
		} else {
			const currentFacilityPercentage = store.getState().admission.facilityPercentage;
			if (!_.isEqual(currentFacilityPercentage, newPayload)) {
				store.dispatch(setFacilityPercentage(newPayload))
			}
		}

		if (!lockedTotalModified) {
			const newSetIsCensusTotalLocked = false;
			if (forComparison) {
				const currentIsCensusTotalLocked = store.getState().deceasedComparison.isCensusTotalLocked;
				if (!_.isEqual(currentIsCensusTotalLocked, newSetIsCensusTotalLocked)) {
					store.dispatch(setIsCensusTotalLockedComparison(newSetIsCensusTotalLocked))
				}
			} else {
				const currentIsCensusTotalLocked = store.getState().admission.isCensusTotalLocked;
				if (!_.isEqual(currentIsCensusTotalLocked, newSetIsCensusTotalLocked)) {
					store.dispatch(setIsCensusTotalLocked(newSetIsCensusTotalLocked));
				}
			}

			const newLockedTotalBy = null;
			if (forComparison) {
				const currentLockedTotalBy = store.getState().deceasedComparison.lockedTotalBy;
				if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
					store.dispatch(setLockedTotalByComparison(newLockedTotalBy))
				}
			} else {
				const currentLockedTotalBy = store.getState().admission.lockedTotalBy;
				if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
					store.dispatch(setLockedTotalBy(newLockedTotalBy))
				}
			}

		}
		if (lockedTotalBy) {
			const newLockedTotalBy = null;
			const newLockTotal = null;
			if (lockedTotalBy === TYPES.ALL || lockedTotalBy === "census") {
				if (lockedTotalModified && transferType !== lockedTotalBy && lockedTotalBy !== "census") {
					if (forComparison) {
						const currentLockedTotalBy = store.getState().deceasedComparison.lockedTotalBy;
						if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
							store.dispatch(setLockedTotalByComparison(newLockedTotalBy))
						}
					} else {
						const currentLockedTotalBy = store.getState().admission.lockedTotalBy;
						if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
							store.dispatch(setLockedTotalBy(newLockedTotalBy))
						}
					}

					if (forComparison) {
						const currentLockedTotal = store.getState().deceasedComparison.lockedTotal;
						if (!_.isEqual(currentLockedTotal, newLockTotal)) {
							store.dispatch(setLockTotalComparison(newLockTotal))
						}
					} else {
						const currentLockedTotal = store.getState().admission.lockedTotal;
						if (!_.isEqual(currentLockedTotal, newLockTotal)) {
							store.dispatch(setLockTotal(newLockTotal))
						}
					}

					lockedTotalModified = null;
				}
			} else {
				const lockedFilterRemoved = _.find(priorityData, { type: lockedTotalBy });
				if (!lockedFilterRemoved) {
					if (forComparison) {
						const currentLockedTotalBy = store.getState().deceasedComparison.lockedTotalBy;
						if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
							store.dispatch(setLockedTotalByComparison(newLockedTotalBy))
						}
					} else {
						const currentLockedTotalBy = store.getState().admission.lockedTotalBy;
						if (!_.isEqual(currentLockedTotalBy, newLockedTotalBy)) {
							store.dispatch(setLockedTotalBy(newLockedTotalBy))
						}
					}

					if (forComparison) {
						const currentLockedTotal = store.getState().deceasedComparison.lockedTotal;
						if (!_.isEqual(currentLockedTotal, newLockTotal)) {
							store.dispatch(setLockTotalComparison(newLockTotal))
						}
					} else {
						const currentLockedTotal = store.getState().admission.lockedTotal;
						if (!_.isEqual(currentLockedTotal, newLockTotal)) {
							store.dispatch(setLockTotal(newLockTotal))
						}
					}

					lockedTotalModified = null;
				}
			}
		}

		if (lockedTotalModified) {
			//mainNumPercentage = null;
			totalFilterData.totalForPercentage = lockedTotalModified;
		}
		if (lockedTotalModified && priorityData.length === 1 && !transferType) {
			totalFilterData.totalForPercentage = lockedTotalModified;
		}

		if (priorityData?.length > 0) {
			isComparingAgainstAvgCensus = false;
		}

		if (
			(priorityData?.length > 0 && (!lockedTotalBy || !lockedTotal)) ||
			(priorityData?.length > 0 && lockedTotalBy !== "census" && lockedTotalBy !== TYPES.ALL)
		) {
			totalFilterData.totalForPercentage = null;
		}

	});

	let dynamicCardsObj = {}; // 
	if (priorityData?.length > 0) {
		let i = 0;
		for await (const ele of priorityData) {
			i++;
			if (priorityData?.length === i && lockedTotalModified && !lockedTotalBy) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(ele.type))
					: store.dispatch(setLockedTotalBy(ele.type));
			}
			if (ele?.question?.isCustom) {
				patientFilterData = filterCustomPatientData(patientFilterData, cardFilter, ele);
			}

			if (ele.type === DECEASED_CARDS_TYPE.INSURANCE_DATA) {
				patientFilterData = _.filter(patientFilterData, ({ insuranceId }) =>
					_.every([_.includes(cardFilter.insuranceData, insuranceId)])
				);
			}

			if (ele.type === DECEASED_CARDS_TYPE.FLOORS_DATA) {
				objectCustom.floorsData = await floorsData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ floorId }) =>
					_.every([_.includes(cardFilter.floorsData, floorId)])
				);
			}

			if (ele.type === DECEASED_CARDS_TYPE.DOCTOR_DATA) {
				objectCustom.doctorData = await doctorData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ doctorId }) =>
					_.every([_.includes(cardFilter.doctorData, doctorId)])
				);
			}

			if (ele.type === DECEASED_CARDS_TYPE.NINETY_DAYS_DATA) {
				objectCustom.ninetyDaysData = await ninetyDaysDataList(patientList.ninetyDaysData, patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				if (i === priorityData.length) {
					updateFacilityPercentageTotal(patientFilterData, forComparison);
				}
				patientFilterData = await ninetyDaysDataFilter(
					cardFilter.ninetyDaysData,
					patientFilterData,
					patientList.ninetyDaysData
				);
			}
			newSavedFilters = [...newSavedFilters, ele.type];
		}
		const totalCount = lockedTotalModified ? lockedTotalModified : patientFilterData.length;
		if (forComparison) {
			const currentFilterTotal = store.getState().deceasedComparison.filterTotal;
			if (!_.isEqual(currentFilterTotal ?? 0, totalCount ?? 0)) {
				store.dispatch(setFilterTotalComparison(totalCount));
			}
		} else {
			const currentFilterTotal = store.getState().admission.filterTotal;
			if (!_.isEqual(currentFilterTotal ?? 0, totalCount ?? 0)) {
				store.dispatch(setFilterTotal(totalCount));
			}
		}

		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.INSURANCE_DATA)) {
			objectCustom.insuranceData = await insuranceData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.FLOORS_DATA)) {
			objectCustom.floorsData = await floorsData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.DOCTOR_DATA)) {
			objectCustom.doctorData = await doctorData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)) {
			objectCustom.ninetyDaysData = await ninetyDaysDataList(patientList.ninetyDaysData, patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}

		if (dynamicCards.length > 0) {
			for (const item of dynamicCards) {
				let objectCustomRes = {};
				if (!_.includes(newSavedFilters, item?.accessor)) {
					await processDynamicCard(item, patientFilterData, objectCustomRes, {
						...totalFilterData,
						totalForPercentage: totalCount,
					});
					if (!_.isEmpty(objectCustomRes)) {
						objectCustom[item?.accessor] = objectCustomRes?.[item?.accessor];
					}
				}
			}
		}

		if (!lockedTotalModified) {
			if (forComparison) {
				const currentLockedByFacility = store.getState().deceasedComparison.lockedByFacility;
				if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
					store.dispatch(setLockedByFacilityComparison(patientFilterData));
				}
			} else {
				const currentLockedByFacility = store.getState().admission.lockedByFacility;
				if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
					store.dispatch(setLockedByFacility(patientFilterData));
				}
			}
		}
	} else {
		batch(async () => {
			let totalCount;

			if (mainNumPercentage) {
				totalCount = mainNumPercentage;
			} else if (transferType && transferType.length > 0) {
				totalCount = patientFilterData.length;
			} else {
				totalCount = censusAverage;
			}
			forComparison
				? store.dispatch(setFilterTotalComparison(lockedTotalModified ? lockedTotalModified : totalCount))
				: store.dispatch(setFilterTotal(lockedTotalModified ? lockedTotalModified : totalCount));
			if (lockedTotalModified && !lockedTotalBy) {
				if (transferType) {
					forComparison
						? store.dispatch(setLockedTotalByComparison(transferType))
						: store.dispatch(setLockedTotalBy(transferType));
				} else {
					forComparison
						? store.dispatch(setLockedTotalByComparison("census"))
						: store.dispatch(setLockedTotalBy("census"));
				}
			}
			if (!transferType && lockedTotalModified) {
				var isCensusTotalLocked = true;
				if (forComparison) {
					const currentIsCensusTotalLocked = store.getState().deceasedComparison.isCensusTotalLocked;
					if (!_.isEqual(currentIsCensusTotalLocked, isCensusTotalLocked)) {
						store.dispatch(setIsCensusTotalLockedComparison(isCensusTotalLocked));
					}
				} else {
					const currentIsCensusTotalLocked = store.getState().admission.isCensusTotalLocked;
					if (!_.isEqual(currentIsCensusTotalLocked, isCensusTotalLocked)) {
						store.dispatch(setIsCensusTotalLocked(isCensusTotalLocked));
					}
				};
			}
			if (!lockedTotalModified) {
				if (forComparison) {
					const currentLockedByFacility = store.getState().deceasedComparison.lockedByFacility;
					if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
						store.dispatch(setLockedByFacilityComparison(patientFilterData));
					}
				} else {
					const currentLockedByFacility = store.getState().admission.lockedByFacility;
					if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
						store.dispatch(setLockedByFacility(patientFilterData));
					}
				}
			}

			updateFacilityPercentageTotal(patientFilterData, forComparison);
		});
		[objectCustom.insuranceData, objectCustom.floorsData, objectCustom.doctorData, objectCustom.ninetyDaysData] =
			await Promise.all([
				insuranceData(patientFilterData, totalFilterData),
				floorsData(patientFilterData, totalFilterData),
				doctorData(patientFilterData, totalFilterData),
				ninetyDaysDataList(patientList.ninetyDaysData, patientFilterData, totalFilterData),
			]);
		if (dynamicCards.length > 0) {
			dynamicCardsObj = await dynamicCardFilter(patientFilterData, dynamicCards, totalFilterData);
		}
		objectCustom = { ...objectCustom, ...dynamicCardsObj }
	}


	objectCustom.isComparingAgainstAvgCensus = isComparingAgainstAvgCensus;

	return objectCustom;
}

async function ninetyDaysDataFilter(cardFilter, patientData, ninetyDaysData) {
	// Filter ninetyDaysData by matching cardFilter IDs
	const ninetyDaysDataIds = _.flatMap(
		_.filter(ninetyDaysData, ({ _id }) => cardFilter.includes(_id)),
		'ids'
	);

	// Filter patientData by matching ninetyDaysData IDs
	return _.filter(patientData, ({ _id }) => ninetyDaysDataIds.includes(_id));
}

async function ninetyDaysDataList(data, patients, totalFilter) {
	const patientIds = patients?.map(({ id }) => id) ?? [];
	const percentageTotal = totalFilter?.totalForPercentage ?? patients.length;

	return await updateListTotalValue(data, patientIds, "value", percentageTotal, totalFilter);
}

async function processDataByGroup(data, totalFilter, groupKey, filterKey) {
	if (!Array.isArray(data) || data.length === 0) return [];

	const percentageTotal = totalFilter?.totalForPercentage ?? data.length;

	// Use lodash groupBy for better performance
	const groupedData = _.groupBy(data, groupKey);
	const originalData = _.groupBy(totalFilter?.originalData || [], groupKey);

	// Return filtered data or empty array
	return groupedData
		? await filterListDataItems(groupedData, filterKey, percentageTotal, { ...totalFilter, originalData })
		: [];
}

// Wrapper functions for specific data types
export const insuranceData = (data, totalFilter) =>
	processDataByGroup(data, totalFilter, "insuranceId", "insurance");

export const doctorData = (data, totalFilter) =>
	processDataByGroup(data, totalFilter, "doctorId", "doctor");

export const floorsData = (data, totalFilter) =>
	processDataByGroup(data, totalFilter, "floorId", "unit");

