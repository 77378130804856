import { Box, Stack } from "@mui/material";
import _ from "lodash";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./ShiftCard.module.scss";
import ShiftCardSkeleton from "./ShiftCardSkeleton";
import useLoadingToggle from "../../../hooks/useLoadingToggle";
import CheckboxLoader from "../../../shared/checkbox-loader/CheckboxLoader";

const TotalTextWithBar = ({
	total,
	percentage,
	comparisonColor,
	comparingAgainst,
	comparingAgainstScaled,
	numberOfDays,
	loading,
}) => {
	return (
		<Stack direction={"row"} display={"flex"} alignItems={"center"}>
			<Stack>
				{!loading && (
					<ColorBox
						color={comparisonColor}
						sx={{ height: "25px", width: "5px", mr: 0.1 }}
						comparingAgainst={comparingAgainst}
						comparingAgainstScaled={comparingAgainstScaled}
						numberOfDays={numberOfDays}
					/>
				)}
			</Stack>
			<Stack justifyContent={"center"} display={"flex"} alignItems={"center"}>
				<p className={`fs15 ffmar-bold ${styles.totalText}`}>{total || 0}</p>
				<p className={`fs12 ffmar-reg ${styles.percentageText}`}>{percentageLabel(percentage)}</p>
			</Stack>
		</Stack>
	);
};

const ShiftCard = ({
	data,
	dataComparison,
	handleToggle,
	selectedItem = [],
	type,
	page,
	averageCensusComparison,
	averageCensus,
	filter,
	filterComparison,
	cardTitle,
	loading,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
}) => {
	const isFirstItemInPriorityData = priorityNumber === 1;
	const { loadingItems, handleToggleWithLoader } = useLoadingToggle();

	return loading ? (
		<ShiftCardSkeleton />
	) : (
		<div className={`w100 ${styles.shiftsSec}`}>
			<div style={{ height: "230px", width: "100%", paddingTop: "40px" }}>
				<div className={`ffmar df jcc ${styles.leftSec}`}>
					{data &&
						data.length > 0 &&
						data.map((item, index) => {
							const ID = item._id;
							const itemComparison = _.find(dataComparison, { _id: item._id });
							const selected = selectedItem.indexOf(ID) !== -1;
							const percentageOfAverageCensus = itemPercentage(item.value, averageCensus, "number");
							const percentageOfAverageCensusComparison = itemPercentage(
								itemComparison?.value || 0,
								averageCensusComparison,
								"number"
							);
							const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
								percentageOfAverageCensus,
								percentageOfAverageCensusComparison,
								false,
								filter,
								filterComparison,
								itemComparison?.value,
								projectionDays
							);
							const comparisonColor = pickComparisonColor(percentageDiff);

							const value = calcProPercentsBasedOnFilterAndDays(item.value, filter, projectionDays);
							const percentage = calcProPercentsBasedOnFilterAndDays(
								item.percentage,
								filter,
								projectionDays,
								true,
								isComparingAgainstAvgCensus ||
								lockedTotalBy === "census" ||
								(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
							);

							return (
								<div
									className={`df ffc aic ${styles.barWrpr} ${selectedItem.indexOf("DC") !== -1 ? styles.selectedItem : ""
										}`}
									key={ID}
									id={`DC${index}`}
								>
									{percentage <= 20 && (
										<TotalTextWithBar
											total={value}
											percentage={percentage}
											comparisonColor={comparisonColor}
											comparingAgainst={itemComparison?.value}
											comparingAgainstScaled={itemTotalComparisonScaled}
											numberOfDays={numberOfDays}
											loading={loading}
										/>
									)}
									<div
										className={`df ffc acc ${styles.bar} `}
										style={{
											height: `${item.percentage}%`,
											background: `${selected ? "#4879f5" : "#636578"}`,
										}}
									>
										{percentage > 20 && (
											<TotalTextWithBar
												total={value}
												percentage={percentage}
												comparisonColor={comparisonColor}
												comparingAgainst={itemComparison?.value}
												comparingAgainstScaled={itemTotalComparisonScaled}
												numberOfDays={numberOfDays}
												loading={loading}
											/>
										)}
									</div>
									<p className={`fs14 m-t-8 ${styles.label}`}>{item.label}</p>
								</div>
							);
						})}
				</div>
			</div>

			<div className={`${styles.footerWrapper}`}>
				<p className={`p-l-20 p-r-20 p-t-15 p-b-15 ${styles.title}`}>Choose shift to show</p>

				<div className={`ffmar fs14 fw500 ${styles.itemsWrpr}`}>
					{data?.length > 0 &&
						data.map((item, index) => {
							const ID = item._id;
							const labelId = `checkbox-list-return-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							return (
								<CardItemTooltip item={item} key={ID + "shiftList"}>
									<div
										key={`${index}shiftList`}
										className={`df cp aic ${styles.sec} ${selected ? styles.selected : null}`}
										onClick={() => handleToggleWithLoader(() => handleToggle({ item, type, isChecked: selectedItem.indexOf(ID) !== -1, cardTitle }), item?._id)}
									>
										<Box position="relative" display="inline-flex" alignItems="center">
											<CheckboxButton
												className={styles.shiftCheckbox}
												labelId={labelId}
												checked={selectedItem.indexOf(ID) !== -1}
												buttonText={`${item.label}`}
												sx={{
													...(loadingItems[item?._id] && { opacity: 0 })
												}}
											/>
											<CheckboxLoader
												className={"small-dots-day-list"}
												isLoading={loadingItems[item?._id]}
											/>
										</Box>
									</div>
								</CardItemTooltip>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default ShiftCard;
