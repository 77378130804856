import axios from "../../../../../../axios";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Validation.module.scss";
import update from "immutability-helper";
import HideSVG from "../../../../../../assets/svgs/hide.svg";
import EditSVG from "../../../../../../assets/svgs/edit.svg";
import { getValidations } from "../../../../../../services/validations.service";
import { CircularProgress } from "@mui/material";
import useResetDynamicCards from "../../../../../hooks/useResetDynamicCards";

const Validation = props => {
  const { auth } = useSelector(({ auth }) => ({ auth }));
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [showList, setShowList] = useState(false);
  const [editId, setEditId] = useState(undefined);
  const [editValue, setEditValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [getListLoading, setGetListLoading] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const resetDynamicCards = useResetDynamicCards();

  const facilityId = useMemo(() => {
    return props?.selectedItem && props?.selectedItem?.facility ? props?.selectedItem?.facility?._id || props?.selectedItem?.facilityId : localStorage.getItem("facilityId")
  }, [props?.selectedItem]);

  const getList = async () => {
    setGetListLoading(true)
    let list = await getValidations({
      type: props.type,
      facilityid: facilityId,
      validationBase: props.validationBase
    })
    setList(list);
    setGetListLoading(false)
    if (props.value) {
      if (props.multiple) {
        setSelectedValues(props?.value?.map(val => list.find(l => l._id === val)));
      } else {
        setSelectedValues([list.find(l => l._id === props.value)]);
      }
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const isSelected = value => {
    return selectedValues?.find(item => item?._id === value) ? true : false;
  };

  const updateValidation = async (id, value) => {
    if (!loading) {
      setLoading(true);
      let validation = await axios.put(`/api/validation/${id}`, { ...value });
      if (validation) {
        // eslint-disable-next-line eqeqeq
        setList(previousListData => previousListData.filter((data) => data._id != id))
      }
      setLoading(false);
    }
  };

  const addValue = async () => {
    if (!searchValue) return;
    let newVal;
    let exists = list.find(item => item?.label === searchValue && item.active !== true);
    if (!exists) {
      if (!loading) {
        setLoading(true);
        newVal = await axios.post(`/api/validation/`, {
          facilityId: facilityId,
          label: searchValue,
          active: true,
          type: props.type,
          createdBy: auth._id,
        });
        setLoading(false);
        setList(update(list, { $push: [newVal.data] }));

        if (props?.isCustomQuestion) {
          resetDynamicCards();
        }
      }
    } else {
      if (!loading) {
        setLoading(true);
        newVal = await axios.put(`/api/validation/${exists._id}`, {
          active: true,
        });
        setLoading(false);
        setList(
          update(list, {
            [list.findIndex(item => item._id === exists._id)]: {
              active: { $set: true },
            },
          })
        );
      }
    }

    setSearchValue("");

    if (props.multiple) {
      setSelectedValues(update(selectedValues, { $push: [newVal.data] }));
    } else {
      setSelectedValues([newVal.data]);
      setShowList(false);
    }
    // setList(newVal.data);
  };

  useEffect(() => {
    if (props.onChange) {
      if (props.multiple) {
        props.onChange(selectedValues);
      } else {
        if (selectedValues.length > 0) {
          props.onChange(selectedValues[0]?._id);
        } else {
          props.onChange(null);
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedValues]);

  const updateValue = async () => {
    setLoading(true)
    let re = await axios.put(`/api/validation/${editId}`, {
      label: editValue,
    });
    setLoading(false)
    setList(
      update(list, {
        [list.findIndex(item => item._id === editId)]: {
          $set: re.data,
        },
      })
    );
    setShowConfirmEdit(false);
    setEditId(undefined);
    setEditValue(undefined);
  };

  return (
    <>
      <div className={styles.validation}>
        <div
          className={`df aic ${styles.dummyInputWrpr} ${showList ? styles.open : ""}`}
          style={{
            ...(props.isHighLightInfo && {
              border: `1px solid ${props.isHighLightInfo}`,
              borderRadius: "4px",
            }),
          }}>
          <div
            className={`df aic ${styles.selectedValContainer} ${selectedValues.length === 0 ? styles.empty : ""
              }`}
          >
            {selectedValues.map(val => (
              <p className={`ffmr df aicffmr fs14 ${styles.selectedValue}`} key={val?.label}>
                {val?.label}{" "}
                <span
                  className={`df aic  m-l-5 fs10 cp`}
                  onClick={() => {
                    setSelectedValues(
                      update(selectedValues, {
                        $splice: [[selectedValues.indexOf(val), 1]],
                      })
                    );
                  }}
                >
                  X
                </span>
              </p>
            ))}
          </div>
          <input
            className={`${props.multiple ? styles.multiple : ""} ${selectedValues.length ? styles.hasValues : ""
              }`}
            onFocus={() => setShowList(true)}
            type="text"
            value={searchValue}
            disabled={getListLoading}
            onChange={e => (selectedValues.length === 0 || props.multiple) && setSearchValue(e.target.value)}
          />
          <div
            onClick={() => {
              setShowList(!showList);
              setEditId(undefined);
              setEditValue(undefined);
            }}
            className={`${styles.opener} ${showList ? styles.open : ""}`}
          ></div>
        </div>
        {showList ? (
          <div className={`ffmr fs14 ${styles.listWrpr}`}>
            {list
              .filter(
                val =>
                  val.label.toLowerCase().includes(searchValue.toLowerCase()) &&
                  // !isSelected(val._id) &&
                  val.active === true
              )
              .map(item => (
                <p
                  className={`df aic ${styles.line} ${isSelected(item?._id) ? styles.isSelected : ""
                    }`}
                  key={item._id}
                  onClick={e => {
                    e.stopPropagation();
                    setSearchValue("");

                    if (props.multiple) {
                      let isFound = selectedValues?.find(val => val?._id === item._id) || false;

                      if (isFound) {
                        setSelectedValues(
                          update(selectedValues, {
                            $splice: [[selectedValues.indexOf(isFound), 1]],
                          })
                        );
                      } else {
                        setSelectedValues(update(selectedValues, { $push: [item] }));
                      }
                    } else {
                      setSelectedValues([item]);
                      setShowList(false);
                    }
                  }}
                >
                  {editId === item._id ? (
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      className={`df aic p-l-10 p-r-10 ${styles.editInputWrpr}`}
                    >
                      <input value={editValue} onChange={e => setEditValue(e.target.value)} />
                      <div className={`mla`}>
                        <button
                          className={`${styles.btn}`}
                          onClick={e => {
                            e.stopPropagation();
                            setEditId(undefined);
                            setEditValue(undefined);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className={`m-l-5 ${styles.btn} ${styles.saveBtn}`}
                          disabled={editValue === item.label || loading}
                          onClick={() => setShowConfirmEdit(true)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : undefined}
                  {item.label}

                  {item.isEditable ? (
                    <div className={`df aic mla`}>
                      <div
                        className={`${styles.editIcon} m-r-10`}
                        onClick={e => {
                          e.stopPropagation();
                          setEditId(item._id);
                          setEditValue(item.label);
                        }}
                      >
                        <EditSVG />
                      </div>
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          updateValidation(item._id, { active: false });
                        }}
                      >
                        <HideSVG />
                      </div>
                    </div>
                  ) : undefined}
                </p>
              ))}
            {searchValue &&
              !list.find(
                elem =>
                  elem.label.toLowerCase().trim() === searchValue.toLowerCase().trim() &&
                  elem.active === true
              ) &&
              !props.autoSave ? (<>
                <p onClick={addValue} className={`${styles.addLine} ${loading ? styles.disabled : null}`}>
                  Add "{searchValue}"
                  {loading && (<span className={styles.addLineLoader}><CircularProgress size={15} /></span>)}
                </p>
              </>
            ) : undefined}
          </div>
        ) : undefined}
      </div>
      {showList ? (
        <div
          className={styles.validationOverlay}
          onClick={() => {
            setSearchValue("");
            setShowList(false);
            setEditId(undefined);
            setEditValue(undefined);
          }}
        ></div>
      ) : undefined}

      {showConfirmEdit ? (
        <div className={styles.confirmUpdateWrpr}>
          <div className={styles.confirmUpdateOverlay}></div>
          <div className={styles.confirmUpdate}>
            <p className={`ffmr fs18 tac`}>

              {list.some(function (el) {
                return el.label === editValue;
              }) ? `Updating value ${editValue} already exist` : (
                `Updating the value will also update all other records containing ${list.find(elem => elem._id === editId).label}" to "${editValue}"`
              )}
            </p>
            <div className={`df acc m-t-15 p-b-25`}>
              <button
                onClick={() => {
                  setShowConfirmEdit(false);
                  setEditId(undefined);
                  setEditValue(undefined);
                }}
                className={styles.btn}
              >
                Cancel
              </button>
              <button onClick={updateValue} disabled={list.some((el) => el.label === editValue)} className={`m-l-10 ${styles.btn} ${styles.updateBtn} ${list.some((el) => el.label === editValue) ? styles.disabled : null}`}>
                Update
              </button>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default Validation;
