import * as React from "react";
import { List, ListItem, CircularProgress, Grid, Typography, FormControlLabel, Box } from "@mui/material";
import { Stack } from "@mui/system";
import styles from "./ChartDialogCommon.module.scss";
import CheckboxButton from "../../dashboard/shared/checkboxButton/CheckboxButton";
import { Checkbox } from "@mui/material";

export default function ChartDialogLeftSidebar({
    loading,
    filterData,
    selectedItem,
    title,
    subtitle,
    handleToggle,
    handleToggleAll,
    selectedTab
}) {
    const [selectAll, setSelectAll] = React.useState(false);

    const toggleAll = async (e) => {
        setSelectAll(e.target.checked);
        handleToggleAll(e.target.checked ? "all" : "unSelectAll")
    };

    React.useEffect(() => {
        if (filterData.length === selectedItem.length) {
            setSelectAll(true)
        }
    }, [filterData, selectedItem]);

    return (
        <Grid item xs={1.3} className={styles.leftPanel}>
            <Stack direction={"row"} className={styles.header}>
                <div>{title}</div>
                {subtitle && (
                    <div>
                        <Typography variant="subtitle2">{subtitle}</Typography>
                    </div>
                )}
            </Stack>
            {!loading && filterData.length > 0 && (
                <>
                    <Stack direction={"row"}>
                        <Box sx={{ padding: "12px 20px 0px 15px", ml: "12px" }}>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={toggleAll}
                                    checked={selectAll}
                                />}
                                label={<Typography fontSize={14}>Select / Clear All</Typography>}
                            />
                        </Box>
                    </Stack>
                    <Stack direction={"row"} sx={{
                        mt: "0px",
                        maxHeight: "calc(100vh - 280px)",
                        overflowY: "auto"
                    }}>
                        <List className={styles.itemsWrpr}>
                            {filterData.length > 0 &&
                                filterData.map((item) => {
                                    const labelId = `checkbox-list-dialog-button-label-${item._id}`;
                                    const selected = selectedItem.indexOf(item._id) !== -1;
                                    return (
                                        <ListItem
                                            className={`${styles.sec} ${selected ? styles.selected : null
                                                }`}
                                            id={labelId}
                                        >
                                            <CheckboxButton
                                                isSpacialItem={item?.isSpacialItem}
                                                labelId={labelId}
                                                handleToggle={() => handleToggle(item._id)}
                                                checked={selectedItem.indexOf(item._id) !== -1}
                                                buttonText={`${item.label}`}
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </Stack>
                </>
            )
            }
            {
                loading && (
                    <Stack
                        direction={"row"}
                        sx={{ alignItems: "center", justifyContent: "center", mt: 4 }}
                    >
                        <CircularProgress />
                    </Stack>
                )
            }
        </Grid >
    );
}
