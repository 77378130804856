import { CARD_LABEL_COMMON } from "./common.type"

export const HOSPITAL_CARDS_TYPE = {
    DOCTOR_DATA: 'doctorData',
    DCER_DATA: 'DCERData',
    INSURANCE_DATA: 'insuranceData',
    RETURNS_DATA: 'returnsData',
    NINETY_DAYS_DATA: 'ninetyDaysData',
    NURSE_DATA: 'nurseData',
    FLOORS_DATA: 'floorsData',
    DAYS_DATA: 'daysData',
    DX_DATA: 'dxData',
    SHIFT_DATA: 'shiftData',
    PERMISSION: 'permission',
    HOSPITAL_DATA: 'hospitalData',
    HOSPITALIZATIONS: 'hospitalizations',
    UNPLANNED: "unplannedHospitalTransfer",
    PLANNED: "plannedHospitalTransfer",
    TOTAL: "total",
}



export const HOSPITAL_CARDS_LABELS = {
    hospitalizationsReHospitalizations: 'New Hospitalizations & Re-Hospitalizations',
    hospitalizations: 'New / Re Hospitalizations',
    DCERData: 'DC / ER',
    insuranceData: CARD_LABEL_COMMON.perInsurance,
    returnsData: "Returned / Didn't Return",
    ninetyDaysData: '90 Days Analysis',
    floorsData: CARD_LABEL_COMMON.perFloor,
    doctorData: CARD_LABEL_COMMON.perDoctor,
    daysData: CARD_LABEL_COMMON.perDay,
    dxData: 'Per Diagnosis',
    shiftData: 'Per Shift',
    nurseData: 'Per Nurse',
    hospitalData: 'Hospitals transferred to',
    unplannedHospitalTransfer: "Unplanned Transfers",
    plannedHospitalTransfer: "Planned Transfers",
    total: "Total"
}



