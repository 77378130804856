/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, CircularProgress, Grid } from "@mui/material";
import _, { uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import Card from "../components/dashboard/card/Card";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import DayCardList from "../components/dashboard/hospital/dayCardList/DayCardList";
import DiagnosticsCardList from "../components/dashboard/hospital/diagnosticsCardList/DiagnosticsCardList";
import DischargeEmergencyList from "../components/dashboard/hospital/dischargeEmergencyList/DischargeEmergencyList";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import HospitalCardDetailsDialog from "../components/dashboard/hospital/HospitalCardDetailsDialog";
import HospitalHeader from "../components/dashboard/hospital/HospitalHeader";
import HospitalizationsList from "../components/dashboard/hospital/hospitalizationsList/HospitalizationsList";
import HospitalSlider from "../components/dashboard/hospital/hospitalSlider/HospitalSlider";
import NinetyDaysAnalysis from "../components/dashboard/hospital/ninetyDaysAnalysis/NinetyDaysAnalysis";
import ReturnCardList from "../components/dashboard/hospital/returnCardList/ReturnCardList";
import ShiftCard from "../components/dashboard/hospital/shiftCard/ShiftCard";
import TotalCountCircle from "../components/dashboard/hospital/totalCountCircle/TotalCountCircle";
import TotalTransferCard from "../components/dashboard/hospital/totalTransferCard/TotalTransferCard";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import SelectableCard from "../components/dashboard/selectableCard/SelectableCard";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import Page from "../components/Page";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import { TYPES } from "../data/hospital.data";
import { getAllPatientCount } from "../services/hospital.service";
import {
	openDetailsDialog as openDetailsDialogComparison,
	setCardFilter as setCardFilterComparison,
	setFilterListDataHospital as setFilterListDataComparison,
	setPatientList as setPatientListComparison,
} from "../store/reducers/comparisonReducers/hospitalComparison.slice";
import { setCardExpandState } from "../store/reducers/dataCardsExpand.slice";
import {
	DEFAULT_FILTER_PAGE,
	DEFAULT_HOSPITAL_LIST,
	openDetailsDialog,
	setCardFilter,
	setDynamicCards,
	setIsSpecialComparison,
	setLockTotal,
	setLockedTotalBy,
	setMainTotal,
	setResetFilter,
	setTransferType,
	setLoading as setHospitalLoading
} from "../store/reducers/hospital.slice";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { HOSPITAL_CARDS_LABELS, HOSPITAL_CARDS_TYPE } from "../types/hospital.type";
import { PAGE_TYPE } from "../types/pages.type";
import {
	calcProPercentsBasedOnFilterAndDays,
	getDynamicPercentageBy,
	getPercentageByTotal,
	isOnlyHospitalTabAccess,
	itemPercentage,
	priorityFilter,
	projectionPerMonth,
	updateChartFilterByPriority,
	updateCurrentFilterListData,
} from "../utilis/common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import { updateFilterListData } from "../utilis/hospital-common";
import tooltipsInfo from "../utilis/tooltipContents";
import { setHospitalCards } from "../store/reducers/customCard.slice";
import { HOSPITAL_CUSTOM_CARD } from "../types/custom-card-type";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";
import useSetParamToURL from "../components/hooks/useSetParamToURL";
import NoteContainer from "../components/shared/NoteContainer";
import DynamicDataCardList from "../components/dashboard/hospital/DynamicDataCardList/DynamicDataCardList";
import { getQuestionsData } from "../services/dynamic-data-tab.service";
import useAdditionalData from "../components/hooks/useAdditionalData";
import { useDashboard } from "../context/DashboardContext";

const HospitalPage = () => {
	const dispatch = useDispatch();
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const isOnlyHospitalDashboard = useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const [patientList, setPatientList] = useState(DEFAULT_HOSPITAL_LIST);
	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		cardFilter: cardFilterComparison,
		transferType: transferTypeComparison,
		patientList: patientListComparison,
		filterTotal: filterTotalComparison,
		facilityPercentage: facilityPercentageComparison,
		lockedTotal: lockedTotalComparison,
		lockedByFacility: lockedByFacilityComparison,
		isCensusTotalLocked: isCensusTotalLockedComparison,
	} = useSelector((state) => state.hospitalComparison);
	
	const [filterListData, setFilterListData] = useState(DEFAULT_FILTER_PAGE);
	const {
		filter,
		dbData,
		lockedTotalBy,
		transferType,
		cardFilter,
		isResetFilter,
		detailsDialog,
		mainNumPercentage,
		lockedTotal,
		facilityPercentage,
		lockedByFacility,
		isCensusTotalLocked,
		specialComparisonSet,
		filterTotal,
		loading: hospitalLoading,
		dynamicCards
	} = useSelector((state) => state.hospital);
	const { percentageBy, isProjectionPopup } = useSelector((state) => state.common);
	const HOSPITAL_CUSTOM_CARD_DATA = useAdditionalData(dynamicCards, HOSPITAL_CUSTOM_CARD);
	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy, isOnlyHospitalDashboard);
	}, [percentageBy, dbData, isOnlyHospitalDashboard]);

	const { rangesSet, comparingAgainstDifferentFacility } = useSelector(
		(state) => state.comparison
	);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");
	const fetchRequestRef = useRef(null);
	const fetchRequestRefComparison = useRef(null);
	const { hospitalCards } = useSelector((state) => state.customCard);
	const { saveCustomCardFilter } = useCustomCardFilter();
	const activeRequestRef = useRef(null);

	// loading states
	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(false);

	const getDynamicTab = useCallback(async (page) => {
		let query = { page, forType: 'transfer', forTransferType: ["hospitalTransfer"], isCustom: true };
		const res = await getQuestionsData(query);
		if (res && res.length > 0) {
			dispatch(setDynamicCards(res));
		}
	}, [dispatch]); // Add dispatch to the dependency array

	const { updateDashboard } = useDashboard();

	useEffect(() => {
		if (dbData) {
			updateDashboard({
				dbData,
				page: PAGE_TYPE.HOSPITAL,
				dbDataComparison
			});
		}
	}, [dbData, updateDashboard, dbDataComparison]);

	useEffect(() => {
		if (dynamicCards?.length === 0) {
			getDynamicTab(PAGE_TYPE.HOSPITAL);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dynamicCards]);

	const handleOnclickCard = useCallback(
		(value, total, mainTotal = null) => {
			batch(() => {
				if (transferType && transferType === value) {
					dispatch(setTransferType(null));
					dispatch(setMainTotal(mainTotal));
				} else {
					dispatch(setTransferType(value));
					dispatch(setMainTotal(total));
				}
			});
		},
		[dispatch, transferType]
	);

	const getAllDataCount = async (filter, forComparison) => {
		setFilterListData({ ...DEFAULT_FILTER_PAGE });
		setPatientList([]);
		setLoading(true);
		const currentRequest = uniqueId();
		if (forComparison) {
			fetchRequestRefComparison.current = currentRequest;
		} else {
			fetchRequestRef.current = currentRequest;
		}
		activeRequestRef.current = currentRequest;
		// const filterData = _.omit(filter, ["rangeFilter"]);

		try {
			const [mainResult, comparisonResult] = await Promise.all([
				getAllPatientCount(filter, false, 'main'),
				rangesSet ? getAllPatientCount({ ...filterComparison, facilityIds: activeFacilitiesComparison }, true, 'comparison') : null
			]);

			if (activeRequestRef.current !== currentRequest) return;

			if (mainResult && mainResult.list) {
				setPatientList(mainResult);
				await updateTotalToDBFilter(mainResult);
			}

			if (comparisonResult && comparisonResult.list) {
				dispatch(setPatientListComparison(comparisonResult));
				await updateTotalToDBFilterComparison(comparisonResult);
			} else {
				dispatch(setPatientListComparison([]));
			}
		} catch (e) {
			if (activeRequestRef.current !== currentRequest) return;

			if (e?.message !== "canceled") {
				setLoading(false);

				if (hospitalLoading) {
					dispatch(setHospitalLoading(false));
				}
			}
		} finally {
			if (activeRequestRef.current === currentRequest) {
				setLoading(false);

				if (hospitalLoading) {
					dispatch(setHospitalLoading(false));
				}
			}
		}
	};

	const { setPathWithParams } = useSetParamToURL();

	useEffect(() => {
		const handlePopState = () => {
			// When back button is pressed, redirect to the desired page
			setPathWithParams("/");
		};
		window.addEventListener('popstate', handlePopState);
		// Cleanup: remove event listener when effect is cleaned up or `isOnlyHospitalDashboard` changes
		return () => {
			window.removeEventListener('popstate', handlePopState);
		};
	}, [setPathWithParams, isOnlyHospitalDashboard]);

	useEffect(() => {
		async function fetchMyAPI(filter) {
			batch(() => {
				dispatch(setLockTotal(null));
				dispatch(setLockedTotalBy(null));
			});
			// const rangeFilter = filter?.defaultComparison?.comparisonFilter ? filter.defaultComparison?.comparisonFilter : null;
			// const filterData = _.omit(filter, ["defaultComparison"]);
			await getAllDataCount(filter);
			// if (rangeFilter) {
			// 	dispatch(setManuallyClickComparison({ range: rangeFilter, isDefault: true }));
			// 	// dispatch(setComparisonRanges(rangeFilter));
			// 	// dispatch(setRefetchComparison(true));
			// 	// await getAllDataCount(rangeFilter, true);
			// }
		}
		if (isDateUpdated && filter && filter.startDate && activeFacilityLocalStorage && activeFacilities.length > 0) {
			fetchMyAPI({ ...filter, facilityIds: activeFacilities });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		activeFacilityLocalStorage,
		filter,
	]);

	useEffect(() => {

		async function fetchMyAPI(filter) {
			batch(() => {
				dispatch(setLockTotal(null));
				dispatch(setLockedTotalBy(null));
			});
			const filterData = _.omit(filter, ["defaultComparison"]);
			await getAllDataCount(filterData, true);
		}
		if (isDateUpdated && filter && filter.startDate && activeFacilityLocalStorage && activeFacilities.length > 0) {
			fetchMyAPI({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterComparison]);

	useEffect(() => {
		if (isResetFilter) {
			updateTotalToDBFilter(patientList);
			dispatch(setResetFilter(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilter]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainNumPercentage, lockedTotal, projectionDays, percentageAgainst]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison, transferType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferType, projectionDays]);

	const updateTotalToDBFilter = async (res) => {
		const latestListData = await updateFilterListData(cardFilter, transferType, res, cardFilter.priorityData, false);
		setFilterListData((prevState) => ({
			...prevState,
			...latestListData,
		}));
	};

	const updateTotalToDBFilterComparison = async (res, transferType) => {
		const latestListData = await updateFilterListData(
			cardFilterComparison,
			transferType,
			res,
			cardFilterComparison.priorityData,
			true
		);
		dispatch(setFilterListDataComparison({ ...filterListDataComparison, ...latestListData }));
	};

	const handleToggle = useCallback(
		async ({ item, type: typeData, setSpecialNumberFlag = true, cardTitle = null, question, isSpacialItem = false }) => {
			if (item) {
				let type = typeData;
				if (isSpacialItem) {
					type = `${type}_spacial`;
				}
				const value = item._id;
				const patientIds = item?.patientIds ?? [];
				const filtersItem = { ...cardFilter };
				if (filtersItem[type] === undefined) {
					filtersItem[type] = [];
				}
				const filterData = filtersItem[type];
				const currentIndex = filterData.indexOf(value);
				const newChecked = filterData ? [...filterData] : [];
				let isRemoveIds = false
				if (currentIndex === -1) {
					newChecked.push(value);
				} else {
					isRemoveIds = question?.isCustom ? true : false;
					newChecked.splice(currentIndex, 1);
				}
				if (!isSpacialItem) {
					const [priorityData, priorityDataComparison] = await Promise.all([
						priorityFilter(
							cardFilter.priorityData,
							type,
							newChecked.length,
							cardTitle,
							question,
							patientIds,
							isRemoveIds
						),
						rangesSet
							? priorityFilter(
								cardFilterComparison?.priorityData,
								type,
								newChecked.length,
								cardTitle,
								question,
								patientIds,
								isRemoveIds
							)
							: undefined,
					]);

					batch(() => {
						dispatch(setCardFilter({ [type]: newChecked, priorityData: priorityData }));
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));

						if (specialComparisonSet && !setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(false));
						} else if (!specialComparisonSet && !setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(true));
						}
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
					});

					const [latestListData, latestListDataComparison] = await Promise.all([
						updateFilterListData({ ...cardFilter, [type]: newChecked }, transferType, patientList, priorityData, false),
						rangesSet
							? updateFilterListData(
								{ ...cardFilterComparison, [type]: newChecked },
								transferType,
								patientListComparison,
								priorityDataComparison,
								true
							)
							: undefined,
					]);

					batch(async () => {
						setFilterListData((prevState) => {
							return { ...prevState, ...latestListData };
						});
						if (rangesSet) {
							const updatedFilterListData = await updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
							await dispatch(setFilterListDataComparison({ ...filterListDataComparison, ...updatedFilterListData }));
						}
					});
				} else {
					batch(() => {
						dispatch(setCardFilter({ [type]: newChecked }));
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked }));

						if (specialComparisonSet && !setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(false));
						} else if (!specialComparisonSet && !setSpecialNumberFlag) {
							dispatch(setIsSpecialComparison(true));
						}
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked }));
					});
				}
			}
		},
		[
			cardFilter,
			rangesSet,
			cardFilterComparison,
			dispatch,
			specialComparisonSet,
			transferType,
			patientList,
			patientListComparison,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [priorityData, priorityDataComparison] = await Promise.all([
			priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
			rangesSet ? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle) : undefined,
		]);
		batch(() => {
			dispatch(setCardFilter({ [type]: newChecked, priorityData: priorityData }));
			rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));

			rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
		});
		const [latestListData, latestListDataComparison] = await Promise.all([
			updateFilterListData({ ...cardFilter, [type]: newChecked }, transferType, patientList, priorityData, false),
			rangesSet
				? updateFilterListData(
					{ ...cardFilterComparison, [type]: newChecked },
					transferType,
					patientListComparison,
					priorityDataComparison,
					true
				)
				: undefined,
		]);

		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
		if (rangesSet) {
			const updatedFilterListData = await updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
			await dispatch(setFilterListDataComparison(updatedFilterListData));
		}
	};

	// * added comparison
	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false, defaultTab = null, question = null) => {

			let latestCardFilter = await updateChartFilterByPriority(cardFilter, type);

			let filterSelected =
				(cardFilter.priorityData.length > 0 && cardFilter.priorityData[0]["type"] !== type) ||
				transferType != null;
			let data = [];
			if (
				type === HOSPITAL_CARDS_TYPE.UNPLANNED ||
				type === HOSPITAL_CARDS_TYPE.PLANNED ||
				type === HOSPITAL_CARDS_TYPE.TOTAL
			) {
				filterSelected = false;
				data = [];
			} else {
				data = filterListData[type];
			}

			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				selectedFilterData: cardFilter[type] || [],
				type,
				filter,
				relation,
				transferType,
				filterSelected,
				facilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				filterListData,
				defaultTab,
				question
			};
			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
					dbData,
				})
			);

			// COMPARISON ANALOG ------>
			let latestCardFilterComparison = await updateChartFilterByPriority(cardFilterComparison, type);
			let filterSelectedComparison =
				(cardFilterComparison?.priorityData.length > 0 &&
					cardFilterComparison?.priorityData[0]["type"] !== type) ||
				transferType != null;
			let dataComparison = [];
			if (
				type === HOSPITAL_CARDS_TYPE.UNPLANNED ||
				type === HOSPITAL_CARDS_TYPE.PLANNED ||
				type === HOSPITAL_CARDS_TYPE.TOTAL
			) {
				filterSelectedComparison = false;
				dataComparison = [];
			} else {
				dataComparison = filterListDataComparison ? filterListDataComparison[type] : [];
			}
			const filtersComparison = {
				facilityId: activeFacilitiesComparison[0],
				facilityIds: activeFacilitiesComparison,
				cardFilter: latestCardFilterComparison,
				selectedFilterData: cardFilterComparison?.[type] || [],
				type,
				filter: filterComparison,
				relation,
				transferType: transferTypeComparison,
				filterSelected: filterSelectedComparison,
				facilityPercentage: facilityPercentageComparison,
				lockedByFacility: lockedByFacilityComparison,
				lockedTotal: lockedTotalComparison,
				isCensusTotalLocked: isCensusTotalLockedComparison,
			};
			dispatch(
				openDetailsDialogComparison({
					isOpen: true,
					title,
					type,
					data: dataComparison,
					filters: filtersComparison,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			filterListData,
			cardFilter,
			filter,
			filterListDataComparison,
			cardFilterComparison,
			filterComparison,
			filterListDataComparison,
		]
	);

	const handleClickTotal = useCallback(
		(total) => {
			batch(() => {
				dispatch(setTransferType(transferType === TYPES.ALL ? null : TYPES.ALL));
				dispatch(setMainTotal(total));
			});
		},
		[dispatch, transferType]
	);

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.priorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const handleOnClickReport = async (type, title, isTotalCard = false, automaticallyReport = null, customData = null) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.HOSPITAL,
				filterListData,
				isTotalCard,
				automaticallyReport: { ...automaticallyReport, ...customData },
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.HOSPITAL,
				filterListData,
				isTotalCard,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.HOSPITAL,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				graphData: { ...graphDataUpdated, detailsDialog },
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.HOSPITAL,
				filterListData,
				isTotalCard: false,
				graphData,
				isGraphReport: true,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...hospitalCards];
		if (key === "selectAll") {
			cards = _.map(HOSPITAL_CUSTOM_CARD_DATA, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setHospitalCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.HOSPITAL, cards })
	}, [hospitalCards, dispatch, saveCustomCardFilter, HOSPITAL_CUSTOM_CARD_DATA]);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	return (
		<Page title="Dashboard: Hospital">
			{detailsDialog.isOpen && (
				<HospitalCardDetailsDialog
					handleOnClickReport={handleOnClickReportGraph}
					handleGenerateExcelReport={downloadExcel}
				/>
			)}

			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`} id="headerOffset">
					<div className={`df aic ${styles.aoWrapper}`}>
						<HospitalHeader
							filterListData={filterListData}
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							isOnlyHospitalDashboard={isOnlyHospitalDashboard}
							filter={filter}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							dynamicCards={dynamicCards}
						/>
					</div>

				</div>
				{(loading || hospitalLoading) ?
					<div style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "90vh"
					}}>
						<CircularProgress />
					</div> : (
						<NoteContainer page={PAGE_TYPE.HOSPITAL}>
							<Grid container justifyContent={"center"} sx={{ mt: "30px" }}>
								{rangesSet && <ColorInfoBox style={{ right: "3%" }} loading={loading || loadingComparison} />}
								<Grid item xs={12} position={"relative"}>
									<AddCardDrawer
										listData={isOnlyHospitalDashboard ? HOSPITAL_CUSTOM_CARD_DATA?.filter(ele => ele.value !== HOSPITAL_CARDS_TYPE.RETURNS_DATA) : HOSPITAL_CUSTOM_CARD_DATA}
										selectedCards={hospitalCards}
										handleAddCustomCard={handleAddCustomCard}
										scrollTop={scrollPosition?.scrollTop}
									/>
									<TotalCountCircle
										loading={loading || loadingComparison}
										page={PAGE_TYPE.HOSPITAL}
										totalTitle={`Total Hospital Transfers`}
										projectTitle={percentageBy}
										selected={transferType === TYPES.ALL}
										filter={filter}
										filterComparison={filterComparison}
										handleClickTotal={() => {
											handleClickTotal(calcProPercentsBasedOnFilterAndDays(dbData.total, filter, projectionDays));
										}}
										total={dbData.total}
										totalComparison={dbDataComparison?.total}
										totalPercentage={itemPercentage(dbData.total, percentageAgainst, "number")}
										projectTotal={projectionPerMonth(dbData.total, filter, projectionDays)}
										projectTotalComparison={projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays)}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.total, filter, projectionDays),
											percentageAgainst,
											"number"
										)}
										tooltipContent={tooltipsInfo.hospitalPage.totalHospitalization}
										handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.TOTAL, null, true, defaultTab)}
										totalComparisonPercentage={itemPercentage(
											dbDataComparison?.total,
											getPercentageByTotal(dbDataComparison),
											"number"
										)}
										totalProjectionComparisonPercentage={itemPercentage(
											projectionPerMonth(dbDataComparison?.total, filterComparison),
											getPercentageByTotal(dbDataComparison),
											"number"
										)}
										handleOnClickReport={(title, otherData) => handleOnClickReport(HOSPITAL_CARDS_TYPE.TOTAL, title, true, otherData)}
										projectionDays={projectionDays}
										totalCardType={"total"}
									/>
								</Grid>
							</Grid>
							<Box sx={{ p: 2 }}>
								{/* Transfer Type Selection */}
								<Grid container spacing={2} direction={"row"} sx={{ mb: "80px" }}>
									<Grid item md={6} xs={12}>
										<SelectableCard
											pageType={PAGE_TYPE.HOSPITAL}
											loading={loading || loadingComparison}
											title="Unplanned Transfers"
											disabled={transferType === TYPES.PLANNED}
											selected={transferType === TYPES.UNPLANNED}
											handleOnclickCard={() =>
												handleOnclickCard(TYPES.UNPLANNED, dbData.unplannedHospitalTransfer, dbData.total)
											}
											handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.UNPLANNED, null, true, defaultTab)}
											tooltipContent={tooltipsInfo.hospitalPage.unplannedHosspitalTransfers}
											handleOnClickReport={(title, otherData) =>
												handleOnClickReport(HOSPITAL_CARDS_TYPE.UNPLANNED, title, true, otherData)
											}
										>
											<TotalTransferCard
												loading={loading || loadingComparison}
												page={PAGE_TYPE.HOSPITAL}
												title={"Total Hospital Transfers"}
												total={dbData.unplannedHospitalTransfer}
												totalComparison={dbDataComparison?.unplannedHospitalTransfer}
												totalPercentage={itemPercentage(dbData.unplannedHospitalTransfer, percentageAgainst, "number")}
												totalProjection={projectionPerMonth(dbData.unplannedHospitalTransfer, filter, projectionDays)}
												totalProjectionComparison={projectionPerMonth(
													dbDataComparison?.unplannedHospitalTransfer,
													filterComparison,
													projectionDays
												)}
												totalProjectionPercentage={itemPercentage(
													projectionPerMonth(dbData.unplannedHospitalTransfer, filter, projectionDays),
													percentageAgainst,
													"number"
												)}
												filter={filter}
												filterComparison={filterComparison}
												secondaryTitle={percentageBy}
												secondaryText={""}
												totalComparisonPercentage={itemPercentage(
													dbDataComparison?.unplannedHospitalTransfer,
													getPercentageByTotal(dbDataComparison),
													"number"
												)}
												totalProjectionComparisonPercentage={itemPercentage(
													projectionPerMonth(dbDataComparison?.unplannedHospitalTransfer, filterComparison, projectionDays),
													getPercentageByTotal(dbDataComparison),
													"number"
												)}
												projectionDays={projectionDays}
											/>
										</SelectableCard>
									</Grid>
									<Grid item md={6} xs={12}>
										<SelectableCard
											pageType={PAGE_TYPE.HOSPITAL}
											flex="1"
											loading={loading || loadingComparison}
											title="Planned Transfers"
											disabled={transferType === TYPES.UNPLANNED}
											selected={transferType === TYPES.PLANNED}
											handleOnclickCard={() => handleOnclickCard(TYPES.PLANNED, dbData.plannedHospitalTransfer, dbData.total)}
											handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.PLANNED, null, true, defaultTab)}
											tooltipContent={tooltipsInfo.hospitalPage.plannedHospitalTransfers}
											handleOnClickReport={(title, otherData) =>
												handleOnClickReport(HOSPITAL_CARDS_TYPE.plannedHospitalTransfer, title, true, otherData)
											}
										>
											<TotalTransferCard
												loading={loading || loadingComparison}
												page={PAGE_TYPE.HOSPITAL}
												filter={filter}
												filterComparison={filterComparison}
												title={"Total Hospital Transfers"}
												total={dbData.plannedHospitalTransfer}
												totalComparison={dbDataComparison?.plannedHospitalTransfer}
												totalPercentage={itemPercentage(dbData.plannedHospitalTransfer, percentageAgainst, "number")}
												totalProjection={projectionPerMonth(dbData.plannedHospitalTransfer, filter, projectionDays)}
												totalProjectionComparison={projectionPerMonth(
													dbDataComparison?.plannedHospitalTransfer,
													filterComparison,
													projectionDays
												)}
												totalProjectionPercentage={itemPercentage(
													projectionPerMonth(dbData.plannedHospitalTransfer, filter, projectionDays),
													percentageAgainst,
													"number"
												)}
												secondaryTitle={percentageBy}
												secondaryText={""}
												totalComparisonPercentage={itemPercentage(
													dbDataComparison?.plannedHospitalTransfer,
													getPercentageByTotal(dbDataComparison),
													"number"
												)}
												totalProjectionComparisonPercentage={itemPercentage(
													projectionPerMonth(dbDataComparison?.plannedHospitalTransfer, filterComparison, projectionDays),
													getPercentageByTotal(dbDataComparison),
													"number"
												)}
												projectionDays={projectionDays}
											/>
										</SelectableCard>
									</Grid>

									<ShadowLineWithText data={hospitalCards} />

									{/* End of Transfer Type Selection */}

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS)}>
										<Grid item lg={4} md={6} xs={12}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="New Hospitalizations &amp; Re-Hospitalizations"
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS, null, false, defaultTab)}
												tooltipContent={tooltipsInfo.hospitalPage.newHospitalizationANDReHospitalization}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS)}
												cardType={HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS, title, false, otherData)
												}
											>
												<HospitalizationsList
													cardTitle="New Hospitalizations &amp; Re-Hospitalizations"
													handleToggle={handleToggle}
													selectedItem={cardFilter.hospitalizations}
													data={filterListData.hospitalizations}
													filter={filter}
													filterComparison={filterComparison}
													dataComparison={filterListDataComparison?.hospitalizations}
													page={PAGE_TYPE.HOSPITAL}
													loading={loading || loadingComparison}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.DCER_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.DCER_DATA)}>
										<Grid item lg={4} md={6} xs={12}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="DC / ER"
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.DCER_DATA, null, false, defaultTab)}
												tooltipContent={tooltipsInfo.hospitalPage["dc/er"]}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DCER_DATA)}
												cardType={HOSPITAL_CARDS_TYPE.DCER_DATA}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.DCER_DATA, title, false, otherData)
												}
											>
												<DischargeEmergencyList
													cardTitle="DC / ER"
													handleToggle={handleToggle}
													selectedItem={cardFilter.DCERData}
													data={filterListData.DCERData}
													loading={loading || loadingComparison}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													dataComparison={filterListDataComparison?.DCERData}
													type={HOSPITAL_CARDS_TYPE.DCER_DATA}
													page={PAGE_TYPE.HOSPITAL}
													filter={filter}
													filterComparison={filterComparison}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DCER_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.INSURANCE_DATA)}>
										<Grid item lg={4} md={6} xs={12}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title={HOSPITAL_CARDS_LABELS.insuranceData}
												itemAlign={"ais"}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, HOSPITAL_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.INSURANCE, false, defaultTab)
												}
												tooltipContent={tooltipsInfo.hospitalPage.perInsurance}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.INSURANCE_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.INSURANCE_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.insuranceData}
												data={filterListData.insuranceData}
												type={HOSPITAL_CARDS_TYPE.INSURANCE_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={HOSPITAL_CARDS_TYPE.INSURANCE_DATA}
											>
												<InsuranceCard
													cardTitle={HOSPITAL_CARDS_LABELS.insuranceData}
													data={filterListData.insuranceData}
													dataComparison={filterListDataComparison?.insuranceData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													selectedItem={cardFilter.insuranceData}
													type={HOSPITAL_CARDS_TYPE.INSURANCE_DATA}
													page={PAGE_TYPE.HOSPITAL}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													height={300}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.INSURANCE_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.RETURNS_DATA}_scroll`} checked={(!isOnlyHospitalDashboard && _.includes(hospitalCards, HOSPITAL_CARDS_TYPE.RETURNS_DATA))}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="Returned / Didn't Return"
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.RETURNS_DATA, null, false, defaultTab)}
												tooltipContent={tooltipsInfo.hospitalPage["returned/didntReturn"]}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.RETURNS_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.RETURNS_DATA, title, false, otherData)
												}
												cardType={HOSPITAL_CARDS_TYPE.RETURNS_DATA}
											>
												<ReturnCardList
													cardTitle="Returned / Didn't Return"
													handleToggle={handleToggle}
													selectedItem={cardFilter.returnsData}
													transfers={dbData.total}
													transfersComparison={dbDataComparison?.total}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													type={HOSPITAL_CARDS_TYPE.RETURNS_DATA}
													data={filterListData.returnsData}
													comparisonData={filterListDataComparison?.returnsData}
													page={PAGE_TYPE.HOSPITAL}
													averageCensus={percentageAgainst}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.RETURNS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA)}>
										<Grid item xs={8} md={12} lg={!isOnlyHospitalDashboard ? 8 : 12}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="90 Days Analysis"
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA, null, false, defaultTab)}
												tooltipContent={tooltipsInfo.hospitalPage["90DaysAnalysis"]}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA, title, false, otherData)
												}
												{...(isOnlyHospitalDashboard && { style: { height: "100%", paddingBottom: "25px" } })}
												{...(isOnlyHospitalDashboard && { styleContent: { marginTop: "20px" } })}
												cardType={HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA}
											>
												<NinetyDaysAnalysis
													cardTitle="90 Days Analysis"
													handleToggle={handleToggle}
													isOnlyHospitalDashboard={isOnlyHospitalDashboard}
													loading={loading || loadingComparison}
													selectedItem={cardFilter.ninetyDaysData}
													type={HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA}
													data={filterListData.ninetyDaysData}
													comparisonData={filterListDataComparison?.ninetyDaysData}
													page={PAGE_TYPE.HOSPITAL}
													filterListData={filterListData}
													filterListDataComparison={filterListDataComparison}
													filter={filter}
													filterComparison={filterComparison}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													height={"100%"}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.FLOORS_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title={HOSPITAL_CARDS_LABELS.floorsData}
												// minimizable={true}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, HOSPITAL_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.FLOORS_DATA}
														data={filterListData.floorsData}
														selectedItem={cardFilter.floorsData}
													/>
												}
												tooltipContent={tooltipsInfo.hospitalPage.perFloor}
												isMinimized={true}
												// setIsMinimized={(newState) =>
												// 	dispatch(
												// 		setCardExpandState({
												// 			page: "hospitalPage",
												// 			card: "floor",
												// 			state: newState,
												// 		})
												// 	)
												// }
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.FLOORS_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.FLOORS_DATA, title, false, otherData)
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												selectedItems={cardFilter.floorsData}
												data={filterListData.floorsData}
												type={HOSPITAL_CARDS_TYPE.FLOORS_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={HOSPITAL_CARDS_TYPE.FLOORS_DATA}
											>
												<FloorCardList
													cardTitle={HOSPITAL_CARDS_LABELS.floorsData}
													data={filterListData.floorsData}
													dataComparison={filterListDataComparison?.floorsData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													loading={loading || loadingComparison}
													selectedItem={cardFilter.floorsData}
													type={HOSPITAL_CARDS_TYPE.FLOORS_DATA}
													page={PAGE_TYPE.HOSPITAL}
													filter={filter}
													filterComparison={filterComparison}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.FLOORS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.DOCTOR_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title={HOSPITAL_CARDS_LABELS.doctorData}
												// minimizable={true}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, HOSPITAL_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR, false, defaultTab)
												}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DOCTOR_DATA)}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.DOCTOR_DATA}
														data={filterListData.doctorData}
														selectedItem={cardFilter.doctorData}
													/>
												}
												tooltipContent={tooltipsInfo.hospitalPage.perDoctor}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "hospitalPage",
															card: "doctor",
															state: newState,
														})
													)
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.DOCTOR_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.doctorData}
												data={filterListData.doctorData}
												type={HOSPITAL_CARDS_TYPE.DOCTOR_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={HOSPITAL_CARDS_TYPE.DOCTOR_DATA}
											>
												<DoctorCard
													cardTitle={HOSPITAL_CARDS_LABELS.doctorData}
													data={filterListData.doctorData}
													comparisonData={filterListDataComparison?.doctorData}
													minimizable={true}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.doctorData}
													type={HOSPITAL_CARDS_TYPE.DOCTOR_DATA}
													page={PAGE_TYPE.HOSPITAL}
													loading={loading || loadingComparison}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													filter={filter}
													filterComparison={filterComparison}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DOCTOR_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.DAYS_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.DAYS_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title={HOSPITAL_CARDS_LABELS.daysData}
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DAYS_DATA)}
												// minimizable={true}
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.DAYS_DATA, null, false, defaultTab)}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.DAYS_DATA}
														data={filterListData.daysData}
														selectedItem={cardFilter.daysData}
													/>
												}
												tooltipContent={tooltipsInfo.hospitalPage.perDayOfTheWeek}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "hospitalPage",
															card: "day",
															state: newState,
														})
													)
												}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.DAYS_DATA, title, false, otherData)
												}
												cardType={HOSPITAL_CARDS_TYPE.DAYS_DATA}
											>
												<DayCardList
													cardTitle={HOSPITAL_CARDS_LABELS.daysData}
													data={filterListData.daysData}
													dataComparison={filterListDataComparison?.daysData}
													handleToggle={handleToggle}
													selectedItem={cardFilter.daysData}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													type={HOSPITAL_CARDS_TYPE.DAYS_DATA}
													page={PAGE_TYPE.HOSPITAL}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DAYS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.DX_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.DX_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="Per Diagnosis"
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DX_DATA)}
												//secondaryTitle="* Only showing Unplanned Hospital transfers"
												// minimizable={true}
												//isDisabled={transferType === TYPES.PLANNED}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, HOSPITAL_CARDS_TYPE.DX_DATA, PATIENT_RELATION.DX, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.DX_DATA}
														data={filterListData.dxData}
														selectedItem={cardFilter.dxData}
													/>
												}
												tooltipContent={tooltipsInfo.hospitalPage.perDiagnosis}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "hospitalPage",
															card: "diagnosis",
															state: newState,
														})
													)
												}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.DX_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.dxData}
												data={filterListData.dxData}
												type={HOSPITAL_CARDS_TYPE.DX_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={HOSPITAL_CARDS_TYPE.DX_DATA}
											>
												<DiagnosticsCardList
													cardTitle="Per Diagnosis"
													data={filterListData.dxData}
													dataComparison={filterListDataComparison?.dxData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.dxData}
													type={HOSPITAL_CARDS_TYPE.DX_DATA}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													page={PAGE_TYPE.HOSPITAL}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.DX_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.SHIFT_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.SHIFT_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="Per Shift"
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.SHIFT_DATA)}
												//secondaryTitle="* Only showing Unplanned Hospital transfers"
												// minimizable={true}
												//isDisabled={transferType === TYPES.PLANNED}
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, HOSPITAL_CARDS_TYPE.SHIFT_DATA, null, false, defaultTab)}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.SHIFT_DATA}
														data={filterListData.shiftData}
														selectedItem={cardFilter.shiftData}
													/>
												}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "hospitalPage",
															card: "shift",
															state: newState,
														})
													)
												}
												tooltipContent={tooltipsInfo.hospitalPage.perShift}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.SHIFT_DATA, title, false, otherData)
												}
												cardType={HOSPITAL_CARDS_TYPE.SHIFT_DATA}
											>
												<ShiftCard
													cardTitle="Per Shift"
													data={filterListData.shiftData}
													dataComparison={filterListDataComparison?.shiftData}
													handleToggle={handleToggle}
													selectedItem={cardFilter.shiftData}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													type={HOSPITAL_CARDS_TYPE.SHIFT_DATA}
													page={PAGE_TYPE.HOSPITAL}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.SHIFT_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.NURSE_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.NURSE_DATA)}>
										<Grid item xs={12} md={6} lg={4}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="Per Nurse"
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.NURSE_DATA)}
												//secondaryTitle="* Only showing Unplanned Hospital transfers"
												// minimizable={true}
												//isDisabled={transferType === TYPES.PLANNED}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, HOSPITAL_CARDS_TYPE.NURSE_DATA, PATIENT_RELATION.NURSE, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.NURSE_DATA}
														data={filterListData.nurseData}
														selectedItem={cardFilter.nurseData}
													/>
												}
												tooltipContent={tooltipsInfo.hospitalPage.perNurse}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "hospitalPage",
															card: "nurse",
															state: newState,
														})
													)
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.NURSE_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.nurseData}
												data={filterListData.nurseData}
												type={HOSPITAL_CARDS_TYPE.NURSE_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={HOSPITAL_CARDS_TYPE.NURSE_DATA}
											>
												<DoctorCard
													cardTitle="Per Nurse"
													data={filterListData.nurseData}
													comparisonData={filterListDataComparison?.nurseData}
													minimizable={true}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.nurseData}
													type={HOSPITAL_CARDS_TYPE.NURSE_DATA}
													page={PAGE_TYPE.HOSPITAL}
													loading={loading || loadingComparison}
													averageCensus={specialComparisonSet ? filterTotal : getPercentageByTotal(dbData)}
													averageCensusComparison={
														specialComparisonSet ? filterTotalComparison : getPercentageByTotal(dbDataComparison)
													}
													filter={filter}
													filterComparison={filterComparison}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													filterTotal={filterTotal}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.NURSE_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${HOSPITAL_CARDS_TYPE.HOSPITAL_DATA}_scroll`} checked={_.includes(hospitalCards, HOSPITAL_CARDS_TYPE.HOSPITAL_DATA)}>
										<Grid item xs={12}>
											<Card
												pageType={PAGE_TYPE.HOSPITAL}
												loading={loading || loadingComparison}
												title="Hospitals residents transferred to"
												priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.HOSPITAL_DATA)}
												// minimizable={true}
												//isDisabled={transferType === TYPES.PLANNED}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, HOSPITAL_CARDS_TYPE.HOSPITAL_DATA, PATIENT_RELATION.HOSPITAL, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={HOSPITAL_CARDS_TYPE.HOSPITAL_DATA}
														data={filterListData.hospitalData}
														selectedItem={cardFilter.hospitalData}
														labelName="name"
													/>
												}
												tooltipContent={tooltipsInfo.hospitalPage.hospitalResidentsTransferred}
												isSlider={true}
												isMinimized={true}
												setIsMinimized={(newState) =>
													dispatch(
														setCardExpandState({
															page: "hospitalPage",
															card: "hospitalResidentTransferredTo",
															state: newState,
														})
													)
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(HOSPITAL_CARDS_TYPE.HOSPITAL_DATA, title, false, otherData)
												}
												selectedItems={cardFilter.hospitalData}
												data={filterListData.hospitalData}
												type={HOSPITAL_CARDS_TYPE.HOSPITAL_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={HOSPITAL_CARDS_TYPE.HOSPITAL_DATA}
											>
												{filterListData.hospitalData && filterListData.hospitalData.length > 0 && (
													<HospitalSlider
														cardTitle="Hospitals residents transferred to"
														data={filterListData.hospitalData}
														dataComparison={filterListDataComparison?.hospitalData}
														minimizable={true}
														handleToggle={handleToggle}
														handleToggleAll={handleToggleAll}
														filter={filter}
														filterCommaprison={filterComparison}
														selectedItem={cardFilter.hospitalData}
														type={HOSPITAL_CARDS_TYPE.HOSPITAL_DATA}
														isDetailsOpen={true}
														loading={loading || loadingComparison}
														totalTransfers={dbData.total}
														totalTransfersComparison={dbDataComparison?.total}
														comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
														projectionDays={projectionDays}
														filterTotal={filterTotal}
														averageCensus={percentageAgainst}
														isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
														priorityNumber={getPriorityNumber(HOSPITAL_CARDS_TYPE.HOSPITAL_DATA)}
														transferType={transferType}
														lockedTotalBy={lockedTotalBy}
													/>
												)}
											</Card>
										</Grid>
									</CardAnimation>
								</Grid>
								{dynamicCards?.length > 0 ? (
									<Grid container spacing={2} sx={{ mt: "-80px" }} display="flex" direction="row">
										{dynamicCards.map((ele) => (
											<CardAnimation id={`${ele?.accessor}_scroll`} checked={(!isOnlyHospitalDashboard && _.includes(hospitalCards, ele?.accessor))}>
												<Grid item xs={12} sm={6} md={6} lg={4} key={ele?.accessor || Math.random()}>
													<Card
														pageType={PAGE_TYPE.HOSPITAL}
														loading={loading || loadingComparison}
														title={ele?.label || ele?.tableLabel}
														priorityNumber={getPriorityNumber(ele?.accessor)}
														handleOnClickAction={(title, defaultTab) =>
															handleChartDialog(title, ele?.accessor, null, false, defaultTab, { isCustom: true, customQuestionInputType: ele?.customQuestionInputType })
														}
														taggedList={
															<TaggedList
																type={ele?.accessor}
																data={filterListData?.[ele?.accessor] ?? []}
																selectedItem={cardFilter?.[ele?.accessor] ?? []}
															/>
														}
														handleOnClickReport={(title, otherData) =>
															handleOnClickReport(ele?.accessor, title, false, otherData, { questionId: ele?._id, isCustomCard: true })
														}
														selectAllOption={false}
														selectedItems={cardFilter?.[ele?.accessor] ?? []}
														data={filterListData?.[ele?.accessor] ?? []}
														type={ele?.accessor}
														handleToggleAll={handleToggleAll}
														canBeSearched={true}
														cardType={ele?.accessor}
													>
														<DynamicDataCardList
															question={ele}
															cardTitle={ele?.label || ele?.tableLabel}
															data={filterListData?.[ele?.accessor] ?? []}
															dataComparison={filterListDataComparison?.[ele?.accessor] ?? []}
															handleToggle={handleToggle}
															handleToggleAll={handleToggleAll}
															selectedItem={cardFilter?.[ele?.accessor]}
															spacialSelectedItem={cardFilter?.[ele?.accessor + "_spacial"]}
															type={ele?.accessor}
															filter={filter}
															loading={loading || loadingComparison}
															filterComparison={filterComparison}
															page={PAGE_TYPE.HOSPITAL}
															averageCensusComparison={dbDataComparison?.censusAverage}
															averageCensus={percentageAgainst}
															projectionDays={projectionDays}
															isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
															priorityNumber={getPriorityNumber(ele?.accessor)}
															transferType={transferType}
															lockedTotalBy={lockedTotalBy}
														/>
													</Card>
												</Grid>
											</CardAnimation>
										))}
									</Grid>
								) : null}
							</Box>
						</NoteContainer>)
				}
			</div>
		</Page >
	);
};

export default HospitalPage;
