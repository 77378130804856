import { Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { calcProPercentsBasedOnFilterAndDays } from "../../../../utilis/common";
import useDaysCard from "../../../hooks/useDaysCard";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./NinetyDaysAnalysis.module.scss";
import NinetyDaysAnalysisSkeleton from "./NinetyDaysAnalysisSkeleton";
import { PAGE_TYPE } from "../../../../types/pages.type";
import useLoadingToggle from "../../../hooks/useLoadingToggle";
import CheckboxLoader from "../../../shared/checkbox-loader/CheckboxLoader";
import { Box } from "@mui/system";

const defaultRadialData = [
	{
		id: "Total",
		parentId: "total",
		color: "#92C7FF",
		data: [
			{
				x: "f",
				y: 0,
				percentage: 0,
				color: "#92C7FF",
			},
		],
	},
	{
		id: "1 - 7 Days",
		parentId: "a",
		color: "#21B1D0",
		data: [
			{
				x: "a",
				y: 0,
				percentage: 0,
				color: "#21B1D0",
			},
		],
	},
	{
		id: "8 - 14 Days",
		parentId: "b",
		color: "#5195DD",
		data: [
			{
				x: "b",
				y: 0,
				percentage: 0,
				color: "#5195DD",
			},
		],
	},
	{
		id: "15 - 30 Days",
		parentId: "c",
		color: "#4F80FF",
		data: [
			{
				x: "c",
				y: 0,
				percentage: 0,
				color: "#4F80FF",
			},
		],
	},
	{
		id: "31 - 60 Days",
		color: "#6B69DA",
		parentId: "d",
		data: [
			{
				x: "d",
				y: 0,
				percentage: 0,
				color: "#6B69DA",
			},
		],
	},
	{
		id: "61 - 90 Days",
		color: "#1D7CD3",
		parentId: "e",
		data: [
			{
				x: "e",
				y: 0,
				percentage: 0,
				color: "#1D7CD3",
			},
		],
	},
];

const NinetyDaysAnalysis = ({
	data,
	comparisonData,
	handleToggle,
	selectedItem = [],
	type,
	page,
	height,
	averageCensusComparison,
	averageCensus,
	filterListData,
	filterListDataComparison,
	totalTransfers,
	totalTransfersComparison,
	filter,
	filterComparison,
	cardTitle,
	reverseColors,
	loading,
	projectionDays,
	priorityNumber,
	isOnlyHospitalDashboard,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
}) => {
	const { dataList, dataListBarChart, dataListComparisonBarChart, radialDataList, radialDataComparisonList } =
		useDaysCard({
			data,
			comparisonData,
			defaultRadialData,
			page,
			averageCensusComparison,
			averageCensus,
			filterListData,
			filterListDataComparison,
			filter,
			filterComparison,
			totalTransfers,
			totalTransfersComparison,
			reverseColors,
		});

	const { loadingItems, handleToggleWithLoader } = useLoadingToggle();

	return loading ? (
		<NinetyDaysAnalysisSkeleton style={{ height: height || "100%" }} />
	) : (
		<div className={`df aic w100 ${styles.ninetyDays}`} {...(height && { style: { height: height } })}>
			<div className={`${styles.leftSec}`}>
				<div className={`df aic`} style={{ height: "calc(100% - 64px)" }}>
					<div
						className={`${styles.ninetyDaysList}`}
						style={{
							...page === PAGE_TYPE.HOSPITAL ? { marginTop: "5px" } : {},
							...page === PAGE_TYPE.DECEASED || page === PAGE_TYPE.OVERALL ? { marginTop: "-2px" } : {},
						}}
					>
						{dataList.length > 0 &&
							dataList.map((line, index) => {
								const colorItem = dataListComparisonBarChart.find((x) => x._id === line._id);
								return (
									<div
										key={`${index}-nineteen`}
										className={`df aic ${styles.ninetyDaysLine} ${selectedItem.indexOf(line._id) !== -1 ? styles.ninetyDaysLineSelected : null
											}`}
									>
										{!loading && (
											<ColorBox
												type="div"
												color={colorItem?.comparisonColor}
												barWidth={colorItem?.width}
												comparingAgainst={colorItem?.value}
												comparingAgainstScaled={colorItem?.comparingAgainstScaled}
												numberOfDays={colorItem?.numberOfDays}
												sx={{ marginRight: "10px" }}
											/>
										)}
										<Box position="relative" display="inline-flex" alignItems="center">
											<CheckboxButton
												labelId={`checkbox-list-90-label-${line._id}`}
												checked={selectedItem.indexOf(line._id) !== -1}
												handleToggle={() =>
													handleToggleWithLoader(() => handleToggle({
														item: line,
														type,
														isChecked: selectedItem.indexOf(line._id) !== -1,
														cardTitle,
													}), line?._id)
												}
												buttonText={
													<CardItemTooltip item={line} key={`tooltip-${index}`}>
														<Typography variant="subtitle4">{line.label}</Typography>
													</CardItemTooltip>
												}
												disableRipple={true}
												sx={{
													mr: "-2px",
													...(loadingItems[line?._id] && { opacity: 0 })
												}}
											/>
											<CheckboxLoader className={"small-dots-nineteen"} isLoading={loadingItems[line?._id]} />
										</Box>
									</div>
								);
							})}
					</div>
					<div style={{ width: "calc(100% - 130px)", height: "100%" }}>
						{dataListBarChart && dataListBarChart.length > 0 && (
							<ResponsiveBar
								height={285}
								data={dataListBarChart}
								keys={["value"]}
								indexBy="key"
								margin={{ top: 0, right: 12, bottom: 22, left: 5 }}
								padding={0.3}
								layout="horizontal"
								valueScale={{ type: "linear" }}
								indexScale={{ type: "band", round: true }}
								enableArcLabels={false}
								enableArcLinkLabels={false}
								enableGridY={false}
								enableGridX={true}
								axisLeft={null}
								borderRadius={4}
								labelTextColor="#fff"
								valueFormat={(v, a, b, c) => {
									return `${v}%1531564561644657`;
								}}
								theme={{
									grid: {
										line: {
											strokeWidth: 1,
											strokeDasharray: "12 12",
										},
									},
								}}
								barComponent={({ bar }) => {
									const isFirstItemInPriorityData = priorityNumber === 1;

									return (
										<g transform={`translate(${bar.x},${bar.y})`} className="nineteen_days_bar">
											<rect
												style={{ borderRadius: "25% 10%" }}
												width={bar.width}
												height={"32px"}
												fill={bar.data.data.color}
												strokeWidth="0"
												stroke={bar.data.data.color}
												focusable="false"
												ry={20}
											></rect>
											<text
												textAnchor="middle"
												alignmentBaseline="center"
												fillOpacity="1"
												transform={`translate(${bar.x + bar.width / 2},20)`}
												style={{
													fontFamily: "manrope",
													fontSize: "14px",
													fill: "rgb(255, 255, 255)",
													pointerEvents: "none",
													fontWeight: "600",
												}}
											>
												{calcProPercentsBasedOnFilterAndDays(bar.data.data.value, filter, projectionDays)}{" "}
												<tspan fillOpacity="0.85" alignmentBaseline="center" style={{ fontWeight: "400" }}>
													(
													{calcProPercentsBasedOnFilterAndDays(
														bar.data.data.percentage,
														filter,
														projectionDays,
														true,
														isComparingAgainstAvgCensus ||
														lockedTotalBy === "census" ||
														(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
													)}
													%)
												</tspan>
											</text>
										</g>
									);
								}}
							/>
						)}
					</div>
				</div>
			</div>
			<div
				className=""
				style={{
					height: "250px",
					marginBottom: "58px",
					width: "37%",
				}}
			>
				{radialDataList.length > 0 && (
					<ResponsiveRadialBar
						data={radialDataList}
						padding={0.4}
						cornerRadius={50}
						margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
						radialAxisStart={null}
						endAngle={360}
						enableTracks={false}
						enableRadialGrid={false}
						enableCircularGrid={false}
						colors={(d) => d.data.color}
						axisLeft={null}
						axisBottom={null}
						circularAxisOuter={null}
						enableArcLabels={false}
						enableArcLinkLabels={false}
					/>
				)}
				<div className={`${styles.radialLegend}`}>
					{radialDataList.length > 0 &&
						radialDataList.map((line, index) => {
							const comparisonItem = radialDataComparisonList.find((x) => line.id === x.id);
							return (
								<span className={`df aic fs10 fw600 ffint`} key={`line${index}`}>
									<div className={`${styles.icon}`} style={{ backgroundColor: line.color }}></div>
									{!loading && (
										<ColorBox
											color={comparisonItem?.data[0]?.comparisonColor || null}
											sx={{ height: "13px !important", ml: "-2px", width: "9px", mr: "1px" }}
											comparingAgainst={comparisonItem?.data[0]?.y}
											comparingAgainstScaled={comparisonItem?.data[0]?.comparingAgainstScaled}
											numberOfDays={comparisonItem?.data[0]?.numberOfDays}
										/>
									)}
									{line.id}: {line.data[0].y}
								</span>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default NinetyDaysAnalysis;
