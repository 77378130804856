import React, { useState } from 'react';
import { Box, CircularProgress, IconButton, Paper } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { RenderCardTypes } from './AlertReport';

export const PAGE_TYPE = {
    COMMUNITY_TRANSFER: 'communityTransfer',
    ADMISSION: 'admission',
    HOSPITAL: 'hospital',
    DECEASED: 'deceased',
};

const pages = Object.values(PAGE_TYPE);

const AlertSettings = ({
    formik,
    handleSelectAll,
    formatLabel,
    FILTER_KEYS,
    pageLoading,
    formType,
    handleDeleteClick
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleLeftClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : 0;
            formik.setFieldValue('page', pages[newIndex]); // Update formik's page value
            return newIndex;
        });
    };

    const handleRightClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex < pages.length - 1 ? prevIndex + 1 : pages.length - 1;
            formik.setFieldValue('page', pages[newIndex]); // Update formik's page value
            return newIndex;
        });
    };

    return (
        <Paper
            elevation={4}
            sx={{
                position: 'relative',
                width: '100%',
                overflow: 'hidden',
                bgcolor: 'background.default',
                borderRadius: 2,
                minHeight: '500px',
            }}
        >
            <IconButton
                onClick={handleLeftClick}
                disabled={currentIndex === 0} // Disable when at the first page
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '15px',
                    transform: 'translateY(-50%)',
                    zIndex: 10,
                    bgcolor: currentIndex === 0 ? 'grey.400' : 'primary.main', // Greyed out when disabled
                    color: 'white',
                    '&:hover': {
                        bgcolor: currentIndex === 0 ? 'grey.400' : 'primary.dark', // No hover effect when disabled
                    },
                    boxShadow: currentIndex === 0 ? 'none' : 3,
                    borderRadius: '50%',
                    padding: '4px',
                    fontSize: '1.2rem',
                }}
            >
                <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>

            {/* Right Arrow */}
            <IconButton
                onClick={handleRightClick}
                disabled={currentIndex === pages.length - 1} // Disable when at the last page
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '15px',
                    transform: 'translateY(-50%)',
                    zIndex: 10,
                    bgcolor: currentIndex === pages.length - 1 ? 'grey.400' : 'primary.main', // Greyed out when disabled
                    color: 'white',
                    '&:hover': {
                        bgcolor: currentIndex === pages.length - 1 ? 'grey.400' : 'primary.dark', // No hover effect when disabled
                    },
                    boxShadow: currentIndex === pages.length - 1 ? 'none' : 3,
                    borderRadius: '50%',
                    padding: '4px',
                    fontSize: '1.2rem',
                }}
            >
                <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>

            {/* Content with transition */}
            <Box
                display="flex"
                transition="transform 0.5s ease-in-out"
                sx={{
                    // transform: `translateX(-${currentIndex * 100}%)`,
                    width: `${pages.length * 100}%`, // Adjust width based on the number of views
                    minHeight: '100%', // Ensure it takes at least the full height of its container
                    padding: 4
                }}
            >
                {pageLoading && <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '100%', // Ensures it takes up the full available height
                        minHeight: '300px', // Optional: Set a minimum height for better visibility
                    }}
                >
                    <CircularProgress />
                </Box>
                }
                {!pageLoading && (
                    pages.map((page, index) => (
                        <Box
                            key={page}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="flex-start"
                            sx={{
                                px: 2,
                                minHeight: '100%', // Ensures the content fills the container
                                display: 'flex', // Ensures content is arranged in flex layout
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {/* RenderCardTypes handles its internal layout */}
                            <RenderCardTypes
                                formik={formik}
                                handleSelectAll={handleSelectAll}
                                page={page}
                                FILTER_KEYS={FILTER_KEYS}
                                formatLabel={formatLabel}
                                type="settings"
                                formType={formType}
                                handleDeleteClick={handleDeleteClick}
                            />
                        </Box>
                    ))
                )}
            </Box>
        </Paper>
    );
};

export default AlertSettings;
