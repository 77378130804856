import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/community-transfer.slice";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/communityTransferComparison.slice";
import { getCardPatientData, getCardPatientChartData, getAllCommunicationCount } from "./api/community-transfer.api";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};
const ongoingPatientRequestsChart = new Map();

const cardPatientAndChartData = async (data = null, type) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	if (ongoingPatientRequestsChart.has(requestKey)) {
		const { controller } = ongoingPatientRequestsChart.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientRequestsChart.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;

	try {
		const requestPromise = getCardPatientChartData(data, { signal });
		ongoingPatientRequestsChart.set(requestKey, { requestPromise, controller });
		const res = await requestPromise;
		return res;
	} catch (e) {
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Cleanup: Remove the request from the map after it completes (success or failure)
		ongoingPatientRequestsChart.delete(requestKey);
	}
};

const ongoingPatientRequests = new Map();

const getAllCommunicationPatient = async (data = null, forComparison, type) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	if (ongoingPatientRequests.has(requestKey)) {
		const { controller } = ongoingPatientRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientRequests.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;

	try {

		const requestPromise = getAllCommunicationCount(data, { signal });
		ongoingPatientRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;
		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res.totals.total) : setMainTotal(res.totals.total));

		return res.data;
	} catch (e) {
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Cleanup: Remove the request from the map after it completes (success or failure)
		ongoingPatientRequests.delete(requestKey);
	}
};

export { cardPatientData, cardPatientAndChartData, getAllCommunicationPatient };
