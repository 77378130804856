/* eslint-disable array-callback-return */
import { Box, Grid, CircularProgress } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import Page from "../components/Page";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import Card from "../components/dashboard/card/Card";
import DeceasedCardDetailsDialog from "../components/dashboard/deceased/DeceasedCardDetailsDialog";
import DeceasedHeader from "../components/dashboard/deceased/DeceasedHeader";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import NinetyDaysAnalysis from "../components/dashboard/hospital/ninetyDaysAnalysis/NinetyDaysAnalysis";
import TotalCountCircle from "../components/dashboard/hospital/totalCountCircle/TotalCountCircle";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import { DECEASED_TYPES } from "../data/common.data";
import { getAllDeceasedPatient } from "../services/deceased.service";
import {
	setCardFilterDeceased as setCardFilterComparison,
	setFilterListDataDeceased as setFilterListDataComparison,
	setPatientList as setPatientListComparison,
} from "../store/reducers/comparisonReducers/deceasedComparison.slice";
import {
	DEFAULT_DECEASED_DATA,
	openDetailsDialog,
	setCardFilterDeceased,
	setDynamicCards,
	setLockTotal,
	setLockedTotalBy,
	setResetFilter,
	setTransferType,
	setLoading as setDeceasedLoading
} from "../store/reducers/deceased.slice";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { DECEASED_CARDS_LABELS, DECEASED_CARDS_TYPE } from "../types/deceased.type";
import { PAGE_TYPE } from "../types/pages.type";
import {
	getDynamicPercentageBy,
	itemPercentage,
	priorityFilter,
	projectionPerMonth,
	updateChartFilterByPriority,
	updateCurrentFilterListData,
} from "../utilis/common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import { updateFilterListData } from "../utilis/deceased-common";
import tooltipsInfo from "../utilis/tooltipContents";
import { setDeceasedCards } from "../store/reducers/customCard.slice";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import { DECEASED_CUSTOM_CARD } from "../types/custom-card-type";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";
import NoteContainer from "../components/shared/NoteContainer";
import { getQuestionsData } from "../services/dynamic-data-tab.service";
import DynamicDataCardList from "../components/dashboard/hospital/DynamicDataCardList/DynamicDataCardList";
import useAdditionalData from "../components/hooks/useAdditionalData";
import { useDashboard } from "../context/DashboardContext";

const DEFAULT_LIST = {
	ninetyDaysData: [],
	list: [],
};

const DeceasedPage = (props) => {
	const dispatch = useDispatch();
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const { saveCustomCardFilter } = useCustomCardFilter();
	const [patientList, setPatientList] = useState(DEFAULT_LIST);
	const [filterListData, setFilterListData] = useState(DEFAULT_DECEASED_DATA);
	// eslint-disable-next-line no-unused-vars
	const {
		filter,
		dbData,
		cardFilter,
		detailsDialog,
		isResetFilter,
		transferType,
		lockedTotal,
		facilityPercentage,
		lockedByFacility,
		isCensusTotalLocked,
		lockedTotalBy,
		loading: deceasedLoading,
		dynamicCards
	} = useSelector((state) => state.deceased);
	const { percentageBy } = useSelector((state) => state.common);
	const { deceasedCards } = useSelector((state) => state.customCard);
	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);
	const DECEASED_CUSTOM_CARD_DATA = useAdditionalData(dynamicCards, DECEASED_CUSTOM_CARD);
	const getDynamicTab = useCallback(async (page) => {
		let query = { page };
		if (page === PAGE_TYPE.DECEASED) {
			query = { page, forType: 'transfer', forTransferType: ["deceased"], isCustom: true };
		}
		const res = await getQuestionsData(query);
		if (res && res.length > 0) {
			dispatch(setDynamicCards(res));
		}
	}, [dispatch]); // Add dispatch to the dependency array

	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const { rangesSet, comparingAgainstDifferentFacility } = useSelector(
		(state) => state.comparison
	);
	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		cardFilter: cardFilterComparison,
		patientList: patientListComparison,
	} = useSelector((state) => state.deceasedComparison);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");
	const activeRequestRef = useRef(null);

	// loading states
	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(false);
	const { updateDashboard } = useDashboard();

	useEffect(() => {
		if (dbData) {
			updateDashboard({ dbData, page: PAGE_TYPE.DECEASED, dbDataComparison });
		}
	}, [dbData, updateDashboard, dbDataComparison]);

	useEffect(() => {
		if (dynamicCards?.length === 0) {
			getDynamicTab(PAGE_TYPE.DECEASED);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dynamicCards]);

	const getAllDataCount = async (filter) => {
		const currentRequest = _.uniqueId();
		activeRequestRef.current = currentRequest;
		setLoading(true);

		try {
			const [mainResult, comparisonResult] = await Promise.all([
				getAllDeceasedPatient(filter, false, "main"),
				rangesSet ? await getAllDeceasedPatient({ ...filterComparison, facilityIds: activeFacilitiesComparison }, true, "comparison") : null
			]);

			if (activeRequestRef.current !== currentRequest) return;

			if (mainResult && mainResult.list) {
				setPatientList(mainResult);
				await updateTotalToDBFilter(mainResult);
			} else {
				setPatientList([]);
				setFilterListData(DEFAULT_DECEASED_DATA);
			}

			if (comparisonResult && comparisonResult.list) {
				dispatch(setPatientListComparison(comparisonResult));
				updateTotalToDBFilterComparison(comparisonResult);
			} else {
				dispatch(setPatientListComparison([]));
				dispatch(setFilterListDataComparison(DEFAULT_DECEASED_DATA));
			}

		} catch (e) {
			if (activeRequestRef.current !== currentRequest) return;
			if (e?.message !== "canceled") {
				setLoading(false);

				if (deceasedLoading) {
					dispatch(setDeceasedLoading(false));
				}
			}
		} finally {
			if (activeRequestRef.current === currentRequest) {
				setLoading(false);

				if (deceasedLoading) {
					dispatch(setDeceasedLoading(false));
				}
			}
		}
	};

	useEffect(() => {
		if (isResetFilter) {
			updateTotalToDBFilter(patientList);
			dispatch(setResetFilter(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilter, projectionDays]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lockedTotal, projectionDays, percentageAgainst]);

	useEffect(() => {
		async function fetchMyAPI(filter) {
			batch(() => {
				dispatch(setLockTotal(null));
				dispatch(setLockedTotalBy(null));
			});
			getAllDataCount(filter);
		}
		if (
			isDateUpdated &&
			filter &&
			filter.startDate &&
			localStorage.getItem("facilityId") &&
			activeFacilities.length > 0
		) {
			fetchMyAPI({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filter,
		filterComparison,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		activeFacilityLocalStorage,
	]);

	const updateTotalToDBFilter = async (res) => {
		const latestListData = await updateFilterListData(cardFilter, res, cardFilter.priorityData, false);

		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
	};

	const updateTotalToDBFilterComparison = async (res) => {
		const latestListData = await updateFilterListData(
			cardFilterComparison,
			res,
			cardFilterComparison.priorityData,
			true
		);

		dispatch(setFilterListDataComparison(latestListData));
	};

	const handleClickTotal = useCallback(
		(total) => {
			dispatch(setTransferType(transferType === DECEASED_TYPES.ALL ? null : DECEASED_TYPES.ALL));
		},
		[dispatch, transferType]
	);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferType]);

	const handleToggle = useCallback(
		async ({ item, type: typeData, cardTitle, question, isSpacialItem = false }) => {
			if (item) {
				let type = typeData;
				if (isSpacialItem) {
					type = `${type}_spacial`;
				}
				const value = item._id;
				const patientIds = item?.patientIds ?? [];
				const filtersItem = { ...cardFilter };
				if (filtersItem[type] === undefined) {
					filtersItem[type] = [];
				}

				const filterData = filtersItem[type];
				const currentIndex = filterData?.indexOf(value);
				const newChecked = filterData ? [...filterData] : [];
				let isRemoveIds = false
				if (currentIndex === -1) {
					newChecked.push(value);
				} else {
					isRemoveIds = question?.isCustom ? true : false;
					newChecked.splice(currentIndex, 1);
				}
				if (!isSpacialItem) {
					const [priorityData, priorityDataComparison] = await Promise.all([
						priorityFilter(
							cardFilter.priorityData,
							type,
							newChecked.length,
							cardTitle,
							question,
							patientIds,
							isRemoveIds
						),
						rangesSet
							? priorityFilter(
								cardFilterComparison?.priorityData,
								type,
								newChecked.length,
								cardTitle,
								question,
								patientIds,
								isRemoveIds
							)
							: undefined,
					]);
					batch(() => {

						dispatch(
							setCardFilterDeceased({
								[type]: newChecked,
								priorityData: priorityData,
							})
						);
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
					});

					const [latestListData, latestListDataComparison] = await Promise.all([
						updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, false),
						rangesSet
							? updateFilterListData(
								{ ...cardFilterComparison, [type]: newChecked },
								patientListComparison,
								priorityDataComparison,
								true
							)
							: undefined,
					]);
					setFilterListData((prevState) => {
						return { ...prevState, ...latestListData };
					});
					if (rangesSet) {
						const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
						dispatch(setFilterListDataComparison(updatedFilterListData));
					}
				} else {
					batch(() => {
						dispatch(
							setCardFilterDeceased({
								[type]: newChecked,
							})
						);
						rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked }));
					});
				}
			}
		},
		[
			cardFilter,
			rangesSet,
			cardFilterComparison,
			dispatch,
			patientList,
			patientListComparison,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [priorityData, priorityDataComparison] = await Promise.all([
			priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
			rangesSet ? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle) : undefined,
		]);
		dispatch(
			setCardFilterDeceased({
				[type]: newChecked,
				priorityData: priorityData,
			})
		);

		rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
		const [latestListData, latestListDataComparison] = await Promise.all([
			updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, false),
			rangesSet
				? updateFilterListData(
					{ ...cardFilterComparison, [type]: newChecked },
					patientListComparison,
					priorityDataComparison,
					true
				)
				: undefined,
		]);
		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
		if (rangesSet) {
			const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
			dispatch(setFilterListDataComparison(updatedFilterListData));
		}
	};

	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false, defaultTab = null, question = null) => {
			let latestCardFilter = await updateChartFilterByPriority(cardFilter, type);
			let filterSelected =
				(cardFilter.priorityData.length > 0 && cardFilter.priorityData[0]["type"] !== type) ||
				transferType != null;
			if (type === DECEASED_CARDS_TYPE.TOTAL) {
				filterSelected = false;
			}
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				selectedFilterData: cardFilter[type] || [],
				type,
				filter,
				relation,
				filterSelected,
				facilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				filterListData,
				defaultTab,
				question
			};
			let data = type === DECEASED_CARDS_TYPE.TOTAL ? [] : filterListData[type];
			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
					dbData,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, filterListData, cardFilter, filter, facilityPercentage, dbData]
	);

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.priorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const handleOnClickReport = async (type, title, isTotalCard = false, automaticallyReport = null, customData = null) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				isTotalCard,
				automaticallyReport: { ...automaticallyReport, ...customData },
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				isTotalCard,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				graphData: { ...graphDataUpdated, detailsDialog },
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				isTotalCard: false,
				graphData,
				isGraphReport: true,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...deceasedCards];
		if (key === "selectAll") {
			cards = _.map(DECEASED_CUSTOM_CARD_DATA, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setDeceasedCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.DECEASED, cards })
	}, [DECEASED_CUSTOM_CARD_DATA, deceasedCards, dispatch, saveCustomCardFilter]);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	return (
		<Page title="Dashboard: Deceased Page">
			{detailsDialog.isOpen && (
				<DeceasedCardDetailsDialog
					handleOnClickReport={handleOnClickReportGraph}
					handleGenerateExcelReport={downloadExcel}
				/>
			)}
			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.aoWrapper}`}>
						<DeceasedHeader
							filterListData={filterListData}
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							dynamicCards={dynamicCards}
						/>
					</div>
				</div>

				{(loading || deceasedLoading) ?
					<div style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "90vh"
					}}>
						<CircularProgress />
					</div> : (
						<NoteContainer page={PAGE_TYPE.DECEASED}>
							<Grid container justifyContent={"center"} sx={{ mt: "30px" }}>
								<Grid item xs={12} position={"relative"}>
									{rangesSet && <ColorInfoBox style={{ right: "1%" }} loading={loading || loadingComparison} />}
									<AddCardDrawer
										listData={DECEASED_CUSTOM_CARD_DATA}
										selectedCards={deceasedCards}
										handleAddCustomCard={handleAddCustomCard}
										scrollTop={scrollPosition?.scrollTop}
									/>
									<TotalCountCircle
										page={PAGE_TYPE.DECEASED}
										totalTitle="Deceased"
										selected={transferType === DECEASED_TYPES.ALL}
										handleClickTotal={() => handleClickTotal(dbData.total)}
										projectTitle={percentageBy}
										total={dbData.total}
										totalComparison={dbDataComparison?.total}
										filter={filter}
										filterComparison={filterComparison}
										totalPercentage={itemPercentage(dbData.total, percentageAgainst, "number")}
										totalComparisonPercentage={itemPercentage(
											dbDataComparison?.total,
											dbDataComparison?.censusAverage,
											"number"
										)}
										projectTotal={projectionPerMonth(dbData.total, filter, projectionDays)}
										projectTotalComparison={projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays)}
										totalProjectionComparisonPercentage={itemPercentage(
											projectionPerMonth(dbDataComparison?.total, filterComparison),
											dbDataComparison?.censusAverage,
											"number"
										)}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.total, filter, projectionDays),
											percentageAgainst,
											"number"
										)}
										tooltipContent={tooltipsInfo.deceasedPage.deceased}
										handleOnClickAction={(title, defaultTab) => handleChartDialog(title, DECEASED_CARDS_TYPE.TOTAL, null, true, defaultTab)}
										loading={loading || loadingComparison}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(DECEASED_CARDS_TYPE.TOTAL, title, true, otherData)
										}
										projectionDays={projectionDays}
										totalCardType={"total"}
									/>
								</Grid>
							</Grid>

							<Box 
							sx={{ p: 2 }}>
								<Grid
									container
									spacing={2}
									sx={{ mb: "80px" }}
									display="flex"
									direction={"row"}
								>
									<CardAnimation id={`${DECEASED_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.INSURANCE_DATA)}>
										<Grid item md={6} lg={4} xs={12}>
											<Card
												pageType={PAGE_TYPE.DECEASED}
												loading={loading || loadingComparison}
												title={DECEASED_CARDS_LABELS.insuranceData}
												itemAlign={"ais"}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, DECEASED_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.INSURANCE, false, defaultTab)
												}
												tooltipContent={tooltipsInfo.deceasedPage.perInsurance}
												priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.INSURANCE_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(DECEASED_CARDS_TYPE.INSURANCE_DATA, title, false, otherData)
												}
												style={{ height: "100%" }}
												selectedItems={cardFilter.insuranceData}
												data={filterListData.insuranceData}
												type={DECEASED_CARDS_TYPE.INSURANCE_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={DECEASED_CARDS_TYPE.INSURANCE_DATA}
											>
												<InsuranceCard
													cardTitle={DECEASED_CARDS_LABELS.insuranceData}
													height={350}
													data={filterListData.insuranceData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.insuranceData}
													type={DECEASED_CARDS_TYPE.INSURANCE_DATA}
													page={PAGE_TYPE.DECEASED}
													dataComparison={filterListDataComparison?.insuranceData}
													filter={filter}
													filterComparison={filterComparison}
													averageCensus={percentageAgainst}
													averageCensusComparison={dbDataComparison?.censusAverage}
													loading={loading || loadingComparison}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.INSURANCE_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${DECEASED_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.DOCTOR_DATA)}>
										<Grid item md={6} lg={4} xs={12}>
											<Card
												pageType={PAGE_TYPE.DECEASED}
												loading={loading || loadingComparison}
												title={DECEASED_CARDS_LABELS.doctorData}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, DECEASED_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={DECEASED_CARDS_TYPE.DOCTOR_DATA}
														data={filterListData.doctorData}
														selectedItem={cardFilter.doctorData}
													/>
												}
												style={{ height: "100%" }}
												tooltipContent={tooltipsInfo.deceasedPage.perDoctor}
												priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.DOCTOR_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(DECEASED_CARDS_TYPE.DOCTOR_DATA, title, false, otherData)
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												selectedItems={cardFilter.doctorData}
												data={filterListData.doctorData}
												type={DECEASED_CARDS_TYPE.DOCTOR_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={DECEASED_CARDS_TYPE.DOCTOR_DATA}
											>
												<DoctorCard
													cardTitle={DECEASED_CARDS_LABELS.doctorData}
													data={filterListData.doctorData}
													minimizable={true}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.doctorData}
													type={DECEASED_CARDS_TYPE.DOCTOR_DATA}
													page={PAGE_TYPE.DECEASED}
													comparisonData={filterListDataComparison?.doctorData}
													averageCensusComparison={dbDataComparison?.censusAverage}
													averageCensus={percentageAgainst}
													filter={filter}
													filterComparison={filterComparison}
													loading={loading || loadingComparison}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.DOCTOR_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${DECEASED_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.FLOORS_DATA)}>
										<Grid item md={6} lg={4} xs={12}>
											<Card
												pageType={PAGE_TYPE.DECEASED}
												loading={loading || loadingComparison}
												title={DECEASED_CARDS_LABELS.floorsData}
												handleOnClickAction={(title, defaultTab) =>
													handleChartDialog(title, DECEASED_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT, false, defaultTab)
												}
												taggedList={
													<TaggedList
														type={DECEASED_CARDS_TYPE.FLOORS_DATA}
														data={filterListData.floorsData}
														selectedItem={cardFilter.floorsData}
													/>
												}
												style={{ height: "100%" }}
												tooltipContent={tooltipsInfo.deceasedPage.perFloor}
												priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.FLOORS_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(DECEASED_CARDS_TYPE.FLOORS_DATA, title, false, otherData)
												}
												secondaryTitleError={
													comparingAgainstDifferentFacility &&
													rangesSet &&
													"Trend Analysis disabled when comparing to other facilities"
												}
												secondaryTitleClassName="error"
												selectedItems={cardFilter.floorsData}
												data={filterListData.floorsData}
												type={DECEASED_CARDS_TYPE.FLOORS_DATA}
												handleToggleAll={handleToggleAll}
												canBeSearched={true}
												cardType={DECEASED_CARDS_TYPE.FLOORS_DATA}
											>
												<FloorCardList
													cardTitle={DECEASED_CARDS_LABELS.floorsData}
													data={filterListData.floorsData}
													handleToggle={handleToggle}
													handleToggleAll={handleToggleAll}
													selectedItem={cardFilter.floorsData}
													type={DECEASED_CARDS_TYPE.FLOORS_DATA}
													page={PAGE_TYPE.DECEASED}
													filter={filter}
													filterComparison={filterComparison}
													dataComparison={filterListDataComparison?.floorsData}
													averageCensusComparison={dbDataComparison?.censusAverage}
													averageCensus={percentageAgainst}
													comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
													loading={loading || loadingComparison}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.FLOORS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>

									<CardAnimation id={`${DECEASED_CARDS_TYPE.NINETY_DAYS_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}>
										<Grid item md={12} lg={12} xs={12}>
											<Card
												pageType={PAGE_TYPE.DECEASED}
												loading={loading || loadingComparison}
												title="90 Days Analysis"
												handleOnClickAction={(title, defaultTab) => handleChartDialog(title, DECEASED_CARDS_TYPE.NINETY_DAYS_DATA, null, false, defaultTab)}
												tooltipContent={tooltipsInfo.deceasedPage["90DaysAnalysis"]}
												priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}
												handleOnClickReport={(title, otherData) =>
													handleOnClickReport(DECEASED_CARDS_TYPE.NINETY_DAYS_DATA, title, false, otherData)
												}
												style={{ height: "100%" }}
												cardType={DECEASED_CARDS_TYPE.NINETY_DAYS_DATA}
											>
												<NinetyDaysAnalysis
													cardTitle="90 Days Analysis"
													height={"360px"}
													handleToggle={handleToggle}
													selectedItem={cardFilter.ninetyDaysData}
													type={DECEASED_CARDS_TYPE.NINETY_DAYS_DATA}
													data={filterListData.ninetyDaysData}
													page={PAGE_TYPE.DECEASED}
													comparisonData={filterListDataComparison?.ninetyDaysData}
													averageCensusComparison={dbDataComparison?.censusAverage}
													averageCensus={percentageAgainst}
													filter={filter}
													loading={loading || loadingComparison}
													filterComparison={filterComparison}
													projectionDays={projectionDays}
													isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
													priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}
													transferType={transferType}
													lockedTotalBy={lockedTotalBy}
												/>
											</Card>
										</Grid>
									</CardAnimation>
								</Grid>
								{dynamicCards?.length > 0 ? (
									<Grid container spacing={2} sx={{ mt: "-80px" }} display="flex" direction="row">
										{dynamicCards.map((ele) => (
											<CardAnimation id={`${ele?.accessor}_scroll`} checked={_.includes(deceasedCards, ele?.accessor)}>
												<Grid item xs={12} sm={6} md={6} lg={4} key={ele?.accessor || Math.random()}>
													<Card
														pageType={PAGE_TYPE.DECEASED}
														loading={loading || loadingComparison}
														title={ele?.label || ele?.tableLabel}
														priorityNumber={getPriorityNumber(ele?.accessor)}
														handleOnClickAction={(title, defaultTab) =>
															handleChartDialog(title, ele?.accessor, null, false, defaultTab, { isCustom: true, customQuestionInputType: ele?.customQuestionInputType })
														}
														taggedList={
															<TaggedList
																type={ele?.accessor}
																data={filterListData?.[ele?.accessor] ?? []}
																selectedItem={cardFilter?.[ele?.accessor] ?? []}
															/>
														}
														isMinimized={true}
														handleOnClickReport={(title, otherData) =>
															handleOnClickReport(ele?.accessor, title, false, otherData, { questionId: ele?._id, isCustomCard: true })
														}
														selectedItems={cardFilter?.[ele?.accessor] ?? []}
														data={filterListData?.[ele?.accessor] ?? []}
														type={ele?.accessor}
														handleToggleAll={handleToggleAll}
														canBeSearched={true}
														cardType={ele?.accessor}
														selectAllOption={false}
													>
														<DynamicDataCardList
															question={ele}
															cardTitle={ele?.label || ele?.tableLabel}
															data={filterListData?.[ele?.accessor] ?? []}
															dataComparison={filterListDataComparison?.[ele?.accessor] ?? []}
															handleToggle={handleToggle}
															handleToggleAll={handleToggleAll}
															selectedItem={cardFilter?.[ele?.accessor]}
															spacialSelectedItem={cardFilter?.[ele?.accessor + "_spacial"]}
															type={ele?.accessor}
															filter={filter}
															loading={loading || loadingComparison}
															filterComparison={filterComparison}
															page={PAGE_TYPE.DECEASED}
															averageCensusComparison={dbDataComparison?.censusAverage}
															averageCensus={percentageAgainst}
															projectionDays={projectionDays}
															isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
															priorityNumber={getPriorityNumber(ele?.accessor)}
															transferType={transferType}
															lockedTotalBy={lockedTotalBy}
														/>
													</Card>
												</Grid>
											</CardAnimation>
										))}
									</Grid>
								) : null}
							</Box>
						</NoteContainer>)
				}
				<ShadowLineWithText data={deceasedCards} />
			</div>
		</Page>
	);
};

export default DeceasedPage;
