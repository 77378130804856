import React, { useState } from 'react';
import { Button, IconButton, TextField, Box } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { NUMBER_RANGE_TYPE, QUESTION_INPUT_TYPE } from "../../../../../../types/question.type";
import RangeInput from './number-range/RangeInput';
import ConditionPopover from './number-range/ConditionPopover';

const NumberRangeWithUnlimitedAnswer = ({
    setQuestionValues,
    questionValues,
}) => {
    const [anchorEl, setAnchorEl] = useState({ index: null, type: null, element: null });

    const handleAdd = (type, isNumberRange = false) => {
        if (isNumberRange) {
            setQuestionValues({
                ...questionValues,
                customQuestionOptions: [...questionValues.customQuestionOptions, {
                    type,
                    min: '',
                    max: '',
                    option: '',                    
                    title: ''
                }],
            });
        } else {
            setQuestionValues((prevRanges) => {
                const hasType = prevRanges.customQuestionOptions?.some((range) => range.type === type);
                if (hasType) {
                    return prevRanges;
                }

                return {
                    ...prevRanges,
                    customQuestionOptions: [
                        ...(prevRanges.customQuestionOptions || []),  // Ensure it handles undefined gracefully
                        {
                            type,
                            min: '',
                            max: '',
                            option: '',
                            title: ''
                        },
                    ],
                };
            });
        }
    };

    const handleMinChange = (index, value) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.map((item, i) =>
                i === index && item.type === QUESTION_INPUT_TYPE.NUMBER_RANGE ? { ...item, min: value } : item
            ),
        }));
    };

    const handleMaxChange = (index, value) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.map((item, i) =>
                i === index && item.type === QUESTION_INPUT_TYPE.NUMBER_RANGE ? { ...item, max: value } : item
            ),
        }));
    };

    const handleConditionChange = (index, type, value) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.map((item, i) => {
                if (i === index && item.type === QUESTION_INPUT_TYPE.NUMBER_RANGE) {
                    if (type === 'min') {
                        return { ...item, min: value };
                    } else {
                        return { ...item, max: value };
                    }
                }
                return item;
            }),
        }));
        setAnchorEl({ index: null, type: null, element: null });
    };

    const handleEdit = (index, value) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.map((item, i) =>
                i === index && item.type === QUESTION_INPUT_TYPE.LIMITED_ANSWERS ? { ...item, option: value } : item
            ),
        }));
    };

    const handleRemove = (index) => {
        setQuestionValues(prevValues => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.filter((_, i) => i !== index),
        }));
    };

    const handleTitleChange = (index, value) => {
        setQuestionValues((prevValues) => ({
            ...prevValues,
            customQuestionOptions: prevValues.customQuestionOptions.map((range, i) =>
                i === index ? { ...range, title: value } : range
            )
        }));
    };

    return (
        <>
            {questionValues.customQuestionOptions && questionValues.customQuestionOptions.map((item, index) => (
                <Box key={index} mt={2} display="flex" alignItems="center" gap={2}>
                    {(item.type === NUMBER_RANGE_TYPE.TOTAL || item.type === NUMBER_RANGE_TYPE.AVERAGE) && (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={item.title}
                                onChange={(e) => handleTitleChange(index, e.target.value)}
                                label={`${item.type === 'total' ? 'Total' : 'Average'} Title`}
                                sx={{ mb: 2 }}
                            />
                            <IconButton aria-label="delete" onClick={() => handleRemove(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                    {item.type === QUESTION_INPUT_TYPE.NUMBER_RANGE && (
                        <>
                            <RangeInput
                                range={item}
                                index={index}
                                handleMinChange={handleMinChange}
                                handleMaxChange={handleMaxChange}
                                setAnchorEl={setAnchorEl}
                            />
                            <IconButton aria-label="delete" onClick={() => handleRemove(index)}>
                                <DeleteIcon />
                            </IconButton>
                            <ConditionPopover
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl({ index: null, type: null, element: null })}
                                index={index}
                                type={'min'}
                                handleConditionChange={handleConditionChange}
                            />
                            <ConditionPopover
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl({ index: null, type: null, element: null })}
                                index={index}
                                type={'max'}
                                handleConditionChange={handleConditionChange}
                            />
                        </>
                    )}
                    {item.type === QUESTION_INPUT_TYPE.LIMITED_ANSWERS && (
                        <>
                            <TextField
                                value={item.option}
                                onChange={(e) => handleEdit(index, e.target.value)}
                                fullWidth
                                variant="outlined"
                            />
                            <IconButton aria-label="delete" onClick={() => handleRemove(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </Box>
            ))}
            <Box mt={3}>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleAdd(QUESTION_INPUT_TYPE.NUMBER_RANGE, true)}
                >
                    Add Range
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleAdd(QUESTION_INPUT_TYPE.LIMITED_ANSWERS, true)}
                    sx={{ ml: 2 }}
                >
                    Add Option
                </Button>
                <Button
                    sx={{ ml: 2 }}
                    variant={"outlined"}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        // handleNumberRageChange(NUMBER_RANGE_TYPE.AVERAGE)
                        handleAdd(NUMBER_RANGE_TYPE.AVERAGE);
                    }}
                    disabled={questionValues?.customQuestionOptions?.some((option) => option.type === NUMBER_RANGE_TYPE.AVERAGE)}
                >
                    Add Average
                </Button>
                <Button
                    sx={{ ml: 2 }}
                    variant={"outlined"}
                    startIcon={<AddIcon />}
                    onClick={() => {
                        // handleNumberRageChange(NUMBER_RANGE_TYPE.TOTAL)
                        handleAdd(NUMBER_RANGE_TYPE.TOTAL);
                    }}
                    disabled={questionValues?.customQuestionOptions?.some((option) => option.type === NUMBER_RANGE_TYPE.TOTAL)}
                >
                    Add Total
                </Button>
            </Box>
        </>
    );
};

export default NumberRangeWithUnlimitedAnswer;