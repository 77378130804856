import { getAllDynamicDataTabs, getDynamicQuestions, saveCustomDynamicDataTab } from "./api/dynamic-data-tab.api";

const handleAsyncOperation = async (operation, ...args) => {
    try {
        const res = await operation(...args);
        return res;
    } catch (e) {
        console.error(e);
    }
};

const getDynamicDataTabs = async (type) => handleAsyncOperation(getAllDynamicDataTabs, type);
const getQuestionsData = async (type) => handleAsyncOperation(getDynamicQuestions, type);
const createDynamicDataTab = async (body) => handleAsyncOperation(saveCustomDynamicDataTab, body);
const deleteDynamicDataTab = async (body) => handleAsyncOperation(deleteDynamicDataTab, body);


export {
    getQuestionsData,
    getDynamicDataTabs,
    createDynamicDataTab,    
    deleteDynamicDataTab
}
