import {
    Typography,
    Button,
    Stack,
} from "@mui/material";
import { useCallback, useState } from "react";
import Popover from "@mui/material/Popover";
import DeleteIcon from '@mui/icons-material/Delete';
import { batch, useDispatch } from "react-redux";
import { setCardFilter, setDefaultADTData, setSelectedADTTableData } from "../../../store/reducers/admission.slice";
import _ from "lodash";

const RemoveCardButton = ({ item, cardFilter, filters, reloadAdmissionFilter, defaultADTData }) => {
    const [anchorDeleteEl, setAnchorDeleteEl] = useState(null);
    const [isDeleteFilter, setIsDeleteFilter] = useState(null);
    const dispatch = useDispatch();
    const handleDeleteFilter = useCallback((event, value) => {
        event.stopPropagation();
        setAnchorDeleteEl(event.currentTarget);
        setIsDeleteFilter(value);
    }, []);

    const handleCloseConfirmation = useCallback(() => {
        setAnchorDeleteEl(null);
        setIsDeleteFilter(null);
    }, []);

    const handleDeleteDBFilter = useCallback(async () => {
        if (!cardFilter?.adtData?.length) return;

        const { cardId, childId } = item;

        // ✅ Update ADT Data: Unselect matching child
        const latestAdtData = cardFilter.adtData.map((ele) => ({
            ...ele,
            children: ele.children.map((child) =>
                child.id === childId && child.parentId === cardId
                    ? { ...child, isSelected: false }
                    : child
            )
        }));

        // ✅ Remove the current filter item
        const latestAdtAdmitPatientIds = filters.filter(f => f !== item);

        // ✅ Update Default ADT Data
        const defaultADTDataLatest = defaultADTData.filter(
            (p) => !(p.childId === childId && p.cardId === cardId)
        );

        await dispatch(setDefaultADTData(defaultADTDataLatest));

        // ✅ Check if the filter exists in adtAdmitPatientIds
        const isFilter = _.find(cardFilter.adtAdmitPatientIds, { id: childId, cardId: cardId });

        if (isFilter) {
            const latestAdtFilterList = cardFilter.adtAdmitPatientIds.filter(
                (f) => !(f.id === childId && f.cardId === cardId)
            );

            const priorityDataFilter = cardFilter.mainPriorityData.filter(
                (f) => !(f.childId === childId && f.cardId === cardId)
            );

            await dispatch(setCardFilter({
                adtData: latestAdtData,
                adtAdmitPatientIds: latestAdtFilterList,
                mainPriorityData: priorityDataFilter
            }));

            setAnchorDeleteEl(null);
            reloadAdmissionFilter(priorityDataFilter, defaultADTDataLatest);
        } else {
            batch(() => {
                dispatch(setCardFilter({ adtData: latestAdtData }));
                dispatch(setSelectedADTTableData(latestAdtAdmitPatientIds));
            });
            setAnchorDeleteEl(null);
        }
    }, [cardFilter, filters, item, dispatch, reloadAdmissionFilter, defaultADTData]);

    return (
        <>
            <DeleteIcon
                sx={{ color: "#FF5B62", cursor: "pointer" }}
                onClick={(e) => handleDeleteFilter(e, item)}
            />
            {isDeleteFilter && (
                <Popover
                    anchorEl={anchorDeleteEl}
                    open={!!isDeleteFilter}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handleCloseConfirmation}
                    key={item?.childId + "_deleteTray_" + item?.cardId}
                >
                    <Stack display={"flex"} sx={{ padding: 2 }}>
                        <Stack item direction="row">
                            <Typography variant="subtitle2">
                                Are you sure want to delete ?
                            </Typography>
                        </Stack>
                        <Stack item direction="row" spacing={2} sx={{ mt: 1 }}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={handleCloseConfirmation}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={handleDeleteDBFilter}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </Stack>
                </Popover>
            )}
        </>

    );
};

export default RemoveCardButton;
