import update from "immutability-helper";
import _ from "lodash";
import moment from "moment";
import { store } from "..";
import { percentageByEnum } from "../data/common.data";
import { ADT_SUB_TYPES, ADT_TABLE_TYPE, TOTAL_TYPE } from "../types/common.type";
import { ACCOUNT_PERCENTAGE_BY, OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../types/pages.type";
import { NUMBER_RANGE_TYPE, QUESTION_INPUT_TYPE } from "../types/question.type";

export async function processCustomChartFiltersADT(chartMainDataArr, filters, newChecked, filterData) {
	const filterList = filterData;

	const patientIds = filterList?.reduce((acc, ele) => {
		if (_.includes(newChecked, ele?._id)) {
			acc.push(...(ele?.patientIds || []));
		}
		return acc;
	}, []) || [];

	const filterType = typeof filters?.type === "string" ? filters.type : filters?.type?.childId;

	return filterCustomPatientData(chartMainDataArr, { [filterType]: newChecked }, {
		...filters,
		patientIds,
		...{ type: filterType, patientIds }
	});
}

export async function processCustomChartFilters(chartMainDataArr, filters, newChecked) {
	const filterList = filters?.filterListData?.[filters?.type];
	const patientIds = filterList?.reduce((acc, ele) => {
		if (_.includes(newChecked, ele?._id)) {
			acc.push(...ele?.patientIds || []);
		}
		return acc;
	}, []) || [];

	const newChartFilters = filterCustomPatientData(
		chartMainDataArr,
		{ [filters?.type]: newChecked },
		{
			...filters,
			patientIds,
		}
	);
	return newChartFilters;
}

export function filterCustomPatientData(patientFilterData, cardFilter, ele) {
	return _.filter(patientFilterData, (eleAccessor) => {
		const questionType = ele?.question?.customQuestionInputType;

		if (questionType === QUESTION_INPUT_TYPE.UNLIMITED_ANSWERS) {
			const accessor = ele?.type;
			const filterValues = cardFilter?.[accessor] || [];
			const elementValue = eleAccessor?.[accessor] || null;

			return filterValues.length === 0 || _.includes(filterValues, elementValue);
		}

		if (questionType === QUESTION_INPUT_TYPE.LIMITED_ANSWERS) {
			if (!cardFilter) return true;

			const accessor = ele?.type;
			const filterValues = cardFilter?.[accessor] || [];
			const elementValue = eleAccessor?.[accessor] || null;

			return filterValues.length === 0 || _.includes(filterValues, elementValue);
		}

		if (
			questionType === QUESTION_INPUT_TYPE.NUMBER_RANGE ||
			questionType === QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS
		) {
			// return ele?.patientIds?.includes(eleAccessor?._id) || !ele?.patientIds?.length;
			return ele?.patientIds?.includes(eleAccessor?._id);
		}

		return true;
	});
}

export async function customDataTabFilter(data, totalFilter, accessor) {
	//insuranceData Cal
	let insuranceDBDataGroup = [];
	if (data && data.length > 0) {

		const insuranceDBData = _.groupBy(data, accessor);
		const originalData = _.groupBy(totalFilter.originalData, accessor);
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (insuranceDBData) {
			insuranceDBDataGroup = await filterListDataItems(insuranceDBData, accessor, percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}

	return insuranceDBDataGroup;
}

const calculateTotalResponses = (patients, accessor, options, totalFilter) => {
	let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : patients?.length;
	const totalType = totalFilter?.totalType;
	let isADT = totalFilter?.isADT ?? false;

	const responseCounts = patients?.reduce((acc, patient) => {
		const value = patient?.[accessor];
		if (value !== undefined) {
			if (!acc[value]) {
				acc[value] = { count: 0, patientIds: [], admissionIds: [] }; // Initialize patientIds array
			}
			acc[value].count++;
			acc[value].patientIds.push(patient._id); // Store patient IDs
			acc[value].admissionIds.push(patient.admissionId);
		}
		return acc;
	}, {});

	return options
		.map((option) => {
			const optionId = option._id ?? option;
			const label = option.label ?? option;
			const totalRes = responseCounts?.[optionId]?.count || 0;
			const patientIds = responseCounts?.[optionId]?.patientIds || [];
			const admissionIds = responseCounts?.[optionId]?.admissionIds || [];

			const result = {
				_id: optionId,
				id: optionId,
				label,
				name: label,
				type: option.type || undefined,
				total: totalRes,
				value: totalRes,
				originalTotal: totalRes,
				percentage: itemPercentage(totalRes, percentageTotal, "number"),
				patientIds: patientIds,
				...(isADT && { transferIds: patientIds }),
				...(isADT && { admissionIds: admissionIds }),
				...(isADT && { ids: patientIds }),
				...(isADT && { type: ADT_TABLE_TYPE.CUSTOM }),
			};

			if (totalType === QUESTION_INPUT_TYPE.UNLIMITED_ANSWERS && totalRes <= 0) {
				return null;
			}

			return result;
		})
		.filter(Boolean);
};

const calculateTotalOrAverage = (patients, accessor, type, title, patientIds, isADT = false) => {
	const patientsFilterArr = patients?.filter(patient => patient?.[accessor] != null) || [];	

	const total = patientsFilterArr.reduce((sum, patient) => {
		const value = Number(patient[accessor]);
		return !isNaN(value) ? sum + value : sum;  // Only add numeric values
	}, 0);
	
	const average = patientsFilterArr.length > 0 ? total / patientsFilterArr.length : 0;

	const formatValue = (value) => value < 1 ? value.toFixed(2) : Math.round(value);

	const result = {
		_id: title,
		id: title,
		label: title,
		name: title,
		total: type === NUMBER_RANGE_TYPE.AVERAGE
			? formatValue(average)
			: formatValue(total),
		isSpacialItem: true,
		percentage: '',
		patientIds,
		...(isADT && { type: ADT_TABLE_TYPE.CUSTOM }),
	};

	return result;
};

// Helper function to calculate totals for number ranges
const calculateTotalResponsesNumberRange = (patients, accessor, options, totalFilterData) => {
	let isADT = totalFilterData?.isADT ?? false;

	return options.map(({ min, max, type, title }) => {
		let patientsFilter = [];

		if (min === ">") {
			patientsFilter = patients?.filter((patient) => {
				const age = Number(patient[accessor]);
				return age > Number(max);
			});
		} else if (max === ">") {
			patientsFilter = patients?.filter((patient) => {
				const age = Number(patient[accessor]);
				return age > Number(min);
			});
		} else {
			let minValue = Number(min);
			let maxValue = Number(max);
			if (minValue > maxValue) {
				[minValue, maxValue] = [maxValue, minValue];
			}
			patientsFilter = patients?.filter((patient) => {
				const age = Number(patient[accessor]);
				return age >= minValue && age <= maxValue;
			}) ?? []
		}
		const total = patientsFilter?.length || 0;
		const label = `${min} - ${max}`;
		const patientIds = patientsFilter?.length > 0 ? patientsFilter.map((item) => item._id) : [];
		const admissionIds = patientsFilter?.length > 0 ? patientsFilter.map((item) => item.admissionId) : [];
		let percentageTotal = totalFilterData?.totalForPercentage ? totalFilterData.totalForPercentage : patients?.length;

		if (type === NUMBER_RANGE_TYPE.TOTAL || type === NUMBER_RANGE_TYPE.AVERAGE) {
			return calculateTotalOrAverage(patients, accessor, type, title, patientIds, isADT);
		}

		return {
			_id: label,
			id: label,
			label,
			name: label,
			total,
			value: total,
			originalTotal: total,
			percentage: itemPercentage(total, percentageTotal, "number"),
			patientIds: patientIds,
			...(isADT && { transferIds: patientIds }),
			...(isADT && { admissionIds: admissionIds }),
			...(isADT && { ids: patientIds }),
			...(isADT && { type: ADT_TABLE_TYPE.CUSTOM }),
		};
	});
};

// Helper function to handle NUMBER_RANGE_LIMITED_ANSWERS
const calculateTotalRangeLimitedResponses = (patients, accessor, options, totalFilter) => {
	const numberRangeOptions = options.filter((opt) => opt.type === 'numberRange');
	const limitedAnswerOptions = options.filter((opt) => opt.type === 'limitedAnswers');
	const totalAndAverageOptions = options.filter((opt) => [NUMBER_RANGE_TYPE.TOTAL, NUMBER_RANGE_TYPE.AVERAGE].includes(opt.type));
	
	const results = calculateTotalResponsesNumberRange(patients, accessor, totalAndAverageOptions, totalFilter);
	
	const numberRangeResults = calculateTotalResponsesNumberRange(patients, accessor, numberRangeOptions, totalFilter);
	const limitedAnswerResults = calculateTotalResponses(
		patients,
		accessor,
		limitedAnswerOptions.map((opt) => opt.option),
		totalFilter
	);

	return [...numberRangeResults, ...limitedAnswerResults, ...results];
};

export function processDynamicCard(item, patientFilterData, objectCustomRes, totalFilterData) {
	const { customQuestionInputType, accessor, validationOptions, customQuestionOptions = [] } = item;

	switch (customQuestionInputType) {
		case QUESTION_INPUT_TYPE.UNLIMITED_ANSWERS:
			objectCustomRes[accessor] = calculateTotalResponses(patientFilterData, accessor, validationOptions, { ...totalFilterData, totalType: QUESTION_INPUT_TYPE.UNLIMITED_ANSWERS });
			break;

		case QUESTION_INPUT_TYPE.LIMITED_ANSWERS:
			objectCustomRes[accessor] = calculateTotalResponses(patientFilterData, accessor, customQuestionOptions, totalFilterData);
			break;

		case QUESTION_INPUT_TYPE.NUMBER_RANGE:
			objectCustomRes[accessor] = calculateTotalResponsesNumberRange(patientFilterData, accessor, customQuestionOptions, totalFilterData);
			break;

		case QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS:
			objectCustomRes[accessor] = calculateTotalRangeLimitedResponses(patientFilterData, accessor, customQuestionOptions, totalFilterData);
			break;

		default:
			break;
	}
}

export function filterDynamicQuestions(allDynamicCards, optionId) {
	let dynamicQuestion = [];

	if (optionId === PAGE_TYPE.HOSPITAL) {
		dynamicQuestion = _.filter(allDynamicCards, {
			forType: 'transfer',
			forTransferType: 'hospitalTransfer',
		});
	} else if (optionId === PAGE_TYPE.COMMUNITY_TRANSFER) {
		const validTransferTypes = [
			ADT_SUB_TYPES.AMA,
			ADT_SUB_TYPES.SAFE_DISCHARGE,
			ADT_SUB_TYPES.SNF,
		];
		dynamicQuestion = _.filter(allDynamicCards, (ele) =>
			ele.forType === 'transfer' && _.includes(validTransferTypes, ele.forTransferType)
		);
	} else if (optionId === PAGE_TYPE.DECEASED) {
		dynamicQuestion = _.filter(allDynamicCards, {
			forType: 'transfer',
			forTransferType: 'deceased',
		});
	}

	return dynamicQuestion;
}

export async function dynamicCardFilter(patientFilterData, dynamicCards, totalFilterData) {
	const objectCustomRes = {};
	for (const item of dynamicCards) {
		processDynamicCard(item, patientFilterData, objectCustomRes, totalFilterData);
	}
	return objectCustomRes;
}

export const validateQuestionValues = (values) => {
	const errors = {};

	if (!values.label) {
		errors.label = 'Label is required.';
	}

	if (!values.customQuestionInputType) {
		errors.customQuestionInputType = 'Input type is required.';
	}

	const shouldCheckOptions = [
		QUESTION_INPUT_TYPE.LIMITED_ANSWERS,
		QUESTION_INPUT_TYPE.NUMBER_RANGE,
		QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS,
	].includes(values.customQuestionInputType);

	if (shouldCheckOptions) {
		if (!values.customQuestionOptions || values.customQuestionOptions.length === 0) {
			errors.customQuestionOptions = 'Options are required.';
		} else {
			if (values.customQuestionInputType === QUESTION_INPUT_TYPE.LIMITED_ANSWERS) {
				if (!Array.isArray(values.customQuestionOptions)) {
					errors.customQuestionOptions = 'Options must be an array.';
				} else if (values.customQuestionOptions.every(option => !option)) {
					errors.customQuestionOptions = 'Options must contain at least one valid option.';
				}
			} else if (values.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE) {

				if (!Array.isArray(values.customQuestionOptions)) {
					errors.customQuestionOptions = 'Range must be an array.';
				} else {

					values.customQuestionOptions.forEach((range, index) => {
						const isMinSymbol = typeof range.min === 'string' && (range.min.includes('<') || range.min.includes('>'));
						const isMaxSymbol = typeof range.max === 'string' && (range.max.includes('<') || range.max.includes('>'));
						const isMinNumber = !isNaN(range.min) && range.min !== '';
						const isMaxNumber = !isNaN(range.max) && range.max !== '';

						if (range.type === NUMBER_RANGE_TYPE.TOTAL || range.type === NUMBER_RANGE_TYPE.AVERAGE) {
							if (range.title === undefined || range.title === '') {
								if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
								if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
								errors.customQuestionOptions[index].title = `${range.type} Title is required.`;
							}
						} else {
							if (range.min === undefined || range.min === '') {
								if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
								if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
								errors.customQuestionOptions[index].min = 'Min value is required.';
							}

							if (range.max === undefined || range.max === '') {
								if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
								if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
								errors.customQuestionOptions[index].max = 'Max value is required.';
							}

							if (isMinSymbol && isMaxSymbol) {
								if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
								if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
								errors.customQuestionOptions[index].min = 'Min and Max cannot both be symbols.';
								errors.customQuestionOptions[index].max = 'Min and Max cannot both be symbols.';
							}

							if (isMinNumber && isMaxNumber) {
								if (Number(range.min) > Number(range.max)) {
									if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
									if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
									errors.customQuestionOptions[index].min = 'Min value must be less than or equal to Max value.';
									errors.customQuestionOptions[index].max = 'Max value must be greater than or equal to Min value.';
								}
							}
						}
					});
				}
			} else if (values.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE_LIMITED_ANSWERS) {
				if (!Array.isArray(values.customQuestionOptions)) {
					errors.customQuestionOptions = 'Range or option must be an array.';
				} else {
					const hasRange = values.customQuestionOptions.some(item => typeof item === 'object' && item !== null && item.type === 'numberRange' && (item.min !== null && item.min !== undefined && item.min !== '') && (item.max !== null && item.max !== undefined && item.max !== ''));
					const hasLimitedAnswers = values.customQuestionOptions.some(item => typeof item === 'object' && item !== null && item.type === 'limitedAnswers' && (item.option !== null && item.option !== undefined && item.option !== ''));

					if (!hasRange && !hasLimitedAnswers) {
						errors.customQuestionOptions = 'Must contain at least one range or one limited answer.';
					} else {

						values.customQuestionOptions.forEach((option, index) => {
							if (typeof option === 'object' && option !== null) {

								if ([NUMBER_RANGE_TYPE.TOTAL, NUMBER_RANGE_TYPE.AVERAGE].includes(option.type)) {
									if (!option.title) {
										errors.customQuestionOptions = errors.customQuestionOptions || [];
										errors.customQuestionOptions[index] = errors.customQuestionOptions[index] || {};
										errors.customQuestionOptions[index].title = `${option.type} Title is required.`;
									}
								}

								if (option.type === 'numberRange') {
									const isMinSymbol = typeof option.min === 'string' && (option.min.includes('<') || option.min.includes('>'));
									const isMaxSymbol = typeof option.max === 'string' && (option.max.includes('<') || option.max.includes('>'));
									const isMinNumber = !isNaN(option.min) && option.min !== '';
									const isMaxNumber = !isNaN(option.max) && option.max !== '';

									if (option.min === undefined || option.min === '') {
										if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
										if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
										errors.customQuestionOptions[index].min = 'Min value is required.';
									}

									if (option.max === undefined || option.max === '') {
										if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
										if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
										errors.customQuestionOptions[index].max = 'Max value is required.';
									}

									if (isMinSymbol && isMaxSymbol) {
										if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
										if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
										errors.customQuestionOptions[index].min = 'Min and Max cannot both be symbols.';
										errors.customQuestionOptions[index].max = 'Min and Max cannot both be symbols.';
									}

									if (isMinNumber && isMaxNumber) {
										if (Number(option.min) > Number(option.max)) {
											if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
											if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
											errors.customQuestionOptions[index].min = 'Min value must be less than or equal to Max value.';
											errors.customQuestionOptions[index].max = 'Max value must be greater than or equal to Min value.';
										}
									}
								} else if (option.type === 'limitedAnswers') {
									if (!option.option) {
										if (!errors.customQuestionOptions) errors.customQuestionOptions = [];
										if (!errors.customQuestionOptions[index]) errors.customQuestionOptions[index] = {};
										errors.customQuestionOptions[index].option = 'Option is required.';
									}
								}
							}
						});
					}
				}
			}
		}
	}
	return errors;
};

export const updateChartFilterByPriority = async (cardFilter, type) => {
	let latestCardFilter = cardFilter;
	const selectedPriority = _.find(cardFilter.priorityData, { type: type });
	if (selectedPriority) {
		let idx = cardFilter.priorityData.findIndex((elem) => elem.type === type);
		if (idx !== -1) {
			let selectedCardPriority = [];
			let selectedFilters = [];
			if (cardFilter.priorityData.length > 1) {
				selectedCardPriority = cardFilter.priorityData.slice(0, idx);
				selectedFilters = _.map(selectedCardPriority, "type");
			}
			let updatedFilter = Object();
			for await (const [key, value] of Object.entries(cardFilter)) {
				if (_.includes(selectedFilters, key)) {
					updatedFilter[key] = value;
				} else {
					updatedFilter[key] = [];
				}
			}
			latestCardFilter = updatedFilter;
		}
	}
	return latestCardFilter;
};

export const ADTpriorityFilter = async (priorityDataFilter, childId, cardId, latestAdmitFilters = []) => {
	const selectedFilter = _.find(latestAdmitFilters, { childId, cardId });

	const totalChecked = selectedFilter?.selectedIds?.length || 0;
	let priorityData = [];
	const selectedPriority = _.find(priorityDataFilter, { childId, cardId });
	if (selectedPriority) {
		const idx = priorityDataFilter.findIndex((p) => p.childId === childId && p.cardId === cardId);

		if (totalChecked > 0) {
			priorityData = update(priorityDataFilter, {
				[idx]: { $set: selectedFilter },
			});
		} else {
			priorityData = update(priorityDataFilter, {
				$splice: [[idx, 1]],
			});
		}
	} else {
		if (totalChecked > 0) {
			priorityData = update(priorityDataFilter, { $push: [selectedFilter] });
		}
	}
	return priorityData;
};

export const priorityFilterOld = async (priorityDataFilter, type, totalChecked, cardTitle, question, patientIds, isRemoveIds) => {
	let priorityData = [];
	const selectedPriority = _.find(priorityDataFilter, { type });
	if (selectedPriority) {
		const idx = priorityDataFilter.findIndex((p) => p.type === type);
		if (totalChecked > 0) {
			let priObject = { type, priority: 1, selectedChk: totalChecked, cardTitle };
			priorityData = update(priorityDataFilter, { [idx]: { $set: priObject } });
		} else {
			priorityData = update(priorityDataFilter, {
				$splice: [[idx, 1]],
			});
		}
	} else {
		if (totalChecked > 0) {
			let priObject = { type, priority: 1, selectedChk: totalChecked, cardTitle };
			priorityData = update(priorityDataFilter, { $push: [priObject] });
		}
	}
	return priorityData;
};

export const priorityFilter = async (priorityDataFilter, type, totalChecked, cardTitle, question, patientIds, isRemoveIds) => {

	let priorityData = [];
	const selectedPriority = _.find(priorityDataFilter, { type });
	if (selectedPriority) {
		const idx = priorityDataFilter.findIndex((p) => p.type === type);
		if (totalChecked > 0) {
			let priObject = {
				type,
				priority: 1,
				selectedChk: totalChecked,
				cardTitle,
				...(question !== undefined && question !== null && { question }),
			};

			if (patientIds) {
				if (isRemoveIds) {
					priObject.patientIds = _.difference(selectedPriority.patientIds || [], patientIds);
				} else {
					priObject.patientIds = _.union(selectedPriority.patientIds || [], patientIds);
				}
			} else {
				priObject.patientIds = selectedPriority.patientIds;
			}

			priorityData = update(priorityDataFilter, { [idx]: { $set: priObject } });
		} else {
			priorityData = update(priorityDataFilter, {
				$splice: [[idx, 1]],
			});
		}
	} else {
		if (totalChecked > 0) {
			let priObject = {
				type,
				priority: 1,
				selectedChk: totalChecked,
				cardTitle,
				...(question !== undefined && question !== null && { question }),
				patientIds: patientIds || [],
			};
			priorityData = update(priorityDataFilter, { $push: [priObject] });
		}
	}
	return priorityData;
};

export const admissionPriorityFilter = async (
	priorityDataFilter,
	cardFilterPart,
	existingFilters,
	filterType,
	cardTitle = null,
	question,
	patientIds,
	isRemoveIds
) => {
	let priorityData = [];
	if (cardFilterPart && cardFilterPart.type) {
		let type = cardFilterPart.type;
		const selectedPriority = _.find(priorityDataFilter, { type });
		if (selectedPriority) {
			const idx = priorityDataFilter.findIndex((p) => p.type === type);
			if (existingFilters > 0) {
				let priObject = {
					type,
					priority: 1,
					selectedChk: existingFilters,
					filterType,
					cardTitle,
					...(question !== undefined && question !== null && { question }),
				};
				if (patientIds) {
					if (isRemoveIds) {
						priObject.patientIds = _.difference(selectedPriority.patientIds || [], patientIds);
					} else {
						priObject.patientIds = _.union(selectedPriority.patientIds || [], patientIds);
					}
				} else {
					priObject.patientIds = selectedPriority.patientIds;
				}
				priorityData = update(priorityDataFilter, {
					[idx]: { $set: priObject },
				});
			} else {
				priorityData = update(priorityDataFilter, {
					$splice: [[idx, 1]],
				});
			}
		} else {
			if (existingFilters > 0) {
				let priObject = {
					type,
					priority: 1,
					selectedChk: existingFilters,
					filterType,
					cardTitle,
					patientIds: patientIds || [],
					...(question !== undefined && question !== null && { question }),
				};
				priorityData = update(priorityDataFilter, { $push: [priObject] });
			}
		}
	} else {
		let childId = cardFilterPart.childId;
		let cardId = cardFilterPart.cardId;
		let latestAdmitFilters = existingFilters;
		let selectedFilter = _.find(latestAdmitFilters, { childId, cardId });
		if (selectedFilter) {
			selectedFilter = Object.assign({}, selectedFilter, { filterType });
		}
		const totalChecked = selectedFilter?.selectedIds?.length || 0;
		const selectedPriority = _.find(priorityDataFilter, { childId, cardId });
		if (selectedPriority) {
			const idx = priorityDataFilter.findIndex((p) => p.childId === childId && p.cardId === cardId);
			if (totalChecked > 0) {
				priorityData = update(priorityDataFilter, {
					[idx]: { $set: selectedFilter },
				});
			} else {
				priorityData = update(priorityDataFilter, {
					$splice: [[idx, 1]],
				});
			}
		} else {
			if (totalChecked > 0) {
				priorityData = update(priorityDataFilter, { $push: [selectedFilter] });
			}
		}
	}
	return priorityData;
};

export function projectionPerMonth(total, filter, projectionDays = 30) {
	let projections = 0;
	const { startDate, endDate } = filter;
	var a = moment.utc(startDate).startOf("day");
	var b = moment.utc(endDate).endOf("day");
	let days = b.diff(a, "days") + 1;
	//days = days === 0 ? 1 : (days + 1);
	if (days <= 30) {
		//projectionDays = 30
		projections = (projectionDays * total) / days;
	} else {
		//projectionDays = 30
		const months = days / projectionDays;
		projections = total / months;
	}
	return projections ? Math.round(projections) : 0;
}

export function itemPercentageChart(total, mainTotalVal = null) {
	let percentage = 0;
	if (total !== 0 && mainTotalVal !== 0) {
		percentage = (total / mainTotalVal) * 100;
	}
	percentage = percentage ? percentage : 0;

	return `${percentage.toFixed(2)}%`;
}

export function itemPercentage(total, mainTotalVal = null, type, page = "hospital", forComparison) {
	let selectedTotal = 0;
	if (!mainTotalVal) {
		if (page === PAGE_TYPE.HOSPITAL) {
			const { mainTotal } = store.getState().hospital;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.ADMISSION) {
			const { mainTotal } = store.getState().admission;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.COMMUNITY_TRANSFER) {
			const { mainTotal } = store.getState().communityTransfer;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.DECEASED) {
			const { mainTotal } = store.getState().deceased;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.OVERALL) {
			const { mainTotal } = store.getState().overall;
			selectedTotal = mainTotal;
		}
	} else {
		selectedTotal = mainTotalVal;
	}
	let percentage = 0;

	if (forComparison && total === mainTotalVal) {
		percentage = 0;
	} else if (total !== 0 && selectedTotal !== 0) {
		percentage = (total / selectedTotal) * 100;
	}
	percentage = percentage ? percentage : 0;
	if (type && type === "number") {
		return percentage < 1 ? percentage.toFixed(1) : percentage.toFixed();
	}
	if (type && type === "percentage") {
		return `${percentage.toFixed()}%`;
	}
	return `(${percentage.toFixed()}%)`;
}

export function matchedArray(array1, array2) {
	return _.intersectionWith(array1, array2, _.isEqual);
}

export function getMatchedId(array1, array2) {
	let resArr = [];
	_.intersectionWith(array1, array2, (a, b) => {
		if (a._id === b) resArr = [...resArr, ...a.ids];
	});
	return resArr;
}

export function getMatchedCount(array1, array2, type) {
	let totalCount = 0;
	_.intersectionWith(array1, array2, (a, b) => {
		if (a._id === b) totalCount++;
	});
	return { [type + "Total"]: totalCount };
}

export async function getArrayIds(array1) {
	let resArr = [];
	array1.map((item) => {
		if (item.ids.length > 0) {
			resArr = [...resArr, ...item.ids];
		}
		return true;
	});
	return resArr;
}

export async function checkArrayPermission(checkedPermission) {
	let response = false;
	const { permissions, user } = store.getState();
	const intersection = matchedArray(permissions, checkedPermission);
	if (intersection.length > 0 || user?.role.slug === "super") {
		response = true;
	} else {
		response = false;
	}
	return response;
}

export async function getChartFilterId(type, filter) {
	return { type, ids: filter[type] };
}

export function percentageLabel(percentage, type = "bracket") {
	percentage = percentage ? percentage : 0;
	if (type === "percentage") {
		return `${percentage}%`;
	} else {
		return `(${percentage}%)`;
	}
}

//This function are used for nineteen days analysis
export async function updateListTotalValue(listData, matchedIds, type = "total", totalPatients = 0, totalFilter) {
	const { totalType } = totalFilter;
	if (listData && listData.length > 0) {
		const latestData = listData.map((RItem) => {
			const intersection = matchedArray(matchedIds, RItem.ids);
			let obj = {
				[type]: intersection.length || 0,
				ids: intersection,
				percentage: itemPercentage(intersection.length, totalPatients, "number"),
			};
			if (totalType === TOTAL_TYPE.MAIN) {
				obj.originalTotal = intersection.length || 0;
				obj.isTooltip = false;
			} else {
				obj.originalTotal = RItem?.originalTotal || 0;
				obj.isTooltip = true;
			}
			return Object.assign({}, RItem, obj);
		});
		return latestData;
	}
}

export async function filterListDataItems(dataGroupBy, type, totalPatient, totalFilter) {
	const { originalData = [], totalType = null } = totalFilter;

	let listGroup = [];
	if (dataGroupBy) {
		for await (const [key, value] of Object.entries(dataGroupBy)) {
			const valueArr = value[0];
			if (key && valueArr && valueArr[type]) {
				let object = Object();
				object._id = key;
				object.id = key;
				object.label = valueArr[type].label;
				object.name = valueArr[type].label;
				object.total = value.length;
				object.value = value.length;
				let original = originalData[key] ? originalData[key]?.length : 0;
				object.originalTotal = original;
				object.isTooltip = totalType && totalType === TOTAL_TYPE.MAIN ? false : true;
				object.percentage = itemPercentage(value.length, totalPatient, "number");
				listGroup.push(object);
			}
		}
		if (listGroup.length > 0) {
			listGroup = _.orderBy(listGroup, "total", "desc");
		}
	}
	return listGroup;
}

export const getPositivePlusSign = (value) => {
	if (value > 0) return `+${value}`;
	return `${value}`;
};

export const getDaysBetweenDates = (filter) => {
	const start = new Date(filter.startDate);
	const end = new Date(filter.endDate);

	const timeDiff = Math.abs(end - start);
	const parsedDiff = Math.round(timeDiff / (1000 * 60 * 60 * 24)) + 1;

	return parsedDiff;
};

export const updateCurrentFilterListData = (currentFilterListdata, latestFilterListData) => {
	const currentFilterListDataCopy = { ...currentFilterListdata };
	for (const key in latestFilterListData) {
		if (currentFilterListDataCopy.hasOwnProperty(key)) {
			currentFilterListDataCopy[key] = latestFilterListData?.[key] ?? [];
		}
	}
	return currentFilterListDataCopy;
};

export const areArraysDifferent = (arr1, arr2) => {
	if (arr1.length !== arr2.length) {
		return true; // Arrays are different if lengths are not equal
	}
	return !arr1.every((item) => arr2.includes(item)); // Check if every item in arr1 is in arr2
};

export const calcProPercentsBasedOnFilterAndDays = (value, filter, projectionDays, isPercentage, scale) => {
	if ((scale && isPercentage) || !isPercentage) {
		const days = getDaysBetweenDates(filter);
		const projectionDaysParsed = projectionDays === 0 || !projectionDays ? days : projectionDays;
		const scalingFactor = projectionDaysParsed / days;
		if (filter?.transferType === OVERALL_PAGE_SUB_TYPE.TOTAL) {
			const scaledValue = Number(value) * scalingFactor;
			const result = scaledValue < 1 ? scaledValue : Math.round(scaledValue);
			return result > 0 ? `+${result}` : result;
		}
		return (Number(value) * scalingFactor) && (Number(value) * scalingFactor) < 1 ? (Number(value) * scalingFactor) : Math.round(Number(value) * scalingFactor);
	} else {
		return value;
	}
};

export const getPercentageByTotal = (total, pageType = null) => {
	const { selectedAccount } = store.getState().common;
	if (selectedAccount && selectedAccount?.percentageBy === ACCOUNT_PERCENTAGE_BY.BED) {
		return total?.bedCapacity;
	} else {
		if (pageType === PAGE_TYPE.HOSPITAL) {
			const { percentageAgainst } = store.getState().hospital;
			return percentageAgainst;
		} else if (pageType === PAGE_TYPE.COMMUNITY_TRANSFER) {
			const { percentageAgainst } = store.getState().communityTransfer;
			return percentageAgainst;
		} else {
			return total?.censusAverage;
		}
	}
};

export const getPercentageByType = (type = null) => {
	const { selectedAccount } = store.getState().common;
	if (selectedAccount && selectedAccount?.percentageBy === ACCOUNT_PERCENTAGE_BY.BED) {
		return type ? ACCOUNT_PERCENTAGE_BY.BED : _.capitalize(selectedAccount?.percentageBy);
	} else {
		return type ? ACCOUNT_PERCENTAGE_BY.CENSUS : _.capitalize(ACCOUNT_PERCENTAGE_BY.CENSUS);
	}
};

export const isOnlyHospitalTabAccess = () => {
	const { selectedAccount } = store.getState().common;
	if (selectedAccount && selectedAccount?.dashboardAccess && selectedAccount?.dashboardAccess.length === 1) {
		return true;
	} else {
		return false;
	}
};

export function getTooltipTitle(facilities, activeFacilities, type = "one") {
	// eslint-disable-next-line array-callback-return
	if (facilities && facilities === "refreshing") return null;
	const sFacilityNames = facilities?.map((facility) => {
		if (_.includes(type === "all" ? activeFacilities : activeFacilities.slice(1), facility._id)) {
			return facility?.name;
		}
		return undefined;
	})
		.filter((ele) => ele)
		.join(", ");

	return sFacilityNames;
}

export function getChartFacilityPercentageBy(response) {
	const { customPercentageLabel, percentageBy, customePercentages } = store.getState().common;

	if (percentageBy === percentageByEnum.bedCapacity) {
		return response?.bedByFacility || [];
	} else if (percentageBy === percentageByEnum.censusAverage) {
		return response?.censusByFacility || []
	} else if (percentageBy === percentageByEnum.censusAsOfNow) {
		return response?.censusAsOfNowByFacility || []
	} else if (percentageBy === customPercentageLabel) {
		let percentageNumber = _.find(customePercentages, { label: customPercentageLabel })?.customPercentage ?? 0;
		return response?.censusByFacility?.map((ele) => {
			return { ...ele, total: percentageNumber }
		});
	} else {
		return response?.censusByFacility || []
	}
}

export function getDynamicPercentageBy(dbData, percentageByType = null, isOnlyHospitalDashboard = false) {
	let percentageNumber = dbData?.censusAverage;
	const { percentageBy, customPercentageLabel, customPercentage, customePercentages } = store.getState().common;

	let type = percentageByType ? percentageByType : percentageBy;

	if (type === percentageByEnum.bedCapacity) {
		percentageNumber = dbData?.bedCapacity;
	} else if (type === percentageByEnum.censusAsOfNow && !isOnlyHospitalDashboard) {
		percentageNumber = dbData?.censusAsOfNow;
	} else if (type === percentageByEnum.customNumber) {
		percentageNumber = customPercentage;
	} else if (type === customPercentageLabel) {
		percentageNumber = _.find(customePercentages, { label: customPercentageLabel })?.customPercentage ?? 0;
	}

	return percentageNumber;
}

export function updateOrAddToArray(array, newObject, identifierKey) {
	if (!array || array.length === 0) return [newObject];

	const index = array?.findIndex(item => item[identifierKey] === newObject[identifierKey]);

	if (index !== -1) {
		// Object exists, update it
		array[index] = { ...array[index], ...newObject };
	} else {
		// Object doesn't exist, add it
		array.push(newObject);
	}

	return array;
}

export const patientDataOrderBy = (patientData, order, orderBy) => {
	return _.orderBy(patientData,
		[
			(item) => {
				// Handle primary sort
				if (orderBy === 'DOB' || orderBy === 'dateOfADT' || orderBy === 'dateOfLatestAdmission') {
					return new Date(item[orderBy]); // Convert to Date object for comparison
				}
				return item[orderBy]; // For other fields
			},
			(item) => new Date(item?.dateOfADT) // Always sort by dateOfADT as secondary
		],
		[order, 'asc'] // Primary order direction and secondary is always ascending
	)
}