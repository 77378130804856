import * as React from "react";
import { Checkbox } from "@mui/material";
import CardItemTooltip from "../../../shared/CardItemTooltip";

const CheckboxButton = ({
	handleToggle,
	labelId,
	checked,
	textWrapColorClass,
	buttonText,
	className,
	size,
	disableRipple,
	disabled,
	sx,
	item,
	colorBox = null,
	isSpacialItem = false
}) => {
	return (
		<>
			{!isSpacialItem && (
				<Checkbox
					className={className}
					edge={"start"}
					disabled={disabled}
					disableRipple
					sx={{ mr: 0.1, ...(sx && sx) }}
					onChange={(e) => handleToggle(e)}
					checked={checked}
					inputProps={{ "aria-labelledby": labelId }}
					{...(size && { size: size })}
					{...(disableRipple && { disableRipple: disableRipple })}
				/>
			)}
			<CardItemTooltip item={item}>
				<div className={`df aic ${textWrapColorClass}`} id={labelId}>
					{colorBox && <span>{colorBox}</span>}
					<p className={`ffmar fs12 fw700`}>{buttonText}</p>
				</div>
			</CardItemTooltip>
		</>
	);
};

export default CheckboxButton;
