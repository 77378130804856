import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Stack } from "@mui/material";
import { itemPercentage } from "../../utilis/common";


export const CartItemTooltipContainer = styled(({ className, ...props }) => {
	return <Tooltip placement="top" children={props.children} classes={{ popper: className }} {...props} />;
})(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		color: "rgba(0, 0, 0, 0.87)",
		fontFamily: "manrope",
		fontSize: "12px",
		border: "1px solid #dadde9",
		background: "#FFFFFF",
		boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.13)",
		borderRadius: "8px",
		top: "-10px",
		padding: "5px",
	},
}));

export default function CardItemTooltip({ children, item }) {
	if (item?.isTooltip) {
		return (
			<CartItemTooltipContainer
				title={
					<Stack direction={"column"} sx={{ padding: 0.2 }} spacing={1}>
						<Stack item>Original total : {item?.originalTotal || 0}</Stack>
						<Stack item>Filtered total : {item?.total || item?.value || 0}</Stack>
						{!item?.isTotalPercentage && (
							<Stack item>
								Percentage : {itemPercentage(item?.total || item?.value, item?.originalTotal, "percentage")}
							</Stack>
						)}
					</Stack>
				}
			>
				<span>
					{children}
				</span>
			</CartItemTooltipContainer>
		);
	} else {
		return children;
	}
}
