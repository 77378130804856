import * as React from "react";
import { Checkbox } from "@mui/material";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import { styled } from '@mui/material/styles';


const CircleCheckbox = styled(Checkbox)(({ theme, checked }) => ({
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    border: `2px solid ${checked ? theme.palette.primary.main : "#ccc"}`,  // Border color changes when checked
    transition: "0.3s",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
        display: "none"  // Hide default icon
    },
    ...(checked && {
        backgroundColor: theme.palette.primary.main,  // Inner circle when checked
        "&::after": {
            content: '""',
            display: "block",
            width: "14px",
            height: "14px",
            backgroundColor: "#fff",   // White inner circle
            borderRadius: "50%",
        }
    })
}));

const CheckboxCircle = ({
    handleToggle,
    labelId,
    checked,
    textWrapColorClass,
    buttonText,
    className,
    size,
    disableRipple,
    disabled,
    sx,
    item,
    colorBox = null,
}) => {
    return (
        <>
            <CircleCheckbox
                className={className}
                edge={"start"}
                disabled={disabled}
                disableRipple
                sx={{ ml: "-2px", mr: 0.9, ...(sx && sx) }}
                onChange={(e) => handleToggle(e)}
                checked={checked}
                inputProps={{ "aria-labelledby": labelId }}
                {...(size && { size: size })}
                {...(disableRipple && { disableRipple: disableRipple })}
            />
            <CardItemTooltip item={item}>
                <div className={`df aic ${textWrapColorClass}`} id={labelId}>
                    {colorBox && <span>{colorBox}</span>}
                    <p className={`ffmar fs12 fw700`}>{buttonText}</p>
                </div>
            </CardItemTooltip>
        </>
    );
};

export default CheckboxCircle;
