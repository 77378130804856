import { store } from "..";
import { setFilterDBData } from "../store/reducers/overall.slice";
import { getCardPatientData, getCardPatientChartData, getAllOverallCount } from "./api/overall.api";
import {
	setFilterDBData as setFilterDBDataComparison,
	setCardFilterOverall,
} from "../store/reducers/comparisonReducers/overallComparison.slice";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const ongoingOverallRequestsChart = new Map();

const cardPatientAndChartData = async (data = null, type) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	if (ongoingOverallRequestsChart.has(requestKey)) {
		const { controller } = ongoingOverallRequestsChart.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingOverallRequestsChart.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;
	try {
		const requestPromise = getCardPatientChartData(data, { signal });
		ongoingOverallRequestsChart.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;
		return res;
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Cleanup: Remove the request from tracking once it completes
		ongoingOverallRequestsChart.delete(requestKey);
	}
};

const ongoingOverallRequests = new Map();

const getAllOverallPatient = async (data = null, forComparison, type) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	if (ongoingOverallRequests.has(requestKey)) {
		const { controller } = ongoingOverallRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingOverallRequests.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;

	try {

		const requestPromise = getAllOverallCount(data, { signal });
		ongoingOverallRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;

		if (res && forComparison) {
			store.dispatch(setCardFilterOverall({ listData: res }));
		}
		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));

		return res.data;
	} catch (e) {
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Cleanup: Remove the request from tracking once it completes
		ongoingOverallRequests.delete(requestKey);
	}
};

// const getAllOverallPatient = async (data = null, forComparison) => {
// 	try {
// 		const res = await getAllOverallCount(data);
// 		if (res && forComparison) {
// 			store.dispatch(setCardFilterOverall({ listData: res }));
// 		}
// 		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
// 		return res.data;
// 	} catch (e) {}
// };

export { cardPatientData, cardPatientAndChartData, getAllOverallPatient };
