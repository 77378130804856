import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grow, IconButton, keyframes, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Keyframe animation for smooth popover opening (optional)
const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Slow blue shine + smooth scale-in animation
const fadeIn = keyframes`
  0% { 
    opacity: 0; 
    transform: scale(0.8); 
    box-shadow: 0 0 40px rgba(0, 123, 255, 1), 0 0 20px rgba(0, 123, 255, 0.8); 
  }
  40% { 
    opacity: 0.7; 
    transform: scale(1.05); 
    box-shadow: 0 0 30px rgba(0, 123, 255, 0.6), 0 0 15px rgba(0, 123, 255, 0.5); 
  }
  100% { 
    opacity: 1; 
    transform: scale(1); 
    box-shadow: 0 0 0 rgba(0, 123, 255, 0); 
  }
`;

// Smooth fade-out effect
const fadeOut = keyframes`
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(0.9); }
  100% { opacity: 0; transform: scale(0.8); }
`;

const shineEffect = keyframes`
  0% { left: -100%; }
  100% { left: 100%; }
`;

const Item = styled(Button)(({ theme, isVisible, open, isLocked }) => ({
    position: "relative",
    overflow: "hidden",
    backgroundColor: "#EEF3F7",
    borderRadius: open ? "3px 3px 0 0" : "3px",
    ...theme.typography.body2,
    padding: 6,
    marginTop: "4px",
    textAlign: "center",
    height: 23,
    color: "#636578",
    marginLeft: 10,
    fontFamily: "inter",
    fontWeight: 500,
    fontSize: 11,
    lineHeight: 13,
    opacity: 0,

    animation: !isLocked && `${isVisible ? fadeIn : fadeOut} 2s ease-out forwards`, // Apply animation only if not locked

    ...(isLocked && {
        boxShadow: "0 0 6px 3px rgba(221, 190, 15, 0.71)", // Always apply box shadow if locked
        opacity: isVisible ? 1 : 0, // Set opacity based on isVisible when locked
        transform: isVisible ? "scale(1)" : "scale(0.8)", // Set scale based on isVisible when locked
        transition: "opacity 2s ease-out, transform 2s ease-out", // Add transition for smooth change
    }),

    "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: "-100%",
        width: "150%",
        height: "100%",
        background: "linear-gradient(120deg, transparent 30%, rgba(255, 255, 255, 0.6) 50%, transparent 70%)",
        animation: `${shineEffect} 1.5s ease-out 0.5s forwards`,
    },

    "&:hover": {
        background: "#EEF3F7",
        transform: "scale(1.05)",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },

    "& span": {
        color: "#636578",
        fontWeight: 700,
        fontSize: 11,
        marginLeft: 2,
    },

    "@media (max-width: 1366px)": {
        marginLeft: 2,
    }
}));



export default function HeaderItem({
    total,
    label,
    type = "filter",
    data,
    cardFilter,
    isLocked
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [visible, setVisible] = React.useState(false);

    // Hide the item after 5 seconds with smooth fade-out
    React.useEffect(() => {
        if (total > 0 || type === "label") {
            setVisible(true);
        }
        if (total === 0 && type !== "label") {
            setVisible(false);
        }
    }, [total, type]);

    const subItems = React.useMemo(() => {
        if (!Array.isArray(data) || !Array.isArray(cardFilter)) {
            return [];
        }

        return data
            .filter(({ _id }) => cardFilter.includes(_id))
            .map(({ label }) => label);
    }, [data, cardFilter]);

    const open = Boolean(anchorEl);

    if (!visible) return null;


    return (
        <>
            <Item
                isVisible={visible}
                open={open}
                isLocked={isLocked}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(open ? null : event.currentTarget)}
            >
                {type === "label" ? label : (
                    <>{label}</>
                )}
                {type !== "label" && (
                    <IconButton size="small" sx={{ padding: 0 }}>
                        <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                )}
            </Item>
            {type !== "label" && (
                <Popover
                    id="smooth-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Grow} // Smooth opening animation
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        '& .MuiPopover-paper': {
                            backgroundColor: '#EEF3F7',
                            borderRadius: 0.1,
                            width: anchorEl ? anchorEl.offsetWidth : 'auto',
                            animation: `${slideDown} 0.5s ease-out`, // Custom animation
                        },
                    }}
                >
                    <Typography sx={{ p: 1 }}>
                        {subItems.length > 0 ? (
                            <List sx={{ padding: 0, margin: 0 }} dense>
                                {subItems.map((item, index) => (
                                    <ListItem key={index} sx={{ padding: 0, margin: 0 }} dense>
                                        <ListItemText
                                            primary={item}
                                            sx={{ margin: 0, padding: 0, fontSize: 5 }}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: "11px",
                                                    fontFamily: "inter",
                                                    fontWeight: 500,
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            'No items available'
                        )}
                    </Typography>
                </Popover>
            )}
        </>
    );
}
