/* eslint-disable array-callback-return */
import _ from "lodash";
import { store } from "..";
import { TYPES } from "../data/hospital.data";
import {
	setFacilityPercentage as setFacilityPercentageComparison,
	setFilterTotal as setFilterTotalComparison,
	setIsCensusTotalLocked as setIsCensusTotalLockedComparison,
	setLockTotal as setLockTotalComparison,
	setLockedByFacility as setLockedByFacilityComparison,
	setLockedTotalBy as setLockedTotalByComparison,
} from "../store/reducers/comparisonReducers/hospitalComparison.slice";
import {
	setFacilityPercentage,
	setFilterTotal,
	setIsCensusTotalLocked,
	setLockTotal,
	setLockedByFacility,
	setLockedTotalBy,
} from "../store/reducers/hospital.slice";
import { TOTAL_TYPE } from "../types/common.type";
import { HOSPITAL_CARDS_TYPE } from "../types/hospital.type";
import { PAGE_TYPE } from "../types/pages.type";
import { dynamicCardFilter, filterCustomPatientData, filterListDataItems, getDynamicPercentageBy, getPercentageByTotal, itemPercentage, processDynamicCard, updateListTotalValue } from "./common";
import { batch } from "react-redux";

const filterData = async (arr, callback) => {
	const fail = Symbol();
	return (await Promise.all(arr.map(async (item) => ((await callback(item)) ? item : fail)))).filter((i) => i !== fail);
};

// * added comparison
export async function updateFacilityPercentageTotal(data, forComparison) {
	let facilityWiseTotal = _(data)
		.groupBy("facilityId")
		.sortBy((group) => data.indexOf(group[0]))
		.map((product) => {
			return {
				id: product[0]?.facilityId,
				total: product.length || 0,
			};
		})
		.value();

	if (forComparison) {
		const currentFacilityPercentage = store.getState().hospitalComparison.facilityPercentage;
		if (!_.isEqual(currentFacilityPercentage, facilityWiseTotal)) {
			store.dispatch(setFacilityPercentageComparison(facilityWiseTotal));
		}
	} else {
		const currentFacilityPercentage = store.getState().hospital.facilityPercentage;
		if (!_.isEqual(currentFacilityPercentage, facilityWiseTotal)) {
			store.dispatch(setFacilityPercentage(facilityWiseTotal));
		}
	}
}

// * added comparison
export async function updateFilterListData(cardFilter, transferType, patientList, priorityData = [], forComparison) {
	const {
		dbData,
		lockedTotal,
		lockedTotalBy = null,
	} = forComparison ? store.getState().hospitalComparison : store.getState().hospital;
	const { dynamicCards } = store.getState().hospital;
	let censusAverage;

	if (!forComparison) {
		censusAverage = await getDynamicPercentageBy(dbData);
	} else {
		censusAverage = await getPercentageByTotal(dbData, PAGE_TYPE.HOSPITAL)
	}
	const { list, ninetyDaysData, hospitalData: hospitalDBData } = patientList;
	let objectCustom = Object();
	let patientData = list;
	let lockedTotalModified = lockedTotal;
	let isComparingAgainstAvgCensus = true;
	let totalFilterData = {
		originalData: patientData,
		totalType: !forComparison ? transferType || priorityData?.length > 0 ? TOTAL_TYPE.FILTER : TOTAL_TYPE.MAIN : TOTAL_TYPE,
		totalForPercentage: censusAverage,
	};

	if (transferType === TYPES.PLANNED) {
		patientData = _.filter(patientData, {
			transferType: "plannedHospitalTransfer",
		});
	}
	if (transferType === TYPES.UNPLANNED) {
		patientData = _.filter(patientData, {
			transferType: "unplannedHospitalTransfer",
		});
	}

	let filter1 = Object();
	let newSavedFilters = [];
	let patientFilterData = patientData;
	let mainNumPercentage = censusAverage;

	if (transferType) {
		mainNumPercentage = null;
		totalFilterData.totalForPercentage = patientData.length;
		isComparingAgainstAvgCensus = false;
	}


	batch(async () => {
		if (lockedTotalBy) {
			if (
				lockedTotalBy === TYPES.PLANNED ||
				lockedTotalBy === TYPES.UNPLANNED ||
				lockedTotalBy === TYPES.ALL ||
				lockedTotalBy === "census"
			) {

				// if (lockedTotalModified && !transferType && lockedTotalBy !== "census") {
				if (lockedTotalModified && !transferType && lockedTotalBy !== "census") {
					forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
					forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
					lockedTotalModified = null;
				}
				if (!lockedTotalModified) {
					forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
					forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
					lockedTotalModified = null;
				}
			} else {
				const lockedFilterRemoved = _.find(priorityData, { type: lockedTotalBy });
				if (!lockedFilterRemoved) {
					forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
					forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
					lockedTotalModified = null;
				}
			}
		}

		if (lockedTotalModified) {
			totalFilterData.totalForPercentage = lockedTotalModified;
		}

		if (lockedTotalModified && priorityData?.length === 1 && !transferType) {
			totalFilterData.totalForPercentage = lockedTotalModified;
		}

		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setIsCensusTotalLockedComparison(false))
				: store.dispatch(setIsCensusTotalLocked(false));
		}
		if (priorityData?.length > 0) {
			isComparingAgainstAvgCensus = false;
		}

		if (
			(priorityData?.length > 0 && (!lockedTotalBy || !lockedTotal)) ||
			(priorityData?.length > 0 &&
				lockedTotalBy !== "census" &&
				lockedTotalBy !== TYPES.PLANNED &&
				lockedTotalBy !== TYPES.UNPLANNED &&
				lockedTotalBy !== TYPES.ALL)
		) {
			totalFilterData.totalForPercentage = null;
		}
		const newPayload = [];
		if (forComparison) {
			const currentFacilityPercentage = store.getState().hospitalComparison.facilityPercentage;
			if (!_.isEqual(currentFacilityPercentage, newPayload)) {
				store.dispatch(setFacilityPercentageComparison(newPayload));
			}
		} else {
			const currentFacilityPercentage = store.getState().hospital.facilityPercentage;
			if (!_.isEqual(currentFacilityPercentage, newPayload)) {
				store.dispatch(setFacilityPercentage(newPayload));
			}
		}
	});
	let dynamicCardsObj = {}; // 
	if (priorityData?.length > 0) {
		let i = 0;
		for await (const ele of priorityData) {
			i++;
			if (priorityData?.length === i && lockedTotalModified && !lockedTotalBy) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(ele.type))
					: store.dispatch(setLockedTotalBy(ele.type));
			}
			if (ele?.question?.isCustom) {
				patientFilterData = filterCustomPatientData(patientFilterData, cardFilter, ele);
			}
			if (ele.type === HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS) {
				objectCustom.hospitalizations = await hospitalizationData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				if (cardFilter.hospitalizations.length === 1) {
					filter1.reHospitalization = hospitalizationsFilter(cardFilter);
					if (!_.isEmpty(filter1)) {
						patientFilterData = _.filter(patientFilterData, filter1);
					}
				}
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.DCER_DATA) {
				objectCustom.DCERData = await dcErData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				if (cardFilter.DCERData.length === 1) {
					filter1.wasAdmitted = DCERDataFilter(cardFilter);
					if (!_.isEmpty(filter1)) {
						patientFilterData = _.filter(patientFilterData, filter1);
					}
				}
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.INSURANCE_DATA) {
				objectCustom.insuranceData = await insuranceData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ insuranceId }) => {
					return _.every([_.includes(cardFilter.insuranceData, insuranceId)]);
				});
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.RETURNS_DATA) {
				objectCustom.returnsData = await returnsData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				if (cardFilter.returnsData.length === 1) {
					filter1.wasReturned = returnsDataFilter(cardFilter);
					if (!_.isEmpty(filter1)) {
						patientFilterData = _.filter(patientFilterData, filter1);
					}
				}
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
				objectCustom.ninetyDaysData = await ninetyDaysDataList(ninetyDaysData, patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				if (i === priorityData?.length) {
					updateFacilityPercentageTotal(patientFilterData, forComparison);
				}
				patientFilterData = await ninetyDaysDataFilter(cardFilter.ninetyDaysData, patientFilterData, ninetyDaysData);
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.FLOORS_DATA) {
				objectCustom.floorsData = await floorsData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = await filterData(patientFilterData, ({ floorId }) =>
					_.every([_.includes(cardFilter.floorsData, floorId)])
				);
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.DOCTOR_DATA) {
				objectCustom.doctorData = await doctorData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				patientFilterData = await filterData(patientFilterData, ({ doctorId }) =>
					_.every([_.includes(cardFilter.doctorData, doctorId)])
				);
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.DAYS_DATA) {
				objectCustom.daysData = await daysData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				patientFilterData = _.filter(patientFilterData, ({ day }) => _.every([_.includes(cardFilter.daysData, day)]));
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.DX_DATA) {
				objectCustom.dxData = await dxData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				patientFilterData = _.filter(patientFilterData, ({ dxIds }) => {
					const matchedIds = _.intersection(cardFilter.dxData, dxIds);
					return matchedIds.length > 0 ? true : false;
				});
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.SHIFT_DATA) {
				objectCustom.shiftData = await shiftData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				patientFilterData = _.filter(patientFilterData, ({ shiftName }) =>
					_.every([_.includes(cardFilter.shiftData, shiftName)])
				);
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.NURSE_DATA) {
				objectCustom.nurseData = await nurseData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				patientFilterData = _.filter(patientFilterData, ({ nurseId }) =>
					_.every([_.includes(cardFilter.nurseData, nurseId)])
				);
			}

			if (ele.type === HOSPITAL_CARDS_TYPE.HOSPITAL_DATA) {
				objectCustom.hospitalData = await hospitalDataList(hospitalDBData, patientFilterData, {
					...totalFilterData,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				patientFilterData = await hospitalDataFilter(cardFilter.hospitalData, patientFilterData, hospitalDBData);
			}
			newSavedFilters = [...newSavedFilters, ele.type];
		}

		//UPdate remaining cards data
		const totalPercentageCount = lockedTotalModified ? lockedTotalModified : patientFilterData.length; //transferType ? patientFilterData?.length : censusAverage

		if (forComparison) {
			const currentFilterTotal = store.getState().hospitalComparison.filterTotal;
			if (!_.isEqual(currentFilterTotal ?? 0, totalPercentageCount ?? 0)) {
				store.dispatch(setFilterTotalComparison(totalPercentageCount));
			}
		} else {
			const currentFilterTotal = store.getState().hospital.filterTotal;
			if (!_.isEqual(currentFilterTotal ?? 0, totalPercentageCount ?? 0)) {
				store.dispatch(setFilterTotal(totalPercentageCount));
			}
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS)) {
			objectCustom.hospitalizations = await hospitalizationData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.DCER_DATA)) {
			objectCustom.DCERData = await dcErData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.INSURANCE_DATA)) {
			objectCustom.insuranceData = await insuranceData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}

		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.RETURNS_DATA)) {
			objectCustom.returnsData = await returnsData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA)) {
			objectCustom.ninetyDaysData = await ninetyDaysDataList(ninetyDaysData, patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.FLOORS_DATA)) {
			objectCustom.floorsData = await floorsData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.DOCTOR_DATA)) {
			objectCustom.doctorData = await doctorData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.DAYS_DATA)) {
			objectCustom.daysData = await daysData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.DX_DATA)) {
			objectCustom.dxData = await dxData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.SHIFT_DATA)) {
			objectCustom.shiftData = await shiftData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.NURSE_DATA)) {
			objectCustom.nurseData = await nurseData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, HOSPITAL_CARDS_TYPE.HOSPITAL_DATA)) {
			objectCustom.hospitalData = await hospitalDataList(hospitalDBData, patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (dynamicCards.length > 0) {
			for (const item of dynamicCards) {
				let objectCustomRes = {};
				if (!_.includes(newSavedFilters, item?.accessor)) {
					await processDynamicCard(item, patientFilterData, objectCustomRes, {
						...totalFilterData,
						totalType: TOTAL_TYPE.FILTER,
						totalForPercentage: totalPercentageCount,
					});
					if (!_.isEmpty(objectCustomRes)) {
						objectCustom[item?.accessor] = objectCustomRes?.[item?.accessor];
					}
				}
			}
		}

		if (!lockedTotalModified) {
			if (forComparison) {
				const currentLockedByFacility = store.getState().hospitalComparison.lockedByFacility;
				if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
					store.dispatch(setLockedByFacilityComparison(patientFilterData));
				}
			} else {
				const currentLockedByFacility = store.getState().hospital.lockedByFacility;
				if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
					store.dispatch(setLockedByFacility(patientFilterData));
				}
			}
		}
	} else {
		batch(async () => {
			const totalCount = mainNumPercentage ? mainNumPercentage : transferType ? patientFilterData.length : censusAverage;
			if (!transferType && lockedTotalModified) {
				var isCensusTotalLocked = true;
				if (forComparison) {
					const currentIsCensusTotalLocked = store.getState().hospitalComparison.isCensusTotalLocked;
					if (!_.isEqual(currentIsCensusTotalLocked, isCensusTotalLocked)) {
						store.dispatch(setIsCensusTotalLockedComparison(isCensusTotalLocked));
					}
				} else {
					const currentIsCensusTotalLocked = store.getState().hospital.isCensusTotalLocked;
					if (!_.isEqual(currentIsCensusTotalLocked, isCensusTotalLocked)) {
						store.dispatch(setIsCensusTotalLocked(isCensusTotalLocked));
					}
				}
			}
			if (lockedTotalModified && !lockedTotalBy) {
				if (transferType) {
					forComparison
						? store.dispatch(setLockedTotalByComparison(transferType))
						: store.dispatch(setLockedTotalBy(transferType));
				} else {
					forComparison
						? store.dispatch(setLockedTotalByComparison("census"))
						: store.dispatch(setLockedTotalBy("census"));
				}
			}
			if (!lockedTotalModified) {
				if (forComparison) {
					const currentLockedByFacility = store.getState().hospitalComparison.lockedByFacility;
					if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
						store.dispatch(setLockedByFacilityComparison(patientFilterData));
					}
				} else {
					const currentLockedByFacility = store.getState().hospital.lockedByFacility;
					if (!_.isEqual(currentLockedByFacility ?? [], patientFilterData ?? [])) {
						store.dispatch(setLockedByFacility(patientFilterData));
					}
				}
			}
			updateFacilityPercentageTotal(patientFilterData, forComparison);
			store.dispatch(
				forComparison
					? setFilterTotalComparison(lockedTotalModified ? lockedTotalModified : totalCount)
					: setFilterTotal(lockedTotalModified ? lockedTotalModified : totalCount)
			);
		});
		[
			objectCustom.hospitalizations,
			objectCustom.DCERData,
			objectCustom.insuranceData,
			objectCustom.returnsData,
			objectCustom.ninetyDaysData,
			objectCustom.floorsData,
			objectCustom.doctorData,
			objectCustom.daysData,
			objectCustom.dxData,
			objectCustom.shiftData,
			objectCustom.nurseData,
			objectCustom.hospitalData,
		] = await Promise.all([
			hospitalizationData(patientFilterData, totalFilterData),
			dcErData(patientFilterData, totalFilterData),
			insuranceData(patientFilterData, totalFilterData),
			returnsData(patientFilterData, totalFilterData),
			ninetyDaysDataList(ninetyDaysData, patientFilterData, totalFilterData),
			floorsData(patientFilterData, totalFilterData),
			doctorData(patientFilterData, totalFilterData),
			daysData(patientFilterData, totalFilterData),
			dxData(patientFilterData, totalFilterData),
			shiftData(patientFilterData, totalFilterData),
			nurseData(patientFilterData, totalFilterData),
			hospitalDataList(hospitalDBData, patientFilterData, totalFilterData),
		]);

		if (dynamicCards.length > 0) {
			dynamicCardsObj = await dynamicCardFilter(patientFilterData, dynamicCards, totalFilterData);
		}

		objectCustom = { ...objectCustom, ...dynamicCardsObj }
	}
	objectCustom.isComparingAgainstAvgCensus = isComparingAgainstAvgCensus;

	return objectCustom;
}

export async function checkMainArray(data, isFilterSelectedCnt) {
	return isFilterSelectedCnt === 1 && data?.length > 0;
}

export async function selectedFilterCount(oldFilter, type = null) {
    return Object.entries(oldFilter)
        .filter(([key, value]) => key !== type && value?.length > 0)
        .length;
}

export async function checkOnlyOneFilter(oldFilter, filterType = null, currentCardFilter = null, type = null) {
	const oldFiltersData = Object.assign({}, oldFilter);
	delete oldFiltersData["permission"];
	if (type === "DBCheck") {
		let isFilterSelectedCount = 0;
		for (const [key, value] of Object.entries(oldFiltersData)) {
			if (key !== filterType) {
				if (value.length > 0) {
					isFilterSelectedCount++;
				}
			}
		}
		if (isFilterSelectedCount > 0) {
			return false;
		} else {
			return oldFilter[filterType].length > 0 ? true : false;
		}
	} else {
		delete oldFiltersData[type];
		if (currentCardFilter && currentCardFilter.length === 0) {
			let isFilterSelectedCount = 0;
			for (const [key, value] of Object.entries(oldFiltersData)) {
				if (key !== filterType) {
					if (value.length > 0) {
						isFilterSelectedCount++;
					}
				}
			}
			return isFilterSelectedCount > 0 ? false : true;
		} else {
			return false;
		}
	}
}

function returnsDataFilter(oldFilter) {
	const value = oldFilter?.returnsData?.[0];
	return value === "Returned" ? true : value === "Didn't Return" ? false : undefined;
}

function hospitalizationsFilter(oldFilter) {
	const value = oldFilter?.hospitalizations?.[0];
	return value === "reHospitalizations" ? true : value === "newHospitalizations" ? false : undefined;
}

function DCERDataFilter(oldFilter) {
	const value = oldFilter?.DCERData?.[0];
	return value === "DC" ? true : value === "ER" ? false : undefined;
}

async function hospitalDataFilter(cardFilter, patientData, ninetyDaysData) {
	let ninetyDaysDataIds = [];
	let ninetyDaysDataFilter = _.filter(ninetyDaysData, ({ _id }) => _.every([_.includes(cardFilter, _id)]));
	if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
		ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
	}
	patientData = _.filter(patientData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
	return patientData;
}

async function ninetyDaysDataFilter(cardFilter, patientData, ninetyDaysData) {
	let ninetyDaysDataIds = [];
	let ninetyDaysDataFilter = _.filter(ninetyDaysData, ({ _id }) => _.every([_.includes(cardFilter, _id)]));
	if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
		ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
	}
	patientData = _.filter(patientData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
	return patientData;
}

const hospitalizationData = async (data, totalFilter) => {
	let newHospitalizationsTotal = 0;
	let reHospitalizationsTotal = 0;
	let newHospitalizationsIds = [];
	let reHospitalizationsIds = [];
	const { originalData = [], totalType = null, totalForPercentage } = totalFilter;

	const originalDataGroupBy = _.countBy(originalData, "reHospitalization");
	if (data && data.length > 0) {
		await filterData(data, async (ele) => {
			if (ele.reHospitalization) {
				reHospitalizationsTotal++;
				reHospitalizationsIds.push(ele.id);
			} else {
				newHospitalizationsTotal++;
				newHospitalizationsIds.push(ele.id);
			}
		});
	}

	let percentageTotal = totalForPercentage ? totalFilter.totalForPercentage : data?.length;

	const hospitalizationsDBTTotal = [
		{
			_id: "newHospitalizations",
			label: "New Hospitalizations",
			color: "#00BAEB",
			total: newHospitalizationsTotal,
			originalTotal: originalDataGroupBy?.[false],
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			ids: newHospitalizationsIds,
			percentage: itemPercentage(newHospitalizationsTotal, percentageTotal, "number"),
		},
		{
			_id: "reHospitalizations",
			label: "Re-Hospitalizations",
			color: "#5195DD",
			total: reHospitalizationsTotal,
			ids: reHospitalizationsIds,
			originalTotal: originalDataGroupBy?.[true],
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			percentage: itemPercentage(reHospitalizationsTotal, percentageTotal, "number"),
		},
	];
	return hospitalizationsDBTTotal;
};

async function dcErData(data, totalFilter) {
	if (!Array.isArray(data) || data.length === 0) {
		console.warn("Invalid or empty data array.");
		return [];
	}

	const { originalData = [], totalType = null, totalForPercentage } = totalFilter ?? {};

	// Ensure totalForPercentage is valid
	const percentageTotal = totalForPercentage || data.length;

	// Use countBy to group by "wasAdmitted" values
	const DCERResult = _.countBy(data, "wasAdmitted");
	const originalDataGroupBy = _.countBy(originalData, "wasAdmitted");

	// Common function to generate chart details
	const createChartDetail = (id, label, color, admitted) => ({
		id,
		label,
		_id: id,
		total: DCERResult[admitted] || 0,
		originalTotal: originalDataGroupBy[admitted] || 0,
		isTooltip: totalType !== TOTAL_TYPE.MAIN,
		color,
		percentage: itemPercentage(DCERResult[admitted], percentageTotal, "number"),
		isDefaultData: !DCERResult[admitted]
	});

	// Generate chart details
	return [
		createChartDetail("DC", "DC", "#606BDF", true),
		createChartDetail("ER", "ER", "#87BCFE", false)
	];
}

async function insuranceData(data, totalFilter) {
	//insuranceData Cal	
	let insuranceDBDataGroup = [];
	if (data && data.length > 0) {
		const insuranceDBData = _.groupBy(data, "insuranceId");
		const originalData = _.groupBy(totalFilter.originalData, "insuranceId");
		let percentageTotal = totalFilter.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (insuranceDBData) {
			insuranceDBDataGroup = await filterListDataItems(insuranceDBData, "insurance", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return insuranceDBDataGroup;
}

async function returnsData(data, totalFilter) {
	if (!data?.length) return [];

	const { originalData = [], totalType = null, totalForPercentage } = totalFilter;
	const percentageTotal = totalForPercentage || data.length;

	const dintReturnResult = _.countBy(data, "wasReturned");
	const originalDataGroupBy = _.countBy(originalData, "wasReturned");

	const createDetail = async (id, label, key) => ({
		id,
		label,
		_id: id,
		total: dintReturnResult[key] || 0,
		originalTotal: originalDataGroupBy[key] || 0,
		isTooltip: totalType !== TOTAL_TYPE.MAIN,
		percentage: await itemPercentage(dintReturnResult[key] || 0, percentageTotal, "number"),
	});

	const returnDidNotDetails = await Promise.all([
		createDetail("Returned", "Returned", "true"),
		createDetail("Didn't Return", "Didn't Return", "false")
	]);

	return returnDidNotDetails;
}

async function processData(data, totalFilter, groupByKey, itemType) {
	if (!data?.length) return [];

	const groupedData = _.groupBy(data, groupByKey);
	const originalData = _.groupBy(totalFilter?.originalData || [], groupByKey);
	const percentageTotal = totalFilter?.totalForPercentage || data.length;

	return groupedData
		? await filterListDataItems(groupedData, itemType, percentageTotal, {
			...totalFilter,
			originalData
		})
		: [];
}

// Nurse data handler
async function nurseData(data, totalFilter) {
	return await processData(data, totalFilter, "nurseId", "nurse");
}

// Doctor data handler
async function doctorData(data, totalFilter) {
	return await processData(data, totalFilter, "doctorId", "doctor");
}

// Floors data handler
async function floorsData(data, totalFilter) {
	return await processData(data, totalFilter, "floorId", "unit");
}

async function shiftData(data, totalFilter) {
	let totalData = data;
	const { originalData = [], totalType = null } = totalFilter;
	let percentageTotal = totalFilter.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

	const shiftDBData = _.countBy(totalData, "shiftName");
	const originalDataGroupBy = _.countBy(originalData, "shiftName");

	const shiftDataDefault = [
		{
			_id: "Morning",
			label: "Morning",
			value: shiftDBData?.Morning || 0,
			originalTotal: originalDataGroupBy?.Morning || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#636578",
			percentage: itemPercentage(shiftDBData?.Morning || 0, percentageTotal, "number"),
		},
		{
			_id: "Evening",
			label: "Evening",
			value: shiftDBData?.Evening || 0,
			originalTotal: originalDataGroupBy?.Evening || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#4879f5",
			percentage: itemPercentage(shiftDBData?.Evening || 0, percentageTotal, "number"),
		},
		{
			_id: "Night",
			label: "Night",
			value: shiftDBData?.Night || 0,
			originalTotal: originalDataGroupBy?.Night || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#636578",
			percentage: itemPercentage(shiftDBData?.Night || 0, percentageTotal, "number"),
		},
	];
	return shiftDataDefault;
}

async function daysData(data, totalFilter) {
	let totalData = data;
	const { originalData = [], totalType = null } = totalFilter;
	const originalDataGroupBy = _.countBy(originalData, "day");
	const dayDBData = _.countBy(totalData, "day");
	let percentageTotal = totalFilter.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

	const daysDataArr = [
		{
			_id: "Sun",
			label: "Sun",
			value: dayDBData?.Sun || 0,
			originalTotal: originalDataGroupBy?.Sun || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Sun || 0, percentageTotal, "number"),
		},
		{
			_id: "Mon",
			label: "Mon",
			value: dayDBData?.Mon || 0,
			originalTotal: originalDataGroupBy?.Mon || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Mon || 0, percentageTotal, "number"),
		},
		{
			_id: "Tue",
			label: "Tue",
			value: dayDBData?.Tue || 0,
			originalTotal: originalDataGroupBy?.Tue || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Tue || 0, percentageTotal, "number"),
		},
		{
			_id: "Wed",
			label: "Wed",
			value: dayDBData?.Wed || 0,
			originalTotal: originalDataGroupBy?.Wed || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Wed || 0, percentageTotal, "number"),
		},
		{
			_id: "Thu",
			label: "Thu",
			value: dayDBData?.Thu || 0,
			originalTotal: originalDataGroupBy?.Thu || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Thu || 0, percentageTotal, "number"),
		},
		{
			_id: "Fri",
			label: "Fri",
			value: dayDBData?.Fri || 0,
			originalTotal: originalDataGroupBy?.Fri || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Fri || 0, percentageTotal, "number"),
		},
		{
			_id: "Sat",
			label: "Sat",
			value: dayDBData?.Sat || 0,
			originalTotal: originalDataGroupBy?.Sat || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			color: "#FFECA6",
			percentage: itemPercentage(dayDBData?.Sat || 0, percentageTotal, "number"),
		},
	];

	return daysDataArr;
}

async function dxData(data, totalFilter) {
	let totalData = data;
	const { originalData = [], totalType = null, totalForPercentage } = totalFilter;

	let dxDataArr = [];
	let patientData = [];
	let patientOriginalData = [];
	let dxOriginalDataArr = [];
	let patientDxIds = [];
	if (totalData && totalData.length > 0) {
		// eslint-disable-next-line array-callback-return
		totalData.filter((ele) => {
			if (ele.dx && ele.dx.length > 0) {
				dxDataArr = [...ele.dx, ...dxDataArr];
				patientData.push(ele);
				patientDxIds = [...ele.dxIds, ...patientDxIds];
			}
		});

		originalData.filter((ele) => {
			if (ele.dx && ele.dx.length > 0) {
				dxOriginalDataArr = [...ele.dx, ...dxOriginalDataArr];
				patientOriginalData.push(ele);
				// patientDxIds = [...ele.dxIds, ...patientDxIds];
			}
		});
	}
	let dxDataDB = [];
	let percentageTotal = totalForPercentage ? totalForPercentage : data?.length;

	if (dxDataArr.length > 0) {
		const dxDataGroup = _.groupBy(dxDataArr, "_id");
		const dxOriginalDataGroup = _.groupBy(dxOriginalDataArr, "_id");
		if (dxDataGroup) {
			for (const [key, value] of Object.entries(dxDataGroup)) {
				const valueArr = value[0];
				if (key && valueArr) {
					let object = Object();
					object._id = key;
					object.id = key;
					object.label = valueArr.label;
					object.name = valueArr.label;
					object.total = value.length;
					let original = dxOriginalDataGroup[key] ? dxOriginalDataGroup[key]?.length : 0;
					object.originalTotal = original;
					object.isTooltip = totalType && totalType === TOTAL_TYPE.MAIN ? false : true;
					object.chartData = {};
					object.percentage = itemPercentage(value.length || 0, percentageTotal || 0, "number");
					dxDataDB.push(object);
				}
			}
		}
		dxDataDB = _.orderBy(dxDataDB, "total", "desc");
	}
	return dxDataDB;
}

export async function ninetyDaysDataList(data, patients, totalFilter) {
	const patientIds = patients?.length > 0 ? patients.map((item) => item.id) : [];
	let percentageTotal = totalFilter.totalForPercentage ? totalFilter.totalForPercentage : patients?.length;

	const res = await updateListTotalValue(data, patientIds, "value", percentageTotal, totalFilter);
	return res;
}

async function hospitalDataList(data, patients, totalFilter) {
	const { originalData = [], totalType = null } = totalFilter;

	const hospitalIdStrIds = patients?.length > 0 ? patients.map((item) => item.hospitalIdStr) : [];
	const patientIds = patients?.length > 0 ? patients.map((item) => item.id) : [];
	const patientIdsMain = originalData.length > 0 ? originalData.map((item) => item.id) : [];
	let patientData = _.filter(data, ({ _id }) => _.every([_.includes(hospitalIdStrIds, _id)]));

	let totalLength = totalFilter.totalForPercentage ? totalFilter.totalForPercentage : patients?.length;

	let newHospitalData = [];
	patientData.filter((ele) => {
		const intersection = matchedArray(patientIds, ele.ids);
		const intersectionMain = matchedArray(patientIdsMain, ele.ids);
		let patientDataDidNot = _.filter(patients, ({ id }) => _.every([_.includes(intersection, id)]));
		const wasReturnedCount = _.countBy(patientDataDidNot, "wasReturned");
		const wasAdmittedCount = _.countBy(patientDataDidNot, "wasAdmitted");
		const totalEle = _.find(ele.graphData, { id: "Total Transfer" });
		const chartArrData = [];
		chartArrData.push(
			{
				id: totalEle.id,
				data: [
					{
						x: totalEle?.id,
						y: intersection?.length || 0,
						color: "#15BDB2",
					},
				],
			},
			{
				id: "Total DC",
				data: [
					{
						x: "Total DC",
						y: wasAdmittedCount?.true || 0,
						color: "#076673",
					},
				],
			}
		);
		let original = intersectionMain.length || 0;
		let obj = {
			...ele,
			originalTotal: original,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			totalTransfer: intersection.length || 0,
			total: intersection.length || 0,
			totalDidNotReturn: wasReturnedCount.false || 0,
			totalDCCount: wasAdmittedCount?.true || 0,
			graphData: chartArrData,
			ids: intersection || [],
			percentage: itemPercentage(intersection.length || 0, totalLength, "number"),
		};
		newHospitalData.push(obj);
		return Object.assign({}, ele, obj);
	});
	newHospitalData = _.orderBy(newHospitalData, "totalTransfer", "desc");
	return newHospitalData;
}

export function matchedArray(array1, array2) {
	return _.intersectionWith(array1, array2, _.isEqual);
}
