import React from 'react';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';

const CircleTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: "50%",
        width: "200px",
        height: "50px",
        padding: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        "& input": {
            textAlign: "center",
            padding: "0",
            fontSize: "1rem",
            fontWeight: "bold",
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "&[type=number]": {
                "-moz-appearance": "textfield",
            },
        },
        "& fieldset": {
            borderWidth: "2px",
        },
    },
}));

const RangeInput = ({ range, index, handleMinChange, handleMaxChange, setAnchorEl }) => {
    return (
        <>
            <Box display="flex" alignItems="center">
                <CircleTextField
                    type="text"
                    label="Min"
                    variant="outlined"
                    value={range.min}
                    onChange={(e) => {
                        let value = e.target.value;

                        // If the first character is < or > and the user types a number, remove the symbol
                        if ((value.startsWith(">") || value.startsWith("<")) && value.length > 1) {
                            value = value.substring(1); // Remove first character
                        }

                        // Allow only <, >, or numbers
                        if (/^([<>]?\d*)?$/.test(value)) {
                            handleMinChange(index, value);
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={(event) => setAnchorEl({ index, type: 'min', element: event.currentTarget })}>
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box display="flex" alignItems="center">
                <CircleTextField
                    type="text"
                    label="Max"
                    variant="outlined"
                    value={range.max}
                    onChange={(e) => {
                        let value = e.target.value;
                        if ((value.startsWith(">") || value.startsWith("<")) && value.length > 1) {
                            value = value.substring(1);
                        }

                        // Allow only <, >, or numbers
                        if (/^([<>]?\d*)?$/.test(value)) {
                            handleMaxChange(index, value);
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={(event) => setAnchorEl({ index, type: 'max', element: event.currentTarget })}>
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </>
    );
};

export default RangeInput;