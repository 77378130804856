import update from "immutability-helper";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteSVG from "../../../../../assets/svgs/delete.svg";
import DragSVG from "../../../../../assets/svgs/drag.svg";
import EditSVG from "../../../../../assets/svgs/edit.svg";
import axios from "../../../../../axios";
import { getAllQuestions } from "../../../../../services/api/question.api";
import { isOnlyHospitalTabAccess } from "../../../../../utilis/common";
import AlertDialog from "../../../../shared/AlertDialog";
import Button from "../../../../ui/button/Button";
import DropDown from "../../../../ui/drop-down/DropDown";
import styles from "./QuestionSetup.module.scss";
import { updateQuestionLabel } from "../../../../../services/api/data-input.api";
import AddQuestionDialog from "./AddQuestion";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { AUTH_ROLES } from "../../../../../types/auth.type";
import useResetDynamicCards from "../../../../hooks/useResetDynamicCards";
import { QUESTION_INPUT_TYPE } from "../../../../../types/question.type";

const initialValues = {
	label: "",
	tableLabel: "",
	isCustom: false,
	customQuestionInputType: "",
	customQuestionOptions: [],
	numberRangeType: null
}

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: theme.spacing(2, 3),
}));

const QuestionSetup = ({ close }) => {
	const { permission } = useSelector(
		({ auth, account, facility, permission, }) => ({
			auth,
			account,
			facility,
			permission
		})
	);

	const isOnlyHospitalDashboard = useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);

	const transferOptions = useMemo(() => {
		if (!isOnlyHospitalDashboard) {
			return [
				{
					label: "Hospital Transfer",
					value: "hospitalTransfer",
				},
				{ label: "Safe Discharge", value: "safeDischarge" },
				{ label: "SNF", value: "SNF" },
				{ label: "AMA", value: "AMA" },
				{ label: "Deceased", value: "deceased" },
			];
		} else {
			return [
				{
					label: "Hospital Transfer",
					value: "hospitalTransfer",
				},
			];
		}
	}, [isOnlyHospitalDashboard]);

	const [list, setList] = useState(undefined);
	const [dragDisabled, setDragDisabled] = useState(false);
	const [showAddQ, setShowAddQ] = useState(false);
	const [confirmDialog, setConfirmDialog] = useState(false);
	const [type, setType] = useState("admission");
	const [transferType, setTransferType] = useState("hospitalTransfer");
	const [editQuestion, setEditQuestion] = useState(undefined);
	const [deleteIdx, setDeleteIdx] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [questionValues, setQuestionValues] = useState(initialValues);
	const resetDynamicCards = useResetDynamicCards();

	const resetQuestionValues = () => {
		setQuestionValues(initialValues)
	}

	useEffect(() => {
		if (isOnlyHospitalDashboard) {
			setType("transfer");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDragEnd = async (droppedItem, b) => {
		if (!droppedItem.destination) return;
		var updatedList = cloneDeep(list);
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

		let modifiedList = await updatedList.map((item, index) => {
			item.order.order = index;
			return item.order;
		});

		setDragDisabled(true);
		await axios.put(`/api/question/set/?id=${list[0]._id}`, modifiedList);
		setDragDisabled(false);
		setList(updatedList);
	};

	const getQuestions = async () => {
		const latestType = isOnlyHospitalDashboard && type !== "return" ? "transfer" : type;
		const questions = await getAllQuestions({
			facilityid: localStorage.getItem("facilityId"),
			forType: latestType,
			...(latestType === "transfer" && { forTransferType: transferType }),
		});
		// const questions = await axios.get(
		//   `/api/question/set/?forType=${type}&facilityid=${localStorage.getItem("facilityId")}${type === "transfer" ? `&forTransferType=${transferType}` : ""
		//   }`
		// );
		setList(questions);
	};

	const saveQuestion = async (isCustomQuestion) => {
		let newQuestion;
		setLoading(true);
		if (editQuestion?._id) {
			const latestType = isOnlyHospitalDashboard && type !== "return" ? "transfer" : type;

			let updatedQ = await updateQuestionLabel(
				{
					label: questionValues.label,
					tableLabel: questionValues.tableLabel,
					forType: latestType,
					...(isCustomQuestion && { customData: questionValues }),
					...(latestType === "transfer" && { forTransferType: transferType }),
				},
				editQuestion._id
			);

			let indexAAA = list.findIndex(item => item.question._id === editQuestion._id);

			update(list, {
				[indexAAA]: {
					question: {
						$set: updatedQ.data,
					},
				},
			});
			resetDynamicCards();
			getQuestions();
			setLoading(false);
			setEditQuestion(undefined);
			resetQuestionValues()
			setShowAddQ(false);
		} else {
			newQuestion = await axios.post("/api/question/set", {
				facilityId: localStorage.getItem("facilityId"),
				label: questionValues.label,
				tableLabel: questionValues?.tableLabel,
				forType: type,
				forTransferType: transferType,
				active: true,
				type: "text",
				accessor: questionValues?.label?.replace(/\s/g, ""),
				allowAfter: true,
				isEditable: true,
				accountId: localStorage.getItem("accountId"),
				customData: questionValues,
			});
			let modifiedList = await list.map((item, index) => {
				item.order.order = index;
				return item.order;
			});

			const newOrder = await axios.put(`/api/question/set/?id=${list[0]._id}`, [
				...modifiedList,
				{ order: list.length, questionId: newQuestion.data._id },
			]);
			resetDynamicCards();
			getQuestions(newOrder.data);
			setLoading(false);
			resetQuestionValues()
			setShowAddQ(false);
		}
	};

	useEffect(() => {
		getQuestions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getQuestions();
		// eslint-disable-next-line
	}, [type, transferType]);

	const deleteQuestion = async (id, idx) => {
		await axios.delete(`/api/question/set/?id=${id}`);

		// remove deleted question from list
		let updatedList = update(list, {
			$splice: [[idx, 1]],
		});

		let modifiedList = await updatedList.map((item, index) => {
			item.order.order = index;
			return item.order;
		});

		const newOrder = await axios.put(`/api/question/set/?id=${list[0]._id}`, modifiedList);
		resetDynamicCards();
		getQuestions(newOrder.data);
	};

	const handleClose = () => {
		close();
		setEditQuestion(undefined);
	};

	const handleCustomQuestion = () => {
		if (permission && permission.role === (AUTH_ROLES.SUPER || permission.role === AUTH_ROLES.ADMIN)) {
			if (editQuestion) {
				return editQuestion.isCustom ? editQuestion.isCustom : false
			} else {
				return true
			}
		} else {
			return false;
		}
	}

	return (
		<>
			{!!confirmDialog && (
				<AlertDialog
					content="New Question will be display for all account facility."
					handleClose={() => setConfirmDialog(false)}
					handleSubmit={() => {
						setConfirmDialog(false);
						saveQuestion(false);
					}}
				/>
			)}
			<Dialog open={true} maxWidth="xl" fullWidth={true}>
				<StyledDialogTitle>
					{editQuestion?._id ? 'Edit Question' : 'Add Question'}
					<IconButton aria-label="close" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</StyledDialogTitle>
				<Divider />
				<DialogContent>
					{showAddQ && (
						<AddQuestionDialog
							open={showAddQ}
							onClose={() => setShowAddQ(false)}
							onSave={saveQuestion}
							editQuestion={editQuestion}
							loading={loading}
							setEditQuestion={setEditQuestion}
							setQuestionValues={setQuestionValues}
							questionValues={questionValues}
							setConfirmDialog={setConfirmDialog}
							resetQuestionValues={resetQuestionValues}
							isCustomQuestion={handleCustomQuestion()}
							latestType={type}
							transferType={transferType}
						/>
					)}
					<div className={styles.questionSetup}>
						<div className={styles.content}>
							<div className={`df aic ffmar fs15 fw500 p-r-20 p-l-20 ${styles.tabsWrpr}`}>
								{!isOnlyHospitalDashboard && (
									<>
										<div
											className={`df acc ${styles.tab} ${type === "admission" ? styles.active : ""}`}
											onClick={() => setType("admission")}
										>
											Admissions
										</div>
										{/* <div
									className={`df acc ${styles.tab} ${type === "readmission" ? styles.active : ""}`}
									onClick={() => setType("readmission")}
								>
									Readmissions
								</div> */}
									</>
								)}
								<div
									className={`df acc ${styles.tab} ${type === "return" ? styles.active : ""}`}
									onClick={() => setType("return")}
								>
									Returns
								</div>
								<div
									className={`df acc ${styles.tab} ${type === "transfer" ? styles.active : ""}`}
									onClick={() => setType("transfer")}
								>
									Transfers
								</div>
								{type === "transfer" ? (
									<DropDown
										options={transferOptions}
										selected={transferType}
										setOption={setTransferType}
										width={`275`}
										size={`medium`}
									/>
								) : undefined}

								<div className={`mla`}>
									<Button text="Add Question" buttonStyle="theme" size="medium" action={() => {
										setLoading(false);
										resetQuestionValues();
										setShowAddQ(!showAddQ)
									}} />
								</div>
							</div>
							<p
								style={{
									padding: "14px 22px 10px",
									fontStyle: "italic",
								}}
							>
								Click and drag questions to adjust the question order
							</p>
							{list && (
								<div className={styles.questionList}>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="droppable">
											{(provided, snapshot) => (
												<div {...provided.droppableProps} ref={provided.innerRef}>
													{list.map((itm, idx) => (
														<Draggable
															isDragDisabled={dragDisabled}
															draggableId={`id-${idx}`}
															key={`${idx}-${idx}`}
															index={idx}
														>
															{(provided, snapshot) => (
																<p
																	className={`ffmsb df aic ${styles.line} ${snapshot.isDragging ? styles.dragging : ""}`}
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<span className={styles.iconWrpr}>
																		<DragSVG />
																	</span>
																	<span className={styles.num}>{idx + 1}.</span> {itm.order?.label || itm.question.label}
																	{provided.placeholder}
																	<div className={`df aic mla`}>
																		<button
																			onClick={() => {
																				setQuestionValues({
																					label: itm.order?.label || itm.question.label,
																					tableLabel: itm.order?.tableLabel || itm.question.tableLabel,
																					customQuestionInputType: itm.question?.customQuestionInputType,
																					customQuestionOptions: itm.question?.customQuestionOptions,
																					numberRangeType: itm.question?.customQuestionInputType === QUESTION_INPUT_TYPE.NUMBER_RANGE ? itm.question?.numberRangeType : null
																				})
																				setEditQuestion(itm.question);
																				setShowAddQ(true);
																			}}
																			className={`m-r-15 df acc ${styles.action}`}
																		>
																			<EditSVG />
																		</button>
																		{itm.question.isEditable && (
																			<>
																				<button
																					className={`df acc ${styles.action}`}
																					onClick={() => {
																						if (!itm.question.isEditable) return;
																						setDeleteIdx(idx);
																					}}
																				>
																					<DeleteSVG />
																				</button>
																				{deleteIdx === idx && (
																					<div className={`df aic ${styles.confirmDelete}`}>
																						<button
																							className={`m-r-10`}
																							onClick={() => {
																								setDeleteIdx(undefined);
																							}}
																						>
																							Cancel
																						</button>
																						<button
																							className={styles.confirmDeleteBtn}
																							onClick={() => {
																								deleteQuestion(itm.question._id, idx);
																								setDeleteIdx(undefined);
																							}}
																						>
																							Confirm
																						</button>
																					</div>
																				)}
																			</>
																		)}
																	</div>
																</p>
															)}
														</Draggable>
													))}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							)}
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default QuestionSetup;
