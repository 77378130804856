import { CO_TRANSFER_CARDS_TYPE, COMMUNITY_CARD_LABELS, TRANSFER_TYPE } from "../../../types/community-transfer.type";
import HeaderFilter from "../../shared/dashboard-header/HeaderFilter";
import { useSelector } from "react-redux";
import useHeaderCustomLabelsItem from "../../hooks/useHeaderCustomLabelsItem";

export const headerItemsData = [
    { label: COMMUNITY_CARD_LABELS.totalMain, type: "label", dataKey: TRANSFER_TYPE.ALL, isMain: true, isCommunity: true, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.AMA, type: "label", dataKey: TRANSFER_TYPE.AMA, isMain: true, isCommunity: true, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.SNF, type: "label", dataKey: TRANSFER_TYPE.SNF, isMain: true, isCommunity: true, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.safeDischarge, type: "label", dataKey: TRANSFER_TYPE.SAFE_DISCHARGE, isMain: true, isCommunity: true, isDefault: true },

    { label: COMMUNITY_CARD_LABELS.insuranceData, dataKey: CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.sixtyDaysData, dataKey: CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.returnsData, dataKey: CO_TRANSFER_CARDS_TYPE.RETURNS_DATA, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.doctorData, dataKey: CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA, isDefault: true },
    { label: COMMUNITY_CARD_LABELS.floorsData, dataKey: CO_TRANSFER_CARDS_TYPE.FLOORS_DATA, isDefault: true },
    { label: "Assisted Living", dataKey: CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA, isDefault: true },
    { label: "SNF Facility", dataKey: CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA },
];

const CommunityHeaderFilterList = ({
    filterListData,
    cardFilter,
    transferType,
    percentageBy,
    lockedTotal,
    lockedTotalBy,
    openFrom = "default", // "detailView"
}) => {
    const { dynamicCards } = useSelector((state) => state.communityTransfer);
    const headerItemsDataArray = useHeaderCustomLabelsItem(cardFilter, dynamicCards, headerItemsData);

    return <HeaderFilter
        items={headerItemsDataArray}
        filterListData={filterListData}
        cardFilter={cardFilter}
        transferType={transferType}
        percentageBy={percentageBy}
        lockedTotal={lockedTotal}
        lockedTotalBy={lockedTotalBy}
        openFrom={openFrom}
    />
};

export default CommunityHeaderFilterList;