import { Paper } from "@mui/material";
import { OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays, getPositivePlusSign, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./FloorCardList.module.scss";
import FloorCardListSkeleton from "./FloorCardListSkeleton";
import { useMemo } from "react";
import useLoadingToggle from "../../../hooks/useLoadingToggle";
import { Box } from "@mui/system";
import CheckboxLoader from "../../../shared/checkbox-loader/CheckboxLoader";

const FloorCardList = ({
	data,
	dataComparison,
	filter,
	filterComparison,
	handleToggle,
	selectedItem = [],
	type,
	page,
	isPercentageShow = true,
	averageCensusComparison,
	averageCensus,
	cardTitle,
	reverseColors,
	reverseColorsAdmissionPage,
	comparingAgainstDifferentFacility,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	loading,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const isFirstItemInPriorityData = priorityNumber === 1;
	const isPlusSign = useMemo(() => {
		return page === PAGE_TYPE.OVERALL && transferType === OVERALL_PAGE_SUB_TYPE.TOTAL;
	}, [page, transferType]);
	const { loadingItems, handleToggleWithLoader } = useLoadingToggle();

	return (
		<Paper style={{ minHeight: 350, height: 350, overflow: "auto", width: "100%" }}>
			<div className={`${styles.floor} `}>
				{loading ? (
					<FloorCardListSkeleton />
				) : (
					data.length > 0 &&
					data
						.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
						.map((item, index) => {
							const ID = item._id;
							const labelId = `checkbox-list-floor-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							const itemComparison = dataComparison?.find((x) => x._id === item._id);

							const percentage = item.percentage ? item.percentage : itemPercentage(item.total, null, "number", page);

							let comparisonColor;
							let comparingAgainstScaled;
							let numberOfDays;

							if (page === PAGE_TYPE.ADMISSION) {
								const itemTotal = itemPercentage(item.total, admissionCompareAgainst, "number");

								const itemTotalComparison = itemPercentage(
									itemComparison?.total,
									admissionCompareAgainstComparison,
									"number"
								);
								const {
									percentageDiff,
									itemTotalComparisonScaled,
									numberOfDays: numberOfDaysTemp,
								} = calculateDiffBetweenPercentages(
									itemTotal,
									itemTotalComparison,
									reverseColorsAdmissionPage,
									filter,
									filterComparison,
									itemComparison?.total,
									projectionDays
								);

								numberOfDays = numberOfDaysTemp;
								comparingAgainstScaled = itemTotalComparisonScaled;
								comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
							} else {
								const percentageOfAverageCensus = itemPercentage(item.total, averageCensus, "number");

								const percentageOfAverageCensusComparison = itemPercentage(
									itemComparison?.total || 0,
									averageCensusComparison,
									"number"
								);
								const {
									percentageDiff,
									itemTotalComparisonScaled,
									numberOfDays: numberOfDaysTemp,
								} = calculateDiffBetweenPercentages(
									percentageOfAverageCensus,
									percentageOfAverageCensusComparison,
									reverseColors,
									filter,
									filterComparison,
									itemComparison?.total,
									projectionDays
								);

								numberOfDays = numberOfDaysTemp;
								comparingAgainstScaled = itemTotalComparisonScaled;
								comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
							}

							if (comparingAgainstDifferentFacility) {
								comparisonColor = null;
							}

							return (
								<div className={`df aic m-b-10 ${styles.line} ${selected ? styles.selected : null}`} key={index}>
									<div className={`df fs15 fw500 ${styles.sec} ${styles.left}`} htmlFor={labelId}>
										<Box position="relative" display="inline-flex" alignItems="center">
											<CheckboxButton
												className={styles.checkboxButton}
												labelId={labelId}
												handleToggle={(e) => handleToggleWithLoader(() => handleToggle({ item, itemComparison, type, isChecked: selected, cardTitle }), item?._id)}
												checked={selectedItem.indexOf(ID) !== -1}
												buttonText={item.label}
												item={item}
												sx={{
													mr: "-2px",
													...(loadingItems[item?._id] && { opacity: 0 })
												}}
											/>
											<CheckboxLoader
												className={"small-dots-left"}
												isLoading={loadingItems[item?._id]}
											/>
										</Box>
									</div>

									<div className={`df acc ${styles.sec}`}>
										{!loading && (
											<ColorBox
												color={comparisonColor}
												sx={{ width: "9px", height: "18px", mr: "3px" }}
												comparingAgainst={itemComparison?.total}
												comparingAgainstScaled={isPlusSign ? getPositivePlusSign(comparingAgainstScaled) : comparingAgainstScaled}
												numberOfDays={numberOfDays}
											/>
										)}
										<span className={`ffmar-bold fs16`}>
											{calcProPercentsBasedOnFilterAndDays(item.total, { ...filter, transferType }, projectionDays)}
										</span>
										{isPercentageShow && (
											<span className={`ffint fw400 fs14 m-l-4`}>{`(${calcProPercentsBasedOnFilterAndDays(
												percentage,
												{ ...filter, transferType },
												projectionDays,
												true,
												isComparingAgainstAvgCensus ||
												lockedTotalBy === "census" ||
												(isFirstItemInPriorityData && (!transferType || transferType?.length === 0))
											)})%`}</span>
										)}
									</div>
								</div>
							);
						})
				)}
				<NoRecordFound data={data} />
			</div>
		</Paper>
	);
};

export default FloorCardList;
