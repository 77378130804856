import { Box, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import styles from "./ColorBoxes.module.scss";
import { useMemo } from "react";
import _ from "lodash";
import { useDashboard } from "../../../context/DashboardContext";
import { isOnlyHospitalTabAccess } from "../../../utilis/common";


const DateDifference = ({ comparisonRanges }) => {
	if (comparisonRanges && comparisonRanges.length > 0) {
		const { startDate, endDate } = comparisonRanges[0];

		// Validate startDate and endDate before processing
		if (startDate && endDate) {
			const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
			const formattedEndDate = moment(endDate).format("MM/DD/YYYY");
			const daysDifference = moment(endDate).diff(moment(startDate), "days");

			return (
				<Typography variant="caption" fontWeight="normal" color={"#fff"} sx={{
					fontSize: "11px",
				}}>
					{`${formattedStartDate} - ${formattedEndDate} (${daysDifference} days)`}
				</Typography>
			);
		}
	}

	return null // Fallback when no valid data
};

const TooltipTitle = ({
	comparingAgainst,
	comparingAgainstScaled,
	numberOfDays,
	comparisonRanges,
	matchingNames,
	comparingAgainstDifferentFacility
}) => {
	const { dashboardData: { dbData = null, dbDataComparison = null } } = useDashboard();
	const isOnlyHospitalDashboard = useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);

	const comparingAgainstScaledByCensus = useMemo(() => {
		if (
			comparingAgainstScaled &&
			dbDataComparison &&
			!_.isEmpty(dbDataComparison) &&
			comparingAgainstDifferentFacility &&
			dbData?.censusAverage
		) {
			const currentCensus = dbDataComparison.censusAverage / dbData.censusAverage;
			return Math.round(comparingAgainstScaled / currentCensus) || 0;
		}		
		return comparingAgainstScaled;
	}, [comparingAgainstScaled, dbDataComparison, dbData, comparingAgainstDifferentFacility]);


	return (
		<div className={styles.tooltipWrapper}>
			<div>
				<div>
					{matchingNames && (
						<Typography
							variant="body1"
							fontWeight="bold"
							color={"#fff"}
							sx={{
								fontSize: "11px"
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: matchingNames }} />
						</Typography>
					)}
					<Typography
						variant="body1"
						sx={{
							textDecoration: "underline",
							fontSize: "11px",
						}}
						fontWeight="bold"
						color={"#fff"}>
						Comparison Date :
					</Typography>

					<DateDifference
						comparisonRanges={comparisonRanges}
					/>
					<Typography
						variant="body2"
						fontWeight="bold"
						color={"#fff"}
						sx={{
							fontSize: "14px"
						}}
					>
						{comparingAgainst || 0} Transfers
					</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "2px",
						}}
					>
						<Typography
							fontWeight="normal"
							color={"#fff"}
							sx={{
								fontSize: "10px",
								fontStyle: "italic",
							}}
						>
							{dbData && !_.isEmpty(dbData) && comparingAgainstDifferentFacility ? (
								isOnlyHospitalDashboard ? (
									<>
										{numberOfDays || 0} Day Projection / Bed Capacity - {dbData?.bedCapacity || 0} :
									</>
								) : (
									<>
										{numberOfDays || 0} Day Projection / Avg. Census - {dbData?.censusAverage || 0} :
									</>
								)
							) : (
								<>
									{`${numberOfDays || 0} Day Projection:`}
								</>
							)}
						</Typography>
						{!(dbData && !_.isEmpty(dbData) && comparingAgainstDifferentFacility) && (
							<Typography
								variant="caption"
								fontWeight="bold"
								sx={{
									fontSize: "12px",
									fontStyle: "italic",
									color: "#fff",  // Moved color to sx for consistency
								}}
							>
								{`${comparingAgainstScaled || 0} Transfers`}
							</Typography>
						)}
					</Box>
					{dbData && !_.isEmpty(dbData) && comparingAgainstDifferentFacility && (
						<Typography
							variant="body2"
							fontWeight="bold"
							sx={{
								fontSize: "13px",
								fontStyle: "italic",
							}}
							color={"#fff"}
						>
							{comparingAgainstScaledByCensus || 0} Transfers
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
};

export default function ColorBox({
	color,
	type = "div",
	barWidth,
	bar = null,
	sx,
	comparingAgainst,
	comparingAgainstScaled,
	numberOfDays,
}) {
	const {
		rangesSet,
		comparisonRanges,
		activeComparisonFacilitiesId,
		facilityByAccount,
		comparingAgainstDifferentFacility
	} = useSelector((state) => state.comparison);

	const matchingNames = useMemo(() => {
		const facilityNames = facilityByAccount
			.filter(({ _id }) => activeComparisonFacilitiesId.includes(_id))
			.map(({ name }) => name)
			.join(", ");

		const label = activeComparisonFacilitiesId.length === 1 ? "Facility Compared To" : "Facilities Compared To";

		return `<u>${label} :</u> <br /> ${facilityNames}`;
	}, [facilityByAccount, activeComparisonFacilitiesId]);

	if (color && rangesSet) {
		switch (type) {
			case "button":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
								matchingNames={comparingAgainstDifferentFacility && rangesSet && matchingNames ? matchingNames : null}
								comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
							/>
						}
						arrow
						PopperProps={{
							sx: {
								'& .MuiTooltip-tooltip': {
									maxWidth: '300px' // Set your desired width
								}
							}
						}}
					>
						<Box
							component={"div"}
							sx={{
								background: color,
								width: "9px",
								height: "18px",
								borderRadius: "2px",
								position: "absolute",
								left: "3px",
								...sx,
							}}
						/>
					</Tooltip>
				);
			case "div":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
								matchingNames={comparingAgainstDifferentFacility && rangesSet && matchingNames ? matchingNames : null}
								comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
							/>
						}
						arrow
						PopperProps={{
							sx: {
								'& .MuiTooltip-tooltip': {
									maxWidth: '400px' // Set your desired width
								}
							}
						}}
					>
						<Box
							component={"div"}
							sx={{
								display: "inline-block",
								background: color,
								width: "11px",
								height: "27px",
								borderRadius: "2px",
								ml: 0.3,
								...sx,
							}}
						></Box>
					</Tooltip>
				);

			case "bar":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
								matchingNames={comparingAgainstDifferentFacility && rangesSet && matchingNames ? matchingNames : null}
								comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
							/>
						}
						arrow
						PopperProps={{
							sx: {
								'& .MuiTooltip-tooltip': {
									maxWidth: '300px' // Set your desired width
								}
							}
						}}
					>
						<rect
							width="9px"
							height="18px"
							fill={color}
							y={7}
							x={barWidth / 2 - 35}
						//transform={`translate(${barWidthCount},7)`}
						/>
					</Tooltip>
				);

			case "dayBar":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
								matchingNames={comparingAgainstDifferentFacility && rangesSet && matchingNames ? matchingNames : null}
								comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
							/>
						}
						arrow
						PopperProps={{
							sx: {
								'& .MuiTooltip-tooltip': {
									maxWidth: '300px' // Set your desired width
								}
							}
						}}
					>
						<rect width="24px" height="9px" x={bar.width / 4} y={bar.height / 2 + 10} fill={color} />
					</Tooltip>
				);
			case "list":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
								matchingNames={comparingAgainstDifferentFacility && rangesSet && matchingNames ? matchingNames : null}
								comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								display: "inline-block",
								background: color,
								width: "9px",
								height: "18px",
								borderRadius: "2px",
								...sx,
							}}
						/>
					</Tooltip>
				);

			default:
				return null;
		}
	}
	return null;
}
